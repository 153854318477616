import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import axios from "axios";
import Vital2 from "./Vital2";
import PastHistory2 from "./PastHistory2";
import Treatment2 from "./TreatmentComponent2";
import Prescription2 from "./Prescription2";
import Procedure2 from "./Procedure2";
import { useSelector } from "react-redux";
import bgImg2 from "../assets/bgImg2.jpg";

function Navigation2() {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const therapydata = useSelector((state) => state.userRecord?.therapydata);

  const [activeTab, setActiveTab] = useState("vitalForm2");
  const [isToggled, setIsToggled] = useState(false);

  const [formData, setFormData] = useState({
    SerialNo: "",
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    firstName: "",
    lastName: "",
    AppointmentDate: "",
    Complaint: "",
    PatientPhoto: "",
    DoctorName: "",
    Age: "",
    Gender: "",
    Location: "",
  });
  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (!event.target.closest(".new-kit")) {
        closeToggle();
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  });

  useEffect(() => {
    // Function to fetch data using Axios
    const fetchData = async () => {
      try {
        // Define the data to send in the POST request

        // Send a POST request to your PHP endpoint
        const response = await axios.get(
          `${urllink}appointmentmanagement/get_appointments_Navigation?patientid=${therapydata?.serialid}&location=${userRecord.location}`
        );

        const matchingAppointment = response.data[0];

        setFormData({
          SerialNo: matchingAppointment.appointment_patientregisterID,
          PatientID: matchingAppointment.PatientID,
          AppointmentID: matchingAppointment.AppointmentID,
          visitNo: matchingAppointment.VisitID,
          firstName: matchingAppointment.FirstName,
          lastName: matchingAppointment.LastName,
          DoctorName: matchingAppointment.DoctorName,
          Status: matchingAppointment.Status,
          AppointmentDate: matchingAppointment.AppointmentDate,
          Complaint: matchingAppointment.Complaint,
          PatientPhoto: `data:image/jpeg;base64,${matchingAppointment.PatientPhoto}`,
          Age: matchingAppointment.Age,
          Gender: matchingAppointment.Gender,
          Location: matchingAppointment.Location,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    };

    //   // Call the fetchData function when the component mounts
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Navigation</title>
      </Helmet>
      <div className="new-patient-registration-form">
        <br />
        <div className="dctr_info_up_head">
          <div className="RegisFormcon ">
            <div className="dctr_info_up_head22">
              {formData.PatientPhoto ? (
                <img src={formData.PatientPhoto} alt="Patient Photo" />
              ) : (
                <img src={bgImg2} alt="Default Patient Photo" />
              )}
              <label>Profile</label>
            </div>
          </div>

          <div className="RegisFormcon">
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient Name <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.firstName + " " + formData.lastName}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient ID <span>:</span>
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.PatientID}{" "}
              </span>
            </div>

            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Age <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.Age}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Gender <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.Gender}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Primary Doctor <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.DoctorName}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Location <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {formData.Location}{" "}
              </span>
            </div>
          </div>
        </div>

        <br />
        <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange("vitalForm2")}>
                Vital Form
              </button>
              |
              <button onClick={() => handleTabChange("pastHistory2")}>
                Past History
              </button>
              |
              <button onClick={() => handleTabChange("Treatment2")}>
                Treatment
              </button>
              |
              <button onClick={() => handleTabChange("Prescription2")}>
                Prescription
              </button>
              |
              <button onClick={() => handleTabChange("Procedure2")}>
                Procedure
              </button>
              {/* Add buttons for other tabs */}
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                  <h2>
                    <button onClick={() => handleTabChange("vitalForm2")}>
                      Vital Form
                    </button>
                    |
                    <button onClick={() => handleTabChange("pastHistory2")}>
                      Past History
                    </button>
                    |
                    <button onClick={() => handleTabChange("Treatment2")}>
                      Treatment
                    </button>
                    |
                    <button onClick={() => handleTabChange("Prescription2")}>
                      Prescription
                    </button>
                    |
                    <button onClick={() => handleTabChange("Procedure2")}>
                      Procedure
                    </button>
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTab === "vitalForm2" && (
        <Vital2 formData={formData} userRecord={userRecord} />
      )}
      {activeTab === "pastHistory2" && (
        <PastHistory2 formData={formData} userRecord={userRecord} />
      )}
      {activeTab === "Treatment2" && (
        <Treatment2
          formData={formData}
          userRecord={userRecord}
          isSidebarOpen={isSidebarOpen}
        />
      )}
      {activeTab === "Prescription2" && (
        <Prescription2 formData={formData} userRecord={userRecord} />
      )}
      {activeTab === "Procedure2" && (
        <Procedure2
          formData={formData}
          userRecord={userRecord}
          isSidebarOpen={isSidebarOpen}
        />
      )}
    </>
  );
}

export default Navigation2;
