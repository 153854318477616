import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";
import "jspdf-autotable";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid var(--ProjectColor) !important",
        },
      },
    },
  },
});

const PurchaseRegister = () => {
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");

  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [SearchformData, setFormData] = useState({
    Location: "",
    ProductType: "",
    SupplierCode: "",
    SupplierName: "",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });

  const [Supplierdetailes, setSupplierdetailes] = useState([]);
  const [Location, setLocation] = useState([]);

  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  useEffect(() => {
    axios
      .get(
        "https://vesoftometic.in/cds/quickstockreceive/get_supplier_Master_Name"
      )
      .then((response) => {
        const data = response.data.filter((ele) => {
          return (
            ele.SupplierType === SearchformData.ProductType &&
            ele.Status === "Active"
          );
        });

        setSupplierdetailes(data);
      })
      .catch((error) => {
        console.error("Error fetching patients data:", error);
      });
  }, [SearchformData.ProductType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      if (value === "Current") {
        setFormData({
          ...SearchformData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      } else {
        setFormData({
          ...SearchformData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else if (name === "SupplierCode") {
      let sup_n = Supplierdetailes.find((ele) => ele.SupplierCode === value);
      if (sup_n) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          SupplierName: sup_n.SupplierName,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          SupplierName: "", // Set default value or handle accordingly
        }));
      }
    } else if (name === "SupplierName") {
      let sup_c = Supplierdetailes.find((ele) => ele.SupplierName === value);
      if (sup_c) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          SupplierCode: sup_c.SupplierCode,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          SupplierCode: "", // Set default value or handle accordingly
        }));
      }
    } else {
      setFormData({
        ...SearchformData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setFormData((prev) => ({
      ...prev,
      date: formattedDate,
    }));
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data.map((p) => p.location_name);

        setLocation([...data, "CentralStore"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const dynamicColumns = [
    { field: "id", headerName: "S.NO", width: 50 },
    { field: "Location", headerName: "Branch Name", width: 130 },
    { field: "GRN_InvoiceNo", headerName: "GRN_InvoiceNo", width: 100 },
    { field: "GRN_Date", headerName: "GRN Date", width: 100 },
    { field: "Supplier_Bill_No", headerName: "Supplier Bill No", width: 150 },
    {
      field: "Supplier_Bill_Date",
      headerName: "Supplier Bill Date",
      width: 150,
    },
    { field: "ProductType", headerName: "ProductType", width: 150 },
    { field: "Supplier_Code", headerName: "Supplier Code", width: 130 },
    { field: "Supplier_Name", headerName: "Supplier Name", width: 150 },
    { field: "SupplierGST", headerName: "Supplier GST No", width: 130 },
    { field: "TotalAmountWithoutTax", headerName: "TaxableValue", width: 130 },
    { field: "SGST 9.0", headerName: "CGST 9", width: 130 },
    { field: "CGST 9.0", headerName: "SGST 9", width: 130 },
    { field: "IGST 18.0", headerName: "IGST 18", width: 130 },
    { field: "SGST 6.0", headerName: "CGST 6", width: 130 },
    { field: "CGST 6.0", headerName: "SGST 6", width: 130 },
    { field: "IGST 12.0", headerName: "IGST 12", width: 130 },
    { field: "SGST 2.5", headerName: "CGST 2.5", width: 130 },
    { field: "CGST 2.5", headerName: "SGST 2.5", width: 130 },
    { field: "IGST 5.0", headerName: "IGST 5", width: 130 },
    { field: "TotalTaxAmount", headerName: "Total Tax Amount", width: 130 },
  ];

  const handletoSearch = () => {
    const params = {
      Location: SearchformData.Location,
      ProductType: SearchformData.ProductType,
      SupplierCode: SearchformData.SupplierCode,
      SupplierName: SearchformData.SupplierName,
      DateType: SearchformData.DateType,
      CurrentDate: SearchformData.CurrentDate,
      FromDate: SearchformData.FromDate,
      ToDate: SearchformData.ToDate,
    };

    let requiredFields = [];
    if (SearchformData.DateType === "Customize") {
      requiredFields = [
        "Location",
        "ProductType",
        "DateType",
        "FromDate",
        "ToDate",
      ];
    } else {
      requiredFields = ["Location", "ProductType", "DateType"];
    }
    const existingItem = requiredFields.filter(
      (field) => !SearchformData[field]
    );
    if (existingItem.length > 0) {
      alert(`Please Fill the RequiredFields : ${existingItem.join(",")} `);
    } else {
      axios
        .get(
          `https://vesoftometic.in/cds/quickstockreceive/getPurchaseRegisterDetailsAll`,
          { params }
        )
        .then((response) => {
          const A_data = response.data;
          const B_data = A_data.map((p, index) => ({
            id: index + 1,
            ...p,
          }));
          setFilteredRows(B_data);
          setFormData({
            Location: "",
            ProductType: "",
            SupplierCode: "",
            SupplierName: "",
            DateType: "",
            CurrentDate: "",
            FromDate: "",
            ToDate: "",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleExportToExcel = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { dataKey: "id", header: "S.NO" },
        { dataKey: "Location", header: "Branch Name" },
        { dataKey: "GRN_InvoiceNo", header: "GRN_InvoiceNo" },
        { dataKey: "GRN_Date", header: "GRN Date" },
        { dataKey: "Supplier_Bill_No", header: "Supplier Bill No" },
        { dataKey: "Supplier_Bill_Date", header: "Supplier Bill Date" },
        { dataKey: "ProductType", header: "ProductType" },
        { dataKey: "Supplier_Code", header: "Supplier Code" },
        { dataKey: "Supplier_Name", header: "Supplier Name" },
        { dataKey: "SupplierGST", header: "Supplier GST No" },
        { dataKey: "TotalAmountWithoutTax", header: "TaxableValue" },
        { dataKey: "SGST 9.0", header: "CGST 9" },
        { dataKey: "CGST 9.0", header: "SGST 9" },
        { dataKey: "IGST 18.0", header: "IGST 18" },
        { dataKey: "SGST 6.0", header: "CGST 6" },
        { dataKey: "CGST 6.0", header: "SGST 6" },
        { dataKey: "IGST 12.0", header: "IGST 12" },
        { dataKey: "SGST 2.5", header: "CGST 2.5" },
        { dataKey: "CGST 2.5", header: "SGST 2.5" },
        { dataKey: "IGST 5.0", header: "IGST 5" },
        { dataKey: "TotalTaxAmount", header: "Total Tax Amount" },
      ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...filteredRows.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, "PurchaseRegisterReport.csv");
    } else {
      alert("No Data to Save");
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Purchase Register Report</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="">
            Location <span>:</span>
          </label>
          <select
            name="Location"
            value={SearchformData.Location}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {Location.map((p) => (
              <option key={p} value={p}>
                {p.toUpperCase()}
              </option>
            ))}
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Product Type <span>:</span>
          </label>

          <select
            name="ProductType"
            value={SearchformData.ProductType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Medical">Medical</option>
            <option value="Non_Medical">Non Medical</option>
            <option value="Assets">Assets</option>
            <option value="Stationary">Stationary</option>
            <option value="Non_Stationary">Non Stationary</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Supplier Code <span>:</span>
          </label>
          <input
            type="text"
            name="SupplierCode"
            list="SupplierCode_list"
            value={SearchformData.SupplierCode}
            onChange={handleChange}
            autoComplete="off"
          />
          <datalist id="SupplierCode_list">
            {Supplierdetailes.map((item, index) => (
              <option key={index} value={item.SupplierCode}></option>
            ))}
          </datalist>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Supplier Name<span>:</span>
          </label>
          <input
            type="text"
            name="SupplierName"
            list="SupplierName_list"
            value={SearchformData.SupplierName}
            onChange={handleChange}
            autoComplete="off"
          />

          <datalist id="SupplierName_list">
            {Supplierdetailes.map((item, index) => (
              <option key={index} value={item.SupplierName}></option>
            ))}
          </datalist>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Date Type <span>:</span>
          </label>
          <select
            name="DateType"
            value={SearchformData.DateType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Current">Current Date</option>
            <option value="Customize">Customize</option>
          </select>
        </div>
        {SearchformData.DateType === "Current" && (
          <div className="RegisForm_1">
            <label htmlFor="">
              Current Date <span>:</span>
            </label>
            <input
              type="date"
              name="CurrentDate"
              value={SearchformData.CurrentDate}
              onChange={handleChange}
            />
          </div>
        )}
        {SearchformData.DateType === "Customize" && (
          <div className="RegisForm_1">
            <label htmlFor="">
              From Date <span>:</span>
            </label>
            <input
              type="date"
              name="FromDate"
              value={SearchformData.FromDate}
              onChange={handleChange}
            />
          </div>
        )}

        {SearchformData.DateType === "Customize" && (
          <div className="RegisForm_1">
            <label htmlFor="">
              To Date <span>:</span>
            </label>
            <input
              type="date"
              name="ToDate"
              value={SearchformData.ToDate}
              onChange={handleChange}
            />
          </div>
        )}
      </div>

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handletoSearch}>
          Search
        </button>
      </div>

      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="IP_grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      {filteredRows.length !== 0 && (
        <div className="PrintExelPdf">
          <button onClick={handleExportToExcel}>Save Exel</button>
        </div>
      )}
    </div>
  );
};

export default PurchaseRegister;
