import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./LabMaster.css";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import LabPreview from "./Labpreview";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";

const LabMaster = () => {
  const [allTestName, setAllTestName] = useState([]);
  const [checkedTests, setCheckedTests] = useState({});
  const [defaultDate, setDefaultDate] = useState(new Date().toISOString().split("T")[0]);
  const [labtestdata, setLabtestdata] = useState([]);
  const [lab_patient_name, setlab_patient_name] = useState("");
  const [responsepreview, setresponsepreview] = useState("Not Saved");
  const [openModal2, setOpenModal2] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  
  const DataGnesis = useSelector((state) => state.userRecord?.DataGnesis);
  console.log(userRecord);
  const [packagetestname, setPackageTestname] = useState([]);
  const formData = useSelector((state) => state.userRecord?.workbenchformData);

  const visitNo = formData?.visitNo;
  const [packagename, setPackageName] = useState("");
  console.log(DataGnesis);
  const tableHeadRef = useRef(null); // Ref for the table head element

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getforlabdoc?packagename=${packagename}`)
      .then((response) => {
        setAllTestName(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink,packagename]);


  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getallpackages`)
      .then((response) => {
        console.log(response.data)
        setPackageTestname(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [urllink]);


  const handleCheckboxChange = (testName) => {
    setCheckedTests((prevCheckedTests) => ({
      ...prevCheckedTests,
      [testName]: !prevCheckedTests[testName],
    }));

    if (checkedTests[testName]) {
      setLabtestdata((prevLabtestdata) =>
        prevLabtestdata.filter((test) => test !== testName)
      );
    } else {
      setLabtestdata((prevLabtestdata) => [...prevLabtestdata, testName]);
    }

    // Scroll to table head when checkbox is clicked
    if (tableHeadRef.current) {
      tableHeadRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const test_name_submit = () => {
    const formData = new FormData();
    formData.append("lab_Patient_ID", DataGnesis?.patientID);
    formData.append("lab_patient_name", DataGnesis.PatientName);
    formData.append("Date", defaultDate);
    formData.append("visitNo", visitNo);
    formData.append("location", userRecord?.location);
    formData.append("Test_namelist", JSON.stringify(labtestdata));

    axios
      .post(`${urllink}labtest/Post_Passiont_lab_detailes`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setresponsepreview(response.data.message);
        successMsg(response.data.message);
      })
      .catch((error) => {
        console.error(error);
        errmsg("Error Occurred");
      });
  };


  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handlePreviewClick = () => {
    if (responsepreview === "Saved Successfully") {
      openModal();
    } else {
      alert("Please save the test name before previewing.");
    }
  };

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
    setOpenModal2(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div className="form-container22">
        <h4 style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>Lab Service</h4>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="lastName">
              Patient ID <span>:</span>
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              required
              value={DataGnesis?.patientID || ""}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="firstName">
              Patient Name <span>:</span>
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={DataGnesis.PatientName}
              required
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="lastName">
              Date <span>:</span>
            </label>
            <input
              type="Date"
              id="lastName"
              name="lastName"
              value={defaultDate}
              onChange={(e) => setDefaultDate(e.target.value)}
              required
            />
          </div>

          <div className="RegisForm_1 input-with-icon">
            <label htmlFor="packagename">
              Package Name <span>:</span>
            </label>
            <div className="input-container">
              <input
                list="packagenameOptions"
                id="packagename"
                name="packagename"
                value={packagename}
                onChange={(e) => setPackageName(e.target.value)}
              />
              <datalist id="packagenameOptions">
                {packagetestname.map((pack, index) => (
                  <option key={index} value={pack.packname} />
                ))}
              </datalist>
            </div>
          </div>

          
    
        </div>
        <br />
        <div className="Add_items_Purchase_Master">
          <span>Add Test Name</span>
        </div>
        <div className="Check_box_testname">
          {allTestName.map((test, index) => (
            <div className="Check_box_testname_sub" key={index}>
              <div className="Check_box_testname_sub_con">
                <label htmlFor={test.testname}>
                  {test.testname} <span>:</span>
                </label>
                <input
                  type="checkbox"
                  checked={!!checkedTests[test.testname]}
                  onChange={() => handleCheckboxChange(test.testname)}
                />
              </div>
            </div>
          ))}
        </div>
        {labtestdata.length === 0 ? (
          ""
        ) : (
          <>
            <div ref={tableHeadRef} className="Selected-table-container RateCard_table">
              <table className="selected-medicine-table2 tablewidth">
                <thead>
                  <tr>
                    <th id="vital_Twidth">S No</th>
                    <th id="vital_Twidth">Name</th>
                  </tr>
                </thead>
                <tbody>
                  {labtestdata.map((ele, ind) => (
                    <tr key={"mytbl" + ind}>
                      <td>{ind + 1}</td>
                      <td>{ele}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="Savebtn_class">
              <button
                className="btncon_add prs-ad-sub-btn"
                onClick={test_name_submit}
              >
                Save
              </button>
             
            </div>
          </>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default LabMaster;
