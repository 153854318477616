import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../TreatmentRecords/PatientList.css";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import {useDispatch,useSelector} from "react-redux";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function PatientListupdate() {
  const navigate = useNavigate();

  const dispatchvalue = useDispatch();

  const userRecord=useSelector(state=>state.userRecord?.UserData);
  const [patientscount, setpatientscount] = useState([]);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);

 

  const [columns] = React.useState([
    { field: "id", headerName: "Patient ID", width: 120 },
    {
      field: "Patientphoto",
      headerName: "Patient Photo",
      width: 150,
      renderCell: (params) => (
        <img
          src={`data:image/jpeg;base64,${params.value}`}
          alt="patient"
          style={{ width: "40px", height: "40px", borderRadius: "50%" , border:'2px solid var(--selectbackgroundcolor)' ,objectFit:'cover' }}
        />
      ),
    },
    { field: "firstName", headerName: "First Name", width: 120 },
    { field: "Gender", headerName: "Gender", width: 120 },
    { field: "phone", headerName: "Phone No", width: 120 },
    { field: "location", headerName: "Branch Location", width: 120 },

    { field: "email", headerName: "Email", width: 200 },
    {
      field: "actions ",
      headerName: "View",
      width: 120,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },

    {
      field: "Edit ",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleEditList(params)}>
            <EditIcon />
          </Button>
        </>
      ),
    },
  ]);

  const [PatientData, setPatientData] = React.useState([]);
  // const [selectedRows, setSelectedRows] = React.useState([]);

  const filteredData1 = userRecord.Patient_id
    ? PatientData.filter((row) => row.id === userRecord.Patient_id)
    : PatientData;

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");

  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleList = (params) => {
    // Extract the id from the selected row
    const selectedRowId = params.row.id;

    axios
      .get(`${urllink}patientmanagement/get_personal_info`)
      .then((response) => {
        const data1 = response.data;
        const foundPatient = data1.find(
          (patient) => patient.PatientID === selectedRowId
        );

        axios
          .get(`${urllink}patientmanagement/get_communication_address`)
          .then((response) => {
            const data2 = response.data;
            const patient_address = data2.find(
              (patient) => patient.PatientID === selectedRowId
            );

            axios
              .get(
                `${urllink}doctorsworkbench/get_vitalform1?selectedRowId=${selectedRowId}`
              )
              .then((response) => {
                const data3 = response.data;
                // const vital_data = data3.find(patient => patient.PatientID === selectedRowId);
                const vital = data3[0];

                axios
                  .get(
                    `${urllink}doctorsworkbench/get_treatment_forprofile?selectedRowId=${selectedRowId}`
                  )
                  .then((response) => {
                    const data4 = response.data;

                    const firstimge = data4.first_image;
                    const lastimg = data4.last_image;

                    axios
                      .get(
                        `${urllink}doctorsworkbench/get_patientphoto?selectedRowId=${selectedRowId}`
                      )
                      .then((response) => {
                        const data5 = response.data;

                        const PatientPhoto = data5.PatientPhoto;

                        axios
                          .get(`${urllink}doctorsworkbench/get_treatment`)
                          .then((response) => {
                            const data6 = response.data;
                            const filteredData = data6.filter(
                              (row) => selectedRowId === data5.PatientID
                            );

                            const history = filteredData.History;

                            axios
                              .get(
                                `${urllink}doctorsworkbench/get_emergencydetails_info1?selectedRowId=${selectedRowId}`
                              )
                              .then((response) => {
                                const data7 = response.data;

                                const EmergencyName = data7.Name;

                                axios
                                  .get(
                                    `${urllink}doctorsworkbench/get_allergiesname_forprofile?selectedRowId=${selectedRowId}`
                                  )
                                  .then((response) => {
                                    const data8 = response.data;

                                    const allergies = data8.allergies;

                                    axios
                                      .get(
                                        `${urllink}doctorsworkbench/get_previousvisit?selectedRowId=${selectedRowId}`
                                      )
                                      .then((response) => {
                                        const data9 = response.data;

                                        const previousvisit =
                                          data9.previousvisit;

                                        axios
                                          .get(
                                            `${urllink}doctorsworkbench/get_next_visit?selectedRowId=${selectedRowId}`
                                          )
                                          .then((response) => {
                                            const data10 = response.data;

                                            const Next_Appointment =
                                              data10.Next_Appointment;

                                            axios
                                              .get(
                                                `${urllink}doctorsworkbench/get_diseasename?selectedRowId=${selectedRowId}`
                                              )
                                              .then((response) => {
                                                const data11 = response.data;

                                                axios
                                                  .get(
                                                    `${urllink}doctorsworkbench/get_allimages_for_patients?selectedRowId=${selectedRowId}`
                                                  )
                                                  .then((response) => {
                                                    const data12 =
                                                      response.data;

                                                    const patientAction = (
                                                      data
                                                    ) => ({
                                                      type: "forPatientData",
                                                      value: data,
                                                    });

                                                    dispatchvalue(
                                                      patientAction({
                                                        // ...prevInfo,
                                                        ...foundPatient,
                                                        ...patient_address,
                                                        ...vital,
                                                        ...firstimge,
                                                        ...lastimg,
                                                        ...PatientPhoto,
                                                        ...history,
                                                        ...EmergencyName,
                                                        ...allergies,
                                                        ...previousvisit,
                                                        ...Next_Appointment,
                                                        ...data11,
                                                        ...data12,
                                                      })
                                                    );
                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });

    navigate("/Home/PatientProfile");
  };

  const handleEditList = (params) => {
    const patientid = params.row.id;

    axios
      .get(
        `${urllink}patientmanagement/forpatienteedit?patientid=${patientid}`
      )
      .then((response) => {
        dispatchvalue({ type: "forpatienteedit", value: response.data });

        navigate("/Home/Register-Patient2");
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}patientmanagement/getpatientscount?location=${userRecord?.location}`
      )
      .then((response) => {
        setpatientscount(response.data);
      })
      .catch((error) => {
      });
  }, [userRecord?.location]);

  const fetchPatientData = () => {
    // Fetch patient data from the backend
    fetch(`${urllink}patientmanagement/get_personal_info`)
      .then((response) => response.json())
      .then((data) => {

        if (data && Array.isArray(data)) {
          // Assuming data is an array, adjust this condition based on the actual structure
          // const lastTenRecords = data.slice(-10);
          const rows = data.map((userdata) => ({
            id: userdata.PatientID,
            Patientphoto: userdata.Photo,
            firstName: userdata.FirstName,
            Gender: userdata.Gender,
            BloodGroup: userdata.BloodGroup,
            phone: userdata.PhoneNumber,
            location: userdata.location,
            email: userdata.Email || "-",
          }));
          setPatientData(rows);
        } else {
        }
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    // Fetch and set Patient data when the component mounts
    fetchPatientData();
  }, []);


  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    } else if (id === "gender") {
      setSearchQuery2(value);
    }
  };

  useEffect(() => {
    const filteredData = PatientData.filter((row) => {
      const lowerCaseSupplierName = row.firstName.toLowerCase();
      const lowerCasePhoneNo = row.phone.toString();
      const lowerCaseGender = row.Gender.toLowerCase();

      const matchesFirstName = lowerCaseSupplierName.includes(
        searchQuery.toLowerCase()
      );
      const matchesPhoneNo = lowerCasePhoneNo.includes(
        searchQuery1.toLowerCase()
      );
      const matchesGender = lowerCaseGender === searchQuery2.toLowerCase();

      return (
        (matchesFirstName || !searchQuery) &&
        (matchesPhoneNo || !searchQuery1) &&
        (matchesGender || !searchQuery2)
      );
    });

    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, PatientData, searchQuery2]);

  return (
    <>
      <Helmet>
        <title>Patient List</title>
      </Helmet>
      <div className="appointment">
        <div className="h_head">
          <h4>Patient List</h4>
        </div>

        <form>
          <div className="con_1 ">
            {/* <div className="chart_body_1_main_1_container"> */}
            <div className="chart_body_1_child_1 dww3">
              <div className="chart_body_1_child_1_body">
                <div className="chart_body_1_child_1_body_icon">
                  <PersonAddAlt1Icon />
                </div>
                <div className="chart_body_1_child_1_body_count">
                  <h3>{patientscount[0]?.todaypatients ?? "0"}</h3>
                  {/* <h3>20</h3> */}
                </div>
                <div className="chart_body_1_child_1_body_name">
                  Today Patients
                </div>
              </div>
            </div>
            <div className="chart_body_1_child_1 dww3">
              <div className="chart_body_1_child_1_body">
                <div className="chart_body_1_child_1_body_icon">
                  <PersonIcon />
                </div>
                <div className="chart_body_1_child_1_body_count">
                  <h3>{patientscount[1]?.monthpatients ?? "0"}</h3>
                  {/* <h3>30</h3> */}
                </div>
                <div className="chart_body_1_child_1_body_name">
                  Monthly Patients
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="chart_body_1_child_1_body">
              <div className="chart_body_1_child_1 dww3">
                <div className="chart_body_1_child_1_body">
                  <div className="chart_body_1_child_1_body_icon">
                    <Diversity1Icon />
                  </div>
                  <div className="chart_body_1_child_1_body_count">
                    <h3>{patientscount[2]?.yearpatients ?? "0"}</h3>
                    {/* <h3>50</h3> */}
                  </div>
                  <div className="chart_body_1_child_1_body_name">
                    Yearly Patients
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                First Name <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Phone No <span>:</span>
              </label>
              <input
                type="number"
                id="PhoneNo"
                value={searchQuery1}
                onChange={handleSearchChange}
                placeholder="Enter the Phone No"
              />
            </div>
          </div>
          <div className="con_1 ">
            <div className="inp_1 RegisForm_1">
              <label htmlFor="select">
                Gender <span>:</span>
              </label>
              <select
                id="gender"
                value={searchQuery2}
                onChange={handleSearchChange}
                placeholder="Select Gender"
                className="ee5rt"
                // className="gender-options-gender"
              >
                <option value="">Select </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="transgender">Transgender</option>
              </select>
            </div>
          </div>
        </form>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )}
                columns={columns}
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>

          {filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
