import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";

function VisitingDoctorRegister() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [formDetails, setformDetails] = useState({
    doctorName: "",
    phoneNumber: "",
    email: "",
    Designation: "",
    location: "",
    specialist: "",
    experience: "",
    srnumber: "",
    mcinumber: "",
    sharepercentage: "",
    percraftcharge: "",
    medicaldegreecertificate: null,
    Signature: null,
  });

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;

    if (/^\d{0,10}$/.test(inputPhoneNumber)) {
      setformDetails({ ...formDetails, phoneNumber: inputPhoneNumber });
    } else {
      alert("Phone Number must contain 10 digits");
    }
  };

  const handleSubmit = (e) => {
    if (formDetails.Signature) {
      const form = new FormData();

      for (const key in formDetails) {
        if (formDetails.hasOwnProperty(key)) {
          form.append(key, formDetails[key]);
        }
      }
      form.append("branchlocation", userRecord?.location);
      form.append("createdby", userRecord?.username);

      e.preventDefault();

      axios
        .post(`${urllink}VisitingDoctor/insert_VisitingDoctor_Info`, form)
        .then((response) => {
          successMsg("Saved Successfully");
          setformDetails({
            doctorName: "",
            phoneNumber: "",
            email: "",
            Designation: "",
            location: "",
            specialist: "",
            experience: "",
            srnumber: "",
            mcinumber: "",
            sharepercentage: "",
            percraftcharge: "",
            medicaldegreecertificate: null,
            Signature: null,
          });
        })
        .catch((error) => {
          errmsg("Error occured");
        });
    } else {
      alert("Choose Dotor Signature");
    }
  };

  const handleChange12 = (e) => {
    const { name, value, files } = e.target;

    if (name === "medicaldegreecertificate" && files.length > 0) {
      // Access the file object itself
      const fileObject = e.target.files[0];

      setformDetails((prevData) => ({
        ...prevData,
        [name]: fileObject,
      }));
    } else if (name === "Signature" && files.length > 0) {
      // Access the file object itself
      const fileObject = e.target.files[0];

      setformDetails((prevData) => ({
        ...prevData,
        [name]: fileObject,
      }));
    } else {
      setformDetails((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Visiting Doctor Register</h4>
        </div>
        <br />

        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label>
              Visit Doctor <span>:</span>
            </label>
            <input
              type="text"
              name="doctorName"
              value={formDetails.doctorName}
              onChange={(e) =>
                setformDetails({ ...formDetails, doctorName: e.target.value })
              }
              required
            ></input>
          </div>
          <div className="RegisForm_1">
            <label>
              Phone Number <span>:</span>
            </label>
            <input
              type="text"
              name="phoneNumber"
              value={formDetails.phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Email <span>:</span>
            </label>
            <input
              type="text"
              name="email"
              value={formDetails.email}
              onChange={(e) =>
                setformDetails({ ...formDetails, email: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Designation <span>:</span>
            </label>
            <input
              type="text"
              name="Designation"
              value={formDetails.Designation}
              onChange={(e) =>
                setformDetails({ ...formDetails, Designation: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Specialist <span>:</span>
            </label>
            <input
              type="text"
              name="specialist"
              value={formDetails.specialist}
              onChange={(e) =>
                setformDetails({ ...formDetails, specialist: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Experience Yrs <span>:</span>
            </label>
            <input
              type="text"
              name="experience"
              value={formDetails.experience}
              onChange={(e) =>
                setformDetails({ ...formDetails, experience: e.target.value })
              }
              required
            />
          </div>

          <div class="RegisForm_1">
            <label>
              State Registration Number <span>:</span>
            </label>
            <input
              type="text"
              name="srnumber"
              value={formDetails.srnumber}
              onChange={(e) =>
                setformDetails({ ...formDetails, srnumber: e.target.value })
              }
              required
            />
          </div>
          <div class="RegisForm_1">
            <label>
              MCI Number <span>:</span>
            </label>
            <input
              type="text"
              name="mcinumber"
              value={formDetails.mcinumber}
              onChange={(e) =>
                setformDetails({ ...formDetails, mcinumber: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Share Percentage <span>:</span>
            </label>
            <input
              type="text"
              name="sharepercentage"
              value={formDetails.sharepercentage}
              onChange={(e) =>
                setformDetails({
                  ...formDetails,
                  sharepercentage: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Per Craft Charge <span>:</span>
            </label>
            <input
              type="text"
              name="percraftcharge"
              value={formDetails.percraftcharge}
              onChange={(e) =>
                setformDetails({
                  ...formDetails,
                  percraftcharge: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Location <span>:</span>
            </label>
            <input
              type="text"
              name="location"
              value={formDetails.location}
              onChange={(e) =>
                setformDetails({ ...formDetails, location: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Medical Degree Certificate <span>:</span>
            </label>
            <div className="RegisterForm_2">
              <input
                type="file"
                id="medicaldegreecertificate"
                name="medicaldegreecertificate"
                accept="*"
                className="hiden-nochse-file"
                onChange={handleChange12}
                required
              />
              <label
                htmlFor="medicaldegreecertificate"
                className="RegisterForm_1_btns choose_file_update"
              >
                Choose File
              </label>
            </div>
          </div>
          <div className="RegisForm_1">
            <label>
              Signature <span>:</span>
            </label>
            <div className="RegisterForm_2">
              <input
                type="file"
                id="Signature"
                name="Signature"
                accept="image/*"
                className="hiden-nochse-file"
                onChange={handleChange12}
                required
              />
              <label
                htmlFor="Signature"
                className="RegisterForm_1_btns choose_file_update"
              >
                Choose File
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <ToastContainer />
      </div>
    </>
  );
}

export default VisitingDoctorRegister;
