import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import LoupeIcon from "@mui/icons-material/Loupe";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import "../../Stock/Neww.css";
import Button from "@mui/material/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const GeneralBillingQueList = () => {
  
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const dispatchvalue = useDispatch();

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [summa, setsumma] = useState([]);
  
  const [Rowdata, setRowdata] = useState([]);  
  const [selectedShow, setSelectedShow] = useState(false);

  
  const [page1, setPage1] = useState(0);
  const showdown1 = Rowdata.length;
  const pageSize1 = 10;
  const totalPages1 = Math.ceil(Rowdata.length / 10);

  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  
  const handleRequestEdit = (params) => {
    
    const index = params.row.items_data;

    console.log("index:", index);

    let B_data = index.map((p, index) => ({
      id: index + 1,
      ...p,
    }));
    setRowdata(B_data);
    setSelectedShow(true);
  };

  const Gotobillingpage =()=>{
    navigate("/Home/Billing-Invoice-All");
  }

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
 
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    //  console.log(userRecord);
     if(userRecord && userRecord?.location){
      axios
      .get(
        `${urllink}GeneralBilling/get_billing_and_items?location=${userRecord?.location}`
      )
      .then((response) => {
        const Datas = response.data;
        console.log("data",Datas);
        setsumma(
          Datas.map((row,indx) => ({
            id: indx+1,
            ...row,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
     }
     
   
  }, [userRecord?.location]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.Invoice_NO.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  // Define the columns dynamically
  const dynamicColumns = [
    { field: "id", headerName: "S.No", width:80 },
    { field: "Billing_date", headerName: "Billing Date", width: 150 },
    { field: "PatientID", headerName: "Patient Id", width: 150 },
    { field: "Patient_Name", headerName: "Patient Name", width: 200 },
    { field: "Invoice_NO", headerName: "Invoice NO", width: 150 },
    { field: "PatientType", headerName: "Patient Type", width: 120 },
    { field: "Net_Amount", headerName: "Net Amount", width: 150 },
    { field: "Paid_Amount", headerName: "Paid Amount", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestEdit(params)}
          >
             <VisibilityIcon />
          </Button>
        </>
      ),
    },
  ];


  const dynamicColumns1 = [
    { field: "id", headerName: "S.No", width: 150 },
    { field: "Service_Type", headerName: "Service Type", width: 150 },
    { field: "ProcedureName", headerName: "Procedure Name", width: 200 },
    { field: "Taxable_Amount", headerName: "Taxable Amount", width: 120 },
    { field: "Gstpercent", headerName: "GST", width: 120 },
    { field: "Total_Amount", headerName: "Total Amount", width: 120 }
  ];
  

  return (
    <>
      
      <div className="appointment">
        <div className="h_head">
          <h4>General Billing List</h4>
         
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Patient Name <span>:</span>
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Item Name"
            />
          </div>
          <button className="btn_1" type="submit" onClick={Gotobillingpage} >
          <LoupeIcon />
          </button>
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns}
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}   {selectedShow && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow(false);
              }}
            >
              <div
                className="newwProfiles"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>Item List</h3>
                  </div>
                  <div className="con_1 "></div>
                  <div className="IP_grid">
                    <ThemeProvider theme={theme}>
                      <div className="IP_grid_1">
                        <DataGrid
                          rows={Rowdata.slice(
                            page1 * pageSize1,
                            (page1 + 1) * pageSize1
                          )} 
                          columns={ dynamicColumns1 } 
                          pageSize={10}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          onPageChange={handlePageChange1}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          className="data_grid"
                        />
                        {showdown1 > 0 && filteredRows.length > 10 && (
                          <div className="IP_grid_foot">
                            <button
                              onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                              }
                              disabled={page === 0}
                            >
                              Previous
                            </button>
                            Page {page1 + 1} of {totalPages1}
                            <button
                              onClick={() =>
                                setPage((prevPage) =>
                                  Math.min(prevPage + 1, totalPages1 - 1)
                                )
                              }
                              disabled={page1 === totalPages1 - 1}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </ThemeProvider>
                    {showdown1 !== 0 && filteredRows.length !== 0 ? (
                      ""
                    ) : (
                      <div className="IP_norecords">
                        <span>No Records Found</span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default GeneralBillingQueList;
