import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./PatientProfile.css";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DvrIcon from "@mui/icons-material/Dvr";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BiotechIcon from "@mui/icons-material/Biotech";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import PaymentIcon from "@mui/icons-material/Payment";

import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import userRecorddata from "../DataStore/UserrecordStore";
import { useSelector } from "react-redux";
import bgImg2 from "../assets/profileimg.jpeg";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const PatientProfile = () => {
  const patientData = useSelector((state) => state.userRecord?.forPatientData);

  console.log('lkllklkl',patientData);

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const [modalContent, setModalContent] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const data = patientData.disease_names || [];

  const allimages = patientData.all_images || [];
  const [openModal, setOpenModal] = useState(false);
  const [openModal3, setOpenModal3] = React.useState(false);
  const [openModal4, setOpenModal4] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImage5, setSelectedImage5] = React.useState(null);

  const [show, setShow] = useState("");
  const [page, setPage] = useState(0);
  const [column, setColumn] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [Rowdata, setRowData] = useState([]);

  const [openImageView, setOpenImageView] = useState(false);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [displayedImagesDate, setDisplayedImagesDate] = useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const yourStyles = {
    position: "absolute",
    inset: "100px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(97 90 90 / 75%)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "0px",
  };

  

  useEffect(() => {
    const patient_id = patientData.PatientID;

    axios
      .get(
        `${urllink}patientmanagement/get_patient_photo?patient_id=${patient_id}`
      )
      .then((response) => {
        const data = response.data;

        setPhoto(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [patientData]);

  const organizeImagesByDate = (images) => {
    const organizedImages = {};

    images.forEach((imageData) => {
      const dateKey = imageData.CreatedAt || imageData.CreatedAt1;

      if (!organizedImages[dateKey]) {
        organizedImages[dateKey] = [];
      }

      organizedImages[dateKey].push(imageData);
    });

    return organizedImages;
  };

  const handleFolderClick = (selectedDate) => {
    const selectedImages = organizedImages[selectedDate];
    setDisplayedImagesDate(selectedDate);

    const ImagesForSelectedDate = selectedImages.map(
      (imageData, innerIndex) => (
        <div className="image_display32w" key={innerIndex}>
          <div className="jqwgdyw56">
            <div className="edferki4">
              {imageData.Original_Image && (
                <div>
                  <img
                    src={`data:image/png;base64,${imageData.Original_Image}`}
                    alt=""
                  />
                  <h6>Original Images</h6>
                </div>
              )}
            </div>

            <div className="edferki4">
              {imageData.Anotated_Medical_Image && (
                <div>
                  <img
                    src={`data:image/png;base64,${imageData.Anotated_Medical_Image}`}
                    alt=""
                  />
                  <h6>Annotated Medical Image</h6>
                </div>
              )}
            </div>
          </div>
          <div className="jqwgdyw56">
            <div className="edferki4">
              {imageData.Patient_theraphy_Photo && (
                <div>
                  <img
                    src={`data:image/png;base64,${imageData.Patient_theraphy_Photo}`}
                    alt=""
                  />
                  <h6>Patient Photo Before</h6>
                </div>
              )}
            </div>
            <div className="edferki4">
              {imageData.Patient_Photo_After && (
                <div>
                  <img
                    src={`data:image/png;base64,${imageData.Patient_Photo_After}`}
                    alt=""
                  />
                  <h6>Patient Photo After</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );

    setOpenImageView(true);
    setDisplayedImages(ImagesForSelectedDate);
  };
  const organizedImages = organizeImagesByDate(allimages);

  const Folders = Object.keys(organizedImages).map((date, index) => (
    <div
      key={index}
      className={
        displayedImagesDate !== date
          ? "image-folder"
          : "image-folder newimage-folder "
      }
      onClick={() => handleFolderClick(date)}
    >
      <div className="wasx">
        <button className="shdiwjh34">{date}</button>
      </div>
    </div>
  ));

  const handleVisibilityClick = (ConsentForm) => {
    setSelectedImage(ConsentForm);
    setOpenModal3(true);
  };

  const handleVisibilityClick2 = (Lab_report_document) => {
    setSelectedImage5(`data:application/pdf;base64,${Lab_report_document}`);
    setOpenModal4(true);
  };

  const handleVisibilityClick3 = (Documents) => {
    setModalContent(`data:application/pdf;base64,${Documents}`);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent("");
  };

  const handleCloseModal = () => {
    setOpenModal3(false);
    setOpenModal4(false);
  };

  const handleCloseModal1 = () => {
    setOpenModal4(false);
  };

  useEffect(() => {
    handleOpenModle(userRecord);
  }, [userRecord]);

  const handleOpenModle = (summa) => {
    setShow(summa);
    if (summa === "Treatment") {
      setColumn([
        { field: "History", headerName: "History", width: 230 },
        { field: "Examination", headerName: "Examination", width: 230 },
        { field: "Diagnosis", headerName: "Diagnosis", width: 230 },
        { field: "Treatment_Date", headerName: "Treatment Date", width: 280 },
      ]);
      setOpenModal(true);
      const patient_id = patientData.PatientID;

      axios
        .get(
          `${urllink}doctorsworkbench/get_treatment_profile?patientid=${patient_id}&location=${userRecord?.location}`
        )
        .then((response) => {
          const data = response.data;

          setRowData(
            data.map((row, index) => ({
              id: index + 1,
              PatientID: row.PatientID,
              History: row.History,
              Examination: row.Examination,
              Diagnosis: row.Diagnosis,
              Treatment_Date: row.CreatedAt,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (summa === "Billing") {
      setColumn([
        { field: "PatientID", headerName: "Patient ID", width: 180 },
        { field: "PatientName", headerName: "Patient Name", width: 180 },
        { field: "Invoice_NO", headerName: "Invoice No", width: 180 },
        { field: "BillingDate", headerName: "Bill Date", width: 180 },
        { field: "BillType", headerName: "Bill Type", width: 180 },

        {
          field: "Documents",
          headerName: "Documents",
          width: 180,
          height: 180,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleVisibilityClick3(params.value)}
              >
                <VisibilityIcon />
              </Button>
            </>
          ),
        },
      ]);

      setOpenModal(true);
      const patient_id = patientData.PatientID;

      axios
        .get(
          `${urllink}doctorsworkbench/get_billingpdf_forProfile?patientid=${patient_id}&location=${userRecord?.location}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data;
          setRowData(
            data.map((row, index) => ({
              id: index + 1,
              ...row,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (summa === "Prescriptions") {
      setColumn([
        { field: "Medicine", headerName: "Medicine", width: 150 },
        { field: "Dose", headerName: "Dose", width: 95 },
        { field: "Route", headerName: "Route", width: 100 },
        { field: "Frequency", headerName: "Frequency", width: 130 },
        { field: "Duration", headerName: "Duration", width: 113 },
        { field: "Qty", headerName: "Qty", width: 95 },
        { field: "Instruction", headerName: "Instruction", width: 120 },
        { field: "CreatedAt", headerName: "Prescription Date", width: 130 },
        { field: "Location", headerName: "Location", width: 130 },
      ]);
      setOpenModal(true);
      const patient_id = patientData.PatientID;

      axios
        .get(
          `${urllink}doctorsworkbench/get_prescription_profile?PatientID=${patient_id}`
        )
        .then((response) => {
          const data = response.data;

          setRowData(
            data.map((row, index) => ({
              id: index + 1,
              PatientID: row.PatientID,
              Medicine: row.GenericName + "" + row.ItemName,
              Dose: row.Dose,
              Route: row.Route,
              Frequency: row.Frequency,
              Duration: row.Duration,
              Qty: row.Qty,
              Instruction: row.Instruction,
              CreatedAt: row.CreatedAt,
              Location: row.Location,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (summa === "Appointments") {
      setColumn([
        { field: "VisitID", headerName: "VisitID", width: 80 },
        {
          field: "AppointmentPurpose",
          headerName: "Appointment Purpose",
          width: 150,
        },
        {
          field: "AppointmentDate",
          headerName: "Appointment Date",
          width: 150,
        },
        { field: "Slot", headerName: "Slot", width: 40 },
        { field: "DoctorName", headerName: "Doctor Name", width: 150 },
        { field: "Status", headerName: "Status", width: 150 },
      ]);
      setOpenModal(true);
      const patient_id = patientData.PatientID;

      axios
        .get(
          `${urllink}appointmentmanagement/get_appointments_profile?patientid=${patient_id}&location=${userRecord?.location}`
        )
        .then((response) => {
          const data = response.data;

          setRowData(
            data.map((row, index) => ({
              id: index + 1,

              VisitID: row.VisitID,
              AppointmentPurpose: row.AppointmentPurpose,
              AppointmentDate: row.AppointmentDate,
              Slot: row.Appoiment_Slot,
              DoctorName: row.DoctorName,
              Status: row.Status,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (summa === "Consent Forms") {
      setColumn([
        { field: "id", headerName: "S.No", width: 180 },
        { field: "treatmentname", headerName: "Treatment Name", width: 180 },
        { field: "Date", headerName: "Date", width: 180 },
        {
          field: "ConcernForm",
          headerName: "ConsentForm",
          width: 180,
          height: 180,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleVisibilityClick(params.value)}
              >
                <VisibilityIcon />
              </Button>
            </>
          ),
        },
      ]);

      setOpenModal(true);
      const patient_id = patientData.PatientID;
      axios
        .get(
          `${urllink}appointmentmanagement/get_concernform?location=${userRecord?.location}&patientid=${patient_id}`
        )
        .then((response) => {
          const data = response.data;

          setRowData(
            data.map((row, index) => ({
              id: index + 1,
              treatmentname: row.Treatment_Name,
              Date: row.Date,
              ConcernForm: row.ConcernForm,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (summa === "Vital Signs") {
      setColumn([
        { field: "Temperature", headerName: "Temperature", width: 100 },
        { field: "Pulse", headerName: "Pulse", width: 55 },
        { field: "SPO2", headerName: "SPO2", width: 55 },
        { field: "Heart_Rate", headerName: "Heart Rate", width: 90 },
        { field: "RR", headerName: "RR", width: 30 },
        { field: "SBP", headerName: "SBP", width: 30 },
        { field: "DBP", headerName: "DBP", width: 40 },
        { field: "Position", headerName: "Position", width: 90 },
        { field: "Part", headerName: "Part", width: 90 },
        { field: "Method", headerName: "Method", width: 90 },
        { field: "Weight_kg", headerName: "Weight(kg)", width: 90 },
        { field: "Height_cm", headerName: "Height(cm)", width: 90 },
        { field: "HC_cm", headerName: "HC(cm)", width: 70 },
        { field: "WC_cm", headerName: "WC(cm)", width: 70 },
      ]);
      setOpenModal(true);
      const patient_id = patientData.PatientID;

      axios
        .get(`${urllink}doctorsworkbench/get_vitalform`)
        .then((response) => {
          const data = response.data;

          // Filter the data based on the patient_id
          const filteredData = data.filter(
            (row) => row.PatientID === patient_id
          );

          setRowData(
            filteredData.map((row, index) => ({
              id: index + 1,
              PatientID: row.PatientID,
              Temperature: row.Temperature,
              Pulse: row.Pulse,
              SPO2: row.SPO2,
              Heart_Rate: row.Heart_Rate,
              RR: row.RR,
              SBP: row.SBP,
              DBP: row.DBP,
              Position: row.Position,
              Part: row.Part,
              Method: row.Method,
              Weight_kg: row.WeightKg,
              Height_cm: row.HeightCm,
              HC_cm: row.HCCm,
              WC_cm: row.WCCm,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (summa === "Procedure") {
      setColumn([
        { field: "id", headerName: "S.No", width: 80 },
        { field: "visitid", headerName: "Visitid", width: 80 },
        {
          field: "Treatment_Procedure",
          headerName: "Procedure Name",
          width: 180,
        },
        { field: "TherapistName", headerName: "Therapist Name", width: 180 },
        { field: "Number_of_Sessions", headerName: "Sessions", width: 150 },
        { field: "Sessions_completed", headerName: "Sessions Completed", width: 150 },
        { field: "DoctorName", headerName: "Doctor Name", width: 180 },
      ]);
      setOpenModal(true);
      const patient_id = patientData.PatientID;

      axios
        .get(
          `${urllink}doctorsworkbench/for_profile_get_procedure?patientid=${patient_id}&location=${userRecord?.location}`
        )
        .then((response) => {
          const data = response.data;

          // Filter the data based on the patient_id
          const filteredData = data.filter(
            (row) => row.PatientID === patient_id
          );

          setRowData(
            filteredData.map((row, index) => ({
              id: index + 1,
              PatientID: row.PatientID,
              visitid:row.VisitID,
              Treatment_Procedure: row.Treatment_Procedure,
              TherapistName: row.TherapistName,
              Number_of_Sessions: row.Number_of_Sessions,
              Sessions_completed: row.Number_of_Sessionscomp,
              DoctorName: row.DoctorName,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (summa === "TestMaster") {
      setColumn([
        { field: "id", headerName: "Serial No", width: 180 },
        { field: "documentupdatedate", headerName: "Date", width: 180 },
        {
          field: "Lab_report_document",
          headerName: "TestReport",
          width: 350,
          height: 180,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleVisibilityClick2(params.value)}
              >
                <VisibilityIcon />
              </Button>
            </>
          ),
        },
      ]);

      setOpenModal(true);
      const patient_id = patientData.PatientID;

      axios
        .get(
          `${urllink}labtest/get_Passiont_lab_detailes_forprpfile?patientid=${patient_id}&location=${userRecord?.location}`
        )
        .then((response) => {
          const data = response.data;

          // Update state with the fetched data
          setRowData(
            data.map((row, index) => {
              return {
                id: index + 1,
                PatientID: row.PatientID,
                Lab_report_document: row.lab_image,
                documentupdatedate: row.documentupdatedate,
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (summa === "View All Images") {
      setOpenImageView(true);
    }
  };

  const DisplayData = () => {
    const showdown = Rowdata.length;
    const pageSize = 10;

    const handlePageChange = (params) => {
      setPage(params.page);
    };

    const totalPages = Math.ceil(Rowdata.length / 10);

    return (
      <>
        <Helmet>
          <title> {show + " List"} </title>
        </Helmet>
        <div className="appointment">
          <div className="h_head">
            <h3> {show + " List"}</h3>
          </div>
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={Rowdata.slice(page * pageSize, (page + 1) * pageSize)}
                columns={column}
                pageSize={pageSize} // Set the page size to your desired value
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: pageSize, // Set the page size to your desired value
                    },
                  },
                }}
                pageSizeOptions={[pageSize]} // Set the page size options to your desired values
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > pageSize && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>

          {showdown !== 0 && Rowdata.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="patient_profile">
      <div className="p_head">
        <h3>Patient Profile</h3>
      </div>
      <div className="p_data">
        <div className="p_p_detials hid-prof-tils">
          <div className="p_profile_img_head">
            {photo && photo.PatientPhoto && (
              <div className="p_profile_img img_096">
                <img
                  src={`data:image/*;base64,${photo.PatientPhoto}`}
                  alt={`Patient ${photo.PatientID}`}
                />
              </div>
            )}
            {!(photo && photo.PatientPhoto) && (
              <div className="p_profile_img img_096">
                <img src={bgImg2} alt={`Patient ${photo?.PatientID}`} />
              </div>
            )}

            <div className="p_profile_name">
              <h3>
                {patientData.FirstName}
                {patientData.LastName}
              </h3>

              <div className="p_profile_body_icon icn-emp-pro-cs">
                <h5>
                  <PhoneAndroidIcon />
                  <p>
                    <h4 className="ieudh78e6e34">{patientData.PhoneNumber}</h4>
                  </p>
                </h5>
              </div>
              <div className="p_profile_body_icon">
                <h5>
                  <EmailIcon />
                  <p className="jnj6">
                    <h4 className="ieudh78e6e34">{patientData.Email}</h4>
                  </p>
                </h5>
              </div>
            </div>
          </div>
          <div className="p_profile_body">
            <h3>Address</h3>

            <div className="p_profile_body_icon">
              <HomeIcon />

              <div className="text-hmeicn">
                {`${patientData.DoorNo}, ${patientData.Street}, ${patientData.Area}, ${patientData.Place}, ${patientData.Country}, ${patientData.Pincode}`}
              </div>
            </div>
          </div>
        </div>

        <div className="p_p_detial_1">
          <div className="p_p_data">
            <div className="p_profile_data overview">
              <h3>Overview </h3>
            </div>
            <div className="p_profile_items_11">
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6>Gender :</h6>
                  <h3>{patientData.Gender}</h3>
                </div>
                <div className="p_profile_data">
                  <h6>Previous Visit :</h6>
                  <h3>{patientData.previousvisit}</h3>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6>Date of Birth :</h6>
                  <h3>{patientData.DateofBirth}</h3>
                </div>
                <div className="p_profile_data">
                  <h6>Next Visit :</h6>
                  <h3>{patientData.Next_Appointment}</h3>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6>Age :</h6>
                  <h3>{patientData.Age}</h3>
                </div>
                <div className="p_profile_data">
                  <h6>Next of Kin :</h6>
                  <h3>{patientData.Name}</h3>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6>Allergies :</h6>
                  <h3>{patientData.allergies}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="p_p_data_1">
            <div className="p_p_data_items bmb-gap">
              <h3>{patientData.BMI}</h3>

              <h6>BMI</h6>
            </div>
            <div className="p_p_data_items alifn-stght">
              <h3>
                {patientData.WeightKg}
                <span>(Kg)</span>{" "}
              </h3>
              <h6>weight</h6>
            </div>
            <div className="p_p_data_items alifn-stght">
              <h3>
                {patientData.HeightCm} <span>(cm) </span>
              </h3>
              <h6>Height </h6>
            </div>
            <div className="p_p_data_items bmb-gap" style={{ width: "150px" }}>
              <h3>
                {patientData.SBP}/{patientData.DBP}
              </h3>
              <h6>Blood pressure</h6>
            </div>
            <div
              className="p_p_data_items flex_1 flex_vital"
              onClick={() => handleOpenModle("Vital Signs")}
            >
              <div className="p_profile_img cente_1">
                <FavoriteIcon />
              </div>
              <h3>Vital Signs</h3>
              <ChevronRightIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="p_data_1">
        <div className="p_p_data_detial">
          <div className="p_profile_img_head p_p_space cntr-tmelne">
            <div className="p_profile_name">
              <h3>Timeline</h3>
            </div>
          </div>
          <div className="timeline_body">
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: "0.2",
                },
              }}
            >
              {data.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent color="textSecondary">
                    <div className="timeline_left">
                      <div className="timeline_month">{item.created_at}</div>
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <div className="dot_1">
                      <TimelineDot className="dottt" />
                    </div>
                    {index !== data.length - 1 && (
                      <TimelineConnector className="timeline_connector" />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <div className="timeline_right">
                      <h4>{item.disease_name}</h4>
                      <h5>{item.activity}</h5>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        </div>

        <div className="p_p_data_detial_1">
          <div className="p_p_data_items_left">
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Treatment")}
            >
              <div className="p_profile_img cente_1">
                <MedicalServicesIcon />
              </div>
              <h3>Treatment</h3>
              <ChevronRightIcon />
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Billing")}
            >
              <div className="p_profile_img cente_1">
                <PaymentIcon />
              </div>
              <h3>Billing</h3>
              <ChevronRightIcon />
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Prescriptions")}
            >
              <div className="p_profile_img cente_1">
                <AddShoppingCartIcon />
              </div>
              <h3>Prescriptions</h3>
              <ChevronRightIcon />
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("TestMaster")}
            >
              <div className="p_profile_img cente_1">
                <BiotechIcon />
              </div>
              <h3>Lab Test</h3>
              <ChevronRightIcon />
            </button>
          </div>
          <div className="p_p_data_items_left">
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Appointments")}
            >
              <div className="p_profile_img cente_1">
                <CalendarTodayIcon />
              </div>
              <h3>Appointments</h3>
              <ChevronRightIcon />
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Consent Forms")}
            >
              <div className="p_profile_img cente_1">
                <ArtTrackIcon />
              </div>
              <h3>Consent Forms</h3>
              <ChevronRightIcon />
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Procedure")}
            >
              <div className="p_profile_img cente_1">
                <DvrIcon />
              </div>
              <h3>Procedure Record</h3>
              <ChevronRightIcon />
            </button>
          </div>
        </div>
        <div className="p_p_data_detial_2">
          <div className="p_p_data_detials_2_images">
            <div className="p_p_data_detials_2_img">
              <img
                src={`data:image/png;base64,${patientData.Original_Image}`}
                alt=""
              />
            </div>
            <div className="p_p_data_detials_2_img">
              <img
                src={`data:image/png;base64,${patientData.Original_Image1}`}
                alt=""
              />
            </div>
          </div>
          <div className="p_p_data_detials_2_view">View</div>
          <div className="p_p_data_detials_2_view">
            <button
              className="vie-al-pic"
              onClick={() => handleOpenModle("View All Images")}
            >
              View All
              <InsertPhotoIcon />
            </button>
          </div>
        </div>
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
        >
          <div className="newwProfiles">
            <DisplayData />
            <button
              className="closeicon-cs"
              onClick={() => setOpenModal(false)}
            >
              close
            </button>
          </div>
        </div>
      )}

      {openModal4 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
        >
          <div className="newwProfiles">
            <DisplayData />
            <button
              className="closeicon-cs"
              onClick={() => setOpenModal(false)}
            >
              close
            </button>
          </div>
        </div>
      )}

      <Modal open={openModal3} onClose={handleCloseModal}>
        <div className="pdf_img_show">
          <iframe
            title="PDF Viewer"
            src={selectedImage}
            style={{
              width: "100%",
              height: "435px",
              border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
            }}
          />
          <div className="jhuhhjh">
            <Button
              onClick={handleCloseModal}
              style={{ color: "white" }}
              className="clse_pdf_img"
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "var(--ProjectColor)",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>

      <Modal open={openModal4} onClose={handleCloseModal1}>
        <div className="pdf_img_show">
          <iframe
            title="PDF Viewer"
            src={selectedImage5}
            style={{
              width: "100%",
              height: "435px",
              border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
            }}
          />
          <div className="jhuhhjh">
            <Button
              onClick={handleCloseModal1}
              style={{ color: "white" }}
              className="clse_pdf_img"
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "var(--ProjectColor)",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        style={{ content: { ...yourStyles } }}
      >
        <div className="pdf_img_show">
          <iframe
            title="PDF Viewer"
            src={modalContent}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "80%",
              height: "95%",
              border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
            }}
          />

          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "var(--ProjectColor)",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>

      {openImageView && (
        <div
          onClick={() => setOpenImageView(false)}
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
        >
          <div className="newwProfiles" onClick={(e) => e.stopPropagation()}>
            <div className="images_show">
              <div className="images_show_1">{Folders}</div>
              <div className="images_show_2">
                {displayedImages && displayedImages.length > 0 && (
                  <div className="p_p_data_detials_2_images3 viw-all-allpic">
                    {displayedImages}
                  </div>
                )}
              </div>
            </div>

            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenImageView(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientProfile;
