import React, { useEffect, useState } from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});
const EmployeeList = () => {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [rows, setRows] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const [columns] = React.useState([
    { field: "id", headerName: "Serial No", width: 70 },
    { field: "employeeid", headerName: "Employee ID", width: 100 },
    {
      field: "employeephoto",
      headerName: "Employee Photo",
      width: 120,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Employee"
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
      ),
    },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "phone", headerName: "Phone No", width: 120 },
    { field: "designation", headerName: "Designation", width: 100 },

    {
      field: "Action ",
      headerName: "Profile",
      width: 70,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
    {
      field: "Edit ",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleEditList(params)}>
            <EditIcon />
          </Button>
        </>
      ),
    },
  ]);

  useEffect(() => {
    axios
      .get(
        `${urllink}HRmanagement/get_employee_personaldetails?location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;
        const Records = data.map((userdata, index) => ({
          id: index + 1,
          employeeid: userdata.EmployeeID,
          employeephoto: userdata.EmployeePhoto,
          employeename: userdata.EmployeeName,
          phone: userdata.PhoneNumber,
          designation: userdata.Designation,
          location: userdata.Locations,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord]);

  const navigate = useNavigate();

  const handleList = (params) => {
    const employeeid = params.row.employeeid;
    const location = params.row.location;
    axios
      .get(
        `${urllink}HRmanagement/get_for_employeeprofile?employeeid=${employeeid}&location=${location}`
      )
      .then((response) => {
        dispatchvalue({ type: "foremployeedata", value: response.data });
      })
      .catch((error) => {
        console.error(error);
      });

    navigate("/Home/Employee-Profile");
  };

  const handleEditList = (params) => {
    const employeeid = params.row.employeeid;

    axios
      .get(
        `${urllink}HRmanagement/getfor_employeeedit?employeeid=${employeeid}`
      )
      .then((response) => {
        dispatchvalue({ type: "foremployeeedit", value: response.data });
        navigate("/Home/Employee-Register");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "Employeeid") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.employeename.toLowerCase();
      const lowerCasePhoneNo = row.employeeid.toString().toLowerCase();

      const matchesFirstName = lowerCaseSupplierName.includes(
        searchQuery.toLowerCase()
      );
      const matchesPhoneNo = lowerCasePhoneNo.includes(
        searchQuery1.toLowerCase()
      );

      return (
        (matchesFirstName || !searchQuery) && (matchesPhoneNo || !searchQuery1)
      );
    });

    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, rows]);

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Employee List</h4>
      </div>

      <div className="con_1 ">
      <div className="inp_1">
          <label htmlFor="input">
            Employee ID <span>:</span>
          </label>
          <input
            type="number"
            id="Employeeid"
            value={searchQuery1}
            onChange={handleSearchChange}
            placeholder="Enter the Employee  ID"
          />
        </div>
        <div className="inp_1">
          <label htmlFor="input">
            Employee Name <span>:</span>
          </label>
          <input
            type="text"
            id="FirstName"
            onChange={handleSearchChange}
            value={searchQuery}
            placeholder="Enter the First Name"
          />
        </div>
       
      </div>

      <ThemeProvider theme={theme}>
        <div className="grid_1">
          <DataGrid
            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
            columns={columns}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={handlePageChange}
            hideFooterPagination
            hideFooterSelectedRowCount
            className="data_grid"
          />
          {showdown > 0 && filteredRows.length > 10 && (
            <div className="grid_foot">
              <button
                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() =>
                  setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                }
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </ThemeProvider>
      {filteredRows.length !== 0 ? (
        ""
      ) : (
        <div className="IP_norecords">
          <span>No Records Found</span>
        </div>
      )}
    </div>
  );
};

export default EmployeeList;
