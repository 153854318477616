import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import LoupeIcon from "@mui/icons-material/Loupe";
// import './PatientQueueList.css';
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { isArray } from "lodash";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function Followuplist() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log("userRecord", userRecord);
  const workbenchformData = useSelector(
    (state) => state.userRecord?.workbenchformData
  );
  console.log("workbenchformData", workbenchformData);

  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const navigate = useNavigate();
  const dispatchvalue = useDispatch();
  const [rows, setRows] = React.useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");

  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const [OpenModal4, setOpenModal4] = useState(false);
  const [followupdetaildata, setfollowupdetaildata] = useState([]);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const [StatusType, setStatusType] = useState("Pending");

  const columns = [
    {
      field: "id",
      headerName: "Patient Id",
      width: 150,
    },

    {
      field: "PatientName",
      headerName: "Patient Name",

      width: 150,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      width: 130,
    },

    {
      field: "nextapppoinmentdate",
      headerName: "Next Appointment",
      width: 150,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 170,
    },
    {
      field: "actions ",
      headerName: "View",
      width: 120,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleview(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestProceed(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ];



  const handleRequestProceed = (params) => {
    dispatchvalue({ type: "for_counselor_navigation_data", value: params.row });
    navigate("/Home/Treament-CounselorProcedure");
  };

  const handleview = (params) => {
    console.log(params);
    axios
      .get(
        `${urllink}doctorsworkbench/get_followuplist_detaildata?PatientID=${params.row.id}&VisitID=${params.row.visitid}`
      )
      .then((response) => {
        console.log(response.data);
        setfollowupdetaildata(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    setOpenModal4(true);
  };

  useEffect(() => {
    axios
      .get(`${urllink}doctorsworkbench/get_joined_data_followlist?StatusType=${StatusType}&Location=${userRecord.location}`)
      .then((res) => {
        const data = res.data;
        console.log(data, "----123uuuu");

        const Records = data.map((userdata) => ({
          id: userdata.PatientID,
          visitid: userdata.VisitID,
          PatientName: userdata.fullName,
          PhoneNumber: userdata.phoneNo,
          nextapppoinmentdate: userdata.Next_Appointment || "-",
          Status: userdata?.Status || '-',
        }));

        setRows(Records);
        console.log("Records", rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [StatusType]);

  columns[3].renderCell = ({ row }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        color: row.nextapppoinmentdate === "Tomorrow" ? "black" : "black",
        backgroundColor:
          row.nextapppoinmentdate === "Tomorrow"
            ? "Var(--selectbackgroundcolor)"
            : "transparent", // Light green
      }}
    >
      {row.nextapppoinmentdate}
    </div>
  );

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row?.PatientName.toLowerCase();
      const lowerCasePhoneNo = row?.PhoneNumber.toString();

      const startsWithFirstName = lowerCaseSupplierName.startsWith(
        searchQuery.toLowerCase()
      );
      const startsWithPhoneNo = lowerCasePhoneNo.startsWith(
        searchQuery1.toLowerCase()
      );

      return (
        (startsWithFirstName || !searchQuery) &&
        (startsWithPhoneNo || !searchQuery1)
      );
    });

    // If there is a search query, sort the data to bring the left-to-right matching rows to the top

    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, rows]);

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Follow Up List</h4>
          <div className="doctor_select_1 selt-dctr-nse vcxw2er">
            <label htmlFor="Calender">
              Status <span>:</span>
            </label>
            <select
              className="Product_Master_div_select_opt"
              value={StatusType}
              onChange={(e) => {
                setStatusType(e.target.value);
              }}
            >
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
        </div>
        {/* <form onSubmit={handleSearch}> */}
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Patient Name
              <span>:</span>
            </label>
            <input
              type="text"
              id="FirstName"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">
              Phone No <span>:</span>
            </label>
            <input
              type="number"
              id="PhoneNo"
              value={searchQuery1}
              onChange={handleSearchChange}
              placeholder="Enter the Phone No"
            />
          </div>
        </div>
        {/* </form> */}
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              // rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)
            }
              columns={columns}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>

        {filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      {OpenModal4 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
        >
          <div className="newwProfiles">
            <br />
            <div className="appointment">
              <div className="h_head">
                <h4>Detail Report Of Procedure</h4>
              </div>
              <br />
              <div>
                <h3 style={{ color: "grey" }}>Previous Completed Procedure</h3>
              </div>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>VisitID</th>
                      <th>AppointmentDate</th>
                      <th>Therapist Name</th>
                      <th>Procedure Name</th>
                      <th>Sessions</th>
                      <th>Complementry</th>
                      <th>Kit Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isArray(followupdetaildata) &&
                      followupdetaildata
                        .filter((client) => client.Status === "Completed")
                        .map((client, index) => (
                          <tr key={index}>
                            <td>{client.VisitID}</td>
                            <td>{client.AppointmentDate}</td>
                            <td>{client.TherapistName}</td>
                            <td>{client.Treatment_Procedure}</td>
                            <td>{client.Number_of_Sessions}</td>
                            <td>{client.Complementry || "-"}</td>
                            <td>{client.KitName || "-"}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <h3 style={{ color: "grey" }}>Current Procedure</h3>
              </div>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>VisitID</th>
                      <th>Therapist Name</th>
                      <th>Procedure Name</th>
                      <th>Sessions</th>
                      <th>Completed Session</th>
                      <th>Complementry</th>
                      <th>Kit Name</th>
                      <th>Next AppointmentDate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isArray(followupdetaildata) &&
                      followupdetaildata
                        .filter((client) => client.Status != "Completed")
                        .map((client, index) => (
                          <tr key={index}>
                            <td>{client.VisitID}</td>
                            <td>{client.TherapistName}</td>
                            <td>{client.Treatment_Procedure}</td>
                            <td>{client.Number_of_Sessions}</td>
                            <td>{client?.Number_of_Sessions_completed}</td>
                            <td>{client.Complementry || "-"}</td>
                            <td>{client.KitName || "-"}</td>
                            <td>{client.Next_Appointment || "-"}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
            <button
              className="closeicon-cs"
              onClick={() => setOpenModal4(false)}
            >
              close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
