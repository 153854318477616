import React, { useEffect, useState } from "react";
import "./Preview.css";
import axios from "axios";
import { format } from "date-fns";
import doctor from "../assets/rajeshkumar.png";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";

const LabPreview = () => {
  const [pdfBlob, setPdfBlob] = useState(null);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const Temp_testnamelist = useSelector(
    (state) => state.userRecord?.Temp_testnamelist
  );

  const [selectedDate, setSelectedDate] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  const [PreviewData, setPreviewData] = useState({});
  const [ClinicDetails, setClinicDetails] = useState({});
  const [appointmentDate, setAppointmentDate] = useState("");
  const [selectedVital, setSelectedVital] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState([]);

  const [clinicLogo, setClinicLogo] = useState(null);

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=${userRecord?.location}`)

      .then((response) => {
        const record = response.data[0];
        if (record) {
          setClinicDetails(record);
        } else {
          console.error("No clinic details found");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;
          setClinicLogo(`data:image/png;base64,${firstClinic.clinicLogo}`);
        } else {
          console.error("No record found");
        }
      })
      .catch((error) => console.error("Error fetching data"));
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate(currentDate);
    setSelectedDate(new Date(), "Asia/Kolkata");
  }, []);

  const currdate = selectedDate && format(selectedDate, " dd / MM / yy");

  useEffect(() => {
    const storedFormData = formData;
    if (storedFormData) {
      setPreviewData(storedFormData);
    }
  }, []);
  // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (
      formData &&
      formData.PatientID &&
      formData.SerialNo &&
      formData.visitNo
    ) {
      axios
        .get(
          `${urllink}doctorsworkbench/get_for_labpreview?PatientID=${formData?.PatientID}&VisitID=${formData?.visitNo}`
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const data = response.data;

            setSelectedVital(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      axios
        .get(
          `${urllink}doctorsworkbench/prescription_forpreview?PatientID=${formData.PatientID}`
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setPrescriptionData(response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching prescription data:", error);
        });
    }
  }, [formData.PatientID, formData, appointmentDate]);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {},
    onAfterPrint: async () => {
      setPdfBlob(null);

      const printdata = document.getElementById("reactprintcontent");

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20; // Adjust the padding as needed
          const pdfWidth = contentWidth + 2 * padding; // Add padding to width
          const pdfHeight = contentWidth * 1.5; // Add padding to height
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });
          pdf.html(printdata, {
            x: padding, // Set x-coordinate for content
            y: padding, // Set y-coordinate for content
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              setPdfBlob(generatedPdfBlob);
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  return (
    <div>
      <div className="billing-invoice" id="reactprintcontent">
        <br />
        <div className="New_billlling_invoice_head">
          <div className="new_billing_logo_con">
            <img src={clinicLogo} alt="Medical logo" />
          </div>
          <div className="new_billing_address_1 ">
            <span className="dkjfiuw6">{ClinicDetails.concern_name}</span>

            <div>
              <span className="dkjfiuw6">
                {ClinicDetails.door_no +
                  "," +
                  ClinicDetails.street +
                  "," +
                  ClinicDetails.area +
                  "," +
                  ClinicDetails.city +
                  "," +
                  ClinicDetails.state +
                  "-" +
                  ClinicDetails.pincode}
              </span>
            </div>
            <div>
              <span className="dkjfiuw6">{ClinicDetails.phone_no + " , "}</span>
              <span className="dkjfiuw6">{ClinicDetails.email}</span>
            </div>
          </div>
        </div>
        <div
          className="Register_btn_con"
          style={{ color: "var(--labelcolor)", fontWeight: 600 }}
        >
          Patient Preview
        </div>

        <div className="new_billing_address">
          <div className="new_billing_address_2">
            <div className="new_preview_div">
              <label>
                Patient Name <span>:</span>
              </label>
              <span className="dkjfiuw6">
                {formData?.firstName} {formData?.lastName}
              </span>
            </div>
            <div className="new_preview_div">
              <label>
                Patient ID <span>:</span>
              </label>
              <span className="dkjfiuw6">{formData?.PatientID}</span>
            </div>

            <div className="new_preview_div">
              <label>
                Gender <span>:</span>
              </label>
              <span className="dkjfiuw6">{formData?.Gender}</span>
            </div>
          </div>
          <div className="new_billing_address_2">
            <div className="new_preview_div">
              <label>
                Date <span>:</span>
              </label>
              <span className="dkjfiuw6">{currdate}</span>
            </div>

            <div className="new_preview_div">
              <label>
                Visit ID <span>:</span>
              </label>
              <span className="dkjfiuw6">{formData?.visitNo}</span>
            </div>
            <div className="new_preview_div">
              <label>
                {" "}
                Doctor Name <span>:</span>
              </label>
              <span className="dkjfiuw6">{formData?.DoctorName}</span>
            </div>
          </div>
        </div>

        <div
          className="Register_btn_con"
          style={{ color: "var(--labelcolor)", fontWeight: 600 }}
        >
          Test Name
        </div>
        <div className="new_billing_invoice_detials">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Test Name</th>
              </tr>
            </thead>
            <tbody>
              {selectedVital.map((test, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{test.test_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isPrintButtonVisible && (
        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns"
            onClick={() => {
              handlePrint();
            }}
          >
            Print Data
          </button>
        </div>
      )}
    </div>
  );
};

export default LabPreview;
