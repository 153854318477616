import React, { useEffect, useState, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
// import { saveAs } from 'file-saver';
import Button from "@mui/material/Button";
import { addDays, format } from "date-fns";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const PurchaseRecieveMaster = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const PurchaseInvoiceGRN = useSelector(
    (state) => state.userRecord?.PurchaseInvoiceGRN
  );
  console.log('sssssssss',PurchaseInvoiceGRN);
  const GRNedit = useSelector((state) => state.userRecord?.GRNedit);
  console.log(GRNedit);

  const navigate = useNavigate();

  const [datalist, setdatalist] = useState([]);

  const cu_date = format(new Date(), "yyyy-MM-dd");

  console.log("vathuruchu", datalist);

  const [isedit, setisedit] = useState(null);

  const [Paymentduedays, setPaymentduedays] = useState("");

  const [OverallPurchaseRecieveFormData, setOverallPurchaseRecieveFormData] =
    useState({
      GRN_Invoice_NO: "",
      GRN_Date: cu_date,
      ProductCategory: "",
      ProductCategoryType: "",
      PurchaseRaiseInvoice: "",
      SupplierCode: "",
      SupplierName: "",
      Supplier_Bill_No: "",
      Supplier_Bill_Date: "",
      Supplier_Net_Amount: "",
      Supplier_Bill_Due_Date: "",
      CreatedBy: "",
      Location: "CentralStore",
      RecievedBy: userRecord?.username,
      Purchaserise_date: "",
    });

  console.log(OverallPurchaseRecieveFormData);

  const [PurchaseRecieveFormData, setPurchaseRecieveFormData] = useState({
    Item_Code: "",
    Item_Name: "",
    Generic_Name: "",
    Strength: "",
    UOM: "",
    Pack_type: "",
    Pack_Quantity: "",
    Order_Quantity: "",
    TotalQuantity: "",
    RecievedOrder_Quantity: "",
    RecievedTotalQuantity: "",
    PendingQuantity: "",
    Reason: "",
    HSNCode: "",
    BatchNo: "",
    ManufactureDate: "",
    ExpiryDate: "",
    MRP: "",
    PurchaseRateForStrip: "",
    PurchaseRateForStripGST: "",
    Purchase_Rate_TotalAmount: "",
    Purchase_Rate_TotalAmount_Dis: "",
    Purchase_Rate: "",
    Purchase_Rate_withtax: "",
    GST_Type: "",
    Tax_Percentage: "",
    DiscountType: "",
    Discount: "",
    Tax_totalAmount: "",
    TotalAmount: "",
  });

  console.log("qqq", PurchaseRecieveFormData);



  console.log(PurchaseRecieveFormData);

  const [Finel_Amountdata, setFinel_Amountdata] = useState({
    Grand_Total: "",
    Total_Discount_Type: "",
    Total_Discount: "",
    Finel_NetAmount: "",
  });

  const [showCamera, setShowCamera] = useState(false);
  const [Selected_File, setSelected_File] = useState(null);
  const [isImageCaptured, setIsImageCaptured] = useState(false);
  const [capturedImageSec, setCapturedImageSec] = useState(null);
  const webcamRef = useRef(null);

  const [datalist1, setdatalist1] = useState([]);

  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const showdown = datalist.length;
  const showdown1 = datalist1.length;
  const totalPages = Math.ceil(datalist.length / 10);
  const totalPages1 = Math.ceil(datalist1.length / 10);

  console.log("cu_date:", datalist1);

  useEffect(() => {
    if (PurchaseInvoiceGRN && Object.keys(PurchaseInvoiceGRN).length !== 0) {
      if (
        PurchaseInvoiceGRN.Productype === "MedicalConsumable" ||
        PurchaseInvoiceGRN.Productype === "MedicalNonConsumable" ||
        PurchaseInvoiceGRN.Productype === "NonMedicalConsumable" ||
        PurchaseInvoiceGRN.Productype === "NonMedicalNonConsumable" ||
        PurchaseInvoiceGRN.Productype === "Surgical"
      ) {
        axios
          .get(
            `${urllink}SupplierMaster/get_Medical_GRN_InvoiceNo?producttype=${PurchaseInvoiceGRN.Productype}`
          )
          .then((response) => {
            console.log("INV:", response.data.nextGRN);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              GRN_Invoice_NO: response.data.nextGRN,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (PurchaseInvoiceGRN.Productype === "Assets") {
        axios
          .get(`${urllink}SupplierMaster/get_Assets_GRN_InvoiceNo`)
          .then((response) => {
            console.log("INV:", response.data.nextGRN);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              GRN_Invoice_NO: response.data.nextGRN,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (PurchaseInvoiceGRN.Productype === "Stationary") {
        axios
          .get(
            `${urllink}SupplierMaster/get_Stationary_GRN_InvoiceNo`
          )
          .then((response) => {
            console.log("INV:", response.data.nextGRN);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              GRN_Invoice_NO: response.data.nextGRN,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (PurchaseInvoiceGRN.Productype === "Non_Stationary") {
        axios
          .get(
            `${urllink}SupplierMaster/get_Non_Stationary_GRN_InvoiceNo`
          )
          .then((response) => {
            console.log("INV:", response.data.nextGRN);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              GRN_Invoice_NO: response.data.nextGRN,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [PurchaseInvoiceGRN]);

  useEffect(() => {
    if (GRNedit && Object.keys(GRNedit).length !== 0) {
      console.log("Super", GRNedit);

      if (GRNedit.Productype === "Medical") {
        const Status = "Recieved";
        axios
          .get(
            `${urllink}SupplierMaster/get_Medical_GRN_data?Status=${Status}`
          )
          .then((response) => {
            console.log("INV11:", response.data.data);
            const getdatas1 = response.data.data.find(
              (ele) => ele.GRN_InvoiceNo === GRNedit.Invoice
            );
            console.log("getdatas1:", getdatas1);

            setOverallPurchaseRecieveFormData({
              GRN_Invoice_NO: getdatas1.GRN_InvoiceNo,
              GRN_Date: cu_date,
              ProductCategory: getdatas1.Product_Category,
              PurchaseRaiseInvoice: getdatas1.Raise_Invoice_No,
              SupplierCode: getdatas1.Supplier_Code,
              SupplierName: getdatas1.Supplier_Name,
              Supplier_Bill_No: getdatas1.Supplier_Bill_No,
              Supplier_Bill_Date: getdatas1.Supplier_Bill_Date,
              Supplier_Net_Amount: getdatas1.Bill_Net_Amount,
              Supplier_Bill_Due_Date: getdatas1.Supplier_Bill_Due_Date,
              CreatedBy: getdatas1.Created_By,
              Location: "CentralStore",
              RecievedBy: userRecord?.location,
            });
            setFinel_Amountdata({
              Grand_Total: getdatas1.Grand_Total,
              Total_Discount_Type: getdatas1.Total_Discount_Type,
              Total_Discount: getdatas1.Total_Discount,
              Finel_NetAmount: getdatas1.GRN_Finel_NetAmount,
            });

            const updateddd = dataURItoBlob(getdatas1.Bill_Document);

            setSelected_File(updateddd);

            const getdatas2 = getdatas1.items;
            console.log("getdatas2:", getdatas2);

            let b_data = getdatas2.map((p, index) => {
              const {
                MRP_Per_Quantity,
                TotalOrder_Quantity,
                HSN_Code,
                Batch_No,
                ...rest
              } = p;
              return {
                id: index + 1,
                ...rest,
                MRP: MRP_Per_Quantity,
                TotalQuantity: TotalOrder_Quantity,
                HSNCode: HSN_Code,
                BatchNo: Batch_No,
              };
            });

            setdatalist1(b_data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else if (GRNedit.Productype !== "Medical") {
        const Status = "Recieved";
        const Productype = GRNedit.Productype;

        axios
          .get(
            `${urllink}SupplierMaster/get_Non_Medical_GRN_data?Status=${Status}&Productype=${Productype}`
          )
          .then((response) => {
            console.log("INV22:", response.data);

            const getdatas1 = response.data.data.find(
              (ele) => ele.GRN_InvoiceNo === GRNedit.Invoice
            );
            console.log("getdatas1:", getdatas1);

            setOverallPurchaseRecieveFormData({
              GRN_Invoice_NO: getdatas1.GRN_InvoiceNo,
              GRN_Date: cu_date,
              ProductCategory: getdatas1.Product_Category,
              PurchaseRaiseInvoice: getdatas1.Raise_Invoice_No,
              SupplierCode: getdatas1.Supplier_Code,
              SupplierName: getdatas1.Supplier_Name,
              Supplier_Bill_No: getdatas1.Supplier_Bill_No,
              Supplier_Bill_Date: getdatas1.Supplier_Bill_Date,
              Supplier_Net_Amount: getdatas1.Bill_Net_Amount,
              Supplier_Bill_Due_Date: getdatas1.Supplier_Bill_Due_Date,
              CreatedBy: getdatas1.Created_By,
              Location: "CentralStore",
              RecievedBy: userRecord?.location,
            });
            setFinel_Amountdata({
              Grand_Total: getdatas1.Grand_Total,
              Total_Discount_Type: getdatas1.Total_Discount_Type,
              Total_Discount: getdatas1.Total_Discount,
              Finel_NetAmount: getdatas1.GRN_Finel_NetAmount,
            });

            const updateddd = dataURItoBlob(getdatas1.Bill_Document);

            setSelected_File(updateddd);

            const getdatas2 = getdatas1.items;
            console.log("getdatas2:", getdatas2);

            let b_data = getdatas2.map((p, index) => {
              const {
                MRP_Per_Quantity,
                TotalQuantity,
                HSN_Code,
                Batch_No,
                ...rest
              } = p;
              return {
                id: index + 1,
                ...rest,
                MRP: MRP_Per_Quantity,
                TotalQuantity: TotalQuantity,
              };
            });

            setdatalist1(b_data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    } else if (
      PurchaseInvoiceGRN &&
      Object.keys(PurchaseInvoiceGRN).length !== 0
    ) {
      axios
        .get(
          `${urllink}SupplierMaster/get_all_purchase_order_data?Productype=${PurchaseInvoiceGRN.Productype}&Invoice=${PurchaseInvoiceGRN.Invoice}`
        )
        .then((response) => {
          // console.log(response.data.data);

          let A_data = response.data.data[0];

          console.log('v1v2v3',A_data)

          axios
            .get(
              `${urllink}SupplierMaster/get_supplier_Master_Data?selectedSupplierIndex=${A_data.Supplier_Code}`
            )
            .then((response) => {
              console.log("SuplierData:", response.data);

              let datecalculatte = response.data[0].Payment_Due_Days;

              setPaymentduedays(+datecalculatte);
            })
            .catch((error) => {
              // Handle errors here
              console.error("Error fetching data:", error);
            });

          let b_data = response.data.data[0].items.map((p, index) => ({
            id: index + 1,
            ...p,
          }));

          console.log("POd", b_data);

          setOverallPurchaseRecieveFormData((prev) => ({
            ...prev,
            ProductCategory: A_data.ProductCategory,
            ProductCategoryType: A_data.ProductCategoryType,
            PurchaseRaiseInvoice: A_data.PurchaseOrder_InvoiceNo,
            SupplierCode: A_data.Supplier_Code,
            SupplierName: A_data.Supplier_Name,
            CreatedBy: A_data.Created_By,
            Location: A_data.Location,
            Purchaserise_date: A_data.Purchaserise_date,
          }));

          setdatalist(b_data);
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error fetching data:", error);
        });
    }
  }, [PurchaseInvoiceGRN, GRNedit]);

  useEffect(() => {
    if (datalist1.length !== 0) {
      // console.log('datalist1:',datalist1)

      let G_Total = 0;

      datalist1.map((ele, ind) => {
        return (G_Total += +ele.TotalAmount);
      });

      console.log("G_Total:", G_Total);

      setFinel_Amountdata((prev) => ({
        ...prev,
        Grand_Total: G_Total.toFixed(2),
        Finel_NetAmount: G_Total.toFixed(2),
      }));
    }
  }, [datalist1]);

  useEffect(() => {
    if (Finel_Amountdata.Total_Discount_Type === "Cash") {
      let CashDiscountAmount =
        Finel_Amountdata.Grand_Total - Finel_Amountdata.Total_Discount;
      setFinel_Amountdata((prevData) => ({
        ...prevData,
        Finel_NetAmount: CashDiscountAmount.toFixed(2),
      }));
    } else if (Finel_Amountdata.Total_Discount_Type === "Percentage") {
      const percentageDiscount = Math.max(
        0,
        Math.min(Finel_Amountdata.Total_Discount, 100)
      );

      const percentageDiscountAmount =
        Finel_Amountdata.Grand_Total * (percentageDiscount / 100);
      let CashDiscountAmount =
        Finel_Amountdata.Grand_Total - percentageDiscountAmount;

      setFinel_Amountdata((prevData) => ({
        ...prevData,
        Finel_NetAmount: CashDiscountAmount.toFixed(2),
      }));
    } else {
      setFinel_Amountdata((prevData) => ({
        ...prevData,
        Total_Discount: "",
        Finel_NetAmount: Finel_Amountdata.Grand_Total,
      }));
    }
  }, [
    Finel_Amountdata.Total_Discount_Type,
    Finel_Amountdata.Total_Discount,
    Finel_Amountdata.Grand_Total,
  ]);

  const dynamicColumns = () => {
    if (
      OverallPurchaseRecieveFormData.ProductCategoryType ===
        "MedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategoryType ===
        "MedicalNonConsumable"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 120 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 140 },
        { field: "ApprovedQuantity", headerName: "Order Quantity", width: 140 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 140 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else if (
      OverallPurchaseRecieveFormData.ProductCategoryType ===
        "NonMedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategoryType ===
        "NonMedicalNonCOnsumable" ||
      OverallPurchaseRecieveFormData.ProductCategoryType === "Surgical"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 150 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 140 },
        { field: "ApprovedQuantity", headerName: "Order Quantity", width: 140 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 140 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Pack_type", headerName: "Pack Type", width: 170 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 170 },
        { field: "ApprovedQuantity", headerName: "Order_Quantity", width: 170 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 140 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEditNon(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    }
  };
  const dynamicColumns1 = () => {
    if (
      OverallPurchaseRecieveFormData.ProductCategoryType ===
        "MedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategoryType ===
        "MedicalNonCOnsumable"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 100 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Order_Quantity", headerName: "Order Quantity", width: 150 },
        {
          field: "RecievedOrder_Quantity",
          headerName: "Recieved Quantity",
          width: 170,
        },
        { field: "PendingQuantity", headerName: "PendingQuantity", width: 170 },
        { field: "Reason", headerName: "Reason", width: 170 },
        { field: "MRP", headerName: "MRP", width: 150 },
        { field: "GST_Type", headerName: "GST Type", width: 170 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        {
          field: "Purchase_Rate_withtax",
          headerName: "Purchase Rate Withtax",
          width: 170,
        },
        { field: "DiscountType", headerName: "Discount Type", width: 170 },
        { field: "Discount", headerName: "Discount", width: 170 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else if (
      OverallPurchaseRecieveFormData.ProductCategoryType ===
        "NonMedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategoryType ===
        "NonMedicalNonConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategoryType === "Surgical"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Order_Quantity", headerName: "Order Quantity", width: 150 },
        {
          field: "RecievedOrder_Quantity",
          headerName: "Recieved Quantity",
          width: 170,
        },
        { field: "PendingQuantity", headerName: "PendingQuantity", width: 170 },
        { field: "Reason", headerName: "Reason", width: 170 },
        { field: "MRP", headerName: "MRP", width: 150 },
        { field: "GST_Type", headerName: "GST Type", width: 170 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        {
          field: "Purchase_Rate_withtax",
          headerName: "Purchase Rate Withtax",
          width: 170,
        },
        { field: "DiscountType", headerName: "Discount Type", width: 170 },
        { field: "Discount", headerName: "Discount", width: 170 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Pack_type", headerName: "Pack Type", width: 170 },
        { field: "Order_Quantity", headerName: "Pack Quantity", width: 170 },
        {
          field: "RecievedOrder_Quantity",
          headerName: "Recieved Quantity",
          width: 170,
        },
        { field: "PendingQuantity", headerName: "PendingQuantity", width: 170 },
        { field: "Reason", headerName: "Reason", width: 170 },
        { field: "MRP", headerName: "MRP", width: 170 },
        { field: "GST_Type", headerName: "GST Type", width: 170 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        {
          field: "Purchase_Rate_withtax",
          headerName: "Purchase Rate Withtax",
          width: 170,
        },
        { field: "DiscountType", headerName: "Discount Type", width: 170 },
        { field: "Discount", headerName: "Discount", width: 150 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEditNon1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    }
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };

  const handleInputFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "RecievedOrder_Quantity") {
      if (+PurchaseRecieveFormData.Order_Quantity >= +value) {
        if (+value <= +PurchaseRecieveFormData.Order_Quantity) {
          const newval = +PurchaseRecieveFormData.Order_Quantity - +value;
          const newval2 = +PurchaseRecieveFormData.Pack_Quantity * +value;

          console.log(newval);
          setPurchaseRecieveFormData((prev) => ({
            ...prev,
            [name]: value,
            PendingQuantity: newval,
            RecievedTotalQuantity: newval2,
          }));
        } else {
          setPurchaseRecieveFormData((prev) => ({
            ...prev,
            [name]: value,
            PendingQuantity: 0,
          }));
        }
      } else {
        alert("Enter Correct Recieved Quantity");
      }
    } else if (name === "Discount") {
      if (PurchaseRecieveFormData.DiscountType !== "") {
        if (PurchaseRecieveFormData.DiscountType === "Cash") {
          if (+value > +PurchaseRecieveFormData.Purchase_Rate_TotalAmount) {
            alert(
              `The discount value Must Under the PurchaseRateForStrip ${PurchaseRecieveFormData.Purchase_Rate_TotalAmount}`
            );
          } else {
            setPurchaseRecieveFormData((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        } else {
          if (+value > 100) {
            alert(`The discount percentage Must Under the 100`);
          } else {
            setPurchaseRecieveFormData((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        }
      } else {
        alert("Please select the Discount Type");
        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (
      PurchaseRecieveFormData.Discount !== "" ||
      PurchaseRecieveFormData.PurchaseRateForStrip !== "" ||
      PurchaseRecieveFormData.RecievedOrder_Quantity !== "" ||
      PurchaseRecieveFormData.Pack_Quantity !== ""
    ) {
      const newval1 = +PurchaseRecieveFormData.PurchaseRateForStrip;
      const newval2 =
        +newval1 * +PurchaseRecieveFormData.RecievedOrder_Quantity;
      const newval3 = +PurchaseRecieveFormData.Discount || 0;
      let discountamt = 0;

      console.log("3333", newval1, newval2, newval3);

      if (PurchaseRecieveFormData.DiscountType === "Cash") {
        discountamt = newval2 - newval3;
      } else if (PurchaseRecieveFormData.DiscountType === "Percentage") {
        discountamt = newval2 - (newval2 * newval3) / 100;
      } else {
        discountamt = newval2;

        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          Discount: "",
        }));
      }

      const nweval3 =
        +discountamt /
        (+PurchaseRecieveFormData.Pack_Quantity *
          +PurchaseRecieveFormData.RecievedOrder_Quantity);
      console.log(discountamt);
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Purchase_Rate_TotalAmount: newval2.toFixed(2),
        Purchase_Rate_TotalAmount_Dis: discountamt.toFixed(2),
        Purchase_Rate: nweval3.toFixed(2),
      }));
    }
  }, [
    PurchaseRecieveFormData.PurchaseRateForStrip,
    PurchaseRecieveFormData.RecievedOrder_Quantity,
    PurchaseRecieveFormData.Discount,
    PurchaseRecieveFormData.Pack_Quantity,
    PurchaseRecieveFormData.DiscountType,
  ]);

  useEffect(() => {
    const newval1 = +PurchaseRecieveFormData.Tax_Percentage || 0;
    const newval2 =
      (+PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis * +newval1) / 100;
    const newval3 =
      +PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis +
      (+PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis * +newval1) / 100;
    const newval4 = +PurchaseRecieveFormData.PurchaseRateForStrip + newval1;
    const newval5 = newval4 / +PurchaseRecieveFormData.Pack_Quantity;

    console.log("4444", newval1, newval2, newval3);

    if (PurchaseRecieveFormData.GST_Type === "GST") {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Tax_totalAmount: newval2.toFixed(2),
        TotalAmount: newval3.toFixed(2),
        PurchaseRateForStripGST: newval4.toFixed(2),
        Purchase_Rate_withtax: newval5.toFixed(2),
      }));
    } else if (PurchaseRecieveFormData.GST_Type === "IGST") {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Tax_totalAmount: newval2.toFixed(2),
        TotalAmount: newval3.toFixed(2),
        PurchaseRateForStripGST: newval4.toFixed(2),
        Purchase_Rate_withtax: newval5.toFixed(2),
      }));
    } else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Tax_totalAmount: 0,
        TotalAmount: +PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis,
        Tax_Percentage: "",
      }));
    }
  }, [
    PurchaseRecieveFormData.Purchase_Rate_withtax,
    PurchaseRecieveFormData.PurchaseRateForStripGST,
    PurchaseRecieveFormData.Tax_Percentage,
    PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis,
    PurchaseRecieveFormData.GST_Type,
  ]);

  // ------------------------PurchaseRecieveFormData Data--------------

  useEffect(() => {
    if (
      PurchaseRecieveFormData.Discount !== "" ||
      PurchaseRecieveFormData.PurchaseRateForStrip !== "" ||
      PurchaseRecieveFormData.RecievedOrder_Quantity !== "" ||
      PurchaseRecieveFormData.Pack_Quantity !== ""
    ) {
      const newval1 = +PurchaseRecieveFormData.PurchaseRateForStrip;
      const newval2 =
        +newval1 * +PurchaseRecieveFormData.RecievedOrder_Quantity;
      const newval3 = +PurchaseRecieveFormData.Discount || 0;
      let discountamt = 0;
      console.log("2222", newval1, newval2, newval3);

      if (PurchaseRecieveFormData.DiscountType === "Cash") {
        discountamt = newval2 - newval3;
      } else if (PurchaseRecieveFormData.DiscountType === "Percentage") {
        discountamt = newval2 - (newval2 * newval3) / 100;
      } else {
        discountamt = newval2;

        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          Discount: "",
        }));
      }
      const nweval3 =
        +discountamt /
        (+PurchaseRecieveFormData.Pack_Quantity *
          +PurchaseRecieveFormData.RecievedOrder_Quantity);
      console.log(discountamt);
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Purchase_Rate_TotalAmount: newval2.toFixed(2),
        Purchase_Rate_TotalAmount_Dis: discountamt.toFixed(2),
        Purchase_Rate: nweval3.toFixed(2),
      }));
    }
  }, [
    PurchaseRecieveFormData.PurchaseRateForStrip,
    PurchaseRecieveFormData.RecievedOrder_Quantity,
    PurchaseRecieveFormData.Discount,
    PurchaseRecieveFormData.Pack_Quantity,
    PurchaseRecieveFormData.DiscountType,
  ]);

  useEffect(() => {
    const newval1 = +PurchaseRecieveFormData.Tax_Percentage || 0;
    const newval2 =
      (+PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis * +newval1) /
      100;
    const newval3 =
      +PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis +
      (+PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis * +newval1) /
        100;
    const newval4 = +PurchaseRecieveFormData.PurchaseRateForStrip + newval1;
    const newval5 = newval4 / +PurchaseRecieveFormData.Pack_Quantity;

    console.log("1111", newval1, newval2, newval3);
    if (PurchaseRecieveFormData.GST_Type === "GST") {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Tax_totalAmount: newval2.toFixed(2),
        TotalAmount: newval3.toFixed(2),
        PurchaseRateForStripGST: newval4.toFixed(2),
        Purchase_Rate_withtax: newval5.toFixed(2),
      }));
    } else if (PurchaseRecieveFormData.GST_Type === "IGST") {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Tax_totalAmount: newval2.toFixed(2),
        TotalAmount: newval3.toFixed(2),
        PurchaseRateForStripGST: newval4.toFixed(2),
        Purchase_Rate_withtax: newval5.toFixed(2),
      }));
    } else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Tax_totalAmount: 0,
        TotalAmount: +PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis,
      }));
    }
  }, [
    PurchaseRecieveFormData.Purchase_Rate_withtax,
    PurchaseRecieveFormData.PurchaseRateForStripGST,
    PurchaseRecieveFormData.Tax_Percentage,
    PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis,
    PurchaseRecieveFormData.GST_Type,
  ]);

  useEffect(() => {
    if (+PurchaseRecieveFormData.PendingQuantity === 0) {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Reason: "Nill",
      }));
    } else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Reason: "",
      }));
    }
  }, [PurchaseRecieveFormData.PendingQuantity]);

  useEffect(() => {
    if (+PurchaseRecieveFormData.PendingQuantity === 0) {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        Reason: "Nill",
      }));
    }
  }, [PurchaseRecieveFormData.PendingQuantity]);

  const handleInputFinel_AmountdataChange = (e) => {
    const { name, value } = e.target;

    setFinel_Amountdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputFormNonChange = (e) => {
    const { name, value } = e.target;
    if (name === "RecievedOrder_Quantity") {
      if (+PurchaseRecieveFormData.Order_Quantity >= +value) {
        if (+value <= +PurchaseRecieveFormData.Order_Quantity) {
          const newval = +PurchaseRecieveFormData.Order_Quantity - +value;
          const newval2 = +PurchaseRecieveFormData.Pack_Quantity * +value;
          console.log(newval);

          setPurchaseRecieveFormData((prev) => ({
            ...prev,
            [name]: value,
            PendingQuantity: newval,
            RecievedTotalQuantity: newval2,
          }));
        } else {
          setPurchaseRecieveFormData((prev) => ({
            ...prev,
            [name]: value,
            PendingQuantity: 0,
            Reason: "Nill",
          }));
        }
      } else {
        alert("Enter Correct Recieved Quantity");
      }
    } else if (name === "Discount") {
      if (PurchaseRecieveFormData.DiscountType !== "") {
        if (PurchaseRecieveFormData.DiscountType === "Cash") {
          if (+value > +PurchaseRecieveFormData.Purchase_Rate_TotalAmount) {
            alert(
              `The discount value Must Under the PurchaseRateForStrip ${PurchaseRecieveFormData.Purchase_Rate_TotalAmount}`
            );
          } else {
            setPurchaseRecieveFormData((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        } else {
          if (+value > 100) {
            alert(`The discount percentage Must Under the 100`);
          } else {
            setPurchaseRecieveFormData((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        }
      } else {
        alert("Please select the Discount Type");
        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRequestEdit = (params) => {
    setPurchaseRecieveFormData((prev) => ({
      ...prev,
      Item_Code: params.Item_Code,
      Item_Name: params.Item_Name,
      Generic_Name: params.Generic_Name,
      Strength: params.Strength,
      UOM: params.UOM,
      Pack_type: params.Pack_type,
      Pack_Quantity: params.Pack_Quantity,
      Order_Quantity: params.ApprovedQuantity,
      TotalQuantity: params.TotalQuantity,
    }));
  };

  const handleRequestEdit1 = (params) => {
    setPurchaseRecieveFormData({ ...params });
    setisedit(params);
  };
  const handleAdd = () => {
    let requiredFields;

    if (
      OverallPurchaseRecieveFormData.ProductCategoryType == "Surgical" ||
      OverallPurchaseRecieveFormData.ProductCategoryType ==
        "NonMedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategoryType ==
        "NonMedicalNonConsumable"
    ) {
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Generic_Name",
        "Pack_type",
        "Order_Quantity",
        "TotalQuantity",
        "RecievedOrder_Quantity",
        "RecievedTotalQuantity",
        "HSNCode",
        "BatchNo",
        "ManufactureDate",
        "ExpiryDate",
        "MRP",
        "Purchase_Rate",
        "GST_Type",
        "Tax_Percentage",
        "TotalAmount",
      ];
    } else {
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Generic_Name",
        "Strength",
        "UOM",
        "Pack_type",
        "Order_Quantity",
        "TotalQuantity",
        "RecievedOrder_Quantity",
        "RecievedTotalQuantity",
        "HSNCode",
        "BatchNo",
        "ManufactureDate",
        "ExpiryDate",
        "MRP",
        "Purchase_Rate",
        "GST_Type",
        "Tax_Percentage",
        "TotalAmount",
      ];
    }
    if (
      PurchaseRecieveFormData.PendingQuantity !== 0 &&
      PurchaseRecieveFormData.PendingQuantity !== ""
    ) {
      requiredFields.push("Reason");
    }

    if (PurchaseRecieveFormData.DiscountType !== "") {
      requiredFields.push("Discount");
    }
    if (
      (PurchaseRecieveFormData.GST === null &&
        PurchaseRecieveFormData.IGST === null) ||
      (PurchaseRecieveFormData.GST === 0 &&
        PurchaseRecieveFormData.IGST === 0) ||
      (PurchaseRecieveFormData.GST === "" &&
        PurchaseRecieveFormData.IGST === "")
    ) {
      requiredFields.push("GST");
    }

    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      console.log("PurchaseRecieveFormData", PurchaseRecieveFormData);
      let id = 0;
      const data = { id: datalist1.length + 1, ...PurchaseRecieveFormData };

      console.log("dataArray", data);
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) =>
        prev.filter(
          (item) => item.Item_Code !== PurchaseRecieveFormData.Item_Code
        )
      );
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleUpdate = () => {
    const requiredFields = [
      "Item_Code",
      "Item_Name",
      "Generic_Name",
      "Strength",
      "UOM",
      "Pack_type",
      "Order_Quantity",
      "TotalQuantity",
      "RecievedOrder_Quantity",
      "RecievedTotalQuantity",
      "HSNCode",
      "BatchNo",
      "ManufactureDate",
      "ExpiryDate",
      "MRP",
      "Purchase_Rate",
      "GST_Type",
      "Tax_Percentage",
      "TotalAmount",
    ];

    if (
      PurchaseRecieveFormData.PendingQuantity !== 0 &&
      PurchaseRecieveFormData.PendingQuantity !== ""
    ) {
      requiredFields.push("Reason");
    }

    if (PurchaseRecieveFormData.DiscountType !== "") {
      requiredFields.push("Discount");
    }
    if (
      (PurchaseRecieveFormData.GST === null &&
        PurchaseRecieveFormData.IGST === null) ||
      (PurchaseRecieveFormData.GST === 0 &&
        PurchaseRecieveFormData.IGST === 0) ||
      (PurchaseRecieveFormData.GST === "" &&
        PurchaseRecieveFormData.IGST === "")
    ) {
      requiredFields.push("GST");
    }
    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex(
        (item) => item.Item_Code === isedit.Item_Code
      );
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData;
        return updatedCart;
      });
      setisedit(null);
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  // non medical
  const handleRequestEditNon = (params) => {
    console.log('lllll',params);
    setPurchaseRecieveFormData((prev) => ({
      ...prev,
      Item_Code: params.Item_Code,
      Item_Name: params.Item_Name,
      Generic_Name: params.Generic_Name,
      Pack_type: params.Pack_type,
      Pack_Quantity: params.Pack_Quantity,
      Order_Quantity: params.ApprovedQuantity,
      TotalQuantity: params.TotalQuantity,
    }));
  };


  const handleRequestEditNon1 = (params) => {
    setPurchaseRecieveFormData({ ...params });
    setisedit(params);
  };
  const handleClear = () => {
    
    setPurchaseRecieveFormData((prev)=>({
        ...prev,
        Item_Code: "",
        Item_Name: "",
        Generic_Name: "",
        Strength: "",
        UOM: "",
        Pack_type: "",
        Pack_Quantity: "",
        Order_Quantity: "",
        TotalQuantity: "",
        RecievedOrder_Quantity: "",
        RecievedTotalQuantity: "",
        PendingQuantity: "",
        Reason: "",
        HSNCode: "",
        BatchNo: "",
        ManufactureDate: "",
        ExpiryDate: "",
        MRP: "",
        PurchaseRateForStrip: "",
        PurchaseRateForStripGST: "",
        Purchase_Rate_TotalAmount: "",
        Purchase_Rate_TotalAmount_Dis: "",
        Purchase_Rate: "",
        Purchase_Rate_withtax: "",
        GST_Type: "",
        Tax_Percentage: "",
        DiscountType: "",
        Discount: "",
        Tax_totalAmount: "",
        TotalAmount: "",
    }))
    
  }

  const handleAddNon = () => {
    const requiredFields = [
      "Item_Code",
      "Item_Name",
      "Pack_type",
      "Pack_Quantity",
      "Order_Quantity",
      "TotalQuantity",
      "RecievedOrder_Quantity",
      "RecievedTotalQuantity",
      "MRP",
      "Purchase_Rate",
      "GST_Type",
      "Tax_Percentage",
      "Purchase_Rate_withtax",
      "TotalAmount",
    ];

    if (
      PurchaseRecieveFormData.PendingQuantity !== 0 &&
      PurchaseRecieveFormData.PendingQuantity !== ""
    ) {
      requiredFields.push("Reason");
    }

    if (PurchaseRecieveFormData.DiscountType !== "") {
      requiredFields.push("Discount");
    }
    if (
      (PurchaseRecieveFormData.GST === null &&
        PurchaseRecieveFormData.IGST === null) ||
      (PurchaseRecieveFormData.GST === 0 &&
        PurchaseRecieveFormData.IGST === 0) ||
      (PurchaseRecieveFormData.GST === "" &&
        PurchaseRecieveFormData.IGST === "")
    ) {
      requiredFields.push("GST");
    }
    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const data = { id: datalist1.length + 1, ...PurchaseRecieveFormData };
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) =>
        prev.filter(
          (item) => item.Item_Code !== PurchaseRecieveFormData.Item_Code
        )
      );
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };
  const handleUpdateNon = () => {
    const requiredFields = [
      "Item_Code",
      "Item_Name",
      "Pack_type",
      "Pack_Quantity",
      "Order_Quantity",
      "TotalQuantity",
      "RecievedOrder_Quantity",
      "RecievedTotalQuantity",
      "MRP",
      "Purchase_Rate",
      "GST_Type",
      "Tax_Percentage",
      "Purchase_Rate_withtax",
      "TotalAmount",
    ];

    if (
      PurchaseRecieveFormData.PendingQuantity !== 0 &&
      PurchaseRecieveFormData.PendingQuantity !== ""
    ) {
      requiredFields.push("Reason");
    }

    if (PurchaseRecieveFormData.DiscountType !== "") {
      requiredFields.push("Discount");
    }
    if (
      (PurchaseRecieveFormData.GST === null &&
        PurchaseRecieveFormData.IGST === null) ||
      (PurchaseRecieveFormData.GST === 0 &&
        PurchaseRecieveFormData.IGST === 0) ||
      (PurchaseRecieveFormData.GST === "" &&
        PurchaseRecieveFormData.IGST === "")
    ) {
      requiredFields.push("GST");
    }

    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex(
        (item) => item.Item_Code === isedit.Item_Code
      );
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData;
        return updatedCart;
      });
      setisedit(null);
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  // const handleExportToExcel = () => {
  //     if(datalist1.length !==0){
  //         let columns;
  //         if(OverallPurchaseRecieveFormData.ProductCategory === 'Medical'){
  //            columns = [
  //                 { header: 'Item Code', dataKey: 'Item_Code' },
  //                 { header: 'Item Name', dataKey: 'Item_Name' },
  //                 { header: 'Generic_Name', dataKey: 'Generic_Name' },
  //                 { header: 'Strength', dataKey: 'Strength' },
  //                 { header: 'UOM', dataKey: 'UOM' },
  //                 { header: 'ManufactureDate', dataKey: 'ManufactureDate' },
  //                 { header: 'ExpiryDate', dataKey: 'ExpiryDate' },
  //                 { header: 'HSNCode', dataKey: 'HSNCode' },
  //                 { header: 'BatchNo', dataKey: 'BatchNo' },
  //                 { header: 'Pack_type', dataKey: 'Pack' },
  //                 { header: 'Order_Quantity', dataKey: 'Order_Quantity' },
  //                 { header: 'PerOrder_Quantity', dataKey: 'PerOrder_Quantity' },
  //                 { header: 'RecievedPack', dataKey: 'RecievedPack' },
  //                 { header: 'RecievedOrder_Quantity', dataKey: 'RecievedOrder_Quantity' },
  //                 { header: 'RecievedPerOrder_Quantity', dataKey: 'RecievedPerOrder_Quantity' },
  //                 { header: 'PendingQuantity', dataKey: 'PendingQuantity' },
  //                 { header: 'Reason', dataKey: 'Reason' },
  //                 { header: 'MRP', dataKey: 'MRP' },
  //                 { header: 'GST', dataKey: 'GST' },
  //                 { header: 'IGST', dataKey: 'IGST' },
  //                 { header: 'DiscountType', dataKey: 'DiscountType' },
  //                 { header: 'Discount', dataKey: 'Discount' },
  //                 { header: 'TotalAmount', dataKey: 'TotalAmount' },
  //               ];
  //         }else{
  //             columns = [
  //                 { header: 'Item Code', dataKey: 'Item_Code' },
  //                 { header: 'Item Name', dataKey: 'Item_Name' },
  //                 { header: 'Pack_type', dataKey: 'Pack' },
  //                 { header: 'Order_Quantity', dataKey: 'Order_Quantity' },
  //                 { header: 'PerOrder_Quantity', dataKey: 'PerOrder_Quantity' },
  //                 { header: 'RecievedPack', dataKey: 'RecievedPack' },
  //                 { header: 'RecievedOrder_Quantity', dataKey: 'RecievedOrder_Quantity' },
  //                 { header: 'RecievedPerOrder_Quantity', dataKey: 'RecievedPerOrder_Quantity' },
  //                 { header: 'PendingQuantity', dataKey: 'PendingQuantity' },
  //                 { header: 'Reason', dataKey: 'Reason' },
  //                 { header: 'MRP', dataKey: 'MRP' },
  //                 { header: 'GST', dataKey: 'GST' },
  //                 { header: 'IGST', dataKey: 'IGST' },
  //                 { header: 'DiscountType', dataKey: 'DiscountType' },
  //                 { header: 'Discount', dataKey: 'Discount' },
  //                 { header: 'TotalAmount', dataKey: 'TotalAmount' },
  //               ];
  //         }

  //         const header = columns.map(col => col.header);

  //         const csv = [
  //             `ProductCategory : ${OverallPurchaseRecieveFormData.ProductCategory}`,
  //             `PurchaseRaiseInvoice : ${OverallPurchaseRecieveFormData.PurchaseRaiseInvoice}`,
  //             `PurchaseRaiseDate : ${OverallPurchaseRecieveFormData.Purchaserise_date}`,
  //             `Raised By : ${OverallPurchaseRecieveFormData.CreatedBy}`,
  //             `SupplierCode : ${OverallPurchaseRecieveFormData.SupplierCode}`,
  //             `SupplierName : ${OverallPurchaseRecieveFormData.SupplierName}`,
  //             `GRN Invoice : ${OverallPurchaseRecieveFormData.PurchaseRecieveInvoice}`,
  //             `GRN Date : ${OverallPurchaseRecieveFormData.Purchaserecieve_date}`,
  //             `GRN Recieved By : ${OverallPurchaseRecieveFormData.RecievedBy}`,
  //             " ",
  //             '\ufeff' + header.join(","), // BOM + header row
  //             ...datalist1.map(row => columns.map(col => row[col.dataKey])),
  //             '', // Empty row for spacing
  //         ].join("\r\n");

  //         var data = new Blob([csv], { type: "text/csv" });
  //         // Fix the template literal for saveAs function
  //         saveAs(data, `Report_${new Date().toISOString()}.csv`);
  //     }else{
  //         alert('No Data to Save')
  //     }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "Supplier_Bill_Date") {
      const paymentDueDays = parseInt(+Paymentduedays, 10);

      const supplierBillDate = new Date(value); // Convert the string to a Date object

      const newdates = addDays(new Date(supplierBillDate), paymentDueDays);

      setOverallPurchaseRecieveFormData({
        ...OverallPurchaseRecieveFormData,
        [name]: value,
        Supplier_Bill_Due_Date: format(newdates, "yyyy-MM-dd"),
      });
    } else {
      setOverallPurchaseRecieveFormData({
        ...OverallPurchaseRecieveFormData,
        [name]: value,
      });
    }
  };

  const handleSubmit = () => {
    

    if (
      +OverallPurchaseRecieveFormData.Supplier_Net_Amount ===
      +Finel_Amountdata.Finel_NetAmount
    ) {
      const requiredFields = [
        "ProductCategory",
        "GRN_Invoice_NO",
        "GRN_Date",
        "PurchaseRaiseInvoice",
        "SupplierCode",
        "SupplierName",
        "Supplier_Bill_No",
        "Supplier_Bill_Date",
        "Supplier_Net_Amount",
        "Supplier_Bill_Due_Date",
        "Purchaserise_date",
      ];
      const missingFields = requiredFields.filter(
        (field) => !OverallPurchaseRecieveFormData[field]
      );
      if( !(capturedImageSec || Selected_File)){
        missingFields.push('Bill Documennt')
      }

      if (missingFields.length === 0) {
        let newdataa;
        if (
          Finel_Amountdata.Total_Discount_Type !== "" &&
          Finel_Amountdata.Total_Discount !== ""
        ) {
          if (Finel_Amountdata.Total_Discount_Type === "Cash") {
            const FinelDis = Finel_Amountdata.Total_Discount / datalist1.length;

            const AfterDisdata = datalist1.map((ele, ind) => {
              let A_Amout = ele.Purchase_Rate_TotalAmount - FinelDis;
              let GST_A_Amout = (+A_Amout * +ele.Tax_Percentage) / 100;
              let Total_A_Amout = +A_Amout + +GST_A_Amout;
              let PICAmount = A_Amout / +ele.RecievedTotalQuantity;
              let PICAmountGSTF = Total_A_Amout / +ele.RecievedTotalQuantity;

              console.log("dETAILE", PICAmount, FinelDis);
              console.log("dETAILE", Total_A_Amout);

              return {
                ...ele,
                DiscountType: "OverallCash",
                Discount: FinelDis,
                Tax_totalAmount: GST_A_Amout.toFixed(2),
                TotalAmount: Total_A_Amout.toFixed(2),
                Purchase_Rate_TotalAmount_Dis: A_Amout.toFixed(2),
                Purchase_Rate: PICAmount.toFixed(2),
                Purchase_Rate_withtax: PICAmountGSTF.toFixed(2),
              };
            });

            console.log("AfterDisdata:", AfterDisdata);

            newdataa = {
              OverallPurchaseRecieveFormData: {
                ...OverallPurchaseRecieveFormData,
              },
              Finel_Amountdata: { ...Finel_Amountdata },
              datalist1: [...AfterDisdata],
            };
            console.log(newdataa);
          } else if (Finel_Amountdata.Total_Discount_Type === "Percentage") {
            const FinelDis = +Finel_Amountdata.Total_Discount;

            const AfterDisdata = datalist1.map((ele, ind) => {
              let A_Amout =
                ele.Purchase_Rate_TotalAmount -
                +ele.Purchase_Rate_TotalAmount * (FinelDis / 100);
              let GST_A_Amout = (+A_Amout * +ele.Tax_Percentage) / 100;
              let Total_A_Amout = +A_Amout + +GST_A_Amout;
              let PICAmount = A_Amout / +ele.RecievedTotalQuantity;
              let PICAmountGSTF = Total_A_Amout / +ele.RecievedTotalQuantity;

              console.log("dETAILE", PICAmount);

              return {
                ...ele,
                DiscountType: "OverallPercentage",
                Discount: FinelDis,
                Tax_totalAmount: GST_A_Amout.toFixed(2),
                TotalAmount: Total_A_Amout.toFixed(2),
                Purchase_Rate_TotalAmount_Dis: A_Amout.toFixed(2),
                Purchase_Rate: PICAmount.toFixed(2),
                Purchase_Rate_withtax: PICAmountGSTF.toFixed(2),
              };
            });

            console.log("AfterDisdata", AfterDisdata);

            newdataa = {
              OverallPurchaseRecieveFormData: {
                ...OverallPurchaseRecieveFormData,
              },
              Finel_Amountdata: { ...Finel_Amountdata },
              datalist1: [...AfterDisdata],
            };
          }
        } else {
          newdataa = {
            OverallPurchaseRecieveFormData: {
              ...OverallPurchaseRecieveFormData,
            },
            Finel_Amountdata: { ...Finel_Amountdata },
            datalist1: [...datalist1],
          };
        }

        console.log(newdataa);

        let FormData_all = new FormData();

        for (let key in newdataa) {
          FormData_all.append(key, JSON.stringify(newdataa[key]));
        }
        FormData_all.append(
          "Selected_File",
          capturedImageSec ? capturedImageSec : Selected_File
        );
        console.log(newdataa);

       if (
          OverallPurchaseRecieveFormData.ProductCategory === "Medical" ||
          OverallPurchaseRecieveFormData.ProductCategory === "Non_Medical"
        ) {
          axios
            .post(
              `${urllink}SupplierMaster/insert_Medical_GRN_data`,
              FormData_all
            )
            .then((response) => {
              console.log(response.data);
              navigate("/Home/Purchase_Recieve_list");
            })
            .catch((error) => {
              console.error("Error posting GRN data:", error);
            });
        } else if (
          OverallPurchaseRecieveFormData.ProductCategory === "Assets"
        ) {
          axios
            .post(
              `${urllink}SupplierMaster/insert_Assets_GRN_data`,
              FormData_all
            )
            .then((response) => {
              console.log(response.data);
              navigate("/Home/Purchase_Recieve_list");
            })
            .catch((error) => {
              console.error("Error posting GRN data:", error);
            });
        } else if (
          OverallPurchaseRecieveFormData.ProductCategory === "Stationary"
        ) {
          axios
            .post(
              `${urllink}SupplierMaster/insert_Stationary_GRN_data`,
              FormData_all
            )
            .then((response) => {
              console.log(response.data);
              navigate("/Home/Purchase_Recieve_list");
            })
            .catch((error) => {
              console.error("Error posting GRN data:", error);
            });
        } else if (
          OverallPurchaseRecieveFormData.ProductCategory === "Non_Stationary"
        ) {
          axios
            .post(
              `${urllink}SupplierMaster/insert_Non_Stationary_GRN_data`,
              FormData_all
            )
            .then((response) => {
              console.log(response.data);
              navigate("/Home/Purchase_Recieve_list");
            })
            .catch((error) => {
              console.error("Error posting GRN data:", error);
            });
        }
      } else {
        alert(
          `Please fill out all required fields: ${missingFields.join(", ")}`
        );
      }
    } else {
      alert("Please Check Net Amount");
    }
  };

  const handleUpdatedatas = () => {
    // if (+OverallPurchaseRecieveFormData.Supplier_Net_Amount === +Finel_Amountdata.Finel_NetAmount) {
    //     console.log('datalist1:',datalist1)
    //     let FormData_all = new FormData();
    //     const newdataa = {
    //         OverallPurchaseRecieveFormData: { ...OverallPurchaseRecieveFormData },
    //         Finel_Amountdata: { ...Finel_Amountdata },
    //         datalist1: [...datalist1],
    //         product_category:{product_category:OverallPurchaseRecieveFormData.ProductCategory}
    //     };
    //     for (let key in newdataa) {
    //         FormData_all.append(key, JSON.stringify(newdataa[key]));
    //     }
    //     FormData_all.append("Selected_File", capturedImageSec ? capturedImageSec : Selected_File)
    //     axios.post('${urllink}SupplierMaster/update_GRN_data', FormData_all)
    //         .then((response) => {
    //             console.log(response.data);
    //             navigate('/Purchase_Recieve_list');
    //             setGRNedit({})
    //         })
    //         .catch((error) => {
    //             console.error('Error posting GRN data:', error);
    //         });
    // } else {
    //     alert('Please Check Net Amount');
    // }
  };

  const handleOpenCamera = () => {
    setShowCamera(true);
  };

  const handleHideCamera = () => {
    setShowCamera(false);
  };

  const handleRecaptureImageSec = () => {
    setCapturedImageSec(null);
    setIsImageCaptured(false);
  };

  const capturedImageSecnd = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = dataURItoBlob(imageSrc);
    setCapturedImageSec(blob);
    setIsImageCaptured(true);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleCloseCamera = () => {
    setShowCamera(false);
  };

  return (
    <>
      <div className="Supplier_Master_Container">
        <div className="Product_Master_h_head">
          <h3>GRN Invoice</h3>
        </div>
        <div className="Stoct_Product_master_Container">
          <div className="Stoct_Product_master_form">
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  GRN Invoice No :
                </label>
                <input
                  type="text"
                  name="GRN_Invoice_NO"
                  placeholder="Enter Product Category"
                  value={OverallPurchaseRecieveFormData.GRN_Invoice_NO}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  GRN Date :
                </label>
                <input
                  type="date"
                  name="GRN_Date"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.GRN_Date}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Product Category :
                </label>
                <input
                  type="text"
                  name="ProductCategory"
                  placeholder="Enter Product Category"
                  value={OverallPurchaseRecieveFormData.ProductCategory}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Raise Invoice No :
                </label>
                <input
                  type="text"
                  name="PurchaseRaiseInvoice"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.PurchaseRaiseInvoice}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Stoct_Product_master_Container">
          <div className="Stoct_Product_master_form">
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Supplier Code :
                </label>
                <input
                  type="text"
                  name="SupplierCode"
                  placeholder="Enter Supplier Code"
                  value={OverallPurchaseRecieveFormData.SupplierCode}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Supplier Name :
                </label>
                <input
                  type="text"
                  name="SupplierName"
                  placeholder="Enter Supplier Name"
                  value={OverallPurchaseRecieveFormData.SupplierName}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Supplier Bill No :
                </label>
                <input
                  type="text"
                  name="Supplier_Bill_No"
                  placeholder="Enter Supplier Bill No"
                  value={OverallPurchaseRecieveFormData.Supplier_Bill_No}
                  className="Stoct_Product_master_form_Input"
                  onChange={handleInputChange}
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Supplier Bill Date :
                </label>
                <input
                  type="date"
                  name="Supplier_Bill_Date"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.Supplier_Bill_Date}
                  className="Stoct_Product_master_form_Input"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Stoct_Product_master_Container">
          <div className="Stoct_Product_master_form">
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Bill Net Amount :
                </label>
                <input
                  type="text"
                  name="Supplier_Net_Amount"
                  placeholder="Enter Supplier Code"
                  value={OverallPurchaseRecieveFormData.Supplier_Net_Amount}
                  autoComplete="off"
                  className="Stoct_Product_master_form_Input"
                  onChange={handleInputChange}
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Supplier Bill Due Date :
                </label>
                <input
                  type="date"
                  name="Supplier_Bill_Due_Date"
                  placeholder="Enter Supplier Name"
                  value={OverallPurchaseRecieveFormData.Supplier_Bill_Due_Date}
                  className="Stoct_Product_master_form_Input"
                  onChange={handleInputChange}
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="patientPhoto"
                >
                  Bill Document:
                </label>
                <div className="file-input-444">
                  <div className="input-container-99">
                    <input
                      type="file"
                      id="fileChooser1"
                      name="fileChooser1"
                      accept=".pdf, image/*" // Corrected accept attribute
                      required
                      onChange={(e) => {
                        // Use e.target.files to access selected files
                        const selectedFile = e.target.files[0];

                        // Check if a file was selected
                        if (selectedFile) {
                          // Do something with the selected file, e.g., set state or call a function
                          setSelected_File(selectedFile);
                        }
                      }}
                    />
                    <label
                      htmlFor="fileChooser1"
                      className="choose-file-button-99"
                    >
                      Choose File
                    </label>
                  </div>
                  <span className="Stoct_Product_master_form_Label">or</span>
                  <div className="input-container-99">
                    <button
                      onClick={handleOpenCamera}
                      className="choose-file-button-55"
                    >
                      Take Pic
                    </button>

                    {showCamera && (
                      <div
                        className="showcamera_takepic"
                        onClick={handleHideCamera}
                      >
                        <div
                          className="showcamera_1_takepic1"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {isImageCaptured ? ( // Display the captured image
                            <img
                              src={URL.createObjectURL(capturedImageSec)}
                              alt="captured"
                              className="captured-image11"
                            />
                          ) : (
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              mirrored={true}
                              className="web_cam"
                            />
                          )}
                          <div className="web_btn">
                            {isImageCaptured ? ( // Render the Recapture button if an image is captured
                              <button
                                onClick={handleRecaptureImageSec}
                                className="btncon_add"
                              >
                                Recapture
                              </button>
                            ) : (
                              <button
                                onClick={capturedImageSecnd}
                                className="btncon_add"
                              >
                                Capture
                              </button>
                            )}
                            <button
                              onClick={handleCloseCamera}
                              className="btncon_add"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={datalist.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns()} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && datalist.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && datalist.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>

        {(OverallPurchaseRecieveFormData.ProductCategoryType ===
          "MedicalConsumable" ||
          OverallPurchaseRecieveFormData.ProductCategoryType ===
            "MedicalNonConsumable") && (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Item Code :
                  </label>
                  <input
                    type="text"
                    name="Item_Code"
                    placeholder="Enter Item Code"
                    value={PurchaseRecieveFormData.Item_Code}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Item Name :
                  </label>
                  <input
                    type="text"
                    name="Item_Name"
                    placeholder="Enter Item Name"
                    value={PurchaseRecieveFormData.Item_Name}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Generic Name :
                  </label>
                  <input
                    type="text"
                    name="Generic_Name"
                    placeholder="Enter Generic Name"
                    value={PurchaseRecieveFormData.Generic_Name}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Strength :
                  </label>
                  <input
                    type="text"
                    name="Strength"
                    placeholder="Enter Strength"
                    value={PurchaseRecieveFormData.Strength}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    UOM :
                  </label>
                  <input
                    type="text"
                    name="UOM"
                    placeholder="Enter UOM"
                    value={PurchaseRecieveFormData.UOM}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Pack Type :
                  </label>
                  <input
                    type="text"
                    name="Pack_type"
                    placeholder="Enter Pack Type"
                    value={PurchaseRecieveFormData.Pack_type}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pack Quantity :
                  </label>
                  <input
                    type="number"
                    name="Pack_Quantity"
                    placeholder="Enter Pack Quantity"
                    value={PurchaseRecieveFormData.Pack_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Order Quantity :
                  </label>
                  <input
                    type="number"
                    name="Order_Quantity"
                    placeholder="Enter Order Quantity"
                    value={PurchaseRecieveFormData.Order_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="TotalQuantity"
                    placeholder="Enter Total Quantity"
                    value={PurchaseRecieveFormData.TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Recieved Quantity :
                  </label>
                  <input
                    type="number"
                    name="RecievedOrder_Quantity"
                    placeholder="Enter Recieved Pack Quantity "
                    value={PurchaseRecieveFormData.RecievedOrder_Quantity}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Recieved Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="RecievedTotalQuantity"
                    placeholder="Enter Recieved Recieved TotalQuantity"
                    value={PurchaseRecieveFormData.RecievedTotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pending Quantity :
                  </label>
                  <input
                    type="text"
                    name="PendingQuantity"
                    placeholder="Enter Pending Quantity"
                    value={PurchaseRecieveFormData.PendingQuantity}
                    onChange={handleInputFormChange}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Reason :
                  </label>
                  <input
                    type="text"
                    name="Reason"
                    placeholder="Enter Reason"
                    value={PurchaseRecieveFormData.Reason}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    readOnly={+PurchaseRecieveFormData.PendingQuantity === 0}
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    HSN Code :
                  </label>
                  <input
                    type="text"
                    name="HSNCode"
                    placeholder="Enter HSNCode"
                    value={PurchaseRecieveFormData.HSNCode}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Batch No :
                  </label>
                  <input
                    type="text"
                    name="BatchNo"
                    placeholder="Enter Batch No"
                    value={PurchaseRecieveFormData.BatchNo}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Manufacture Date :
                  </label>
                  <input
                    type="date"
                    name="ManufactureDate"
                    placeholder="Enter ManufactureDate"
                    value={PurchaseRecieveFormData.ManufactureDate}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Expiry Date :
                  </label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    placeholder="Enter ExpiryDate"
                    value={PurchaseRecieveFormData.ExpiryDate}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    MRP :
                  </label>
                  <input
                    type="number"
                    name="MRP"
                    placeholder="Enter MRP"
                    value={PurchaseRecieveFormData.MRP}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Purchase Rate (per Pack):
                  </label>
                  <input
                    type="number"
                    name="PurchaseRateForStrip"
                    placeholder="Enter Purchase Rate"
                    value={PurchaseRecieveFormData.PurchaseRateForStrip}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Purchase Rate :
                  </label>
                  <input
                    type="number"
                    name="Purchase_Rate_TotalAmount"
                    placeholder="Enter Total Purchase Rate"
                    value={PurchaseRecieveFormData.Purchase_Rate_TotalAmount}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                    disabled={PurchaseRecieveFormData.DiscountType === ""}
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Discount Type :
                  </label>
                  <select
                    value={PurchaseRecieveFormData.DiscountType}
                    name="DiscountType"
                    onChange={handleInputFormChange}
                    disabled={Finel_Amountdata.Total_Discount_Type !== ""}
                  >
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Discount :
                  </label>
                  <input
                    type="number"
                    name="Discount"
                    placeholder="Enter Discount"
                    value={PurchaseRecieveFormData.Discount}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                    disabled={PurchaseRecieveFormData.DiscountType === ""}
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Total Taxable Purchase Rate :
                  </label>
                  <input
                    type="number"
                    name="PurchaseRateForStripGST"
                    placeholder="Enter Purchase Rate"
                    value={
                      PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis
                    }
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    {" "}
                    Taxable Purchase Rate (Per Quantity):
                  </label>
                  <input
                    type="number"
                    name="Purchase_Rate"
                    placeholder="Enter Purchase Rate"
                    value={PurchaseRecieveFormData.Purchase_Rate}
                    onChange={handleInputFormChange}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Tax Type:
                  </label>
                  <select
                    value={PurchaseRecieveFormData.GST_Type}
                    name="GST_Type"
                    onChange={handleInputFormChange}
                  >
                    <option value="">Select</option>
                    <option value="GST">GST</option>
                    <option value="IGST">IGST</option>
                    <option value="NON_GST">NON-GST</option>
                  </select>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Tax Percentage
                  </label>

                  <select
                    name="Tax_Percentage"
                    placeholder="Enter Tax Percentage"
                    value={PurchaseRecieveFormData.Tax_Percentage}
                    onChange={handleInputFormChange}
                    required
                    disabled={
                      PurchaseRecieveFormData.GST_Type === "NON_GST" ||
                      PurchaseRecieveFormData.GST_Type === "NON_GST"
                    }
                  >
                    <option value="">Select</option>
                    <option value={0}>Nill</option>
                    <option value={28}>28 %</option>
                    <option value={18}>18 %</option>
                    <option value={12}>12 %</option>
                    <option value={5}>5 %</option>
                  </select>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Tax Amount :
                  </label>
                  <input
                    type="number"
                    name="Tax_totalAmount"
                    placeholder="Enter Tax Amount"
                    value={PurchaseRecieveFormData.Tax_totalAmount}
                    onChange={handleInputFormChange}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Amount :
                  </label>
                  <input
                    type="number"
                    name="TotalAmount"
                    placeholder="Enter Total Amount"
                    value={PurchaseRecieveFormData.TotalAmount}
                    onChange={handleInputFormChange}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_Save">
                <button
                  className=" Stoct_Product_master_form_Save_button"
                  onClick={isedit ? handleUpdate : handleAdd}
                >
                  {isedit ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {OverallPurchaseRecieveFormData.ProductCategoryType === "Surgical" && (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Item Code :
                  </label>
                  <input
                    type="text"
                    name="Item_Code"
                    placeholder="Enter Item Code"
                    value={PurchaseRecieveFormData.Item_Code}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Item Name :
                  </label>
                  <input
                    type="text"
                    name="Item_Name"
                    placeholder="Enter Item Name"
                    value={PurchaseRecieveFormData.Item_Name}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Generic Name :
                  </label>
                  <input
                    type="text"
                    name="Generic_Name"
                    placeholder="Enter Generic Name"
                    value={PurchaseRecieveFormData.Generic_Name}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Pack Type :
                  </label>
                  <input
                    type="text"
                    name="Pack_type"
                    placeholder="Enter Pack Type"
                    value={PurchaseRecieveFormData.Pack_type}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pack Quantity :
                  </label>
                  <input
                    type="number"
                    name="Pack_Quantity"
                    placeholder="Enter Pack Quantity"
                    value={PurchaseRecieveFormData.Pack_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Order Quantity :
                  </label>
                  <input
                    type="number"
                    name="Order_Quantity"
                    placeholder="Enter Order Quantity"
                    value={PurchaseRecieveFormData.Order_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="TotalQuantity"
                    placeholder="Enter Total Quantity"
                    value={PurchaseRecieveFormData.TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Recieved Quantity :
                  </label>
                  <input
                    type="number"
                    name="RecievedOrder_Quantity"
                    placeholder="Enter Recieved Pack Quantity "
                    value={PurchaseRecieveFormData.RecievedOrder_Quantity}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Recieved Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="RecievedTotalQuantity"
                    placeholder="Enter Recieved Recieved TotalQuantity"
                    value={PurchaseRecieveFormData.RecievedTotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pending Quantity :
                  </label>
                  <input
                    type="text"
                    name="PendingQuantity"
                    placeholder="Enter Pending Quantity"
                    value={PurchaseRecieveFormData.PendingQuantity}
                    onChange={handleInputFormChange}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Reason :
                  </label>
                  <input
                    type="text"
                    name="Reason"
                    placeholder="Enter Reason"
                    value={PurchaseRecieveFormData.Reason}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    readOnly={+PurchaseRecieveFormData.PendingQuantity === 0}
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    HSN Code :
                  </label>
                  <input
                    type="text"
                    name="HSNCode"
                    placeholder="Enter HSNCode"
                    value={PurchaseRecieveFormData.HSNCode}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Batch No :
                  </label>
                  <input
                    type="text"
                    name="BatchNo"
                    placeholder="Enter Batch No"
                    value={PurchaseRecieveFormData.BatchNo}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Manufacture Date :
                  </label>
                  <input
                    type="date"
                    name="ManufactureDate"
                    placeholder="Enter ManufactureDate"
                    value={PurchaseRecieveFormData.ManufactureDate}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Expiry Date :
                  </label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    placeholder="Enter ExpiryDate"
                    value={PurchaseRecieveFormData.ExpiryDate}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    MRP :
                  </label>
                  <input
                    type="number"
                    name="MRP"
                    placeholder="Enter MRP"
                    value={PurchaseRecieveFormData.MRP}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Purchase Rate (per Pack):
                  </label>
                  <input
                    type="number"
                    name="PurchaseRateForStrip"
                    placeholder="Enter Purchase Rate"
                    value={PurchaseRecieveFormData.PurchaseRateForStrip}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Purchase Rate :
                  </label>
                  <input
                    type="number"
                    name="Purchase_Rate_TotalAmount"
                    placeholder="Enter Total Purchase Rate"
                    value={PurchaseRecieveFormData.Purchase_Rate_TotalAmount}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                    disabled={PurchaseRecieveFormData.DiscountType === ""}
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Discount Type :
                  </label>
                  <select
                    value={PurchaseRecieveFormData.DiscountType}
                    name="DiscountType"
                    onChange={handleInputFormChange}
                    disabled={Finel_Amountdata.Total_Discount_Type !== ""}
                  >
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Discount :
                  </label>
                  <input
                    type="number"
                    name="Discount"
                    placeholder="Enter Discount"
                    value={PurchaseRecieveFormData.Discount}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                    disabled={PurchaseRecieveFormData.DiscountType === ""}
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Total Taxable Purchase Rate :
                  </label>
                  <input
                    type="number"
                    name="PurchaseRateForStripGST"
                    placeholder="Enter Purchase Rate"
                    value={
                      PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis
                    }
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    {" "}
                    Taxable Purchase Rate (Per Quantity):
                  </label>
                  <input
                    type="number"
                    name="Purchase_Rate"
                    placeholder="Enter Purchase Rate"
                    value={PurchaseRecieveFormData.Purchase_Rate}
                    onChange={handleInputFormChange}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Tax Type:
                  </label>
                  <select
                    value={PurchaseRecieveFormData.GST_Type}
                    name="GST_Type"
                    onChange={handleInputFormChange}
                  >
                    <option value="">Select</option>
                    <option value="GST">GST</option>
                    <option value="IGST">IGST</option>
                    <option value="NON_GST">NON-GST</option>
                  </select>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Tax Percentage
                  </label>

                  <select
                    name="Tax_Percentage"
                    placeholder="Enter Tax Percentage"
                    value={PurchaseRecieveFormData.Tax_Percentage}
                    onChange={handleInputFormChange}
                    required
                    disabled={
                      PurchaseRecieveFormData.GST_Type === "NON_GST" ||
                      PurchaseRecieveFormData.GST_Type === "NON_GST"
                    }
                  >
                    <option value="">Select</option>
                    <option value={0}>Nill</option>
                    <option value={28}>28 %</option>
                    <option value={18}>18 %</option>
                    <option value={12}>12 %</option>
                    <option value={5}>5 %</option>
                  </select>
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Tax Amount :
                  </label>
                  <input
                    type="number"
                    name="Tax_totalAmount"
                    placeholder="Enter Tax Amount"
                    value={PurchaseRecieveFormData.Tax_totalAmount}
                    onChange={handleInputFormChange}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Amount :
                  </label>
                  <input
                    type="number"
                    name="TotalAmount"
                    placeholder="Enter Total Amount"
                    value={PurchaseRecieveFormData.TotalAmount}
                    onChange={handleInputFormChange}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_Save">
                <button
                  className=" Stoct_Product_master_form_Save_button"
                  onClick={isedit ? handleUpdate : handleAdd}
                >
                  {isedit ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {(OverallPurchaseRecieveFormData.ProductCategoryType ===
          "NonMedicalConsumable" ||
          OverallPurchaseRecieveFormData.ProductCategoryType ===
            "NonMedicalNonConsumable") && (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Item Code :
                  </label>
                  <input
                    type="text"
                    name="Item_Code"
                    placeholder="Enter Item Code"
                    value={PurchaseRecieveFormData.Item_Code}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Item Name :
                  </label>
                  <input
                    type="text"
                    name="Item_Name"
                    placeholder="Enter Item Name"
                    value={PurchaseRecieveFormData.Item_Name}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Generic Name :
                  </label>
                  <input
                    type="text"
                    name="Generic_Name"
                    placeholder="Enter Generic Name"
                    value={PurchaseRecieveFormData.Generic_Name}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Pack Type :
                  </label>
                  <input
                    type="text"
                    name="Pack_type"
                    placeholder="Enter Pack Type"
                    value={PurchaseRecieveFormData.Pack_type}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pack Quantity :
                  </label>
                  <input
                    type="number"
                    name="Pack_Quantity"
                    placeholder="Enter Pack Quantity"
                    value={PurchaseRecieveFormData.Pack_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Order Quantity :
                  </label>
                  <input
                    type="number"
                    name="Order_Quantity"
                    placeholder="Enter Order Quantity"
                    value={PurchaseRecieveFormData.Order_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="TotalQuantity"
                    placeholder="Enter Total Quantity"
                    value={PurchaseRecieveFormData.TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Recieved Quantity :
                  </label>
                  <input
                    type="number"
                    name="RecievedOrder_Quantity"
                    placeholder="Enter Recieved Pack Quantity "
                    value={PurchaseRecieveFormData.RecievedOrder_Quantity}
                    onChange={handleInputFormNonChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Recieved Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="RecievedTotalQuantity"
                    placeholder="Enter Recieved Recieved TotalQuantity"
                    value={PurchaseRecieveFormData.RecievedTotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pending Quantity :
                  </label>
                  <input
                    type="text"
                    name="PendingQuantity"
                    placeholder="Enter Pending Quantity"
                    value={PurchaseRecieveFormData.PendingQuantity}
                    onChange={handleInputFormNonChange}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Reason :
                  </label>
                  <input
                    type="text"
                    name="Reason"
                    placeholder="Enter Reason"
                    value={PurchaseRecieveFormData.Reason}
                    onChange={handleInputFormNonChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    readOnly={+PurchaseRecieveFormData.PendingQuantity === 0}
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    HSN Code :
                  </label>
                  <input
                    type="text"
                    name="HSNCode"
                    placeholder="Enter HSNCode"
                    value={PurchaseRecieveFormData.HSNCode}
                    onChange={handleInputFormNonChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Batch No :
                  </label>
                  <input
                    type="text"
                    name="BatchNo"
                    placeholder="Enter Batch No"
                    value={PurchaseRecieveFormData.BatchNo}
                    onChange={handleInputFormNonChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Manufacture Date :
                  </label>
                  <input
                    type="date"
                    name="ManufactureDate"
                    placeholder="Enter ManufactureDate"
                    value={PurchaseRecieveFormData.ManufactureDate}
                    onChange={handleInputFormNonChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Expiry Date :
                  </label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    placeholder="Enter ExpiryDate"
                    value={PurchaseRecieveFormData.ExpiryDate}
                    onChange={handleInputFormNonChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    MRP :
                  </label>
                  <input
                    type="number"
                    name="MRP"
                    placeholder="Enter MRP"
                    value={PurchaseRecieveFormData.MRP}
                    onChange={handleInputFormNonChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Purchase Rate (per Pack):
                  </label>
                  <input
                    type="number"
                    name="PurchaseRateForStrip"
                    placeholder="Enter Purchase Rate"
                    value={PurchaseRecieveFormData.PurchaseRateForStrip}
                    onChange={handleInputFormNonChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Purchase Rate :
                  </label>
                  <input
                    type="number"
                    name="Purchase_Rate_TotalAmount"
                    placeholder="Enter Total Purchase Rate"
                    value={PurchaseRecieveFormData.Purchase_Rate_TotalAmount}
                    onChange={handleInputFormNonChange}
                    className="Stoct_Product_master_form_Input"
                    disabled={PurchaseRecieveFormData.DiscountType === ""}
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Discount Type :
                  </label>
                  <select
                    value={PurchaseRecieveFormData.DiscountType}
                    name="DiscountType"
                    onChange={handleInputFormNonChange}
                    disabled={Finel_Amountdata.Total_Discount_Type !== ""}
                  >
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Discount :
                  </label>
                  <input
                    type="number"
                    name="Discount"
                    placeholder="Enter Discount"
                    value={PurchaseRecieveFormData.Discount}
                    onChange={handleInputFormNonChange}
                    className="Stoct_Product_master_form_Input"
                    disabled={PurchaseRecieveFormData.DiscountType === ""}
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Total Taxable Purchase Rate :
                  </label>
                  <input
                    type="number"
                    name="PurchaseRateForStripGST"
                    placeholder="Enter Purchase Rate"
                    value={
                      PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis
                    }
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    {" "}
                    Taxable Purchase Rate (Per Quantity):
                  </label>
                  <input
                    type="number"
                    name="Purchase_Rate"
                    placeholder="Enter Purchase Rate"
                    value={PurchaseRecieveFormData.Purchase_Rate}
                    onChange={handleInputFormNonChange}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Tax Type:
                  </label>
                  <select
                    value={PurchaseRecieveFormData.GST_Type}
                    name="GST_Type"
                    onChange={handleInputFormNonChange}
                  >
                    <option value="">Select</option>
                    <option value="GST">GST</option>
                    <option value="IGST">IGST</option>
                    <option value="NON_GST">NON-GST</option>
                  </select>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Tax Percentage
                  </label>

                  <select
                    name="Tax_Percentage"
                    placeholder="Enter Tax Percentage"
                    value={PurchaseRecieveFormData.Tax_Percentage}
                    onChange={handleInputFormNonChange}
                    required
                    disabled={
                      PurchaseRecieveFormData.GST_Type === "NON_GST" ||
                      PurchaseRecieveFormData.GST_Type === "NON_GST"
                    }
                  >
                    <option value="">Select</option>
                    <option value={0}>Nill</option>
                    <option value={28}>28 %</option>
                    <option value={18}>18 %</option>
                    <option value={12}>12 %</option>
                    <option value={5}>5 %</option>
                  </select>
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Tax Amount :
                  </label>
                  <input
                    type="number"
                    name="Tax_totalAmount"
                    placeholder="Enter Tax Amount"
                    value={PurchaseRecieveFormData.Tax_totalAmount}
                    onChange={handleInputFormNonChange}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Amount :
                  </label>
                  <input
                    type="number"
                    name="TotalAmount"
                    placeholder="Enter Total Amount"
                    value={PurchaseRecieveFormData.TotalAmount}
                    onChange={handleInputFormNonChange}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_Save">
                <button
                  className=" Stoct_Product_master_form_Save_button"
                  onClick={isedit ? handleUpdateNon : handleAddNon}
                >
                  {isedit ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {(OverallPurchaseRecieveFormData.ProductCategory ===
          "Assets" ||
          OverallPurchaseRecieveFormData.ProductCategory ===
            "Stationary" ||
          OverallPurchaseRecieveFormData.ProductCategory === "Non_Stationary") && (
            <div className="Stoct_Product_master_Container">
              <div className="Stoct_Product_master_form">
                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="ProductType"
                    >
                      Item Code :
                    </label>
                    <input
                      type="text"
                      name="Item_Code"
                      placeholder="Enter Item Code"
                      value={PurchaseRecieveFormData.Item_Code}
                      required
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Item Name :
                    </label>
                    <input
                      type="text"
                      name="Item_Name"
                      placeholder="Enter Item Name"
                      value={PurchaseRecieveFormData.Item_Name}
                      required
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Pack Type :
                    </label>
                    <input
                      type="text"
                      name="Pack_type"
                      placeholder="Enter Pack Type"
                      value={PurchaseRecieveFormData.Pack_type}
                      required
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Pack Quantity :
                    </label>
                    <input
                      type="number"
                      name="Pack_Quantity"
                      placeholder="Enter Pack Quantity"
                      value={PurchaseRecieveFormData.Pack_Quantity}
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                </div>

                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Order Quantity :
                    </label>
                    <input
                      type="number"
                      name="Order_Quantity"
                      placeholder="Enter Order Quantity"
                      value={PurchaseRecieveFormData.Order_Quantity}
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Total Quantity :
                    </label>
                    <input
                      type="number"
                      name="TotalQuantity"
                      placeholder="Enter Total Quantity"
                      value={PurchaseRecieveFormData.TotalQuantity}
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Recieved Quantity :
                    </label>
                    <input
                      type="number"
                      name="RecievedOrder_Quantity"
                      placeholder="Enter Recieved Quantity"
                      value={PurchaseRecieveFormData.RecievedOrder_Quantity}
                      onChange={handleInputFormNonChange}
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Recieved Total Quantity :
                    </label>
                    <input
                      type="number"
                      name="RecievedTotalQuantity"
                      placeholder="Enter Recieved Recieved TotalQuantity"
                      value={PurchaseRecieveFormData.RecievedTotalQuantity}
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                </div>

                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Pending Quantity :
                    </label>
                    <input
                      type="text"
                      name="PendingQuantity"
                      placeholder="Enter Pending Quantity"
                      value={PurchaseRecieveFormData.PendingQuantity}
                      className="Stoct_Product_master_form_Input"
                      readOnly
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="ProductType"
                    >
                      Reason :
                    </label>
                    <input
                      type="text"
                      name="Reason"
                      placeholder="Enter Reason"
                      value={PurchaseRecieveFormData.Reason}
                      onChange={handleInputFormNonChange}
                      required
                      className="Stoct_Product_master_form_Input"
                      readOnly={+PurchaseRecieveFormData.PendingQuantity === 0}
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="ProductType"
                    >
                      MRP :
                    </label>
                    <input
                      type="text"
                      name="MRP"
                      placeholder="Enter MRP"
                      value={PurchaseRecieveFormData.MRP}
                      onChange={handleInputFormNonChange}
                      required
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Purchase Rate :
                    </label>
                    <input
                      type="number"
                      name="PurchaseRateForStrip"
                      placeholder="Enter Purchase Rate"
                      value={PurchaseRecieveFormData.PurchaseRateForStrip}
                      onChange={handleInputFormNonChange}
                      required
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                </div>

                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Total Purchase Rate :
                    </label>
                    <input
                      type="number"
                      name="PurchaseRateForStripGST"
                      placeholder="Enter Purchase Rate"
                      value={PurchaseRecieveFormData.Purchase_Rate_TotalAmount}
                      readOnly
                      required
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Discount Type :
                    </label>
                    <select
                      value={PurchaseRecieveFormData.DiscountType}
                      name="DiscountType"
                      onChange={handleInputFormNonChange}
                      disabled={Finel_Amountdata.Total_Discount_Type !== ""}
                    >
                      <option value="">Select</option>
                      <option value="Cash">Cash</option>
                      <option value="Percentage">Percentage</option>
                    </select>
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Discount :
                    </label>
                    <input
                      type="text"
                      name="Discount"
                      placeholder="Enter Discount"
                      value={PurchaseRecieveFormData.Discount}
                      onChange={handleInputFormNonChange}
                      className="Stoct_Product_master_form_Input"
                      disabled={PurchaseRecieveFormData.DiscountType === ""}
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Taxable Total Purchase Rate :
                    </label>
                    <input
                      type="number"
                      name="Purchase_Rate_TotalAmount_Dis"
                      placeholder="Enter Purchase Rate"
                      value={
                        PurchaseRecieveFormData.Purchase_Rate_TotalAmount_Dis
                      }
                      readOnly
                      required
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                </div>

                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Taxable Purchase Rate (Per Quantity) :
                    </label>
                    <input
                      type="number"
                      name="Purchase_Rate"
                      placeholder="Enter Purchase Rate"
                      value={PurchaseRecieveFormData.Purchase_Rate}
                      readOnly
                      required
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Tax Type:
                    </label>
                    <select
                      value={PurchaseRecieveFormData.GST_Type}
                      name="GST_Type"
                      onChange={handleInputFormNonChange}
                    >
                      <option value="">Select</option>
                      <option value="GST">GST</option>
                      <option value="IGST">IGST</option>
                      <option value="NON_GST">NON-GST</option>
                    </select>
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Tax Percentage
                    </label>
                    <select
                      name="Tax_Percentage"
                      placeholder="Enter Tax Percentage"
                      value={PurchaseRecieveFormData.Tax_Percentage}
                      onChange={handleInputFormNonChange}
                      required
                      disabled={
                        PurchaseRecieveFormData.GST_Type === "NON_GST" ||
                        PurchaseRecieveFormData.GST_Type === "NON_GST"
                      }
                    >
                      <option value="">Select</option>
                      <option value={0}>Nill</option>
                      <option value={28}>28 %</option>
                      <option value={18}>18 %</option>
                      <option value={12}>12 %</option>
                      <option value={5}>5 %</option>
                    </select>
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="ProductType"
                    >
                      Total Tax Amount :
                    </label>
                    <input
                      type="text"
                      name="Tax_totalAmount"
                      placeholder="Enter Total Tax Amount"
                      value={PurchaseRecieveFormData.Tax_totalAmount}
                      onChange={handleInputFormNonChange}
                      required
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                </div>
                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="ProductType"
                    >
                      Total Amount :
                    </label>
                    <input
                      type="text"
                      name="TotalAmount"
                      placeholder="Enter Total Amount"
                      value={PurchaseRecieveFormData.TotalAmount}
                      onChange={handleInputFormNonChange}
                      required
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                </div>

                <div className="Stoct_Product_master_form_Save">
                  <button
                    className=" Stoct_Product_master_form_Save_button"
                    onClick={isedit ? handleUpdateNon : handleAddNon}
                  >
                    {isedit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </div>
          )}

        {datalist1.length !== 0 && (
          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={datalist1.slice(
                    page1 * pageSize,
                    (page1 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns1()} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange1}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown1 > 0 && datalist1.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page1 === 0}
                    >
                      Previous
                    </button>
                    Page {page1 + 1} of {totalPages1}
                    <button
                      onClick={() =>
                        setPage1((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page1 === totalPages1 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown1 !== 0 && datalist1.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        )}
        <br></br>
        {datalist1.length !== 0 ? (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Grand Total :
                  </label>
                  <input
                    type="number"
                    name="Grand_Total"
                    placeholder="Enter Grand Amount"
                    value={Finel_Amountdata.Grand_Total}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Discount Type :
                  </label>
                  <select
                    value={Finel_Amountdata.Total_Discount_Type}
                    name="Total_Discount_Type"
                    onChange={handleInputFinel_AmountdataChange}
                    disabled={datalist1.some((ele) => ele.DiscountType !== "")}
                  >
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Discount :
                  </label>
                  <input
                    type="number"
                    name="Total_Discount"
                    placeholder="Enter Discount"
                    value={Finel_Amountdata.Total_Discount}
                    onChange={handleInputFinel_AmountdataChange}
                    className="Stoct_Product_master_form_Input"
                    disabled={
                      Finel_Amountdata.Total_Discount_Type === "" ||
                      datalist1.some((ele) => ele.DiscountType !== "")
                    }
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Net Amount :
                  </label>
                  <input
                    type="number"
                    name="Finel_NetAmount"
                    placeholder="Enter Total Amount"
                    value={Finel_Amountdata.Finel_NetAmount}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {datalist1.length !== 0 && (
          <div className="Stoct_Product_master_form_Save">
            {GRNedit && Object.keys(GRNedit).length !== 0 ? (
              <button
                className=" Stoct_Product_master_form_Save_button"
                onClick={handleUpdatedatas}
              >
                Update
              </button>
            ) : (
              <button
                className=" Stoct_Product_master_form_Save_button"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PurchaseRecieveMaster;
