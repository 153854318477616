import React, { useEffect, useState } from "react";
import "../ProductMaster/ProductMaster.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

function PharmachyMaster() {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const selectedProductIndex = useSelector(
    (state) => state.userRecord?.selectedProductIndex
  );

  console.log(selectedProductIndex);
  const [prodData, setProdData] = useState([]);

  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();
  const [formDataProduct, setFormDataProduct] = useState({
    ProductCategory: "MedicalConsumable",
    ProductType: "",
    ProductQuantity: "",
    ItemCode: "",
    ItemName: "",
    GenericName: "",
    Strength: "",
    UOM: "",
    ItemRateWithouttax: "",
    Status: "Active",
    createdby: userRecord?.username,
    Location: userRecord?.location,
  });

  console.log(formDataProduct);
  useEffect(() => {
    setFormDataProduct((prevData) => ({
      ...prevData,
      ProductType: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
    }));
    setIsInactive(false);
  }, [formDataProduct.ProductCategory]);

  useEffect(() => {
    if (selectedProductIndex && selectedProductIndex !== null) {
      // if (
      //   selectedProductIndex.Productype === "MedicalConsumable" ||
      //   selectedProductIndex.Productype === "MedicalNonConsumable"
      // ) {
      axios
        .get(
          `${urllink}SupplierMaster/get_Medical_ProductMaster_data?Productype=${selectedProductIndex.Productype}`
        )
        .then((response) => {
          const responsedata = response.data;
          const selectedSupplier = responsedata.find(
            (p) => selectedProductIndex.ItemCode === p.ItemCode
          );
          setFormDataProduct((prev) => ({
            ...prev,
            ProductCategory: selectedProductIndex.Productype,
            ...selectedSupplier,
          }));

          if (selectedSupplier.Status !== "Active") {
            setIsInactive(true);
          } else {
            setIsInactive(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      // } else if (selectedProductIndex.ProductCategory === "Non_Medical") {
      //   axios
      //     .get(
      //       "${urllink}SupplierMaster/get_NONMedical_ProductMaster_data"
      //     )
      //     .then((response) => {
      //       const responsedata = response.data;
      //       const selectedSupplier = responsedata.find(
      //         (p) => selectedProductIndex.ItemCode === p.ItemCode
      //       );
      //       setFormDataProduct((prev) => ({
      //         ...prev,
      //         ProductCategory: selectedProductIndex.ProductCategory,
      //         ...selectedSupplier,
      //       }));

      //       if (selectedSupplier.Status !== "Active") {
      //         setIsInactive(true);
      //       } else {
      //         setIsInactive(false);
      //       }
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching data:", error);
      //     });
      // }
    } else {
      axios
        .get(
          `${urllink}SupplierMaster/get_Medical_Product_code?producttype=${formDataProduct.ProductCategory}`
        )
        .then((response) => {
          setFormDataProduct((prevData) => ({
            ...prevData,
            ItemCode: response.data.nextMedicalProductCode,
          }));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [selectedProductIndex, formDataProduct.ProductCategory]);

  useEffect(() => { 
    axios
      .get(`${urllink}SupplierMaster/getProductData`)
      .then((response) => {
        const data = response.data;
        setProdData(data);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  },[urllink]);

  const handleInactiveChange = () => {
    setIsInactive(!isInactive);
    setFormDataProduct((prevData) => ({
      ...prevData,
      Status: !isInactive ? "Inactive" : "Active",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormDataProduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveOrUpdate = () => {
    console.log(formDataProduct);
    const newData = { ...formDataProduct };
    let requiredFields;

    if (
      newData.ProductCategory === "MedicalConsumable" ||
      newData.ProductCategory === "MedicalNonConsumable"
    ) {
      requiredFields = [
        "ProductCategory",
        "ItemName",
        "ItemCode",
        "GenericName",
        "Strength",
        "UOM",
        "ItemRateWithouttax",
      ];
    } else if (
      newData.ProductCategory === "NonMedicalConsumable" ||
      newData.ProductCategory === "NonMedicalNonConsumable" ||
      newData.ProductCategory === "Surgical"
    ) {
      requiredFields = [
        "ProductCategory",
        "ProductType",
        "ItemName",
        "ItemCode",
      ];
    }

    const missingFields = requiredFields.filter(
      (field) => !formDataProduct[field]
    );

    if (missingFields.length === 0) {
      if (selectedProductIndex) {
        axios
          .post(
            `${urllink}SupplierMaster/Update_productMaster_All`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            clearFormInputs();
            dispatchvalue({ type: "selectedProductIndex", value: null });

            // After successfully updating data, navigate to Pharmacy_MasterList
            navigate("/Home/Pharmacy_MasterList");
          })
          .catch((error) => {
            console.error("Error post Supplier data:", error);
          });
      } else {
        axios
          .post(
            `${urllink}SupplierMaster/insert_Medical_ProductMaster_data`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            clearFormInputs();
            navigate("/Home/Pharmacy_MasterList");
          })
          .catch((error) => {
            console.error("Error post Supplier data:", error);
          });
      }
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };
  const clearFormInputs = () => {
    setFormDataProduct({
      ProductCategory: "MedicalConsumable",
      ProductType: "",
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
    });
    setIsInactive(false);
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Pharmacy Master</h4>
      </div>
     <br />
        
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="ProductType">Product Category <span>:</span></label>
              <select
                value={formDataProduct.ProductCategory}
                name="ProductCategory"
                onChange={handleInputChange}
                disabled={selectedProductIndex}
              >
                <option value="MedicalConsumable">Medical Consumable</option>
                <option value="MedicalNonConsumable">
                  Medical Non-Consumable
                </option>
                <option value="NonMedicalConsumable">
                  Non MedicalConsumable
                </option>
                <option value="NonMedicalNonConsumable">
                  Non MedicalNon-Consumable
                </option>
                <option value="Surgical">Surgical</option>
              </select>
            </div>

            <div className="RegisForm_1">
              <label htmlFor="code">Item Code <span>:</span></label>
              <input
                type="text"
                name="ItemCode"
             
                value={formDataProduct.ItemCode}
                required
                readOnly
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="itemName">Item Name <span>:</span></label>
              <input
                type="text"
                name="ItemName"
           
                value={formDataProduct.ItemName}
                onChange={handleInputChange}
              />
            </div>

            {/* {(formDataProduct.ProductCategory === "MedicalConsumable" ||
              formDataProduct.ProductCategory === "MedicalNonConsumable") && ( */}
            <div className="RegisForm_1">
              <label htmlFor="generic">Generic Name<span>:</span></label>
              <input
                type="text"
                name="GenericName"
          
                value={formDataProduct.GenericName}
                onChange={handleInputChange}
              />
            </div>
            {/* )} */}

            {/* {(formDataProduct.ProductCategory !== "MedicalConsumable" ||
              formDataProduct.ProductCategory !== "MedicalNonConsumable") && ( */}
            <div className="RegisForm_1">
            <label htmlFor="ProductTypewq2">Product Type<span>:</span></label>   
            <select
                    id="ProductType"
                    name="ProductType"
                    value={formDataProduct.ProductType}
                    onChange={handleInputChange}
                    required
                  >
                    <option value={""}>Select</option>
                    {prodData.map((loc) => (
                      <option key={loc.prod_id} value={loc.productType}>
                        {loc.productType}
                      </option>
                    ))}
                  </select>
             
            </div>
            {/* )} */}

            {(formDataProduct.ProductCategory === "MedicalConsumable" ||
              formDataProduct.ProductCategory === "MedicalNonConsumable") && (
              <>
                <div className="RegisForm_1">
                  <label htmlFor="strength">Strength<span>:</span></label>
                  <input
                    type="text"
                    name="Strength"
                 
                    value={formDataProduct.Strength}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="strength">UOM<span>:</span></label>
                  <input
                    type="text"
                    name="UOM"
           
                    value={formDataProduct.UOM}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}

            <div className="RegisForm_1">
              <label htmlFor="uom">Product Quantity<span>:</span></label>
              <input
                type="number"
                name="ProductQuantity"
             
                value={formDataProduct.ProductQuantity}
                onChange={handleInputChange}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="uom">Selling Rate Without Tax(Per Nos)<span>:</span></label>
              <input
                type="number"
                name="ItemRateWithouttax"
      
                value={formDataProduct.ItemRateWithouttax}
                onChange={handleInputChange}
              />
            </div>

            {/* {formDataProduct.ProductCategory !== "MedicalConsumable" &&
              formDataProduct.ProductCategory !== "MedicalNonConsumable" && ( */}
            <div className="RegisForm_1">
              <label htmlFor="inactive">
                Inactive<span>:</span>
                </label>
                <input
                  type="checkbox"
                  name="inactive"
                  checked={isInactive}
                  onChange={handleInactiveChange}
                />
             
            </div>
            {/* )} */}
          </div>

        

          {/* {(formDataProduct.ProductCategory === "MedicalConsumable" ||
            formDataProduct.ProductCategory === "MedicalNonConsumable") && ( */}
          <div className="Stoct_Product_master_form_row">
            {/* <div className="Stoct_Product_master_form_row_div">
                <label
            
                  htmlFor="inactive"
                >
                  Inactive<span>:</span>
                  <input
                    type="checkbox"
                    name="inactive"
                    checked={isInactive}
                    onChange={handleInactiveChange}
                   
                  />
                </label>
              </div> */}
          </div>
          {/* )} */}

          <div className="Register_btn_con">
            <button
              className=" RegisterForm_1_btns"
              onClick={handleSaveOrUpdate}
            >
              {selectedProductIndex ? "Update" : "Save"}
            </button>
          </div>
       
     
    </div>
  );
}

export default PharmachyMaster;
