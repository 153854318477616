import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

import { useSelector } from "react-redux";
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const LeaveStatus = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [columns] = React.useState([
    { field: "employeeid", headerName: "Employee ID", width: 120 },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "leaveType", headerName: "Leave Type", width: 100 },
    { field: "fromdate", headerName: "From Date", width: 100 },
    { field: "todate", headerName: "To Date", width: 100 },
    { field: "days", headerName: "Days", width: 80 },
    { field: "reason", headerName: "Reason", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "rejectstatus",
      headerName: "Reject Reason",
      width: 150,
    },
  ]);
  const [rows, setRows] = useState([]);

  // second datagrid2 for permission approval

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = () => {
    const employeeid = userRecord.EmployeeId;
    fetch(
      `${urllink}HRmanagement/get_leave_register_recent?EmployeeID=${employeeid}&location=${userRecord.location}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            leaveType: userdata.LeaveType,
            fromdate: userdata.FromDate,
            todate: userdata.ToDate,
            days: userdata.DaysCount,
            reason: userdata.Reason,
            status: userdata.status,
            rejectstatus: userdata.rejectstatus,
          }));
          setRows(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showdown = rows.length;

  return (
    <div>
      <ThemeProvider theme={theme}>
        <div
          className=" grid_1"
          style={{ overflowX: "auto", maxWidth: "100%" }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            hideFooter={true}
            isCellEditable={(params) => params.row.id !== 1}
            onEditCellChange={(params) => {
              const updatedRows = rows.map((row) => {
                if (row.id === params.id) {
                  return { ...row, [params.field]: params.props.value };
                }
                return row;
              });
              setRows(updatedRows);
            }}
            className=" data_grid"
          />
        </div>
      </ThemeProvider>
      {showdown !== 0 && rows.length !== 0 ? (
        ""
      ) : (
        <div className="IP_norecords">
          <span>No Records Found</span>
        </div>
      )}
    </div>
  );
};

export default LeaveStatus;
