import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const AddvaceManagement = () => {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [filteredRows, setFilteredRows] = useState([]);
  const [showsudden, setshowsudden] = useState(false);
  const [advanceRows, setAdvanceRows] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const totalPages = Math.ceil(advanceRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [openModal, setOpenModal] = useState(false);

  const [status, setStatus] = useState("");
  const [oldStatus, setOldStatus] = useState("");
  const [reason, setReason] = useState("");
  const [issuedby, setIssued] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [installment, setInstallment] = useState("");
  const columns = [
    { field: "Sl_No", headerName: "Sl No", width: 60 },
    { field: "employeeid", headerName: "Employee ID", width: 100 },
    { field: "employeename", headerName: "Employee Name", width: 100 },
    { field: "designation", headerName: "Designation", width: 100 },
    { field: "reqdate", headerName: "Request Date", width: 100 },
    { field: "reqamount", headerName: "Request Amount", width: 110 },
    { field: "reason", headerName: "Reason", width: 120 },
    { field: "installment", headerName: "No.of.Installment", width: 100 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "rejectreason", headerName: "Reject Reason", width: 130 },

    { field: "issuedby", headerName: "Issued By", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 80,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleEditClick(params.row)}
          >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ];

  const [rolename, setRolename] = useState([]);
  useEffect(() => {
    axios
      .get(`${urllink}HRmanagement/getRole_all`)
      .then((response) => {
        setRolename(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord?.location]);

  useEffect(() => {
    fetchLeaveData();
  }, [userRecord?.location]);

  const fetchLeaveData = () => {
    fetch(
      `${urllink}HRmanagement/get_all_advance_register?location=${userRecord?.location}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            designation: userdata.Designation,
            reqdate: userdata.RequestDate,
            reqamount: userdata.RequestAmount,
            reason: userdata.Reason,
            installment: userdata.RepaymentDue,
            status: userdata.Status,
            rejectreason: userdata.RejectReason,
            approvedby: userdata.ApproverName,
            issueddate: userdata.IssuedDate,
            issuedby: userdata.IssueverName,
          }));
          setAdvanceRows(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditClick = (params) => {
    setOpenModal(true);
    setSelectedRowData(params);
    setOldStatus(params.status);
    setStatus(params.status);
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
  };

  const handleReasonChange = (e) => {
    const newReason = e.target.value;
    setReason(newReason);
  };
  const handleInstallmentChange = (e) => {
    const newReason = e.target.value;
    setInstallment(newReason);
  };

  const handleissuedByChange = (e) => {
    const issued = e.target.value;
    setIssued(issued);
  };

  const handleSubmit = () => {
    const apiUrl = `${urllink}HRmanagement/update_advance_approval_Edit`;
    const submissionData = {
      Sl_No: selectedRowData.Sl_No,
      status: status,
      reason: reason,
      installment: installment,
      issuedBy: issuedby,
    };

    axios
      .post(apiUrl, submissionData)
      .then((response) => {
        alert("Leave status updated successfully");
        setOpenModal(false);
        setshowsudden(!showsudden);
        // window.location.reload();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const [searchQuery, setsearchQuery] = useState("");
  const [searchQuery1, setsearchQuery1] = useState("");
  const showdown = advanceRows.length;

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowerCaseQuery1 = searchQuery1.toLowerCase();

    const filterRow = (row) => {
      const lowerCaseEmployeeName = row.employeename.toLowerCase();
      const lowerCaseDesignation = row.designation.toLowerCase();
      const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
      const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);

      if (!searchQuery && !searchQuery1) {
        // If both search queries are empty, do not filter out any data
        return true;
      }

      return nameMatches && designationMatches;
    };

    const filteredData = advanceRows.filter(filterRow);

    setFilteredRows(filteredData);
  }, [searchQuery, advanceRows, searchQuery1]);

  return (
    <div>
      <div className="appointment">
        <div className="h_head">
          <h4>Advance Management </h4>
        </div>
      </div>
      <div className="appointment">
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Employee Name <span>:</span>
            </label>
            <input
              type="text"
              id="date"
              name="employeeName"
              placeholder="Enter Employee Name"
              value={searchQuery}
              onChange={(e) => setsearchQuery(e.target.value)}
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">
              Designation <span>:</span>
            </label>
            <select
              name="designation"
              value={searchQuery1}
              onChange={(e) => setsearchQuery1(e.target.value)}
              className="new-custom-input-phone vital_select"
              required
            >
              <option value="select">Select </option>
              {rolename.map((role) => (
                <option key={role.role_id} value={role.role_name}>
                  {role.role_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <div className=" grid_1">
          <DataGrid
            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
            columns={columns}
            pageSize={100}
            onSelectionModelChange={handleSelectionModelChange}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={handlePageChange}
            hideFooterPagination
            hideFooterSelectedRowCount
            className=" data_grid"
          />
          {showdown > 10 && filteredRows.length > 10 && (
            <div className="grid_foot">
              <button
                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() =>
                  setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                }
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </ThemeProvider>

      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwProfiles1122"
            onClick={(e) => e.stopPropagation()}
          >
            <br></br>
            <div className="web_head wsdfwe6">
              <h3>Status Edit</h3>
            </div>
            <div className="editcont">
              <div className="RegisFormcon leavecon">
                <div className="RegisForm_1 leaveform_1">
                  <label htmlFor="issued">
                    Status<span>:</span>
                  </label>
                  <select
                    name="approval"
                    id="approval"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="">Select</option>
                    {oldStatus !== "Approved" && (
                      <option value="Approved">Approved</option>
                    )}
                    {oldStatus !== "Not Approved" && (
                      <option value="Not Approved">Not Approved</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="RegisFormcon leavecon">
                <div className="RegisForm_1 leaveform_1">
                  <label htmlFor="installment">
                    No.of Installment <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="installment"
                    id="installment"
                    placeholder="Enter Installment "
                    value={installment}
                    onChange={handleInstallmentChange}
                  />
                </div>
              </div>
            </div>
            <div className="editcont">
              <div className="RegisFormcon leavecon">
                <div className="RegisForm_1 leaveform_1">
                  <label htmlFor="issued">
                    Issued By <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="issued"
                    id="issued"
                    placeholder="Enter Issuever Name"
                    value={issuedby}
                    onChange={handleissuedByChange}
                  />
                </div>
              </div>
              {status === "Not Approved" && (
                <div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1 ">
                    <label htmlFor="reason">
                      Reason <span>:</span>
                    </label>
                    <textarea
                      type="text"
                      name="reason"
                      id=""
                      onChange={handleReasonChange}
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
            <div className="Register_btn_con regster_btn_contsai">
              <button className="RegisterForm_1_btns" onClick={handleSubmit}>
                Submit
              </button>
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddvaceManagement;
