import React, { useState, useEffect } from "react";
import "./UserRegister.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";



function UserRegister() {
  const foruserregisteremployeedata = useSelector(
    (state) => state.userRecord?.foruserregisteremployeedata
  );

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector(state => state.userRecord?.UrlLink);

  const foredituserregisteremployeedata = useSelector(
    (state) => state.userRecord?.foredituserregisteremployeedata
  );

  const navigate = useNavigate();

  console.log(userRecord);
  console.log(foruserregisteremployeedata);
  const [roleOptions, setRoleOptions] = useState([]);
  const [locationoptions, setlocationOptions] = useState([]);

  const [formData, setFormData] = useState({
    username: "",
    employeeid: "",
    firstName: "",
    lastName: "",
    Title: "",
    PhoneNo: "",
    email: "",
    password: "",
    roleName: "",
    gender: "",
    qualification: "",
  });

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);


  useEffect(() => {
    fetchRoleOptions();
    fetchlocationOptions();
    fetchUserList();
  }, []);

  const [checkedItems, setCheckedItems] = useState([
    {
      key: '1',
      value: 'A',
      label: 'ClinicMetrics',
      check: false,
      children: []
    },
    {
      key: '2',
      value: 'B',
      label: 'FrontOffice',
      check: false,
      children: [
        { key: '2-1', value: 'B2-1', label: 'Appoinment Request', check: false },
        { key: '2-2', value: 'B2-2', label: 'Appoinment Register', check: false },
        { key: '2-3', value: 'B2-3', label: 'Followuplist', check: false },
        { key: '2-4', value: 'B2-4', label: 'ConcernForms', check: false },
        { key: '2-5', value: 'B2-5', label: 'LabReport', check: false },
        { key: '2-6', value: 'B2-6', label: 'patientListUpdate', check: false }
      ]
    },
    {
      key: '3',
      value: 'C',
      label: 'Nurse',
      check: false,
      children: [
        { key: '3-1', value: 'C3-1', label: 'PatientQueueList3', check: false },
      ]
    },
    {
      key: '4',
      value: 'D',
      label: 'DoctorWorkbench',
      check: false,
      children: [
        { key: '4-1', value: 'D4-1', label: 'PatientQueueList', check: false },
      ]
    },
   
    {
      key: '5',
      value: 'E',
      label: 'Pharmacy',
      check: false,
      children: [
        { key: '5-1', value: 'E5-1', label: 'PharmacyBilling', check: false },
      ]
    },
    {
      key: '6',
      value: 'F',
      label: 'Cashier',
      check: false,
      children: [
        { key: '6-1', value: 'F6-1', label: 'Billing', check: false },
        { key: '6-2', value: 'F6-2', label: 'DueHistory', check: false },


      ]
    },
    {
      key: '7',
      value: 'G',
      label: 'PettyCash',
      check: false,
      children: [
        { key: '7-1', value: 'G7-1', label: 'Expense Master', check: false },
        { key: '7-2', value: 'G7-2', label: 'Cash Expenses', check: false },
        { key: '7-3', value: 'G7-3', label: 'Digital Expenses', check: false },
        { key: '7-4', value: 'G7-4', label: 'Expenses Report', check: false },
        { key: '7-5', value: 'G7-5', label: 'HandOver Summary', check: false },
        { key: '7-6', value: 'G7-6', label: 'Day Report', check: false },
        { key: '7-7', value: 'G7-7', label: 'Day Closing', check: false }
      ]
    },
    {
      key: '8',
      value: 'H',
      label: 'InventoryMasters',
      check: false,
      children: [
        { key: '8-1', value: 'H8-1', label: 'Supplier List', check: false },
        { key: '8-2', value: 'H8-2', label: 'Product Type Master', check: false },
        { key: '8-3', value: 'H8-3', label: 'Pharmacy Master', check: false },
        { key: '8-4', value: 'H8-4', label: 'General Master ', check: false },
      ]
    },
    {
      key: '9',
      value: 'I',
      label: 'Inventory',
      check: false,
      children: [
        { key: '9-1', value: 'I9-1', label: 'Indent Raise', check: false },
        { key: '9-2', value: 'I9-2', label: 'Indent Recieve', check: false },
        { key: '9-3', value: 'I9-3', label: 'Indent Return', check: false },
        { key: '9-4', value: 'I9-4', label: 'Indent Move', check: false },
        { key: '9-5', value: 'I9-5', label: 'Quick Stock Recieve', check: false },
        { key: '9-6', value: 'I9-6', label: 'Location Stock_List', check: false },
        { key: '9-7', value: 'I9-7', label: 'Purchase Return', check: false },
        { key: '9-8', value: 'I9-8', label: 'Supplier pay', check: false },
      ]
    },
    {
      key: '10',
      value: 'J',
      label: 'CentralStore',
      check: false,
      children: [
        { key: '10-1', value: 'J10-1', label: 'QuickStockMasterCentrallist', check: false },
        { key: '10-2', value: 'J10-2', label: 'Purchase Master', check: false },
        { key: '10-3', value: 'J10-3', label: 'GRN Recieve', check: false },
        { key: '10-4', value: 'J10-4', label: 'Indent Issue', check: false },
        { key: '10-5', value: 'J10-5', label: 'Purchase Return', check: false },
        { key: '10-6', value: 'J10-6', label: 'Central Stock List', check: false },
        { key: '10-7', value: 'J10-7', label: 'Supplier Pay centralStore', check: false },

      ]
    },
    {
      key: '11',
      value: 'K',
      label: 'CentralStoreApprove',
      check: false,
      children: [
        { key: '11-1', value: 'K11-4', label: 'GRN  Approve', check: false },
        { key: '11-2', value: 'K11-5', label: 'Location GRN Return Approve', check: false },
        { key: '11-3', value: 'K11-4', label: 'Purchase Approve', check: false },
        { key: '11-4', value: 'K11-4', label: 'Indent Issue Approve', check: false },
        { key: '11-5', value: 'K11-5', label: 'Indent Return Approve', check: false },
      ]
    },
    {
      key: '12',
      value: 'L',
      label: 'IndentApprove',
      check: false,
      children: [
        { key: '12-1', value: 'L12-1', label: 'Indent Approve', check: false },
        { key: '12-2', value: 'L12-2', label: 'Indent Recieve Approve', check: false }
      ]
    },
    {
      key: '13',
      value: 'N',
      label: 'Reports',
      check: false,
      children: [
        { key: '13-1', value: 'M13-1', label: 'Supplier Pay List', check: false },
        { key: '13-2', value: 'M13-2', label: 'Purchase Register', check: false },
        { key: '13-3', value: 'M13-3', label: 'Sales Register', check: false },
        { key: '13-4', value: 'M13-4', label: 'HSN Report', check: false },
        { key: '13-5', value: 'M13-5', label: 'Due Report', check: false },
      ]
    }, {
      key: '14',
      value: 'N',
      label: 'HRManagement',
      check: false,
      children: [
        { key: '14-1', value: 'N14-1', label: 'EmployeeRegister', check: false },
        { key: '14-2', value: 'N14-2', label: 'EmployeeList', check: false },
        { key: '14-3', value: 'N14-3', label: 'Attendance', check: false },
        { key: '14-4', value: 'N14-4', label: 'LeaveApproval', check: false },
        { key: '14-5', value: 'N14-5', label: 'AdvanceApproval', check: false },
        { key: '14-6', value: 'N14-6', label: 'PerformanceAppraisal', check: false },
        { key: '14-7', value: 'N14-7', label: 'PerformanceManagement', check: false },
        { key: '14-8', value: 'N14-8', label: 'LeaveManagement', check: false },
        { key: '14-9', value: 'N14-9', label: 'AdvanceManagement', check: false },
        { key: '14-10', value: 'N14-10', label: 'PayRoll', check: false },
        { key: '14-11', value: 'N14-11', label: 'DutyManagement', check: false },
      ]
    },
    {
      key: '15',
      value: 'O',
      label: 'EmployeeRequest',
      check: false,
      children: [
        { key: '15-1', value: 'O15-1', label: 'LeaveManagement', check: false },
        { key: '15-2', value: 'O15-2', label: 'AdvanceManagement', check: false },
        { key: '15-3', value: 'O15-3', label: 'Shift Management', check: false },
        { key: '15-4', value: 'O15-4', label: 'Pay Slip Download', check: false },
      ]
    },
    {
      key: '16',
      value: 'P',
      label: 'UserControl',
      check: false,
      children: [
        { key: '16-1', value: 'P16-1', label: 'RoleManagement', check: false },
        { key: '16-2', value: 'P16-2', label: 'EmployeeQueueList', check: false },
        { key: '16-3', value: 'P16-3', label: 'UserRegister', check: false },
        { key: '16-4', value: 'P16-4', label: 'RatecardCharges', check: false },
        { key: '16-5', value: 'P16-5', label: 'LabMaster', check: false },
        { key: '16-6', value: 'P16-6', label: 'AccountSettings', check: false },
        { key: '16-7', value: 'P16-7', label: 'ClinicDetails', check: false },
        { key: '16-8', value: 'P16-8', label: 'UserList', check: false },
        { key: '16-9', value: 'P16-9', label: 'LeaveManagement', check: false },
        { key: '16-10', value: 'P16-10', label: 'AdvanceManagement', check: false },

      ]
    },{
      key: '17',
      value: 'Q',
      label: 'TeleCalling',
      check: false,
      children: [
        { key: '17-1', value: 'Q17-1', label: 'Follow-up List', check: false },
        { key: '17-2', value: 'Q17-2', label: 'Patient Management', check: false },

      ]
    },
     
    

  ]);
  const [Locations, setLocations] = useState([])
  const [isUpdate, setIsUpdate] = useState(false);
  const [LocationData, setLocationData] = useState([])
  const [ParentData, setParentData] = useState([])
  const [ChildData, setChildData] = useState([]);

  const handleParentChange = (index) => {
    setCheckedItems((prev) => {
      const updated = [...prev];
      updated[index].check = !updated[index].check;
      if (updated[index].children.length > 0) {
        updated[index].children.forEach((child) => {
          if (updated[index].check) {
            child.check = true;
            setChildData((prev) => [...prev, child.value]);
          } else {
            child.check = false;
            setChildData((prev) => prev.filter((value) => value !== child.value));
          }
        });
      }
      if (updated[index].check) {
        setParentData((prev) => [...prev, updated[index].value]);
      } else {
        setParentData((prev) => prev.filter((value) => value !== updated[index].value));
      }
      return updated;
    });
  };


  const handleChildChange = (parentIndex, childIndex) => {
    setCheckedItems((prev) => {
      const updated = [...prev];
      updated[parentIndex].children[childIndex].check = !updated[parentIndex].children[childIndex].check;
      if (updated[parentIndex].children[childIndex].check) {
        setChildData((prev) => [...prev, updated[parentIndex].children[childIndex].value]);
      } else {
        setChildData((prev) => prev.filter((value) => value !== updated[parentIndex].children[childIndex].value));
      }
      if (updated[parentIndex].children.some((child) => child.check)) {
        updated[parentIndex].check = true;
        setParentData((prev) => {
          if (!prev.includes(updated[parentIndex].value)) {
            return [...prev, updated[parentIndex].value];
          }
          return prev;
        });
      } else {
        updated[parentIndex].check = false;
        setParentData((prev) => prev.filter((value) => value !== updated[parentIndex].value));
      }
      return updated;
    });
  };

  useEffect(() => {
    if (foruserregisteremployeedata && foruserregisteremployeedata.length > 0) {
      const employeeData = foruserregisteremployeedata[0]; // Assuming employee data is at index 0
      axios
        .get(`${urllink}usercontrol/get_for_useregister_access?employeeid=${employeeData.EmployeeID}`)
        .then((response) => {
          const data = response.data[0];
          setFormData((prevFormData) => ({
            ...prevFormData,
            employeeid: employeeData.EmployeeID,
            username: data?.username,
            firstName: employeeData.EmployeeName,
            lastName: employeeData.FatherName,
            Title: employeeData.Title,
            PhoneNo: employeeData.PhoneNumber,
            email: employeeData.Email,
            roleName: employeeData.Designation,
            gender: employeeData.Gender,
            qualification: employeeData.Qualification,
          }));

          // Preselect Locations
          const preselectedLocations = data.Location.split(',');
          setLocationData(preselectedLocations);



          // Preselect Access
          const accessOne = data.Access_one.split(',');
          if (accessOne.length > 0) {
            setIsUpdate(true);
          } else {
            setIsUpdate(false);
          }
          const accessTwo = data.Access_two.split(',');
          const preselectedAccess = [...accessOne, ...accessTwo];

          setCheckedItems((prevItems) =>
            prevItems.map((item) => {
              const isParentChecked = preselectedAccess.includes(item.value);
              const updatedChildren = item.children.map((child) => ({
                ...child,
                check: preselectedAccess.includes(child.value),
              }));
              return {
                ...item,
                check: isParentChecked,
                children: updatedChildren,
              };
            })
          );

          setParentData(accessOne);
          setChildData(accessTwo);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [foruserregisteremployeedata, urllink]);






  const handlelocationChange = (lname) => {
    setLocationData((prev) => {
      if (lname === 'ALL') {
        if (!prev.includes('ALL')) {
          // Select all locations
          return Locations.map(location => location.location_name);
        } else {
          // Deselect all locations
          return [];
        }
      } else {
        // Normal selection logic
        if (!prev.includes(lname)) {
          const newSelection = [...prev, lname];
          // Check if all other locations are selected
          const allOtherSelected = Locations.every(location => location.location_name === 'ALL' || newSelection.includes(location.location_name));
          return allOtherSelected ? Locations.map(location => location.location_name) : newSelection;
        } else {
          return prev.filter((value) => value !== lname && value !== 'ALL');
        }
      }
    });
  };


  useEffect(() => {
    // Fetch role options from the backend and update state
    fetchRoleOptions();
    fetchlocationOptions();
    // Log all user data when the page reloads
    fetchUserList();
  }, []);

  const fetchlocationOptions = () => {
    // Fetch role options from the backend
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        console.log(response);
        const data = response.data;
        if (data) {
          setLocations(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    fetchUserList()
      .then((usernames) => {
        if (usernames === null) {
          // Handle the case when usernames are null
        } else if (!isUpdate && usernames.includes(formData.username)) {
          // Check if it's not an update and username already exists
          userwarn();
          setFormData({ ...formData, username: "" });
        } else {
          // Filter out 'ALL' from LocationData before submitting
          const filteredLocationData = LocationData.filter(location => location !== 'ALL');
  
          const datatosend = {
            ...formData,
            created_by: userRecord?.username || '',
            ChildData: ChildData.join(','),
            ParentData: ParentData.join(','),
            LocationData: filteredLocationData.join(','),
          };
  
          console.log(datatosend);
  
          const url = isUpdate
            ? `${urllink}usercontrol/update_userregister`
            : `${urllink}usercontrol/insert_userregister`;
  
          axios
            .post(url, datatosend)
            .then((response) => {
              if (response.data.message) {
                successMsg(response.data.message);
                fetchRoleOptions();
                if (isUpdate) {
                  setIsUpdate(false);
                }
              } else {
                console.log(response.data);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };
  
  console.log(Locations)
  const fetchUserList = async () => {
    try {
      const response = await axios.get(
        `${urllink}usercontrol/userRegistercheck`
      );
      const data = response.data;
      console.log(data);
      return data.usernames; // Return only the 'usernames' array from the response data
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };

  const fetchRoleOptions = () => {
    // Fetch role options from the backend
    axios
      .get(`${urllink}usercontrol/getRoleData`)
      .then((response) => {
        console.log(response);
        setRoleOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching role options:", error);
      });
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const fail = () => {
    toast.warn("User Registration Failed", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = () => {
    toast.warn("Username already Exists", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleinpchange = (e) => {
    const { name, value } = e.target;
    if (name === "PhoneNo") {
      if (value.length <= 10) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>User Registration</h4>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            padding: "0px 10px",
            boxSizing: "border-box",
          }}
        >
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="username">
                Employee Id<span>:</span>
              </label>
              <input
                type="text"
                id="employeeid"
                name="employeeid"
                value={formData.employeeid}
                onChange={handleinpchange}
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="username">
                Username<span>:</span>
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleinpchange}
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="password">
                Password<span>:</span>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleinpchange}
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="username">
                Title<span>:</span>
              </label>
              <input
                name="Title"
                value={formData.Title}
                onChange={handleinpchange}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="firstName">
                First Name<span>:</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleinpchange}
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="lastName">
                Last Name<span>:</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleinpchange}
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="Dateofbirth">
                Gender<span>:</span>
              </label>
              <input
                type="text"
                value={formData.gender}
                onChange={handleinpchange}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="PhoneNo">
                Phone No<span>:</span>
              </label>
              <input
                type="number"
                name="PhoneNo"
                value={formData.PhoneNo}
                onChange={handleinpchange}
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="Qualification">
                Qualification<span>:</span>
              </label>
              <input
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={handleinpchange}
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="email">
                Email<span>:</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleinpchange}
                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                required
              />
            </div>


            <div className="RegisForm_1">
              <label htmlFor="role">
                Role<span>:</span>
              </label>
              <input
                name="role"
                value={formData.roleName}
                onChange={handleinpchange}
              />
            </div>
          </div>

          <br />
          <div className="Add_items_Purchase_Master">
                  <span>Location</span>
                </div>
          <div className="displayuseraccess">
            {Locations.map((p, indx) => (
              <div className="displayuseraccess_child" key={indx}>
                <input
                  type="checkbox"
                  id={`${indx}_${p?.location_name}`}
                  checked={LocationData.includes(p?.location_name)}
                  onChange={() => handlelocationChange(p?.location_name)}
                />
                <label htmlFor={`${indx}_${p?.location_name}`} className='par_acc_lab'>{p?.location_name}</label>
              </div>
            ))}
          </div>
          <br></br>
          <div className="Add_items_Purchase_Master">
            Access
          </div>
          <div className='displayuseraccess'>
            {checkedItems.map((item, indx) => (
              <div key={indx} className='displayuseraccess_child'>
                <input
                  type="checkbox"
                  id={item.key}
                  checked={item.check}
                  onChange={() => handleParentChange(indx)}
                />
                <label htmlFor={item.key} className='par_acc_lab'>{item.label}</label>
                {item.children.map((child, ind1) => (
                  <div key={ind1} style={{ marginLeft: "20px", marginTop: '5px' }}>
                    <input
                      type="checkbox"
                      id={child.key}
                      checked={child.check}
                      onChange={() => handleChildChange(indx, ind1)}
                    />
                    <label htmlFor={child.key} className='chi_acc_lab'>{child.label}</label><br />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="Register_btn_con" style={{ marginTop: "20px" }}>
          <button onClick={handleSubmit} type="submit" className="RegisterForm_1_btns">
            {isUpdate ? 'Update' : 'Submit'}
          </button>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        containerId="toast-container-over-header"
      />
    </>
  );
}

export default UserRegister;
