import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import LoupeIcon from "@mui/icons-material/Loupe";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
// import "../SupplierMaster/SupplierMaster.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function IndentRecieveList() {
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const navigate = useNavigate();
  const [page1, setPage1] = useState(0);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [Productype, setProductype] = useState("");
  const [summa, setsumma] = useState([]);
  const [selectedShow, setSelectedShow] = useState(false);
  const [Rowdata, setRowdata] = useState([]);
  const statustype = "Approved";
  // const newlocation = "Chennai";
  const showdown1 = Rowdata.length;
  const pageSize1 = 10;

  useEffect(() => {
    axios
      .get(
        `${urllink}IndentRaise/get_All_Issue_indent_data?Location=${userRecord?.location}&Status=${statustype}&product_category=${Productype}`
      )
      .then((response) => {
        const data = response.data.data;
        console.log("out22", data);
        setsumma([
          ...data.map((row, ind) => ({
            id: ind + 1,
            ...row,
          })),
        ]);
      })
      .catch((error) => {
        console.error("Error fetching patients data:", error);
      });
  }, [Productype, statustype]);

  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click

  const handleRequestEdit = (params) => {
    const index = params.row.items;
    console.log("index:", index);
    let B_data = index.map((p, index) => ({
      id: index + 1,
      ...p,
    }));
    setRowdata(B_data);
    setSelectedShow(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    console.log("ss", summa);

    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.IndentIssue_InvoiceNo.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  const handleMovetorecieve = (params) => {
    const data = params.row;
    navigate("/Home/Indent_Recieve_Master");
    dispatchvalue({ type: "IndentRecieveListData", value: data });
  };

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const totalPages1 = Math.ceil(Rowdata.length / 10);

  // Define the columns dynamically
  const dynamicColumns = [
    {
      field: "IndentIssue_InvoiceNo",
      headerName: "Indent Issue InvoiceNo",
      width: 180,
    },
    { field: "Indent_Issue_Date", headerName: "Indent Issue Date", width: 170 },
    { field: "Issue_From", headerName: "Issue From", width: 150 },
    { field: "Issue_To", headerName: "Issue To", width: 140 },
    { field: "Finel_NetAmount", headerName: "NetAmount", width: 120 },
    {
      field: "Status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <>{params.row.Status === "Approved" && "Pending"}</>
      ),
    },
    {
      field: "View",
      headerName: "View",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestEdit(params)}
          >
            <VisibilityIcon />
          </Button>
          {params.row.Status === "Approved" && (
            <Button
              className="cell_btn"
              onClick={() => handleMovetorecieve(params)}
            >
              <ArrowForwardIcon />
            </Button>
          )}
        </>
      ),
    },
  ];

  const dynamicColumns1 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Generic_Name", headerName: "Generic Name", width: 150 },
    { field: "Strength", headerName: "Strength", width: 120 },
    { field: "UOM", headerName: "UOM", width: 120 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "Order_Quantity", headerName: "Ordered Quantity", width: 120 },
    { field: "Issue_Quantity", headerName: "Issue Quantity", width: 120 },
    {
      field: "Issue_TotalQuantity",
      headerName: "Issue TotalQuantity",
      width: 120,
    },
    { field: "ManufactureDate", headerName: "ManufactureDate", width: 120 },
    { field: "ExpiryDate", headerName: "ExpiryDate", width: 120 },
    { field: "MRP_Per_Quantity", headerName: "MRP", width: 120 },
    { field: "Selling_Rate", headerName: "Selling Rate", width: 120 },
    { field: "Tax_Percentage", headerName: "Tax Percentage", width: 120 },
    {
      field: "Taxable_Selling_Rate",
      headerName: "Taxable Selling Rate",
      width: 120,
    },
    { field: "TotalAmount", headerName: "TotalAmount", width: 120 },
  ];

  const dynamicColumns2 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "Order_Quantity", headerName: "Ordered Quantity", width: 120 },
    { field: "Issue_Quantity", headerName: "Issued Quantity", width: 120 },
    {
      field: "Issue_TotalQuantity",
      headerName: "Total Issued Quantity",
      width: 120,
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head h_head_h_2">
          <h4> Indent Recieve List</h4>

          <div className="doctor_select_1 selt-dctr-nse vcxw2er">
            <label htmlFor="Calender"> Product Type :</label>
            <select
              className="Product_Master_div_select_opt"
              value={Productype}
              onChange={(e) => {
                setProductype(e.target.value);
              }}
            >
              <option value="">Select</option>
              <option value="MedicalConsumable">Medical Consumable</option>
              <option value="MedicalNonConsumable">
                Medical Non-Consumable
              </option>
              <option value="NonMedicalConsumable">
                Non MedicalConsumable
              </option>
              <option value="NonMedicalNonConsumable">
                Non MedicalNon-Consumable
              </option>
              <option value="Surgical">Surgical</option>
              <option value="Assets">Assets</option>
              <option value="Stationary">Stationary</option>
              <option value="Non_Stationary">Non Stationary</option>
            </select>
          </div>
        </div>

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Invoice No <span>:</span>
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Supplier Code"
            />
          </div>
        </div>
        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
          {selectedShow && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow(false);
              }}
            >
              <div
                className="newwProfiles"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>{Productype}List</h3>
                  </div>
                  <div className="con_1 "></div>
                  <div className="IP_grid">
                    <ThemeProvider theme={theme}>
                      <div className="IP_grid_1">
                        <DataGrid
                          rows={Rowdata.slice(
                            page1 * pageSize1,
                            (page1 + 1) * pageSize1
                          )} // Display only the current page's data
                          columns={
                            Productype === "Medical"
                              ? dynamicColumns1
                              : dynamicColumns2
                          } // Use dynamic columns here
                          pageSize={10}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          onPageChange={handlePageChange1}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          className="data_grid"
                        />
                        {showdown1 > 0 && filteredRows.length > 10 && (
                          <div className="IP_grid_foot">
                            <button
                              onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                              }
                              disabled={page === 0}
                            >
                              Previous
                            </button>
                            Page {page1 + 1} of {totalPages1}
                            <button
                              onClick={() =>
                                setPage((prevPage) =>
                                  Math.min(prevPage + 1, totalPages1 - 1)
                                )
                              }
                              disabled={page1 === totalPages1 - 1}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </ThemeProvider>
                    {showdown1 !== 0 && filteredRows.length !== 0 ? (
                      ""
                    ) : (
                      <div className="IP_norecords">
                        <span>No Records Found</span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
