import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const handlePrivacyPolicy = () => {
    navigate("/Home/Privacy-Policy");
  };

  const handleTermsOfUse = () => {
    navigate("/Home/Terms-of-Use");
  };

  const handleNotFound = () => {
    navigate("/Home/Not-Found");
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="powered-by">
          Powered by{" "}
          <span className="blossom-logo" onClick={handleNotFound}>
            Blossom
          </span>
        </div>
        <div className="footer-links">
          <div onClick={handlePrivacyPolicy}>Privacy Policy</div> |
          <div className="termsofuseclr" onClick={handleTermsOfUse}>
            Terms of Use
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
