import React, { useEffect, useState } from "react";
import "./Billing.css";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

function BillingHistory() {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [selectedOption, setSelectedOption] = useState("Month");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedShow, setSelectedShow] = useState(false);

  const [CostomsFromdate, setCostomsFromdate] = useState("");

  const [CostomsTodate, setCostomsTodate] = useState("");
  const [SearchName, setSearchName] = useState("");

  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const [columns] = useState([
    { field: "id", headerName: "S.No", width: 150 },
    { field: "Service_Type", headerName: "Bill Types", width: 250 },
    { field: "Total_Amount", headerName: "Amount", width: 180 },
    { field: "Total_GST", headerName: "GST", width: 100 },
    {
      field: "Status ",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleProceed(params.row)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ]);

  const [rows, setRows] = useState([]);
  const [Rowdata, setRowdata] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [slectedListWiseShow, setslectedListWiseShow] = useState("User_Name");
  const [SelectedNameView, setSelectedNameView] = useState({
    listName: "",
    Searchname: "",
  });

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    setSelectedDate("");
  };

  const handleDateChange = (e) => {
    const inputYear = e.target.value;
    setSelectedDate(inputYear);
  };

  const showdown = rows.length;
  const pageSize = 10;

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const totalPages = Math.ceil(rows.length / 10);

  const Serch_billing_fun = () => {
    axios
      .get(
        `${urllink}GeneralBilling/get_All_billing_data_history?selectedOption=${selectedOption}&selectedDate=${selectedDate}&CostomsFromdate=${CostomsFromdate}&CostomsTodate=${CostomsTodate}&location=${userRecord?.location}`
      )
      .then((response) => {
        let A_data = response.data.data;

        const B_data = A_data.map((p, index) => ({
          id: index + 1,
          ...p,
        }));
        setRows(B_data);

        // Handle the response data here based on your application logic.
      })
      .catch((error) => {
        console.error("Error fetching billing data:", error.message);
        // Handle errors, e.g., display an error message to the user.
      });
  };

  useEffect(() => {
    if (slectedListWiseShow === "Pharmacist") {
      setSelectedNameView({
        listName: "Pharmacy Billing",
        Searchname: "Pharmacist Name",
      });
    } else {
      setSelectedNameView({
        listName: "Pharmacy Billing",
        Searchname: "Item Name",
      });
    }
  }, [slectedListWiseShow]);

  useEffect(() => {
    if (selectedShow === true) {
      axios
        .get(
          `${urllink}GeneralBilling/get_billing_data_history_by_ServiceType?Service_Type=${SelectedNameView.listName}&selectedOption=${selectedOption}&selectedDate=${selectedDate}&CostomsFromdate=${CostomsFromdate}&CostomsTodate=${CostomsTodate}&slectedListWiseShow=${slectedListWiseShow}&location=${userRecord?.location}`
        )
        .then((response) => {
          let A_data = response.data.data;
          let B_data = A_data.map((p, index) => ({
            id: index + 1,
            ...p,
          }));
          setRowdata(B_data);
        })
        .catch((error) => {
          console.error("Error fetching billing data:", error.message);
          // Handle errors, e.g., display an error message to the user.
        });
    }
  }, [
    selectedShow,
    slectedListWiseShow,
    CostomsFromdate,
    CostomsTodate,
    SelectedNameView.listName,
    selectedDate,
    selectedOption,
  ]);

  const handleProceed = (params) => {
    setSelectedShow(true);

    if (params.Service_Type === "DoctorConsultation") {
      setSelectedNameView({
        listName: params.Service_Type,
        Searchname: "Doctor Name",
      });
    } else if (params.Service_Type === "Procedure") {
      setSelectedNameView({
        listName: params.Service_Type,
        Searchname: "Procedure Name",
      });
      // Additional logic for Procedure
    } else if (params.Service_Type === "PharmacyBilling") {
      setSelectedNameView({
        listName: "Pharmacy Billing",
        Searchname: "Pharmacist Name",
      });
      // Additional logic for PharmacyBilling
    }
  };

  useEffect(() => {
    const lowerCaseQuery = SearchName.toLowerCase();
    const filteredData = Rowdata.filter((row) => {
      const lowerCaseSupplierName = row.DoctorName.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [SearchName, Rowdata]);

  const showdown1 = Rowdata.length;
  const pageSize1 = 10;

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const totalPages1 = Math.ceil(Rowdata.length / 10);
  const dynamicColumns = () => {
    if (SelectedNameView.listName === "DoctorConsultation") {
      return [
        { field: "id", headerName: "S.No", width: 100 },
        { field: "DoctorName", headerName: "DoctorName", width: 200 },
        { field: "ServiceType", headerName: "Service Type", width: 200 },
        { field: "Total_Amount", headerName: "Amount", width: 150 },
      ];
    } else if (SelectedNameView.listName === "Procedure") {
      return [
        { field: "id", headerName: "S.No", width: 100 },
        { field: "DoctorName", headerName: "Therapist Name", width: 200 },
        { field: "ServiceType", headerName: "Service Type", width: 200 },
        { field: "Total_Amount", headerName: "Amount", width: 150 },
      ];
    } else if (SelectedNameView.listName === "Pharmacy Billing") {
      return [
        { field: "id", headerName: "S.No", width: 100 },
        { field: "DoctorName", headerName: "Therapist Name", width: 200 },
        { field: "ServiceType", headerName: "Service Type", width: 200 },
        { field: "Total_Amount", headerName: "Amount", width: 150 },
      ];
    }
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Billing History</h4>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px 10px",
          }}
        >
          <div className="RegisFormcon Register_RegisFormcon  bilng-l">
            <div className="RegisForm_1 RegisForm_111">
              <label htmlFor="Title">
                Select option <span>:</span>
              </label>
              <select
                name="BillType"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="Month">Month</option>
                <option value="Week">Week</option>
                <option value="Day">Day</option>
                <option value="Customs">Customs</option>
              </select>
            </div>
            {selectedOption === "Day" && (
              <div className="RegisForm_1 RegisForm_111">
                <label>
                  {" "}
                  From Date <span>:</span>{" "}
                </label>
                <input
                  type="date"
                  value={selectedDate}
                  name="SearchBillingDate"
                  onChange={handleDateChange}
                  required
                />
              </div>
            )}
            {selectedOption === "Week" && (
              <div className="RegisForm_1 RegisForm_111">
                <label>
                  {" "}
                  From Date <span>:</span>{" "}
                </label>
                <input
                  type="week"
                  value={selectedDate}
                  name="SearchBillingDate"
                  onChange={handleDateChange}
                  required
                />
              </div>
            )}
            {selectedOption === "Month" && (
              <div className="RegisForm_1 RegisForm_111">
                <label>
                  {" "}
                  From Date <span>:</span>{" "}
                </label>
                <input
                  type="month"
                  value={selectedDate}
                  name="SearchBillingDate"
                  onChange={handleDateChange}
                  required
                />
              </div>
            )}
            {selectedOption === "Customs" && (
              <>
                <div className="RegisForm_1 RegisForm_111">
                  <label>
                    {" "}
                    From Date <span>:</span>{" "}
                  </label>
                  <input
                    type="date"
                    name="SearchBillingDate"
                    onChange={(e) => {
                      setCostomsFromdate(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="RegisForm_1 RegisForm_111">
                  <label>
                    {" "}
                    To Date <span>:</span>{" "}
                  </label>
                  <input
                    type="date"
                    name="SearchBillingDate"
                    onChange={(e) => {
                      setCostomsTodate(e.target.value);
                    }}
                    required
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={Serch_billing_fun}>
            Search
          </button>
        </div>

        <ThemeProvider theme={theme} className="theme">
          <div className=" grid_1">
            <DataGrid
              rows={rows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && rows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
        {selectedShow && (
          <div
            className={
              isSidebarOpen
                ? "sideopen_showcamera_profile"
                : "showcamera_profile"
            }
            onClick={() => {
              setSelectedShow(false);
              setSearchName("");
            }}
          >
            <div className="newwProfiles" onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
                <div className="calendar_head">
                  <h3>{SelectedNameView.listName} List</h3>
                  {SelectedNameView.listName === "Pharmacy Billing" && (
                    <div className="calender_select_opt">
                      <label htmlFor="input">Select Type :</label>
                      <select
                        className="calender_select_colr"
                        value={slectedListWiseShow}
                        onChange={(e) => {
                          setslectedListWiseShow(e.target.value);
                          setSearchName("");
                        }}
                      >
                        <option value="User_Name">By Pharmacist</option>
                        <option value="Item_Name">By Item</option>
                      </select>
                    </div>
                  )}
                </div>
                <div className="con_1 ">
                  <div className="inp_1">
                    <label htmlFor="input">
                      {SelectedNameView.Searchname} :
                    </label>
                    <input
                      type="text"
                      value={SearchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      placeholder="Enter the Item Name"
                    />
                  </div>

                  <button className="btn_1" type="submit">
                    <SearchIcon />
                  </button>
                </div>
                <div className="grid_1">
                  <ThemeProvider theme={theme}>
                    <div className="grid_1">
                      <DataGrid
                        rows={filteredRows.slice(
                          page1 * pageSize1,
                          (page1 + 1) * pageSize1
                        )} // Display only the current page's data
                        columns={dynamicColumns()} // Use dynamic columns here
                        pageSize={10}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        onPageChange={handlePageChange1}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        className="data_grid"
                      />
                      {showdown1 > 0 && filteredRows.length > 10 && (
                        <div className="IP_grid_foot">
                          <button
                            onClick={() =>
                              setPage((prevPage) => Math.max(prevPage - 1, 0))
                            }
                            disabled={page === 0}
                          >
                            Previous
                          </button>
                          Page {page1 + 1} of {totalPages1}
                          <button
                            onClick={() =>
                              setPage((prevPage) =>
                                Math.min(prevPage + 1, totalPages1 - 1)
                              )
                            }
                            disabled={page1 === totalPages1 - 1}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </ThemeProvider>
                  {showdown1 !== 0 && filteredRows.length !== 0 ? (
                    ""
                  ) : (
                    <div className="IP_norecords">
                      <span>No Records Found</span>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <button
                    className="closeicon-cs"
                    onClick={() => {
                      setSelectedShow(false);
                      setSearchName("");
                    }}
                  >
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default BillingHistory;
