import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";
import { useSelector } from "react-redux";

const Prescription2 = (props) => {
  const formData = props.formData;
  const Location = props.userRecord.location;
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [selectedMedicines, setSelectedMedicines] = useState([]);

  const [appointmentDate, setAppointmentDate] = useState("");

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate(currentDate);
  }, []);

  const fetchData = useCallback(() => {
    const PatientID = formData.PatientID;

    axios
      .get(
        `${urllink}doctorsworkbench/get_prescription?PatientID=${PatientID}&location=${Location}`
      )
      .then((response) => {
        if (response.data) {
          const data = response.data;

          const filteredData = data.filter(
            (item) => item.PatientID === formData.PatientID
          );

          setSelectedMedicines(filteredData);
        } else {
          alert("no data found");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [formData, appointmentDate]);

  useEffect(() => {
    fetchData(); // Call fetchData immediately when formData or appointmentDate changes
  }, [fetchData]);

  return (
    <div className="for">
      <div className="custom-header">
        <h4>Selected Medicine</h4>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Generic Name</th>
              <th id="slectbill_ins">Item Name</th>
              <th id="slectbill_ins">Medicine</th>
              <th id="slectbill_ins">Dose</th>
              <th id="slectbill_ins">Route</th>
              <th id="slectbill_ins">Frequency</th>
              <th id="slectbill_ins">Duration</th>
              <th id="slectbill_ins">Qty</th>
              <th id="slectbill_ins">Instruction</th>
            </tr>
          </thead>

          <tbody>
            {selectedMedicines.map((medicineInfo, index) => (
              <tr key={index}>
                <td>{medicineInfo.GenericName}</td>
                <td>{medicineInfo.ItemName}</td>
                <td>
                  {medicineInfo.ItemName} - {medicineInfo.GenericName}
                </td>
                <td>{medicineInfo.Dose}</td>
                <td>{medicineInfo.Route}</td>
                <td>{medicineInfo.Frequency}</td>
                <td>{medicineInfo.Duration}</td>
                <td>{medicineInfo.Qty}</td>
                <td>{medicineInfo.Instruction}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Prescription2;
