import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const HSNReport = () => {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");

  const [SearchformData, setFormData] = useState({
    Location: "",
    ProductType: "",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });

  const [Location, setLocation] = useState([]);

  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      if (value === "Current") {
        setFormData({
          ...SearchformData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      } else {
        setFormData({
          ...SearchformData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...SearchformData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setFormData((prev) => ({
      ...prev,
      CurrentDate: formattedDate,
    }));
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data.map((p) => p.location_name);

        setLocation([...data, "CentralStore"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const dynamicColumns =
    SearchformData.ProductType === "Pharmacy"
      ? [
          { field: "id", headerName: "S.NO", width: 50 },
          { field: "HSN_Code", headerName: "HSN Code", width: 130 },
          { field: "Item_Name", headerName: "Item Name", width: 180 },
          {
            field: "Billing_Quantity",
            headerName: "Total Quantity",
            width: 100,
          },
          { field: "TotalValue", headerName: "Total Value", width: 150 },
          { field: "Totalwithottax", headerName: "Taxable Value", width: 150 },
          {
            field: "CGST_Amount",
            headerName: "Central Tax Amount",
            width: 150,
          },
          { field: "SGST_Amount", headerName: "State Tax Amount", width: 150 },
          { field: "Unit_Price", headerName: "Rate", width: 150 },
        ]
      : [
          { field: "id", headerName: "S.NO", width: 50 },
          { field: "ProcedureName", headerName: "Procedure Name", width: 180 },
          {
            field: "Billing_Quantity",
            headerName: "Total Quantity",
            width: 100,
          },
          { field: "TotalValue", headerName: "Total Value", width: 150 },
          { field: "Totalwithottax", headerName: "Taxable Value", width: 150 },
          {
            field: "CGST_Amount",
            headerName: "Central Tax Amount",
            width: 150,
          },
          {
            field: "SGST_Amount",
            headerName: "State/UT Tax Amount",
            width: 130,
          },
          { field: "Unit_Price", headerName: "Rate", width: 150 },
        ];

  const handletoSearch = () => {
    const params = {
      Location: SearchformData.Location,
      ProductType: SearchformData.ProductType,
      DateType: SearchformData.DateType,
      CurrentDate: SearchformData.CurrentDate,
      FromDate: SearchformData.FromDate,
      ToDate: SearchformData.ToDate,
    };

    let requiredFields = [];
    if (SearchformData.DateType === "Customize") {
      requiredFields = [
        "Location",
        "ProductType",
        "DateType",
        "FromDate",
        "ToDate",
      ];
    } else {
      requiredFields = ["Location", "ProductType", "DateType"];
    }
    const existingItem = requiredFields.filter(
      (field) => !SearchformData[field]
    );
    if (existingItem.length > 0) {
      alert(`Please Fill the RequiredFields : ${existingItem.join(",")} `);
    } else {
      axios
        .get(`${urllink}FinanceMagement/get_hsn_reports_for_finance`, {
          params,
        })
        .then((response) => {
          const A_data = response.data;
          const B_data = A_data.map((p, index) => ({
            id: index + 1,
            ...p,
          }));
          setFilteredRows(B_data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleExportToExcel = () => {
    if (filteredRows.length !== 0) {
      const columns =
        SearchformData.ProductType === "Pharmacy"
          ? [
              { dataKey: "id", header: "S.NO" },
              { dataKey: "HSN_Code", header: "HSN Code" },
              { dataKey: "Item_Name", header: "Item Name" },
              { dataKey: "Billing_Quantity", header: "Total Quantity" },
              { dataKey: "TotalValue", header: "Total Value" },
              { dataKey: "Totalwithottax", header: "Taxable Value" },
              { dataKey: "CGST_Amount", header: "Central Tax Amount" },
              { dataKey: "SGST_Amount", header: "State Tax Amount" },
              { dataKey: "Unit_Price", header: "Rate" },
            ]
          : [
              { dataKey: "id", header: "S.NO" },
              { dataKey: "ProcedureName", header: "Procedure Name" },
              { dataKey: "Billing_Quantity", header: "Total Quantity" },
              { dataKey: "TotalValue", header: "Total Value" },
              { dataKey: "Totalwithottax", header: "Taxable Value" },
              { dataKey: "CGST_Amount", header: "Central Tax Amount" },
              { dataKey: "SGST_Amount", header: "State Tax Amount" },
              { dataKey: "Unit_Price", header: "Rate" },
            ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...filteredRows.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, "HSNReport.csv");
    } else {
      alert("No Data to Save");
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>HSN Report</h4>
      </div>
      <br />
      
      <div className="con_1">
        <div className="inp_1">
          <label htmlFor="">
            Location <span>:</span>
          </label>
          <select
            name="Location"
            value={SearchformData.Location}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {Location.map((p) => (
              <option key={p} value={p}>
                {p.toUpperCase()}
              </option>
            ))}
          </select>
        </div>
        <div className="inp_1">
          <label htmlFor="">
            Product Type <span>:</span>
          </label>

          <select
            name="ProductType"
            value={SearchformData.ProductType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Pharmacy">Pharmacy</option>
            <option value="General">General</option>
          </select>
        </div>
      </div>
      <div className="con_1">
        <div className="inp_1">
          <label htmlFor="">
            Date Type <span>:</span>
          </label>
          <select
            name="DateType"
            value={SearchformData.DateType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Current">Current Date</option>
            <option value="Customize">Customize</option>
          </select>
        </div>
        {SearchformData.DateType === "Current" && (
          <div className="inp_1">
            <label htmlFor="">
              Current Date <span>:</span>
            </label>
            <input
              type="date"
              name="CurrentDate"
              value={SearchformData.CurrentDate}
              onChange={handleChange}
            />
          </div>
        )}
        {SearchformData.DateType === "Customize" && (
          <div className="inp_1">
            <label htmlFor="">
              From Date <span>:</span>
            </label>
            <input
              type="date"
              name="FromDate"
              value={SearchformData.FromDate}
              onChange={handleChange}
            />
          </div>
        )}
      </div>

      {SearchformData.DateType === "Customize" && (
        <div className="con_1">
          <div className="inp_1">
            <label htmlFor="">
              To Date <span>:</span>
            </label>
            <input
              type="date"
              name="ToDate"
              value={SearchformData.ToDate}
              onChange={handleChange}
            />
          </div>
        </div>
      )}

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handletoSearch}>
          Search
        </button>
      </div>

      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="IP_grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      {filteredRows.length !== 0 && (
        <div className="PrintExelPdf">
          <button onClick={handleExportToExcel}>Save Exel</button>
        </div>
      )}
    </div>
  );
};

export default HSNReport;
