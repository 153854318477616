import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function VisitingDoctor() {
  const visitingdotor = useSelector(
    (state) => state.userRecord?.vistingdoctordata
  );
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const Location = userRecord?.location;
  const username = userRecord?.username;
  const [Procedure, setProcedure] = useState("");

  const [procedurename, setprocedurename] = useState([]);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    doctorName: "",
    phoneNumber: "",
    email: "",
    location: "",
    specialist: "",
    experience: "",
    dateOfVisit: getCurrentDate(),
    duration: "",

    numberOfSessions: "",
    numberOfCraft: "",
  });

  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...visitingdotor[0] }));
    setprocedurename(visitingdotor[0]?.ProcedureName);
  }, [visitingdotor]);

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Ensure that the phone number is only 10 digits
    if (/^\d{0,10}$/.test(inputPhoneNumber)) {
      setFormData({ ...formData, phoneNumber: inputPhoneNumber });
    } else {
      alert("Phone Number must contain 10 digits");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initialize FormData
    const newformData = new FormData();

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        newformData.append(key, formData[key]);
      }
    }
    // Assuming Location is a variable with some value
    newformData.append("branchlocation", Location);
    newformData.append("createdby", username);
    newformData.append("procedure", Procedure);

    const generalbillingdata = {
      PatientID: formData?.PatientID,
      PatientName: formData?.PatientName,
      ProcedureName: formData?.ProcedureName[0]?.name,
      VisitID: formData?.VisitID,
      appointmentDate: formData?.dateOfVisit,
      DoctorName: formData?.doctorName,
      duration: formData?.duration,
      numberOfCraft: formData?.numberOfCraft,
      Sessions: formData?.numberOfSessions,
      ServiceType: "Procedure",
      PaidSessions: 0,
      RemainingSessions: 0,
      Therapy_completed_session: 0,
      Gst_charge: 0,
      Status: "Pending",
      location: userRecord?.location,
      GraftCount: formData.numberOfCraft,
      Complementry: 0,
      phoneNumber: formData.phoneNumber,
    };


    console.log('generalbillingdata',generalbillingdata)

    axios
      .post(
        `${urllink}VisitingDoctor/insert_visitingdoctor_procedure`,
        newformData
      )
      .then((response) => {
        setFormData([]);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .post(
        `${urllink}VisitingDoctor/insert_VisitingDoctor_General_Billing_Data`,
        generalbillingdata
      )
      .then((response) => {
        navigate("/Home/Patient-Visiting-Doctor");
      })
      .catch((error) => {
        console.error("Error insert Billing:", error);
      });
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Visiting Doctor</h4>
        </div>
        <br />

        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label>
              Visit Doctor <span> Dr.</span>
            </label>
            <select
              name="doctorName"
              value={formData.doctorName}
              onChange={(e) =>
                setFormData({ ...formData, doctorName: e.target.value })
              }
              required
            >
              {formData.doctorName ? (
                <>
                  <option value="" disabled>
                    Select
                  </option>
                  <option value={formData.doctorName}>
                    {formData.doctorName}
                  </option>
                </>
              ) : (
                <option value="" disabled>
                  {" "}
                  Select
                </option>
              )}
            </select>
          </div>

          <div className="RegisForm_1">
            <label>
              Date of Visit <span>:</span>
            </label>
            <input
              type="date"
              name="dateOfVisit"
              value={formData.dateOfVisit}
              onChange={(e) =>
                setFormData({ ...formData, dateOfVisit: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Treatment Procedure <span>:</span>
            </label>
            <select
              name="treatmentProcedure"
              value={Procedure}
              onChange={(e) => setProcedure(e.target.value)}
              required
            >
              <option value="">Select</option>
              {Array.isArray(procedurename) ? (
                procedurename.map((procedure, index) => (
                  <option key={index + 1} value={procedure.name}>
                    {procedure.name}
                  </option>
                ))
              ) : (
                <option disabled>No procedure available</option>
              )}
            </select>
          </div>

          <div className="RegisForm_1">
            <label>
              Number of Sessions <span>:</span>
            </label>
            <input
              type="text"
              name="numberOfSessions"
              value={formData.numberOfSessions}
              onChange={(e) =>
                setFormData({ ...formData, numberOfSessions: e.target.value })
              }
              required
            />
          </div>

          <div className="RegisForm_1">
            <label>
              Number of Crafts Per sitting/Session <span>:</span>
            </label>
            <input
              type="text"
              name="numberOfCraft"
              value={formData.numberOfCraft}
              onChange={(e) =>
                setFormData({ ...formData, numberOfCraft: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Duration <span>:</span>
            </label>
            <input
              type="text"
              name="duration"
              value={formData.duration}
              onChange={(e) =>
                setFormData({ ...formData, duration: e.target.value })
              }
              required
            />
          </div>
        </div>
        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default VisitingDoctor;
