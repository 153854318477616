import React, { useState, useRef, useEffect } from "react";
import "./ProPass.css";
import axios from "axios";
import { useSelector } from "react-redux";

const Profile = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);



  const [newImageProfile, setNewImageProfile] = useState(null);
  const [newImageCover, setNewImageCover] = useState(null);
  const [changesMade, setChangesMade] = useState(false);

  const [phoneNo, setPhoneNo] = useState(userRecord?.PhoneNumber);
  const [doorNo, setDoorNo] = useState("");
  const [street, setStreet] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const profileData1 = () => {
    axios
      .get(`${urllink}profile/getuserprofile?user_id=${userRecord?.user_id}`)
      .then((response) => {
        if (response.data) {
          const userData = response.data;
        
          setPhoneNo(userData.PhoneNumber);
          setDoorNo(userData.Door_No);
          setStreet(userData.Street);
          setArea(userData.Area);
          setCity(userData.City);
          setPincode(userData.PinCode);
          setState(userData.State);
          setCountry(userData.Country);

          // Convert base64 strings to Blobs
          const profileBlob = dataURItoBlob(
            `data:image/png;base64,${userData.Profile_image}`
          );
          const coverBlob = dataURItoBlob(
            `data:image/png;base64,${userData.Cover_image}`
          );

          setNewImageProfile(profileBlob);
          setNewImageCover(coverBlob);
        } else {
          console.error("User data not found or empty");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  useEffect(() => {
    if (phoneNo && phoneNo.length > 10) {
      alert('Phone Number Must be 10 Digits');
      setPhoneNo(phoneNo.slice(0, 10));
    }
  }, [phoneNo]);
  

  useEffect(() => {
    axios
      .get(`${urllink}profile/getuserprofile`, {
        params: {
          user_id: userRecord?.user_id,
        },
      })
      .then((response) => {
        if (response.data) {
          const userData = response.data;
         
          setPhoneNo(userData.PhoneNumber);
          setDoorNo(userData.Door_No);
          setStreet(userData.Street);
          setArea(userData.Area);
          setCity(userData.City);
          setPincode(userData.PinCode);
          setState(userData.State);
          setCountry(userData.Country);

          
          const profileBlob = dataURItoBlob(
            `data:image/png;base64,${userData.Profile_image}`
          );
          const coverBlob = dataURItoBlob(
            `data:image/png;base64,${userData.Cover_image}`
          );

          setNewImageProfile(profileBlob);
          setNewImageCover(coverBlob);
        } else {
          console.error("User data not found or empty");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [userRecord?.user_id]);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  useEffect(() => {
    return () => {
      if (newImageCover) {
        URL.revokeObjectURL(newImageCover);
      }
    };
  }, [newImageCover]);

  useEffect(() => {
    return () => {
      if (newImageProfile) {
        URL.revokeObjectURL(newImageProfile);
      }
    };
  }, [newImageProfile]);

  const onImageChange = (e, setImageFunction) => {
    const file = e.target.files[0];

    if (file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onload = (event) => {
          // const dataURL = event.target.result; // Set the result to a variable

          // Convert the image file to a Blob
          fileToBlob(file)
            .then((blob) => {
              // Handle the blob as needed (e.g., send it to the server)
             
              setImageFunction(blob);
              resolve();
            })
            .catch((error) => {
              console.error("Error converting image to Blob:", error);
              reject(error);
            });
        };

        fileReader.onerror = (error) => {
          console.error("Error reading file:", error);
          reject(error);
        };

        fileReader.readAsDataURL(file);
      });
    }
  };

  const handleFileInputCover = async (event) => {
    try {
      await onImageChange(event, setNewImageCover);
      setChangesMade(true);
    } catch (error) {
      console.error("Error handling cover image:", error);
    }
  };

  const handleFileInputProfile = async (event) => {
    try {
      await onImageChange(event, setNewImageProfile);
      setChangesMade(true);
    } catch (error) {
      console.error("Error handling profile image:", error);
    }
  };

  const fileToBlob = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURI = reader.result;
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        resolve(blob);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  

  const handleSaveClick = () => {
    if (changesMade) {
      const newDatatosent = {
        cover_image: newImageCover,
        profile_image: newImageProfile,
        user_id: userRecord?.user_id,
        Phone_No: phoneNo || null,
        Door_No: doorNo.toUpperCase() || null,
        Street: street.toUpperCase() || null,
        Area: area.toUpperCase() || null,
        City: city.toUpperCase() || null,
        Pincode: pincode || null,
        State: state.toUpperCase() || null,
        Country: country.toUpperCase() || null,
      };

     
      const formData = new FormData();

      for (const key in newDatatosent) {
        if (newDatatosent.hasOwnProperty(key)) {
          formData.append(key, newDatatosent[key]);
        }
      }

      const url = `${urllink}profile/profileinsert`;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
        
          alert("Profile Updated Successfully");
          profileData1();
          setChangesMade(false); // Reset changesMade after successful save
        })
        .catch((error) => {
          // Handle error
          console.error("Error uploading images:", error);
        });
    }
  
  };

  const fileInputCover = useRef(null);
  const fileInputProfile = useRef(null);

  

  return (
    <div className="container_pro">
      <div className="head_img_head">
        <div className="head_img">
          {newImageCover instanceof Blob ? (
            <img src={URL.createObjectURL(newImageCover)} alt="Cover" />
          ) : (
            <img src={newImageCover} alt="Cover" />
          )}

          <label className="custom-button-back">
            Choose File
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              onChange={handleFileInputCover}
              className="file-input"
              ref={fileInputCover}
            />
          </label>
        </div>
        <div className="prof_round_head">
          <div className="profile_round">
            {
              <label className="custom-button-profile">
                Choose File
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={handleFileInputProfile}
                  className="file-input"
                  ref={fileInputProfile}
                />
              </label>
            }
            {newImageProfile instanceof Blob ? (
              <img src={URL.createObjectURL(newImageProfile)} alt="Cover" />
            ) : (
              <img src={newImageProfile} alt="Cover" />
            )}
          </div>

          <div className="user_name">
            <div className="name">
              <div className="rolenam">
                <h4>{userRecord?.username || "".toUpperCase()}</h4>
              </div>
              <br></br>
              <div className="rolenam">
                <h6>{userRecord?.role_name || "".toLowerCase()}</h6>
              </div>
            </div>
            {/* <button className="RegisterForm_1_btns" onClick={handleSaveClick}>
              Save As
            </button> */}
          </div>
        </div>
      </div>
<br/>
<br/>
      <div className="RegisFormcon">
       
        <div className="RegisForm_1">
          <label className="first">
            Title <span>:</span>
          </label>

          <input type="text" value={userRecord?.Title} />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            First Name <span>:</span>
          </label>
          <input type="text" value={userRecord?.First_Name} />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            Last Name <span>:</span>
          </label>
          <input type="text" value={userRecord?.Last_Name} />
        </div>
       

        <div className="RegisForm_1">
          <label className="first">
            Email <span>:</span>
          </label>
          <input type="text" value={userRecord?.email} />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            Phone no <span>:</span>
          </label>
          <input
            type="text"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
          />
        </div>

        <div className="RegisForm_1">
          <label className="first">
            Door No <span>:</span>
          </label>
          <input
            type="text"
            value={doorNo}
            onChange={(e) => setDoorNo(e.target.value)}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            Street <span>:</span>
          </label>
          <input
            type="text"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            Area <span>:</span>
          </label>
          <input
            type="text"
            value={area}
            onChange={(e) => setArea(e.target.value)}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            City <span>:</span>
          </label>
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            PinCode <span>:</span>
          </label>
          <input
            type="text"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            State <span>:</span>
          </label>
          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </div>

        <div className="RegisForm_1">
          <label className="first">
            Country <span>:</span>
          </label>
          <input
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>
      </div>

      <br />
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSaveClick}>
          Save
        </button>
      </div>
    </div>
  );
};

export default Profile;
