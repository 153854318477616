import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const HRmanagement = () => {
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [rows, setRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [department, setdepartment] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [intime, setInTime] = useState("");
  const [outtime, setOutTime] = useState("");
  const [shiftstartdate, setshiftstartdate] = useState("");
  const [shiftenddate, setshiftenddate] = useState("");
  const [Status, setStatus] = useState("Active");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");

  const [rolename, setRolename] = useState([]);

  const navigate = useNavigate();
  const totalPages = Math.ceil(rows.length / 10);

  const [page, setPage] = useState(0);

  const pageSize = 10;

  const showdown = rows.length;

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  useEffect(() => {
    axios
      .get(`${urllink}HRmanagement/getRole_all`)
      .then((response) => {
        setRolename(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord?.location]);

  const [columns] = React.useState([
    { field: "employeeid", headerName: "Employee ID", width: 130 },
    { field: "employeename", headerName: "Employee Name", width: 180 },
    { field: "designation", headerName: "Designation", width: 200 },
    { field: "shifttime", headerName: "Shift Timing", width: 200 },
    { field: "Shift_StartDate", headerName: "Shift Start Date", width: 150 },
    { field: "shiftenddate", headerName: "Shift End Date", width: 130 },
    { field: "Status", headerName: "Status", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <Button
          className="cell_btn"
          onClick={() => handleEditClick1(params.row)}
        >
          <EditIcon />
        </Button>
      ),
    },
  ]);

  const handleEditClick1 = (params) => {
    setOpenModal(true);
    setSelectedRowData(params);

    if (params.shifttime) {
      const parts = params?.shifttime?.split("to");

      // Extract the start time and end time
      const startTime = parts[0].trim(); // "08:00 AM"
      const endTime = parts[1].trim(); // "04:00 PM"

      // Convert start time to 24-hour format
      const startDate = new Date(`2000-01-01 ${startTime}`);
      const startHour24 = startDate.getHours();
      const startMinute = startDate.getMinutes();
      const Intime = `${startHour24.toString().padStart(2, "0")}:${startMinute
        .toString()
        .padStart(2, "0")}`;

      // Convert end time to 24-hour format
      const endDate = new Date(`2000-01-01 ${endTime}`);
      const endHour24 = endDate.getHours();
      const endMinute = endDate.getMinutes();
      const Outtime = `${endHour24.toString().padStart(2, "0")}:${endMinute
        .toString()
        .padStart(2, "0")}`;

      setInTime(Intime);
      setOutTime(Outtime);
    }
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}HRmanagement/get_employee_personaldetails_forduty?location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;

        const Records = data.map((userdata, index) => ({
          id: index + 1,
          employeeid: userdata.EmployeeID,
          employeephoto: userdata.EmployeePhoto,
          employeename: userdata.EmployeeName,
          phone: userdata.PhoneNumber,
          designation: userdata.Designation,
          shifttime: userdata.shifttime,
          shiftenddate: userdata.Shift_EndDate,
          location: userdata.Locations,
          Shift_StartDate: userdata.Shift_StartDate,
          Status: userdata.Status,
          department: userdata.Department,
          date: new Date().toISOString().split("T")[0],
          createdby: userRecord?.username,
        }));

        setRows(Records);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${urllink}usercontrol/getDepartment`)
      .then((response) => {
        setdepartment(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord, userRecord?.location]);

  useEffect(() => {
    setSelectedRowData(rows);
  }, [rows]);

  const handleSubmission = () => {
    const apiUrl = `${urllink}HRmanagement/insert_Shift_Details`;

    const submissionData = {
      employeeid: selectedRowData.employeeid,
      employeename: selectedRowData.employeename,
      location: selectedRowData.location,
      department: selectedRowData.department,
      designation: selectedRowData.designation,
      outtime: outtime,
      intime: intime,
      shiftstartdate: shiftstartdate,
      shiftenddate: shiftenddate,
      createdby: userRecord?.username,
      branchlocation: userRecord?.location,
      Status: Status,
    };

    const formData1 = new FormData();

    // Append data to FormData object
    Object.keys(submissionData).forEach((key) => {
      formData1.append(key, submissionData[key]);
    });

    axios
      .post(apiUrl, formData1, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setInTime("");
        setOutTime("");
        setshiftstartdate("");
        setshiftenddate("");
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
    setOpenModal(false);
    navigate("/Home/Duty-Management");
  };

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowerCaseQuery1 = searchQuery1.toLowerCase();
    const lowerCaseQuery2 = searchQuery2.toLowerCase();

    const filterRow = (row) => {
      // Ensure the row has necessary properties before accessing them
      if (row.employeename && row.designation && row.employeeid) {
        const lowerCaseEmployeeName = row.employeename.toLowerCase();
        const lowerCaseDesignation = row.designation.toLowerCase();
        const lowerCaseEmployeeID = row.employeeid.toLowerCase();

        const nameMatchesLeftToRight =
          lowerCaseEmployeeName.startsWith(lowerCaseQuery);
        const designationMatchesLeftToRight =
          lowerCaseDesignation.startsWith(lowerCaseQuery1);
        const employeeidMatchesLeftToRight =
          lowerCaseEmployeeID.startsWith(lowerCaseQuery2);

        // Check for matches anywhere in the string
        const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
        const designationMatches =
          lowerCaseDesignation.includes(lowerCaseQuery1);
        const employeeidMatches =
          lowerCaseDesignation.includes(lowerCaseQuery2);

        if (!searchQuery && !searchQuery1 && !searchQuery2) {
          // If both search queries are empty, do not filter out any data
          return true;
        }

        // Prioritize left-to-right matches in the first row
        return (
          (nameMatchesLeftToRight &&
            designationMatchesLeftToRight &&
            employeeidMatchesLeftToRight) ||
          (nameMatches && designationMatches && employeeidMatches)
        );
      }
      return false;
    };

    const filteredData = rows.filter(filterRow);

    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, rows, searchQuery2]);

  return (
    <div>
      <div className="appointment">
        <div className="h_head">
          <h3>Duty Management</h3>
        </div>

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Employee Name :</label>
            <input
              type="text"
              id="date"
              name="employeeName"
              placeholder="Enter Employee Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Designation :</label>
            <select
              name="designation"
              value={searchQuery1}
              onChange={(e) => setSearchQuery1(e.target.value)}
              className="new-custom-input-phone vital_select"
              required
            >
              <option value="">Select </option>
              {rolename.map((role) => (
                <option key={role.role_id} value={role.role_name}>
                  {role.role_name}
                </option>
              ))}
            </select>
          </div>
          <div className="inp_1">
            <label htmlFor="input">Employee ID :</label>
            <input
              type="text"
              id="employeeID"
              name="employeeID"
              placeholder="Enter Employee ID"
              value={searchQuery2}
              onChange={(e) => setSearchQuery2(e.target.value)}
            />
          </div>
        </div>

        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              hideFooter={true}
              isCellEditable={(params) => params.row.id !== 1}
              onEditCellChange={(params) => {
                const updatedRows = rows.map((row) => {
                  if (row.id === params.id) {
                    return { ...row, [params.field]: params.props.value };
                  }
                  return row;
                });
                setRows(updatedRows);
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 10 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {filteredRows.length === 0 && (
          <div className="norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>

      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="RegisFormcon leavecon">
              <div className="RegisForm_1 leaveform_1">
                <label htmlFor="intime">
                  From Date<span>:</span>
                </label>
                <input
                  type="date"
                  name="fromDate"
                  value={shiftstartdate}
                  onChange={(e) => {
                    setshiftstartdate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="RegisFormcon leavecon">
              <div className="RegisForm_1 leaveform_1">
                <label htmlFor="intime">
                  To Date<span>:</span>
                </label>
                <input
                  type="date"
                  name="toDate"
                  value={shiftenddate}
                  onChange={(e) => {
                    setshiftenddate(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="RegisFormcon leavecon">
              <div className="RegisForm_1 leaveform_1">
                <label htmlFor="intime">
                  In Time<span>:</span>
                </label>
                <input
                  type="time"
                  name="intime"
                  value={intime}
                  onChange={(e) => {
                    setInTime(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="RegisFormcon leavecon">
              <div className="RegisForm_1 leaveform_1 ">
                <label htmlFor="outtime">
                  Out Time <span>:</span>
                </label>
                <input
                  type="time"
                  name="outtime"
                  id="outtime"
                  value={outtime}
                  onChange={(e) => {
                    setOutTime(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="RegisFormcon leavecon">
              <div className="RegisForm_1 leaveform_1">
                <label htmlFor="Status">
                  Status <span>:</span>
                </label>
                <select
                  name="Status"
                  id="Status" 
                  value={Status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value="Active">Active</option>
                  <option value="On leave">On leave</option>
                  <option value="Follow-up Duty">Follow-up Duty</option>
                </select>
              </div>
            </div>

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={handleSubmission}
              >
                Submit
              </button>
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HRmanagement;
