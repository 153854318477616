import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Webcam from "react-webcam";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import Preview from "./Preview";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";

const NewProcedureDoc = () => {
  const workbenchformData = useSelector(
    (state) => state.userRecord?.workbenchformData
  );
  console.log(workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [formValues, setFormValues] = useState({
    Index: null,
    DoctorName: "",
    ProcedureName: "",
    Sessions: "",
    Complementry: "",
    appointmentDate: "",
    PatientID: "",
    VisitID: "",
    createdBy: "",
    Status: "Pending",
    branchlocation: "",
    KitName: "",
    ServiceType: "Procedure",
  });
  const [ProcedureName, setProcedureName] = useState([]);
  const [ProcedureData, setProcedureData] = useState([]);
  const [openModal2, setOpenModal2] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const visitno =formData?.visitNo
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const [type, setType] = useState("Employee");
  const [getprocedure, setgetprocedure] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event) => {
    setType(event.target.value);
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const openModal = (content) => {
    setModalContent(content);
    setOpenModal2(true);
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}doctorsworkbench/get_procedurename_fromratecard?location=${userRecord?.location}`
      )
      .then((response) => {
        setProcedureName(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    setFormValues((prev) => ({
      ...prev,
      DoctorName: workbenchformData?.DoctorName,
      PatientID: workbenchformData?.PatientID,
      createdBy: userRecord?.username,
      VisitID: visitno,
      PatientID: workbenchformData?.PatientID,
      branchlocation: userRecord?.location,
    }));
  }, [userRecord?.location]);

  const handleonchange = (e) => {
    const { name, value } = e.target;
    if (name === "ProcedureName") {
      if (value.split("-")[0].includes("HT")) {
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
          Sessions: 1,
        }));
      } else {
        setFormValues((prev) => ({
          ...prev,
          [name]: value,
          Sessions: 0,
        }));
      }
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const Addprocedure = () => {
    let requiredFields = ["ProcedureName", "Sessions"];
    if (formValues.ProcedureName.split("-")[0]?.includes("HT")) {
      requiredFields = ["ProcedureName", "Sessions", "Complementry"];
    } else if (formValues.ProcedureName.split("-")[0]?.includes("GFC")) {
      requiredFields = ["ProcedureName", "Sessions", "KitName"];
    }

    const existingItem = requiredFields.filter((field) => !formValues[field]);

    if (existingItem.length === 0) {
      const alreadyexist = ProcedureData.find(
        (p) => p.ProcedureName === formValues.ProcedureName
      );
      const lengthval = ProcedureData.length + 1;
      if (!alreadyexist) {
        setProcedureData((prev) => [
          ...prev,
          { ...formValues, Index: lengthval },
        ]);
      } else {
        alert("Procedure Already Exist");
      }
      setFormValues((prev) => ({
        ...prev,
        Index: null,
        ProcedureName: "",
        Sessions: "",
        Complementry: "",
        appointmentDate: "",
        Status: "Pending",
        KitName: "",
      }));
    } else {
      alert(`please fill the required fields : ${existingItem.join(",")}`);
    }
  };
  const Updateprocedure = () => {
    let requiredFields = ["ProcedureName", "Sessions"];
    if (formValues.ProcedureName.split("-")[0].includes("HT")) {
      requiredFields = ["ProcedureName", "Sessions", "Complementry"];
    } else if (formValues.ProcedureName.split("-")[0].includes("GFC")) {
      requiredFields = ["ProcedureName", "Sessions", "KitName"];
    }

    const existingItem = requiredFields.filter((field) => !formValues[field]);

    if (existingItem.length === 0) {
      const updateddate = [...ProcedureData];
      const indx = updateddate.findIndex((p) => p.Index === formValues.Index);

      updateddate[indx] = { ...formValues };
      setProcedureData(updateddate);
      setFormValues((prev) => ({
        ...prev,
        Index: null,
        ProcedureName: "",
        Sessions: "",
        Complementry: "",
        appointmentDate: "",
        Status: "Pending",
        KitName: "",
      }));
    } else {
      alert(`please fill the required fields : ${existingItem.join(",")}`);
    }
  };
  const handleEdit = (client) => {
    setFormValues({ ...client });
  };
  const handleSave = () => {
    const procedure = ProcedureData.flatMap((p) => {
      const additionalRows = [];

      if (+p.Complementry > 0 && p.ProcedureName.split("-")[0].includes("HT")) {
        // Create a new row for complementary procedure
        // alert("hi");
        additionalRows.push({
          ProcedureName: "PRP",
          Therapist_Name: p.DoctorName,
          Sessions: p.Complementry,
          appointmentDate: workbenchformData.AppointmentDate,
          PatientID: workbenchformData.PatientID,
          createdBy: userRecord?.username,
          DoctorName: workbenchformData.DoctorName,
          Status: p.Status,
          VisitID: visitno,
          branchlocation: userRecord?.location,
          Complementry: "Yes",
          KitName: p.KitName,
        });
      }

      // Original row for the current procedure
      return [
        {
          ProcedureName: p.ProcedureName,
          Therapist_Name: p.DoctorName,
          Sessions: p.Sessions,
          appointmentDate: workbenchformData.AppointmentDate,
          PatientID: workbenchformData.PatientID,
          createdBy: userRecord?.username,
          DoctorName: workbenchformData.DoctorName,
          Status: p.Status,
          VisitID: visitno,
          branchlocation: userRecord?.location,
          Complementry: "No",
          KitName: p.KitName,
        },
        ...additionalRows,
      ];
    });

    console.log(procedure, "----");

    axios
      .post(`${urllink}doctorsworkbench/insert_procedure`, procedure)
      .then((response) => {
        console.log(response.data);
        if (response.data?.message) {
          successMsg(response.data?.message);
        } else if (response.data?.warn) {
          userwarn(response.data?.warn);
        }

        setProcedureData([]);
        setgetprocedure((prev) => !prev);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

 

  // -------------ratecard type

  return (
    <>
      <div style={{ display: "grid", placeContent: "center" }}>
        <br />
        <ToggleButtonGroup
          value={type}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            value="Employee"
            style={{
              height: "30px !important",
              padding: "0px 10px !important",
              width: "180px !important",
              backgroundColor:
                type === "Employee"
                  ? "var(--selectbackgroundcolor)"
                  : "inherit",
            }}
            className="togglebutton_container_procedure"
          >
            Add Procedure
          </ToggleButton>

{/* 
          <ToggleButton
            value="Patient"
            style={{
              backgroundColor:
                type === "Patient" ? "var(--selectbackgroundcolor)" : "inherit",
              height: "30px !important",
              width: "180px !important",
            }}
            className="togglebutton_container_procedure"
          >
            Treat Procedure
          </ToggleButton> */}


        </ToggleButtonGroup>
      </div>
      <br />
      {type === "Employee" && (
        <div className="new-patient-registration-form">
          <div className="RegisFormcon" style={{ justifyContent: "center" }}>
            <div className="RegisForm_1">
              <label htmlFor="ProcedureName">
                Treatment Procedure<span>:</span>
              </label>
              <select
                id="ProcedureName"
                name="ProcedureName"
                value={formValues.ProcedureName}
                onChange={handleonchange}
              >
                <option value="">Select </option>
                {Array.isArray(ProcedureName) ? (
                  ProcedureName.map((procedure, index) => (
                    <option key={index} value={procedure}>
                      {procedure}
                    </option>
                  ))
                ) : (
                  <option disabled>No procedure available</option>
                )}
              </select>
            </div>

            <div className="RegisForm_1">
              <label htmlFor="Sessions">
                Number of Sessions<span>:</span>
              </label>
              <input
                type="number"
                id="Sessions"
                name="Sessions"
                readOnly={formValues.ProcedureName.split("-")[0]?.includes(
                  "HT"
                )}
                value={formValues.Sessions}
                onChange={handleonchange}
              />
            </div>
            {formValues.ProcedureName.split("-")[0]?.includes("HT") && (
              <div className="RegisForm_1">
                <label htmlFor="ConsultancyDiscount">
                  Complementary PRP<span>:</span>
                </label>
                <input
                  type="number"
                  id="ConsultancyDiscount"
                  name="Complementry"
                  value={formValues.Complementry}
                  onChange={handleonchange}
                />
              </div>
            )}
            {formValues.ProcedureName.split("-")[0]?.includes("GFC") && (
              <div className="RegisForm_1">
                <label htmlFor="ConsultancyDiscount">
                  GFC Kit Name<span>:</span>
                </label>
                <input
                  type="text"
                  id="KitName"
                  name="KitName"
                  value={formValues.KitName}
                  onChange={handleonchange}
                />
              </div>
            )}
          </div>
          <br />

          <div className="Register_btn_con">
            <button
              className="RegisterForm_1_btns"
              onClick={
                formValues.Index !== null ? Updateprocedure : Addprocedure
              }
            >
              {formValues.Index !== null ? "Update" : "Add"}
            </button>
            {ProcedureData.length === 0 && (
              <button
                onClick={() => openModal("calendar")}
                className="RegisterForm_1_btns"
              >
                preview
              </button>
            )}
          </div>
          {ProcedureData.length > 0 && (
            <div className="Selected-table-container">
              <table className="selected-medicine-table2">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th>Service Type</th>
                    {/* <th>Therapist Name</th> */}
                    <th>Procedure Name</th>
                    <th>Sessions</th>
                    <th>Complementry</th>
                    <th>Kit Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ProcedureData.map((client, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{client.ServiceType}</td>
                      {/* <td>{client.DoctorName}</td> */}
                      <td>{client.ProcedureName}</td>
                      <td>{client.Sessions}</td>
                      <td>{client.Complementry || "-"}</td>
                      <td>{client.KitName || "-"}</td>
                      <td>
                        <button
                          className="delnamebtn"
                          onClick={() => handleEdit(client)}
                        >
                          <EditNoteIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {ProcedureData.length > 0 && (
            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSave}>
                Save
              </button>
              <button
                onClick={() => openModal("calendar")}
                className="RegisterForm_1_btns"
              >
                preview
              </button> 
            </div>
          )}
          {openModal2 && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setOpenModal2(false);
              }}
            >
              <div
                className="newwProfiles newwPopupforreason"
                onClick={(e) => e.stopPropagation()}
              >
                <Preview modalContent={modalContent} />
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => {
                      setOpenModal2(false);
                    }}
                  >
                    <HighlightOffIcon />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default NewProcedureDoc;
