import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { saveAs } from "file-saver";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const ProfitandLoss = () => {
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");

  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [SearchformData, setSearchformData] = useState({
    Location: "",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });

  const [Location, setLocation] = useState([]);

  const [Totaldata, setTotaldata] = useState({
    Expensetotal: "",
    Incometotal: "",
  });

  const [Expensearray, setExpensearray] = useState([]);

  const [Incomearray, setIncomearray] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      if (value === "Current") {
        setSearchformData({
          ...SearchformData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      } else {
        setSearchformData({
          ...SearchformData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else {
      setSearchformData({
        ...SearchformData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setSearchformData((prev) => ({
      ...prev,
      date: formattedDate,
    }));
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data.map((p) => p.location_name);

        setLocation([...data, "CentralStore"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handletoSearch = () => {
    const params = {
      Location: SearchformData.Location,
      DateType: SearchformData.DateType,
      CurrentDate: SearchformData.CurrentDate,
      FromDate: SearchformData.FromDate,
      ToDate: SearchformData.ToDate,
    };

    let requiredFields = [];
    if (SearchformData.DateType === "Customize") {
      requiredFields = ["Location", "DateType", "FromDate", "ToDate"];
    } else {
      requiredFields = ["Location", "DateType"];
    }
    const existingItem = requiredFields.filter(
      (field) => !SearchformData[field]
    );
    if (existingItem.length > 0) {
      alert(`Please Fill the RequiredFields : ${existingItem.join(",")} `);
    } else {
      axios
        .get(
          `${urllink}FinanceMagement/get_Ledger_Entry_details_Profitandloos`,
          { params }
        )
        .then((response) => {
          const Expensesdata = response.data.Expensesdata;

          const Incomedata = response.data.Incomedata;

          const Expensetotal = response.data.Expensetotal;

          const Incometotal = response.data.Incometotal;

          setExpensearray(Expensesdata);
          setIncomearray(Incomedata);

          setTotaldata((prev) => ({
            ...prev,
            Expensetotal: Expensetotal,
            Incometotal: Incometotal,
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleExportToExcel = () => {
    if (Expensearray.length !== 0) {
      const columns = [
        { dataKey: "id", header: "S.NO" },
        { dataKey: "Location", header: "Branch Name" },
        { dataKey: "GRN_InvoiceNo", header: "GRN_InvoiceNo" },
        { dataKey: "GRN_Date", header: "GRN Date" },
        { dataKey: "Supplier_Bill_No", header: "Supplier Bill No" },
        { dataKey: "Supplier_Bill_Date", header: "Supplier Bill Date" },
        { dataKey: "Supplier_Code", header: "Supplier Code" },
        { dataKey: "Supplier_Name", header: "Supplier Name" },
        { dataKey: "SupplierGST", header: "Supplier GST No" },
        { dataKey: "TotalAmountWithoutTax", header: "TaxableValue" },
        { dataKey: "SGST 9.0", header: "CGST 9" },
        { dataKey: "CGST 9.0", header: "SGST 9" },
        { dataKey: "IGST 18.0", header: "IGST 18" },
        { dataKey: "SGST 6.0", header: "CGST 6" },
        { dataKey: "CGST 6.0", header: "SGST 6" },
        { dataKey: "IGST 12.0", header: "IGST 12" },
        { dataKey: "SGST 2.5", header: "CGST 2.5" },
        { dataKey: "CGST 2.5", header: "SGST 2.5" },
        { dataKey: "IGST 5.0", header: "IGST 5" },
        { dataKey: "TotalTaxAmount", header: "Total Tax Amount" },
      ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...Expensearray.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, "PurchaseRegisterReport.csv");
    } else {
      alert("No Data to Save");
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Profit & Loss</h4>
      </div>

      <br />
      <div className="con_1">
        <div className="inp_1">
          <label htmlFor="">
            Location <span>:</span>
          </label>
          <select
            name="Location"
            value={SearchformData.Location}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {Location.map((p) => (
              <option key={p} value={p}>
                {p.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        <div className="inp_1">
          <label htmlFor="">
            Date Type <span>:</span>
          </label>
          <select
            name="DateType"
            value={SearchformData.DateType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Current">Current Date</option>
            <option value="Customize">Customize</option>
          </select>
        </div>
      </div>
      {SearchformData.DateType === "Current" && (
        <div className="con_1">
          <div className="inp_1">
            <label htmlFor="">
              Current Date <span>:</span>
            </label>
            <input
              type="date"
              name="CurrentDate"
              value={SearchformData.CurrentDate}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      {SearchformData.DateType === "Customize" && (
        <div className="con_1">
          <div className="inp_1">
            <label htmlFor="">
              From Date <span>:</span>
            </label>
            <input
              type="date"
              name="FromDate"
              value={SearchformData.FromDate}
              onChange={handleChange}
            />
          </div>
          <div className="inp_1">
            <label htmlFor="">
              To Date <span>:</span>
            </label>
            <input
              type="date"
              name="ToDate"
              value={SearchformData.ToDate}
              onChange={handleChange}
            />
          </div>
        </div>
      )}

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handletoSearch}>
          Search
        </button>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>Particulars</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {Expensearray.map((row, index) => (
              <tr key={index}>
                <td>
                  {row.Ledger_Name}
                  <br />
                  {row.Group_Name}
                </td>
                <td>{row.Current_Balance}</td>
              </tr>
            ))}

            <tr>
              <td>Total</td>
              <td>{Totaldata.Expensetotal}</td>
            </tr>
          </tbody>
        </table>
        <ToastContainer />
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>Particulars</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {Incomearray.map((row, index) => (
              <tr key={index}>
                <td>
                  {row.Ledger_Name}
                  <br />
                  {row.Group_Name}
                </td>
                <td>{row.Current_Balance}</td>
              </tr>
            ))}
            <tr>
              <td>Total</td>
              <td>{Totaldata.Incometotal}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfitandLoss;
