import React, { useEffect, useState, useRef } from "react";
import "./Preview.css";
import axios from "axios";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import DotPic2 from '../assets/DotPic2.png';


const PrintContent = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} id="reactprintcontent">
      {props.children}
    </div>
  );
});

const Preview = () => {
  const [pdfBlob, setPdfBlob] = useState(null);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const [doctorsign, har] = useState(null);
  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [selectedDate, setSelectedDate] = useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  const [PreviewData, setPreviewData] = useState({});
  const [ClinicDetails, setClinicDetails] = useState({});
  const [appointmentDate, setAppointmentDate] = useState("");
  const [selectedVital, setSelectedVital] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  // const [sumavital, setsumavital] = useState(false)

  const [clinicLogo, setClinicLogo] = useState(null);
  const [LabData, setLabData] = useState([]);

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=${userRecord?.location}`)

      .then((response) => {
        const record = response.data[0];
        if (record) {
          setClinicDetails(record);
        } else {
          console.error("No clinic details found");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;
          setClinicLogo(`data:image/png;base64,${firstClinic.clinicLogo}`);
        } else {
          console.error("No record found");
        }
      })
      .catch((error) => console.error("Error fetching data"));
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate(currentDate);
    setSelectedDate(new Date(), "Asia/Kolkata");
  }, []);

  const currdate = selectedDate && format(selectedDate, " dd / MM / yy");

  useEffect(() => {
    const storedFormData = formData;
    if (storedFormData) {
      setPreviewData(storedFormData);
    }
  }, []);
  // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (formData && formData.PatientID && formData.visitNo) {
      axios
        .get(
          `${urllink}doctorsworkbench/prescription_forpreview?PatientID=${formData.PatientID}&visitID=${formData.visitNo}`
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setPrescriptionData(response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching prescription data:", error);
        });

      axios
        .get(
          `${urllink}doctorsworkbench/get_for_labpreview?PatientID=${formData.PatientID}&visitID=${formData.visitNo}`
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setLabData(response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching prescription data:", error);
        });
    }
  }, [formData.PatientID, formData, appointmentDate, formData.visitNo]);

  useEffect(() => {
    if (formData.PatientID) {
      axios
        .get(
          `${urllink}doctorsworkbench/get_for_preview?PatientID=${formData?.PatientID}`
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const data = response.data;
            console.log(data);
            setSelectedVital(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formData.PatientID]);

  useEffect(() => {
    const doctorname = formData?.DoctorName;

    if (doctorname) {
      axios
        .get(`${urllink}usercontrol/get_doctor_sign?doctorname=${doctorname}`)
        .then((response) => {
          const data = response.data;

          har(data.doctor_sign);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formData]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {},
    onAfterPrint: async () => {
      setPdfBlob(null);
    },
    // Remove the duplicate onAfterPrint and define only one
    onAfterPrint: async () => {
      setPdfBlob(null);

      const printdata = document.getElementById("reactprintcontent");

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20; // Adjust the padding as needed
          const pdfWidth = contentWidth + 2 * padding; // Add padding to width
          const pdfHeight = contentWidth * 1.5; // Add padding to height
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });
          pdf.html(printdata, {
            x: padding, // Set x-coordinate for content
            y: padding, // Set y-coordinate for content
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              setPdfBlob(generatedPdfBlob);
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  const Submitalldata = () => {
    setIsPrintButtonVisible(false);
    setTimeout(() => {
      handlePrint();
      setIsPrintButtonVisible(true); // Resetting print button visibility
    }, 500); // Adjust delay as needed
  };

  return (
    <>
      {isPrintButtonVisible ? (
        <div className="Preview_invoice">
          <br />
          {/* <div className="New_billlling_invoice_head">
            <div className="new_billing_logo_con">
              <img src={clinicLogo} alt="Medical logo" />
            </div>
           
          </div>
          <br /> */}

          <div
            className="Register_btn_con"
            style={{ color: "var(--labelcolor)", fontWeight: 600 }}
          >
            Patient Preview
          </div>

          <div className="new_billing_address">
            <div className="new_billing_address_2">
              <div className="new_preview_div">
                <label>
                  Patient Name <span>:</span>
                </label>
                <span className="dkjfiuw6">
                  {selectedVital[0]?.Title} {selectedVital[0]?.FirstName}{" "}
                  {selectedVital[0]?.LastName}
                </span>
              </div>
              <div className="new_preview_div">
                <label>
                  Patient ID <span>:</span>
                </label>
                <span className="dkjfiuw6">{selectedVital[0]?.PatientID}</span>
              </div>
              <div className="new_preview_div">
                <label>
                  Age <span>:</span>
                </label>
                <span className="dkjfiuw6">{selectedVital[0]?.Age}</span>
              </div>
              <div className="new_preview_div">
                <label>
                  Gender <span>:</span>
                </label>
                <span className="dkjfiuw6">{selectedVital[0]?.Gender}</span>
              </div>
            </div>
            <div className="new_billing_address_2">
              <div className="new_preview_div">
                <label>
                  Date <span>:</span>
                </label>
                <span className="dkjfiuw6">{currdate}</span>
              </div>
              <div className="new_preview_div">
                <label>
                  Address <span>:</span>
                </label>
                <span className="dkjfiuw6">
                  {selectedVital[0]?.City +
                    "," +
                    selectedVital[0]?.State +
                    "-" +
                    selectedVital[0]?.Pincode}
                </span>
              </div>

              <div className="new_preview_div">
                <label>
                  {" "}
                  Patient Mobile No <span>:</span>
                </label>
                <span className="dkjfiuw6">
                  {selectedVital[0]?.PhoneNumber}
                </span>
              </div>

              {selectedVital.map((item, index) => (
                <div className="new_preview_div" key={index}>
                  {item.Next_Appointment && (
                    <>
                      <label>
                        Next Appointment <span>:</span>
                      </label>
                      <span className="dkjfiuw6">{item?.Next_Appointment}</span>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
       
          <br />
            <div
              className="invoice_detials_total_1 neww_invoicedetials"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div className="total_con_bill">
                {selectedVital.map((item, index) => (
                  <div className="bill_body bill_body3200" key={index}>
                    {item.Diagnosis && (
                      <>
                        <label>
                          Diagnosis <span>:</span>
                        </label>
                        <span className="dkjfiuw6">{item.Diagnosis}</span>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <br />
          <div
            className="Register_btn_con"
            style={{ color: "var(--labelcolor)", fontWeight: 600 }}
          >
            Prescription
          </div>
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>Generic Name</th>
                  <th>Item Name</th>

                  <th>Dose</th>
                  <th>Notes</th>
                  <th>Frequency</th>
                  <th>Duration</th>
                  <th>Qty</th>
                  <th>Instruction</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionData.map((medicine, index) => {
                  return (
                    <tr key={index}>
                      <td>{medicine.GenericName}</td>
                      <td>{medicine.ItemName}</td>

                      <td>{medicine.Dose}</td>
                      <td>{medicine.notes}</td>
                      <td>{medicine.Frequency}</td>
                      <td>{medicine.Duration}</td>
                      <td>{medicine.Qty}</td>
                      <td>{medicine.Instruction}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

         
         
        

          <br />

          {isPrintButtonVisible && (
            <button className="print_button" onClick={Submitalldata}>
              Print Data
            </button>
          )}
        </div>
      ) : (
        <PrintContent
          ref={componentRef}
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
    
          }}
        > <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `url(${DotPic2})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          backgroundSize: "60% 70%",
          marginTop:'50px',
          zIndex: -2,
          pointerEvents: "none", // Ensure background doesn't interfere with content
          opacity:'0.1'
        }}
      />
          <div className="edxwedwe3_p klidksdj_8io" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 2 }}>
            <br />
           
            <div>
              <div className="New_billlling_invoice_head">
                <div className="new_billing_logo_con ljkkhku7780">
                  <img src={clinicLogo} alt="Medical logo" />
                </div>
              </div>
              <br />

              <div
                className="Register_btn_con"
                style={{ color: "var(--labelcolor)", fontWeight: 600,fontSize:'14px' }}
              >
                Patient Preview
              </div>
              <div className="new_billing_address">
                <div className="new_billing_address_2">
                  <div className="new_preview_div">
                    <label>
                      Patient Name <span>:</span>
                    </label>
                    <span className="dkjfiuw6">
                      {selectedVital[0]?.Title} {selectedVital[0]?.FirstName}{" "}
                      {selectedVital[0]?.LastName}
                    </span>
                  </div>
                  <div className="new_preview_div">
                    <label>
                      Patient ID <span>:</span>
                    </label>
                    <span className="dkjfiuw6">
                      {selectedVital[0]?.PatientID}
                    </span>
                  </div>
                  <div className="new_preview_div">
                    <label>
                      Age <span>:</span>
                    </label>
                    <span className="dkjfiuw6">{selectedVital[0]?.Age}</span>
                  </div>
                  <div className="new_preview_div">
                    <label>
                      Gender <span>:</span>
                    </label>
                    <span className="dkjfiuw6">{selectedVital[0]?.Gender}</span>
                  </div>
                </div>
                <div className="new_billing_address_2">
                  <div className="new_preview_div">
                    <label>
                      Date <span>:</span>
                    </label>
                    <span className="dkjfiuw6">{currdate}</span>
                  </div>
                  <div className="new_preview_div">
                    <label>
                      Address <span>:</span>
                    </label>
                    <span className="dkjfiuw6">
                      {selectedVital[0]?.City +
                        "," +
                        selectedVital[0]?.State +
                        "-" +
                        selectedVital[0]?.Pincode}
                    </span>
                  </div>

                  <div className="new_preview_div">
                    <label>
                      {" "}
                      Patient Mobile No <span>:</span>
                    </label>
                    <span className="dkjfiuw6">
                      {selectedVital[0]?.PhoneNumber}
                    </span>
                  </div>

                  {selectedVital.map((item, index) => (
                    <div className="new_preview_div" key={index}>
                      {item.Next_Appointment && (
                        <>
                          <label>
                            Next Appointment <span>:</span>
                          </label>
                          <span className="dkjfiuw6">
                            {item?.Next_Appointment}
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <br />
              <div>
                </div> 
                <div
                  className="invoice_detials_total_1 neww_invoicedetials"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div className="total_con_bill">
                    {selectedVital.map((item, index) => (
                      <div className="bill_body bill_body3200" key={index}>
                        {item.Diagnosis && (
                          <>
                            <label>
                              Diagnosis <span>:</span>
                            </label>
                            <span className="dkjfiuw6">{item.Diagnosis}</span>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              

              <br />

              {/* body */}
              <div
                className="Register_btn_con"
                style={{ color: "var(--labelcolor)", fontWeight: 600,fontSize:'14px'  }}
              >
                Prescription
              </div>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>Generic Name</th>
                      <th>Item Name</th>

                      <th>Dose</th>
                      <th>Notes</th>
                      <th>Frequency</th>
                      <th>Duration</th>
                      <th>Qty</th>
                      <th>Instruction</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prescriptionData.map((medicine, index) => {
                      return (
                        <tr key={index}>
                          <td>{medicine.GenericName}</td>
                          <td>{medicine.ItemName}</td>

                          <td>{medicine.Dose}</td>
                          <td>{medicine.notes}</td>
                          <td>{medicine.Frequency}</td>
                          <td>{medicine.Duration}</td>
                          <td>{medicine.Qty}</td>
                          <td>{medicine.Instruction}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

                             <br />
            
            </div>

            <div className="preview_footer0">
              {/* footer */}
             
              <div className="djhweyd67u79 ewdwedcxe">
                <p>
                  {" "}
                  *At the heart of GENESIS, we offer services based on trust and
                  honesty. We have integrated the best and up-to-date aesthetic
                  courses and treatment to make sure that you receive the best
                  results and experience. We always tailor our treatment to our
                  client's individual goals*{" "}
                </p>
              </div>
<br />
              <div className="uygftrhy_p3_preview">
                <span className="">{ClinicDetails.ClinicName}</span>

                <span className="kughyftt4543_preview">
                  {ClinicDetails.doorNo +
                    "," +
                    ClinicDetails.street +
                    ClinicDetails.area +
                    "," +
                    ClinicDetails.city +
                    "," +
                    ClinicDetails.state +
                    "-" +
                    ClinicDetails.pincode}
                </span>

                <span className="">
                  Contact Us : {ClinicDetails.phoneNo + " , "}{" "}
                  {ClinicDetails.email}
                </span>
              </div>
            </div>
          </div>
        </PrintContent>
      )}
    </>
  );
};

export default Preview;
