import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";

function PaySlip() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const employeedata = useSelector((state) => state.userRecord?.employeedata);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [pdfBlob, setPdfBlob] = useState(null);

  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinic_Logo] = useState(null);
  const [location, setlocation] = useState("");
  const [date, setdate] = useState("");

  const [formData, setFormData] = useState({
    employeeName: "",
    employeeId: "",
    department: "",
    numberOfPresents: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {},
    onAfterPrint: async () => {
      setPdfBlob(null);

      const printdata = document.getElementById("reactprintcontent");

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20; // Adjust the padding as needed
          const pdfWidth = contentWidth + 2 * padding; // Add padding to width
          const pdfHeight = contentWidth * 1.5; // Add padding to height
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });
          pdf.html(printdata, {
            x: padding, // Set x-coordinate for content
            y: padding, // Set y-coordinate for content
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              setPdfBlob(generatedPdfBlob);

              const formData = new FormData();
              formData.append(
                "pdf",
                new Blob([pdfBlob], { type: "application/pdf" }),
                "paySlip.pdf"
              );
              formData.append("employeeid", employeedata[0]?.EmployeeID);
              formData.append("employeename", employeedata[0]?.EmployeeName);
              formData.append("location", userRecord?.location);
              formData.append("createdby", userRecord?.username);
              formData.append("generatedPdfBlob", generatedPdfBlob);

              axios
                .post(
                  `${urllink}HRmanagement/insert_EmployeePaySlips`,
                  formData
                )
                .then((response) => {})
                .catch((error) => {
                  console.error(error);
                });
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  useEffect(() => {
    const location = userRecord?.location;
    axios
      .get(
        `${urllink}HRmanagement/getAccountsetting_payslip?location=${location}`
      )
      .then((response) => {
        if (response.data) {
          const data = response.data;
          setClinicName(data.Clinic_Name);
          setClinic_Logo(`data:image/png;base64,${data.Clinic_Logo}`);
          setlocation(data.location);
          setdate(data.date);
        } else {
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);

  return (
    <>
      <div className="leaveform">
        <div className="user_patienthead print-button3">
          <h4>Pay Slip</h4>
        </div>

        <body id="reactprintcontent">
          <div className="paymt-fr-mnth-slp">
            <div className="logo-pay-slp">
              <img src={clinicLogo} alt="" />
            </div>
            <div>
              <h2>
                {clinicName} ({location})
              </h2>
            </div>
          </div>
          <div className="paymt-fr-mnth-slp">
            <h3>Pay Slip for the month of ({date})</h3>
          </div>

          <table className="jon-flx-the-twoi">
            <tbody>
              <tr>
                <td>
                  <label htmlFor="employeeName">Employee Name:</label>
                </td>
                <td>{(employeedata && employeedata[0]?.EmployeeName) || ""}</td>
                <td>
                  <label htmlFor="pfNumber">PF Number:</label>
                </td>
                <td>{(employeedata && employeedata[4]?.EPFNumber) || ""}</td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="employeeId">Employee ID:</label>
                </td>
                <td>{(employeedata && employeedata[0]?.EmployeeID) || ""}</td>
                <td>
                  <label htmlFor="panNumber">PAN Number:</label>
                </td>
                <td>{(employeedata && employeedata[3]?.PanNumber) || ""}</td>
              </tr>

              <tr>
                <td>
                  <label htmlFor="department">Department:</label>
                </td>
                <td>{(employeedata && employeedata[1]?.Department) || ""}</td>
                <td>
                  <label htmlFor="designation">Designation:</label>
                </td>
                <td>{(employeedata && employeedata[1]?.Designation) || ""}</td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="bankName">Bank Name:</label>
                </td>
                <td>{(employeedata && employeedata[3]?.BankName) || ""}</td>
                <td>
                  <label htmlFor="bankAccountNumber">Bank Account No:</label>
                </td>
                <td>
                  {(employeedata && employeedata[3]?.AccountNumber) || ""}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="numberOfPresents">Total Working Days:</label>
                </td>
                <td>
                  {(employeedata && employeedata[2]?.TotalWorkingDays) || ""}
                </td>
                <td>
                  <label htmlFor="dateOfJoining">Date of Joining:</label>
                </td>
                <td>
                  {(employeedata && employeedata[1]?.DateofJoining) || ""}
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="numberOfPresents">No of Days absent:</label>
                </td>
                <td>{(employeedata && employeedata[2]?.AbsentDays) || ""}</td>
                <td>
                  <label htmlFor="numberOfPresents">No of Days Present:</label>
                </td>
                <td>{(employeedata && employeedata[2]?.PresentDays) || ""}</td>
              </tr>
            </tbody>
          </table>

          <table className="responsive-table909">
            <thead>
              <tr>
                <th>Earnings</th>
                <th>Amount</th>
                <th>Deduction</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Basic Salary</td>
                <td>
                  {(employeedata && employeedata[2]?.Basic_Salary) || "0"}
                </td>
                <td>PF</td>
                <td>
                  {(employeedata && employeedata[2]?.PF_for_Employee_Amount) ||
                    "0"}
                </td>
              </tr>
              <tr>
                <td>HRA</td>
                <td>
                  {(employeedata && employeedata[2]?.HRA_Allowance_Amount) ||
                    "0"}
                </td>
                <td>Esi Amount</td>
                <td>{(employeedata && employeedata[2]?.Esi_Amount) || "0"}</td>
              </tr>
              <tr>
                <td>Medical</td>
                <td>
                  {(employeedata &&
                    employeedata[2]?.Medical_Allowance_Amount) ||
                    "0"}
                </td>
                <td>Professional Tax</td>
                <td>
                  {(employeedata && employeedata[2]?.professional_tax) || "0"}
                </td>
              </tr>
              <tr>
                <td>Travel</td>
                <td>
                  {(employeedata && employeedata[2]?.Travel_Allowance_Amount) ||
                    "0"}
                </td>
                <td>Loss of Pay</td>
                <td>{(employeedata && employeedata[2]?.lossofpay) || "0"}</td>
              </tr>
              <tr>
                <td>Total Earnings</td>
                <td>
                  {(employeedata && employeedata[2]?.TotalEarnings) || "0"}
                </td>
                <td>Total Deductions</td>
                <td>
                  {(employeedata && employeedata[2]?.totaldeduction) || "0"}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="signature-section909">
            <div className="signature909">
              <p>Net Salary :</p>

              <p>{(employeedata && employeedata[2]?.Net_Salary) || "0"}</p>
            </div>
          </div>
          <div className="signature-section909">
            <p className="disclaimer23">
              This page is created automatically without a signature.
            </p>
          </div>
        </body>
        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns print-button3"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
      </div>
    </>
  );
}

export default PaySlip;
