import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addDays, format } from "date-fns";

function AccountsMaster() {
 
 
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const Editdata = useSelector((state) => state.FinanceStore?.LedgerEditdata);

  const navigate = useNavigate();

  const cu_date = format(new Date(), "yyyy-MM-dd");


  const [GEtledalldata, setGEtledalldata] = useState([]);

  const [LedgerFormState, setLedgerFormState] = useState({
    ID: "",
    Name: "",
    AccountType: "",
    opbal:0,
    BankDetailes: "No",
    phoneNumber: "",
    PAN_OR_IT_NO: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    panNumber: "",
  });

  useEffect(() => {
    if (Object.keys(Editdata).length !== 0) {
      setLedgerFormState((prev) => ({
        ...prev,
        ID: Editdata.ID,
        Name: Editdata.Name,
        AccountType: Editdata.AccountType,
        opbal:Editdata.opbal,
        BankDetailes: Editdata.BankDetailes,
        phoneNumber: Editdata.phoneNumber,
        PAN_OR_IT_NO: Editdata.PAN_OR_IT_NO,
        accountName: Editdata.accountName,
        accountNumber: Editdata.accountNumber,
        bankName: Editdata.bankName,
        branch: Editdata.branch,
        ifscCode: Editdata.ifscCode,
        panNumber: Editdata.panNumber,
      }));
    }
  }, [Editdata]);

    const getId =()=>{
      if (Object.keys(Editdata).length === 0) {
      axios
      .get(`${urllink}FinanceMagement/get_AccountID`)
      .then((response) => {
        const data = response.data.ID;

       setLedgerFormState((prev)=>({
        ...prev,
        ID:data,
       }))
      })
      .catch((error) => {
        console.error("Error fetching patients data:", error);
      });
    }
    }

  
  useEffect(() => {
    getId()
  }, []);




  useEffect(() => {
    axios
      .get(`${urllink}FinanceMagement/get_Ledger_Entry_details`)
      .then((response) => {
        const data = response.data.LedgerEntries;

        setGEtledalldata(data)
      })
      .catch((error) => {
        console.error("Error fetching patients data:", error);
      });
  }, []);


  const cleardatafun = () => {
    setLedgerFormState({
    ID: "",
    Name: "",
    AccountType: "",
    opbal: 0,
    BankDetailes: "No",
    phoneNumber: "",
    PAN_OR_IT_NO: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    panNumber: "",
    });
  };

  const Update_ledgerdata = () => {
    const requiredFields = ["ID", "Name", "AccountType"];
    const isBankDetailsRequired = LedgerFormState.BankDetailes === "Yes";

    if (isBankDetailsRequired) {
      requiredFields.push(
        "accountName",
        "accountNumber",
        "bankName",
        "branch",
        "ifscCode",
        "panNumber"
      );
    }

    const missingFields = requiredFields.filter(
      (field) => !LedgerFormState[field]
    );

   
    if (missingFields.length === 0) {
      const CheckName = GEtledalldata.filter(
        (ele) => ele.ID !== Editdata.ID
      ).find(
        (ele) =>
          ele.Name.trim() === LedgerFormState.Name.trim()
      );

      if (CheckName) {
        alert("Name Already Exists");
      } else {
        const newData = {
          ...LedgerFormState,
          cu_date: cu_date,
          CreatedBy: userRecord.username,
          Location: userRecord.location,
        };

        axios
          .post(
            `${urllink}FinanceMagement/update_Ledger_Entry_details`,
            newData
          )
          .then((response) => {
            alert(response.data.Message);
            cleardatafun();
            navigate("/Home/AccountsMasterList");
          })
          .catch((error) => {
            console.error(error);
            alert("Failed to add data. Please try again.");
          });
      }
    } else {
      alert(`Missing fields: ${missingFields.join(", ")}`);
    }
  };

  const Add_LedgerEntry = () => {

    const requiredFields = ["ID", "Name", "AccountType"];
    const isBankDetailsRequired = LedgerFormState.BankDetailes === "Yes";

    if (isBankDetailsRequired) {
      requiredFields.push(
        "accountName",
        "accountNumber",
        "bankName",
        "branch",
        "ifscCode",
        "panNumber"
      );
    }

    const missingFields = requiredFields.filter(
      (field) => !LedgerFormState[field]
    );

    if (missingFields.length === 0) {
      const CheckName = GEtledalldata.some(
        (ele) =>
          ele.AccountID ===
            LedgerFormState.ID ||
          ele.Name.trim() === LedgerFormState.Name.trim()
      );

      if (CheckName) {
        alert("Name Already Exists");
      } else {
        const newData = {
          ...LedgerFormState,
          cu_date: cu_date,
          CreatedBy: userRecord.username,
          Location: userRecord.location,
        };

        axios
          .post(
            `${urllink}FinanceMagement/insert_Ledger_Entry_details`,
            newData
          )
          .then((response) => {
            alert(response.data.Message);
            cleardatafun();
            getId()
            navigate("/Home/AccountsMasterList");


          })
          .catch((error) => {
            console.error(error);
            alert("Failed to add data. Please try again.");
          });
      }
    } else {
      alert(`Missing fields: ${missingFields.join(", ")}`);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Accounts Master</h4>
      </div>
      <br />

      
      <div className="RegisFormcon">

      <div className="RegisForm_1">
          <label htmlFor="LedgerName">
          ID<span>:</span>
          </label>
          <input
            type="number"
            name="ID"
            required
            readOnly
            value={LedgerFormState.ID}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                ID: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="LedgerName">
            Name<span>:</span>
          </label>
          <input
            type="text"
            name="Name"
            required
            value={LedgerFormState.Name}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                Name: e.target.value.toUpperCase(),
              })
            }
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Account Type<span>:</span>
          </label>
          <select
            name="AccountType"
            required
            disabled={Object.keys(Editdata)!==0}
            value={LedgerFormState.AccountType}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, AccountType: e.target.value })
            }
          >
            <option value=""> Select </option>
            <option value="Assets">Assets</option>
            <option value="Expenses">Expenses</option>
            <option value="Income">Income</option>
            <option value="Liabilities">Liabilities</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="opbal">
            Op.Bal <span>:</span>
          </label>
          <input
            type="number"
            name="opbal"
            required
            // disabled={Object.keys(Editdata)!==0}
            value={LedgerFormState.opbal}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, opbal: e.target.value })
            }
          />
        </div>
        
        <div className="RegisForm_1">
          <label htmlFor="BankDetailes">
            Bank Account<span>:</span>
          </label>
          <select
            name="BankDetailes"
            className="select_with_dwn"
            required
            value={LedgerFormState.BankDetailes}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                BankDetailes: e.target.value,
              })
            }
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="phoneNumber">
            Phone Number<span>:</span>
          </label>
          <input
            type="text"
            name="phoneNumber"
            required
            value={LedgerFormState.phoneNumber}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                phoneNumber: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="PAN_OR_IT_NO">
            PAN/IT NO<span>:</span>
          </label>
          <input
            type="text"
            name="PAN_OR_IT_NO"
            required
            value={LedgerFormState.PAN_OR_IT_NO}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                PAN_OR_IT_NO: e.target.value,
              })
            }
          />
        </div>


        {LedgerFormState.BankDetailes === "Yes" ? (
        <>
            <div className="RegisForm_1">
              <label htmlFor="accountName">
                Bank Holder Name <span>:</span>
              </label>
              <input
                type="text"
                name="accountName"
                required
                value={LedgerFormState.accountName}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    accountName: e.target.value,
                  })
                }
              />
            </div>
            </>):(<></>)}
      </div>
      <br />
      {LedgerFormState.BankDetailes === "Yes" ? (
        <>
          <div className="RegisFormcon">
          <div className="RegisForm_1">
              <label htmlFor="bankName">
                Bank Name <span>:</span>
              </label>
              <input
                type="text"
                name="bankName"
                required
                value={LedgerFormState.bankName}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    bankName: e.target.value,
                  })
                }
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="accountNumber">
                Account Number <span>:</span>
              </label>
              <input
                type="text"
                name="accountNumber"
                required
                value={LedgerFormState.accountNumber}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    accountNumber: e.target.value,
                  })
                }
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="branch">
                Branch <span>:</span>
              </label>
              <input
                type="text"
                name="branch"
                required
                value={LedgerFormState.branch}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    branch: e.target.value,
                  })
                }
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="ifscCode">
                IFSC Code <span>:</span>
              </label>
              <input
                type="text"
                name="ifscCode"
                required
                value={LedgerFormState.ifscCode}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    ifscCode: e.target.value,
                  })
                }
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="panNumber">
                Pan Number <span>:</span>
              </label>
              <input
                type="text"
                name="panNumber"
                required
                value={LedgerFormState.panNumber}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    panNumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="Register_btn_con">
        {Object.keys(Editdata).length !== 0 ? (
          <button
            className="RegisterForm_1_btns"
            type="button"
            onClick={Update_ledgerdata}
          >
            Update
          </button>
        ) : (
          <button
            className="RegisterForm_1_btns"
            type="button"
            onClick={Add_LedgerEntry}
          >
            Save
          </button>
        )}
      </div>

    </div>
  );
}

export default AccountsMaster;
