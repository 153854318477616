import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addDays, format } from "date-fns";

function Accounts() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const Editdata = useSelector((state) => state.FinanceStore?.LedgerEditdata);

  const navigate = useNavigate();

  const cu_date = format(new Date(), "yyyy-MM-dd");

  const [ledgerOpeningdata, setledgerOpeningdata] = useState([]);

  const [filteredRows, setfilteredRows] = useState([]);

  const [GEtledalldata, setGEtledalldata] = useState([]);

  const [LedgerFormState, setLedgerFormState] = useState({
    LedgerName: "",
    alias: "",
    group: "",
    Under_Group: "",
    NatureOfGroup: "",
    opbal: "",
    drCr: "",
    BankDetailes: "No",
    address: "",
    country: "",
    state: "",
    Pincode: "",
    phoneNumber: "",
    PAN_OR_IT_NO: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    panNumber: "",
  });

  useEffect(() => {
    if (Object.keys(Editdata).length !== 0) {
      setLedgerFormState((prev) => ({
        ...prev,
        LedgerName: Editdata.Ledger_Name,
        alias: Editdata.Alias_Name,
        group: Editdata.Group_Name,
        NatureOfGroup: Editdata.NatureOfGroup,
        opbal: Editdata.Opening_Balance,
        drCr: Editdata.Depit_or_Credit,
        BankDetailes: Editdata.BankDetailes,
        address: Editdata.Address,
        country: Editdata.Country,
        state: Editdata.State,
        Pincode: Editdata.Pincode,
        phoneNumber: Editdata.PhoneNumber,
        PAN_OR_IT_NO: Editdata.PAN_OR_IT_NO,
        accountName: Editdata.Account_Holder_Name,
        accountNumber: Editdata.Account_Number,
        bankName: Editdata.Bank_Name,
        branch: Editdata.Branch,
        ifscCode: Editdata.IFSC_Code,
        panNumber: Editdata.Pan_No,
      }));
    }
  }, [Editdata]);

  useEffect(() => {
    if (LedgerFormState.group !== "" && filteredRows.length !== 0) {
      const check = filteredRows.find(
        (ele) => ele.GroupName === LedgerFormState.group
      );

      setLedgerFormState((prev) => ({
        ...prev,
        Under_Group: check ? check.Under_Group : "",
        NatureOfGroup: check ? check.NatureOfGroup : "",
      }));
    } else {
      setLedgerFormState((prev) => ({
        ...prev,
        Under_Group: "",
        NatureOfGroup: "",
      }));
    }
  }, [LedgerFormState.group, filteredRows]);

  useEffect(() => {
    axios
      .get(`${urllink}FinanceMagement/Some_get_accountmaster_group`)
      .then((response) => {
        setfilteredRows(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    Get_ledgerdata();
  }, []);

  const Get_ledgerdata = () => {
    axios
      .get(`${urllink}FinanceMagement/get_Ledger_Entry_For_Diff`)
      .then((response) => {
        setledgerOpeningdata(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${urllink}FinanceMagement/get_Ledger_Entry_details`)
      .then((response) => {
        const data = response.data.LedgerEntries;

        setGEtledalldata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const cleardatafun = () => {
    setLedgerFormState({
      LedgerName: "",
      alias: "",
      group: "",
      Under_Group: "",
      NatureOfGroup: "",
      opbal: "",
      drCr: "",
      BankDetailes: "No",
      address: "",
      country: "",
      state: "",
      Pincode: "",
      phoneNumber: "",
      PAN_OR_IT_NO: "",
      accountName: "",
      accountNumber: "",
      bankName: "",
      branch: "",
      ifscCode: "",
      panNumber: "",
    });
  };

  const Update_ledgerdata = () => {
    const requiredFields = ["LedgerName", "group", "drCr"];
    const isBankDetailsRequired = LedgerFormState.BankDetailes === "Yes";

    if (isBankDetailsRequired) {
      requiredFields.push(
        "accountName",
        "accountNumber",
        "bankName",
        "branch",
        "ifscCode",
        "panNumber"
      );
    }

    const missingFields = requiredFields.filter(
      (field) => !LedgerFormState[field]
    );

    if (missingFields.length === 0) {
      const CheckName = GEtledalldata.filter(
        (ele) => ele.S_No !== Editdata.S_No
      ).find(
        (ele) =>
          ele.Ledger_Name.toLowerCase() ===
            LedgerFormState.LedgerName.toLowerCase() ||
          ele.Alias_Name.toLowerCase() === LedgerFormState.alias.toLowerCase()
      );

      if (CheckName) {
        alert("Name or Alias Name Already Exists");
      } else {
        const newData = {
          ...LedgerFormState,
          S_No: Editdata.S_No,
          cu_date: cu_date,
          CreatedBy: userRecord.username,
          Location: userRecord.location,
        };

        axios
          .post(
            `${urllink}FinanceMagement/update_Ledger_Entry_details`,
            newData
          )
          .then((response) => {
            alert(response.data.Message);
            cleardatafun();
            Get_ledgerdata();
            navigate("/Home/LedgerList");
          })
          .catch((error) => {
            console.error(error);
            alert("Failed to add data. Please try again.");
          });
      }
    } else {
      alert(`Missing fields: ${missingFields.join(", ")}`);
    }
  };

  const Add_LedgerEntry = () => {
    const requiredFields = ["LedgerName", "group", "drCr"];
    const isBankDetailsRequired = LedgerFormState.BankDetailes === "Yes";

    if (isBankDetailsRequired) {
      requiredFields.push(
        "accountName",
        "accountNumber",
        "bankName",
        "branch",
        "ifscCode",
        "panNumber"
      );
    }

    const missingFields = requiredFields.filter(
      (field) => !LedgerFormState[field]
    );

    if (missingFields.length === 0) {
      const CheckName = GEtledalldata.find(
        (ele) =>
          ele.Ledger_Name.toLowerCase() ===
            LedgerFormState.LedgerName.toLowerCase() ||
          ele.Alias_Name.toLowerCase() === LedgerFormState.alias.toLowerCase()
      );

      if (CheckName) {
        alert("Name or Alias Name Already Exists");
      } else {
        const newData = {
          ...LedgerFormState,
          cu_date: cu_date,
          CreatedBy: userRecord.username,
          Location: userRecord.location,
        };

        axios
          .post(
            `${urllink}FinanceMagement/insert_Ledger_Entry_details`,
            newData
          )
          .then((response) => {
            alert(response.data.Message);
            cleardatafun();
            Get_ledgerdata();
          })
          .catch((error) => {
            console.error(error);
            alert("Failed to add data. Please try again.");
          });
      }
    } else {
      alert(`Missing fields: ${missingFields.join(", ")}`);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Ledger</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="LedgerName">
            Name <span>:</span>
          </label>
          <input
            type="text"
            name="LedgerName"
            required
            value={LedgerFormState.LedgerName}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                LedgerName: e.target.value,
              })
            }
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="alias">
            Alias Name<span>:</span>
          </label>
          <input
            type="text"
            name="alias"
            required
            value={LedgerFormState.alias}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, alias: e.target.value })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="group">
            Group <span>:</span>
          </label>
          <input
            type="text"
            name="group"
            list="group_list"
            required
            value={LedgerFormState.group}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, group: e.target.value })
            }
          />
          <datalist id="group_list">
            {filteredRows.map((item, index) => (
              <option key={index} value={item.GroupName}></option>
            ))}
          </datalist>
        </div>
        {LedgerFormState.Under_Group === "Nill" ||
        LedgerFormState.Under_Group === "" ? (
          ""
        ) : (
          <span style={{ fontSize: "13px", marginTop: "25px" }}>
            ({LedgerFormState.Under_Group})
          </span>
        )}

        <div className="RegisForm_1">
          <label htmlFor="opbal">
            Op.Bal <span>:</span>
          </label>
          <input
            type="text"
            name="opbal"
            required
            value={LedgerFormState.opbal}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, opbal: e.target.value })
            }
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="drCr">
            Dr/Cr<span>:</span>
          </label>
          <select
            name="drCr"
            className="select_with_dwn"
            required
            value={LedgerFormState.drCr}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, drCr: e.target.value })
            }
          >
            <option value="">Select</option>
            <option value="Credit">Credit</option>
            <option value="Debit">Debit</option>
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="BankDetailes">
            BankDetailes<span>:</span>
          </label>
          <select
            name="BankDetailes"
            className="select_with_dwn"
            required
            value={LedgerFormState.BankDetailes}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                BankDetailes: e.target.value,
              })
            }
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="address">
            Address <span>:</span>
          </label>
          <textarea
            type="text"
            name="address"
            className="account_txtarea3"
            required
            value={LedgerFormState.address}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                address: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="state">
            State <span>:</span>
          </label>
          <input
            type="text"
            name="state"
            required
            value={LedgerFormState.state}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, state: e.target.value })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="country">
            Country <span>:</span>
          </label>
          <input
            type="text"
            name="country"
            required
            value={LedgerFormState.country}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                country: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Pincode">
            Pincode <span>:</span>
          </label>
          <input
            type="text"
            name="Pincode"
            required
            value={LedgerFormState.Pincode}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                Pincode: e.target.value,
              })
            }
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="phoneNumber">
            Phone Number<span>:</span>
          </label>
          <input
            type="text"
            name="phoneNumber"
            required
            value={LedgerFormState.phoneNumber}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                phoneNumber: e.target.value,
              })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="PAN_OR_IT_NO">
            PAN/IT NO<span>:</span>
          </label>
          <input
            type="text"
            name="PAN_OR_IT_NO"
            required
            value={LedgerFormState.PAN_OR_IT_NO}
            onChange={(e) =>
              setLedgerFormState({
                ...LedgerFormState,
                PAN_OR_IT_NO: e.target.value,
              })
            }
          />
        </div>
      </div>
      <br />
      {LedgerFormState.BankDetailes === "Yes" ? (
        <>
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="accountName">
                Bank Holder Name <span>:</span>
              </label>
              <input
                type="text"
                name="accountName"
                required
                value={LedgerFormState.accountName}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    accountName: e.target.value,
                  })
                }
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="bankName">
                Bank Name <span>:</span>
              </label>
              <input
                type="text"
                name="bankName"
                required
                value={LedgerFormState.bankName}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    bankName: e.target.value,
                  })
                }
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="accountNumber">
                Account Number <span>:</span>
              </label>
              <input
                type="text"
                name="accountNumber"
                required
                value={LedgerFormState.accountNumber}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    accountNumber: e.target.value,
                  })
                }
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="branch">
                Branch <span>:</span>
              </label>
              <input
                type="text"
                name="branch"
                required
                value={LedgerFormState.branch}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    branch: e.target.value,
                  })
                }
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="ifscCode">
                IFSC Code <span>:</span>
              </label>
              <input
                type="text"
                name="ifscCode"
                required
                value={LedgerFormState.ifscCode}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    ifscCode: e.target.value,
                  })
                }
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="panNumber">
                Pan Number <span>:</span>
              </label>
              <input
                type="text"
                name="panNumber"
                required
                value={LedgerFormState.panNumber}
                onChange={(e) =>
                  setLedgerFormState({
                    ...LedgerFormState,
                    panNumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="Register_btn_con">
        {Object.keys(Editdata).length !== 0 ? (
          <button
            className="RegisterForm_1_btns"
            type="button"
            onClick={Update_ledgerdata}
          >
            Update
          </button>
        ) : (
          <button
            className="RegisterForm_1_btns"
            type="button"
            onClick={Add_LedgerEntry}
          >
            Save
          </button>
        )}
      </div>

      {ledgerOpeningdata.length !== 0 ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <h4>Total Opening Balance</h4>
          <div className="Selected-table-container ">
            <table className="selected-medicine-table2 ">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Credit / Debit</th>
                </tr>
              </thead>
              <tbody>
                {ledgerOpeningdata.map((client, index) => (
                  <tr key={index}>
                    <td>{client.Amount}</td>
                    <td>{client.TypeAm}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Accounts;
