import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./RegisterFormNew.css"; // Import the CSS file for styling
import genesisLogo from "../assets/genesisLogo.png";
import DotPic from "../assets/DotPic.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TeddyBear from "../assets/TeddyBear.png";

function RegisterFormNew() {
  const [step, setStep] = useState(1);

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const [openModal2, setOpenModal2] = useState(false);

  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [ClinicDetails, setClinicDetails] = useState([]);

  const openModal = (content) => {
    setOpenModal2(true);
  };

  const handleOthersCheckboxChange = (e) => {
    setRegisformData((prevData) => ({
      ...prevData,
      knowAboutGenesis: {
        ...prevData.knowAboutGenesis,
        othersRef: e.target.checked,
      },
    }));
    if (e.target.checked) {
      openModal("Additional Information"); // or any content you want to display
    }
  };

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  console.log("userrecord", userRecord);
  console.log("IpNurseQueSelectedRow", IpNurseQueSelectedRow);

  const [RegisformData, setRegisformData] = useState({
    regNo: "",
    date: "",
    fullName: "",
    age: "",
    gender: "",
    address: "",
    phoneNo: "",
    email: "",
    occupation: "",
    maritalStatus: "",
    consultation: {
      skin: false,
      hair: false,
      dermatology: false,
      surgery: false,
    },

    knowAboutGenesis: {
      instagram: false,
      facebook: false,
      google: false,
      ledWall: false,
      referral: false,
      othersRef: false,
    },

    referralName: "",
    referralPhoneNumber: "",
    medicalHistory: "",
    allergy: 'No' ,
    signature: "",
  });

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setRegisformData((prevData) => ({
      ...prevData,
      date: currentDate,
    }));
  }, []);

  const initialFormData = {
    regNo: "",
    date: "",
    fullName: "",
    age: "",
    gender: "",
    address: "",
    phoneNo: "",
    email: "",
    occupation: "",
    maritalStatus: "",
    consultation: {
      skin: false,
      hair: false,
      dermatology: false,
      surgery: false,
    },
    knowAboutGenesis: {
      instagram: false,
      facebook: false,
      google: false,
      ledWall: false,
      referral: false,
      othersRef: false,
    },

    referralName: "",
    referralPhoneNumber: "",
    medicalHistory: "",
    signature: "",
  };

  const [emptyInputs, setEmptyInputs] = useState([]);

  const handleReset = () => {
    setRegisformData(initialFormData);
  };

  console.log("RegisformData", RegisformData);
  const navigate = useNavigate();

  const sigCanvas = useRef(null);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;

   
    if (type === "checkbox") {
      setRegisformData((prevData) => ({
        ...prevData,
        [id]: checked,
      }));
    } else if (type === "radio") {
      setRegisformData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
      if (id === "allergy") {
        // Reset medical history if allergy is set to "No"
        if (value === "No") {
          setRegisformData((prevData) => ({
            ...prevData,
            medicalHistory: "",
          }));
        }
      }
    } else {
      setRegisformData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }

    // Update emptyInputs state
    if (value.trim() === "") {
      if (!emptyInputs.includes(id)) {
        setEmptyInputs((prev) => [...prev, id]);
      }
    } else {
      setEmptyInputs((prev) => prev.filter((item) => item !== id));
    }

    // Check if the selected gender is "others"
    if (value === "others") {
      setOpenModal2(true);
    }
  };

  const handlePhoneNo = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNo") {
      const newval = value.length;
      if (newval <= 10) {
        setRegisformData((preFormData) => ({
          ...preFormData,
          [name]: value,
        }));
      } else {
        userwarn("Phone No must contain 10 digits");
      }
    } else {
      setRegisformData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const nextStep = () => {
    const requiredFields = [
      "regNo",
      "date",
      "fullName",
      "age",
      "gender",
      "address",
      "phoneNo",
      "email",
      "occupation",
      "maritalStatus",
      "medicalHistory",
      "signature",
    ];

    // Filter out empty required fields
    const emptyFields = requiredFields.filter((field) => !RegisformData[field]);

    if (emptyFields.length === 0) {
      // If no empty required fields, navigate directly to step 13 (submit step)
      setStep(13);
    } else {
      // Navigate normally to the next step
      setStep(step + 1);
    }
  };

  const prevStep = () => setStep(step - 1);

  const clearSignature = () => {
    sigCanvas.current.clear();
    setRegisformData((prevData) => ({
      ...prevData,
      signature: "",
    }));
  };

  function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  const saveSignature = () => {
    const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL();
    const signatureBlob = dataURLtoBlob(signatureData);

    setRegisformData((prevData) => ({
      ...prevData,
      signature: signatureBlob, // Save data URL in state if needed
    }));
    successMsg('Signature Saved')
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted!");
    console.log(RegisformData);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Validation check
    const requiredFields = [
      "regNo",
      "date",
      "fullName",
      "age",
      "gender",
      "address",
      "phoneNo",
      "email",
      "occupation",
      "maritalStatus",
      "signature",
      "medicalHistory",
    ];


    // Add medicalHistory to required fields only if allergy is "Yes"
    if (RegisformData.allergy === "Yes") {
      requiredFields.push("medicalHistory");
    }

  const emptyFields = requiredFields.filter((field) => !RegisformData[field] && field !== "medicalHistory");
  
    if (emptyFields.length > 0) {
      // alert(`Please fill in the following fields: ${emptyFields.join(', ')}`);
      // Find the first empty field and navigate back to its corresponding step
      const firstEmptyField = emptyFields[0];
      switch (firstEmptyField) {
        case "regNo":
          setStep(1);
          break;
        case "date":
          setStep(2);
          break;
        case "fullName":
          setStep(3);
          break;
        case "age":
          setStep(4);
          break;
        case "gender":
          setStep(5);
          break;
        case "address":
          setStep(6);
          break;
        case "phoneNo":
          setStep(7);
          break;
        case "email":
          setStep(8);
          break;
        case "occupation":
          setStep(9);
          break;
        case "maritalStatus":
          setStep(10);
          break;
        case "signature":
          setStep(13); // Assuming signature is on step 13
          break;
        case "medicalHistory":
          setStep(12);
          break;
        default:
          setStep(1); // Default to step 1
      }
      return;
    }

    const isConsultationSelected = Object.values(
      RegisformData.consultation
    ).some((value) => value);
    const isKnowAboutGenesisSelected = Object.values(
      RegisformData.knowAboutGenesis
    ).some((value) => value);

    if (!isConsultationSelected) {
      alert("Please select at least one consultation type.");
      setStep(11); // Navigate back to consultation step
      return;
    }

    if (!isKnowAboutGenesisSelected) {
      alert(
        "Please select at least one source for how you know about Genesis."
      );
      setStep(11); // Navigate back to consultation step where knowAboutGenesis is located
      return;
    }

    // Prepare formDataToSend and submit
    const formDataToSend = {
      ...RegisformData,
      Createdby: userRecord.username,
      location: userRecord.location,
    };

    console.log(formDataToSend);

    const datatosend = new FormData();
    datatosend.append("cancelReason", null);
    for (let key in formDataToSend) {
      if (formDataToSend.hasOwnProperty(key)) {
        if (key === "consultation" || key === "knowAboutGenesis") {
          // Convert the consultation object to a JSON string
          datatosend.append(key, JSON.stringify(formDataToSend[key]));
        } else {
          datatosend.append(key, formDataToSend[key]);
        }
      }
    }

    axios
      .post(
        `${urllink}appointmentmanagement/insert_appointment_patientrequest_genisis`,
        datatosend
      )
      .then((response) => {
        console.log(response);
        if (response.data.message === "Inserted Successfully") {
          successMsg("Thank you for submitting the form!");
          handleReset();
          clearSignature();
          setIsFormSubmitted(true);
          // navigate('Home/Appoinment-RegisterList1')
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        errmsg("Error Occurred");
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errormsg) => {
    toast.error(`${errormsg}`, {
      position: "top-center",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  useEffect(() => {
    axios
      .get(`${urllink}appointmentmanagement/next_appointment_id`)
      .then((response) => {
        console.log(response.data);
        const data = response.data.Next_regid;
        console.log("data", data);
        if (!!data) {
          setRegisformData((preFormData) => ({
            ...preFormData,
            regNo: data, // Corrected here
          }));
        } else {
          console.error(
            "Invalid response structure or missing Next_regid property."
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // Move side effects to useEffect

    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          console.log(response);
          const firstClinic = response.data;
          console.log("firstClinic", userRecord);
          setClinicDetails(firstClinic); // Access the "Data" key
        } else {
          console.error("No record found");
        }
      })
      .catch((error) => console.error("Error fetching data"));
  }, []);

  return (
    <>
      {isFormSubmitted ? (
        <div className="thank-you-page_09">
          <div className="icn-tle-cntrfx">
            {ClinicDetails.clinicLogo && (
              <img
                src={`data:image/png;base64,${ClinicDetails.clinicLogo}`}
                alt={ClinicDetails.clinicName}
                className="kuedh676565"
              />
            )}
          </div>
          <div className="tdy_uo9">
            <img src={TeddyBear} alt="Thank You!" className="teddy-bear" />
            <h1>Thank You !</h1>
            <p>
              Thank you for choosing Genesis Clinic for your health and wellness
              needs. Our dedicated team will carefully review your submission,
              and you can expect to hear from us shortly. We are committed to
              providing you with exceptional care and look forward to assisting
              you on your journey.
            </p>
            <p>
              Should you have any immediate questions or require further
              assistance, please feel free to contact our support team. We are
              here to ensure that your experience with us is smooth and
              satisfactory.
            </p>
            <p>
              Stay tuned for updates, tips, and insights from Genesis Clinic. We
              are thrilled to be a part of your path to improved well-being.
            </p>
            <p>
              Once again, we appreciate your trust in us and your patience. We
              look forward to welcoming you soon. Have a wonderful day!
            </p>
          </div>
        </div>
      ) : (
        <div className="appointment_new2">
          <div className="h_head_88">
            <h2 style={{ display: "flex" }}>
              <p style={{ paddingRight: "20px" }}>R E G I S T R A T I O N </p>
              <p> F O R M</p>{" "}
            </h2>
          </div>

          <div className="WEKJFUYE">
            <div className="jjk845">
              <h3 style={{ display: "flex", gap: "0px" }}>
                <p style={{ paddingRight: "20px" }}>C O N S U L T A T I O N</p>
                <p> F O R</p>{" "}
              </h3>
            </div>
            <div className="kjedi8">
              <label>
                <input
                  type="checkbox"
                  id="consultation.skin"
                  checked={RegisformData.consultation.skin}
                  onChange={(e) =>
                    setRegisformData((prevData) => ({
                      ...prevData,
                      consultation: {
                        ...prevData.consultation,
                        skin: e.target.checked,
                      },
                    }))
                  }
                />{" "}
                SKIN
              </label>

              <label>
                <input
                  type="checkbox"
                  id="consultation.hair"
                  checked={RegisformData.consultation.hair}
                  onChange={(e) =>
                    setRegisformData((prevData) => ({
                      ...prevData,
                      consultation: {
                        ...prevData.consultation,
                        hair: e.target.checked,
                      },
                    }))
                  }
                />{" "}
                HAIR
              </label>

              <label className="lolp0">
                <input
                  type="checkbox"
                  id="consultation.dermatology"
                  checked={RegisformData.consultation.dermatology}
                  onChange={(e) =>
                    setRegisformData((prevData) => ({
                      ...prevData,
                      consultation: {
                        ...prevData.consultation,
                        dermatology: e.target.checked,
                      },
                    }))
                  }
                />{" "}
                DERMATOLOGY
              </label>

              <label>
                <input
                  type="checkbox"
                  id="consultation.surgery"
                  checked={RegisformData.consultation.surgery}
                  onChange={(e) =>
                    setRegisformData((prevData) => ({
                      ...prevData,
                      consultation: {
                        ...prevData.consultation,
                        surgery: e.target.checked,
                      },
                    }))
                  }
                />{" "}
                SURGERY
              </label>
            </div>
          </div>

          <div className="edciwe7">
            <div className="iiopk8">
              <div>
                <img className="kidiis8_img" src={genesisLogo} alt="Logo"></img>
              </div>

              <div className="background-image">
                <img src={DotPic} alt="DotPic" className="rotate-image" />
                <div className="gold-overlay"></div>
                <div className="iewdchwuid">
                  <form onSubmit={handleSubmit}>
                    {step === 1 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <label>
                            REG NO<span>:</span>
                          </label>
                          <input
                            type="text"
                            id="regNo"
                            value={RegisformData.regNo}
                            onChange={handleChange}
                            // placeholder="Enter your Reg No"
                            readOnly
                            required
                          />
                        </div>

                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <label>
                            Date<span>:</span>
                          </label>
                          <input
                            type="date"
                            id="date"
                            value={RegisformData.date}
                            onChange={handleChange}
                            className={
                              emptyInputs.includes("date") ? "empty-input" : ""
                            }
                            required
                          />
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 3 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <label>
                            Full Name<span>:</span>
                          </label>
                          <input
                            type="text"
                            id="fullName"
                            value={RegisformData.fullName}
                            onChange={handleChange}
                            placeholder="Enter your Full Name"
                            className={
                              emptyInputs.includes("fullName")
                                ? "empty-input"
                                : ""
                            }
                            required
                          />
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 4 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <label>
                            Age<span>:</span>
                          </label>
                          <input
                            type="text"
                            id="age"
                            value={RegisformData.age}
                            onChange={handleChange}
                            placeholder="Enter your Age"
                            className={
                              emptyInputs.includes("age") ? "empty-input" : ""
                            }
                            required
                          />
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 5 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <div>
                            <label>
                              Gender<span>:</span>
                            </label>
                          </div>

                          <div className="wsdq37887">
                            <label>
                              <input
                                type="radio"
                                id="gender"
                                value="male"
                                checked={RegisformData.gender === "male"}
                                onChange={handleChange}
                                required
                              />{" "}
                              Male
                            </label>

                            <label>
                              <input
                                type="radio"
                                id="gender"
                                value="female"
                                checked={RegisformData.gender === "female"}
                                onChange={handleChange}
                                required
                              />{" "}
                              Female
                            </label>

                            <label>
                              <input
                                type="radio"
                                id="gender"
                                value="others"
                                checked={RegisformData.gender === "others"}
                                onChange={handleChange}
                                required
                              />{" "}
                              Others
                            </label>
                          </div>
                        </div>

                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 6 && (
                      <div className="form-step">
                        <div className="oisd67 njkloi9">
                          <label>
                            Address<span>:</span>
                          </label>
                          <textarea
                            id="address"
                            value={RegisformData.address}
                            onChange={handleChange}
                            placeholder="Enter your Address"
                            className={
                              emptyInputs.includes("address")
                                ? "empty-input"
                                : ""
                            }
                            required
                          />
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 7 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <label>
                            Phone No<span>:</span>
                          </label>
                          <input
                            type="tel"
                            id="phoneNo"
                            name="phoneNo"
                            value={RegisformData.phoneNo}
                            onChange={handlePhoneNo}
                            placeholder="Enter your Phone No"
                            className={
                              emptyInputs.includes("phoneNo")
                                ? "empty-input"
                                : ""
                            }
                            required
                          />
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 8 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <label>
                            E-Mail<span>:</span>
                          </label>
                          <input
                            type="email"
                            id="email"
                            value={RegisformData.email}
                            onChange={handleChange}
                            placeholder="Enter your E-Mail"
                            className={
                              emptyInputs.includes("email") ? "empty-input" : ""
                            }
                            required
                          />
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 9 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <label>
                            Occupation<span>:</span>
                          </label>
                          <input
                            type="text"
                            id="occupation"
                            value={RegisformData.occupation}
                            onChange={handleChange}
                            placeholder="Enter your Occupation"
                            className={
                              emptyInputs.includes("occupation")
                                ? "empty-input"
                                : ""
                            }
                            required
                          />
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 10 && (
                      <div className="form-step">
                        <div className="oisd67">
                          <label>
                            Marital Status<span>:</span>
                          </label>
                          <select
                            id="maritalStatus"
                            value={RegisformData.maritalStatus}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                          </select>
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 11 && (
                      <div className="form-step">
                        <div className="oisd67 udsuyu7yw7">
                          <div className="ewcflkwekl dwuiud7">
                            <label>
                              How Do You Know GENESIS DERMATOLOGY<span>:</span>
                            </label>
                          </div>

                          <div className="wsdq37887">
                            <label>
                              <input
                                type="checkbox"
                                id="knowAboutGenesis.instagram"
                                checked={
                                  RegisformData.knowAboutGenesis.instagram
                                }
                                onChange={(e) =>
                                  setRegisformData((prevData) => ({
                                    ...prevData,
                                    knowAboutGenesis: {
                                      ...prevData.knowAboutGenesis,
                                      instagram: e.target.checked,
                                    },
                                  }))
                                }
                              />{" "}
                              Instagram
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                id="knowAboutGenesis.facebook"
                                checked={
                                  RegisformData.knowAboutGenesis.facebook
                                }
                                onChange={(e) =>
                                  setRegisformData((prevData) => ({
                                    ...prevData,
                                    knowAboutGenesis: {
                                      ...prevData.knowAboutGenesis,
                                      facebook: e.target.checked,
                                    },
                                  }))
                                }
                              />{" "}
                              Facebook
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                id="knowAboutGenesis.google"
                                checked={RegisformData.knowAboutGenesis.google}
                                onChange={(e) =>
                                  setRegisformData((prevData) => ({
                                    ...prevData,
                                    knowAboutGenesis: {
                                      ...prevData.knowAboutGenesis,
                                      google: e.target.checked,
                                    },
                                  }))
                                }
                              />{" "}
                              Google
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                id="knowAboutGenesis.ledWall"
                                checked={RegisformData.knowAboutGenesis.ledWall}
                                onChange={(e) =>
                                  setRegisformData((prevData) => ({
                                    ...prevData,
                                    knowAboutGenesis: {
                                      ...prevData.knowAboutGenesis,
                                      ledWall: e.target.checked,
                                    },
                                  }))
                                }
                              />{" "}
                              Led Wall
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                id="knowAboutGenesis.referral"
                                checked={
                                  RegisformData.knowAboutGenesis.referral
                                }
                                onChange={(e) =>
                                  setRegisformData((prevData) => ({
                                    ...prevData,
                                    knowAboutGenesis: {
                                      ...prevData.knowAboutGenesis,
                                      referral: e.target.checked,
                                    },
                                  }))
                                }
                              />{" "}
                              Referral
                            </label>

                            <label>
                              <input
                                type="checkbox"
                                id="knowAboutGenesis.othersRef"
                                checked={
                                  RegisformData.knowAboutGenesis.othersRef
                                }
                                onChange={handleOthersCheckboxChange}
                              />{" "}
                              Others
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {openModal2 && (
                      <div
                        className={
                          isSidebarOpen
                            ? "sideopen_showcamera_profile"
                            : "showcamera_profile"
                        }
                        onClick={() => {
                          setOpenModal2(false);
                        }}
                      >
                        <div
                          className="newwProfiles newwPopupforreason_854"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="form-step">
                            <div className="oisd67">
                              <label>
                                Referral Name<span>:</span>
                              </label>
                              <input
                                type="text"
                                id="referralName"
                                value={RegisformData.referralName}
                                onChange={handleChange}
                                placeholder="Enter your Referral Name"
                                className={
                                  emptyInputs.includes("referralName")
                                    ? "empty-input"
                                    : ""
                                }
                                required
                              />
                            </div>
                            <br />

                            <div className="oisd67">
                              <label>
                                Phone No<span>:</span>
                              </label>
                              <input
                                type="number"
                                id="referralPhoneNumber"
                                value={RegisformData.referralPhoneNumber}
                                onChange={handleChange}
                                placeholder="Enter your Phone Number"
                                className={
                                  emptyInputs.includes("referralPhoneNumber")
                                    ? "empty-input"
                                    : ""
                                }
                                required
                              />
                            </div>

                            <br />
                            <div className="ewuii78">
                              <button
                                onClick={() => {
                                  setOpenModal2(false);
                                }}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {step === 12 && (
                      <div className="form-step">
                        <div className="oisd67 udsuyu7yw7">
                          <div className="ewcflkwekl ecdc">
                            <label>
                              Any Medical History Or Allergies <span>:</span>
                            </label>
                          </div>
                          <div className="wsdq37887">
                            <label>
                              <input
                                type="radio"
                                id="allergy"
                                value="Yes"
                                checked={RegisformData.allergy === "Yes"}
                                onChange={handleChange}
                                required
                              />{" "}
                              Yes
                            </label>

                            <label>
                              <input
                                type="radio"
                                id="allergy"
                                value="No"
                                checked={RegisformData.allergy === "No"}
                                onChange={handleChange}
                                required
                              />{" "}
                              No
                            </label>
                          </div>
                          <br />
                          {RegisformData.allergy === "Yes" && (
                            <div className="s88uu eddcdcd">
                              <textarea
                                id="medicalHistory"
                                value={RegisformData.medicalHistory}
                                onChange={handleChange}
                                placeholder="Type any Medical History Or Allergies"
                                className={
                                  emptyInputs.includes("medicalHistory")
                                    ? "empty-input"
                                    : ""
                                }
                              />
                            </div>
                          )}
                        </div>
                        <br />

                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="button" onClick={nextStep}>
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 13 && (
                      <div className="form-step">
                        <div className="oisd67 ffffffcd">
                          <div className="ewcflkwekl">
                            <label>
                              Signature <span>:</span>
                            </label>
                          </div>
                          <div className="s88uu dwee221">
                            <SignatureCanvas
                              ref={sigCanvas}
                              penColor="black"
                              canvasProps={{ className: "sigCanvas33" }}
                            />
                          </div>
                          <div className="ewoooon">
                            <button type="button" onClick={clearSignature}>
                              Clear
                            </button>
                            <button type="button" onClick={saveSignature}>
                              Save
                            </button>
                          </div>
                        </div>
                        {/* <br /> */}

                        <div className="ewuii78">
                          <button type="button" onClick={prevStep}>
                            Back
                          </button>
                          <button type="submit" onClick={handleFormSubmit}>
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="djhweyd67u79">
            <p>
              {" "}
              At the heart of GENESIS, we offer services based on trust and
              honesty. We have integrated the best and up-to-date aesthetic
              courses and treatment to make sure that you receive the best
              results and experience. We always tailor our treatment to our
              client's individual goals
            </p>
          </div>
          <br />
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default RegisterFormNew;
