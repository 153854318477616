import React, { useState, useEffect } from "react";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./LabMaster.css";
import { DataGrid } from "@mui/x-data-grid";

import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const LabReport = () => {
  let formData;
  const [Selectedpdf5, setSelectedpdf5] = useState(null);
  const [SelectedImage5, setSelectedImage5] = useState(null);
  const [openModal4, setOpenModal4] = React.useState(false);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const formData1 = useSelector((state) => {
    if (
      state.userRecord &&
      state.userRecord.NurseworkbenchformData &&
      Object.keys(state.userRecord.NurseworkbenchformData).length > 0
    ) {
      formData = state.userRecord.labformdata;
    } else {
      formData = state.userRecord.workbenchformData;
    }
  });

  const [PatientData, setPatientData] = React.useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openImageView, setopenImageView] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = PatientData.length;
  const totalPages = Math.ceil(PatientData.length / 10);

  const lab_Patient_ID = formData.PatientID;

  const visitNo = formData.visitNo;

  const [Temp_testnamelist, setTemp_testnamelist] = useState([]);

  const yourStyles = {
    position: "absolute",
    inset: "100px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(97 90 90 / 75%)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "0px",
  };

  const handleVisibilityClick = (params) => {
    const lab_image = params.row.lab_image;

    if (typeof lab_image === "string") {
      if (lab_image.startsWith("data:application/pdf;base64,")) {
        setSelectedpdf5(lab_image);
        setOpenModal4(true);
      } else if (
        lab_image.startsWith("data:image/jpeg;base64,") ||
        lab_image.startsWith("data:image/jpg;base64,") ||
        lab_image.startsWith("data:image/png;base64,")
      ) {
        setSelectedImage5(lab_image);
        setOpenModal4(true);
      }
    } else {
      alert("No Document to View");
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent("");
  };

  const [columns] = React.useState([
    { field: "id", headerName: "Patient ID", width: 150 },
    { field: "PatientName", headerName: "Patient Name", width: 150 },
    {
      field: "lab_image",
      headerName: "View",
      width: 150,
      renderCell: (params) => (
        <>
          {params.row.lab_image === "Not Uploaded" ? (
            <div>Not Yet Upload</div>
          ) : isImageOrPDF ? (
            // Render a button to view the document if it's an image or PDF
            <Button
              className="cell_btn"
              onClick={() => handleVisibilityClick(params)}
            >
              <VisibilityIcon />
            </Button>
          ) : (
            params.row.lab_image
          )}
        </>
      ),
    },
    { field: "Date", headerName: "Date", width: 150 },
  ]);

  // Function to check if lab_image is a PDF or image
  const isImageOrPDF = (labImage) => {
    return (
      labImage.startsWith("application/pdf;") ||
      labImage.startsWith("image/jpeg;") ||
      labImage.startsWith("application/octet-stream;")
    );
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}labtest/getfor_doctorworkbenchlab?patientid=${lab_Patient_ID}&visitNo=${visitNo}&location=${userRecord?.location}`
      )
      .then((response) => {
        setPatientData(
          response.data.map((labData) => ({
            id: labData.PatientID,
            PatientName: labData.lab_patient_Name,
            Date: labData.Date,
            lab_image: labData.lab_image,
          }))
        );

        setTemp_testnamelist(
          response.data.flatMap((ele) => ele.Temp_testnamelist)
        );
      })
      .catch((err) => console.error(err));
  }, [lab_Patient_ID,visitNo,userRecord?.location]);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const DisplayData = () => {
    return (
      <>
        {Selectedpdf5 ? (
          <iframe
            src={Selectedpdf5}
            style={{ height: "100%", width: "100%" }}
            title="PDF Viewer"
          ></iframe>
        ) : (
          <img
            src={SelectedImage5}
            alt="Selected Image"
            style={{ width: "100%", height: "auto" }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Test Preview</h4>
        </div>

        <div className="Selected-table-container RateCard_table">
          <table className="selected-medicine-table2 tablewidth">
            <thead>
              <tr>
                <th id="vital_Twidth">S.No</th>
                <th id="vital_Twidth">Test Name</th>
              </tr>
            </thead>
            <tbody>
              {Temp_testnamelist.map((client, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{client.Test_Name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <br />
        <br />
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={PatientData.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              // onSelectionModelChange={handleSelectionModelChange}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 0 && PatientData.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>

      {openModal4 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
        >
          <div className="newwProfiles">
            <DisplayData />
            <button
              className="closeicon-cs"
              onClick={() => setOpenModal4(false)}
            >
              close
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default LabReport;
