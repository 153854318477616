import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./PatientQueueList.css";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const PatientQueueList = () => {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const Location = userRecord?.location;
  const UserName = userRecord?.username;
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const [Doctors, setDoctors] = useState([]);
  const [selectedDoctor, setselectedDoctor] = useState("all");

  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");
  const [columns, setcolumn] = useState([]);



  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");

  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);


  const getmethod = useCallback(() => {
    axios
      .get(
        `${urllink}doctorsworkbench/get_fortheraphist_queuelist?location=${userRecord?.location}`
      )
      .then((response) => {
        const filteredData = response.data.filter((userdata) => {
          // Check if the doctor matches the selected doctor or if all doctors are selected
          return (
            selectedDoctor === "all" || userdata.DoctorName === selectedDoctor
          );
        });

        const Records = filteredData.map((userdata) => ({
          id: userdata.PatientID,
          PatientName: userdata.FirstName + " " + userdata.LastName,
          AppointmentPurpose: userdata.AppointmentPurpose,
          Status: userdata.Status,
          DoctorName: userdata.DoctorName,
          Location: userdata.Location,
          next_appointment_date: userdata.next_appointment_date || '-',
          TotalSession: userdata.TotalSession,
          completedsessions: userdata.completedsessions,
          currentsession: userdata.currentsession,
          Treatment_Procedure: userdata.Treatment_Procedure,
          PhoneNumber: userdata?.PhoneNumber
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [selectedDoctor, userRecord, userRecord?.location]);

  useEffect(() => {
    getmethod();
  }, [getmethod]); // Add selectedDoctor as a dependency to trigger the effect when the doctor changes

  useEffect(() => {
    axios
      .get(
        `${urllink}usercontrol/get_doctor_info?location=${userRecord?.location}`
      )
      .then((response) => {
        setDoctors(response.data);
        setcolumn([
          {
            field: "PatientName",
            headerName: "Patient Name",
            width: 120,
            renderCell: (params) => (
              <Tooltip
                title={
                  <div className="forTooltip_ou8">
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        Procedurename <span>:</span>{" "}
                      </label>
                      <p>{params.row.Treatment_Procedure}</p>
                    </h4>
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        Total Session <span>:</span>{" "}
                      </label>
                      <p> {params.row.TotalSession}</p>
                    </h4>{" "}
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        {" "}
                        Completed Session <span>:</span>{" "}
                      </label>
                      <p>{params.row.completedsessions}</p>
                    </h4>{" "}
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        {" "}
                        Current Session <span>:</span>{" "}
                      </label>
                      <p>{params.row.currentsession}</p>
                    </h4>
                  </div>
                }
              >
                <span>{params.value}</span>
              </Tooltip>
            ),
          },
          {
            field: "DoctorName",
            headerName: "Doctor Name",
            width: 120,
            renderCell: (params) => (
              <>
                {response.data.length > 0 ? (
                  <select
                    value={params.value}
                    onChange={(e) => handledoctorchange(e, params.row)}
                  >
                    {[...response.data].map((doctor, index) => (
                      <option key={index} value={doctor}>
                        {doctor}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span>Loading...</span>
                )}
              </>
            ),
          },
          {
            field: "AppointmentPurpose",
            headerName: "Appointment Purpose",
            width: 170,
            renderCell: (params) => (
              <Tooltip
                title={
                  <div className="forTooltip_ou8">
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        Procedurename <span>:</span>{" "}
                      </label>
                      <p>{params.row.Treatment_Procedure}</p>
                    </h4>
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        Total Session <span>:</span>{" "}
                      </label>
                      <p> {params.row.TotalSession}</p>
                    </h4>{" "}
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        {" "}
                        Completed Session <span>:</span>{" "}
                      </label>
                      <p>{params.row.completedsessions}</p>
                    </h4>{" "}
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        {" "}
                        Current Session <span>:</span>{" "}
                      </label>
                      <p>{params.row.currentsession}</p>
                    </h4>
                  </div>
                }
              >
                <span>{params.value}</span>
              </Tooltip>
            ),
          },
          {
            field: "Status",
            headerName: "Status",
            width: 170,
            renderCell: (params) => (
              <Tooltip
                title={
                  <div className="forTooltip_ou8">
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        Procedurename <span>:</span>{" "}
                      </label>
                      <p>{params.row.Treatment_Procedure}</p>
                    </h4>
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        Total Session <span>:</span>{" "}
                      </label>
                      <p> {params.row.TotalSession}</p>
                    </h4>{" "}
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        {" "}
                        Completed Session <span>:</span>{" "}
                      </label>
                      <p>{params.row.completedsessions}</p>
                    </h4>{" "}
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        {" "}
                        Current Session <span>:</span>{" "}
                      </label>
                      <p>{params.row.currentsession}</p>
                    </h4>
                  </div>
                }
              >
                <span>{params.value}</span>
              </Tooltip>
            ),
          },
          {
            field: "next_appointment_date",
            headerName: "Next Appointment",
            width: 150,
            renderCell: (params) => (
              <Tooltip
                title={
                  <div className="forTooltip_ou8">
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        Procedurename <span>:</span>{" "}
                      </label>
                      <p>{params.row.Treatment_Procedure}</p>
                    </h4>
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        Total Session <span>:</span>{" "}
                      </label>
                      <p> {params.row.TotalSession}</p>
                    </h4>{" "}
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        {" "}
                        Completed Session <span>:</span>{" "}
                      </label>
                      <p>{params.row.completedsessions}</p>
                    </h4>{" "}
                    <h4>
                      <label className="forTooltip_ou8_labl">
                        {" "}
                        Current Session <span>:</span>{" "}
                      </label>
                      <p>{params.row.currentsession}</p>
                    </h4>
                  </div>
                }
              >
                <span>{params.value}</span>
              </Tooltip>
            ),
          },
          {
            field: "actions ",
            headerName: "View",
            width: 120,
            renderCell: (params) => (
              <>
                <Button className="cell_btn" onClick={() => handleList(params)}>
                  <VisibilityIcon />
                </Button>
              </>
            ),
          },
          {
            field: "Action",
            headerName: "Action",
            width: 100,
            renderCell: (params) => (
              <>
                <Button
                  className="cell_btn"
                  onClick={() => handleRequestProceed(params)}
                >
                  <ArrowForwardIcon />
                </Button>
              </>
            ),
          },
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord?.location]);

  const handleRequestProceed = (params) => {
    const serialid = params.row.id;
    const location = params.row.Location;
    axios
      .post(
        `${urllink}doctorsworkbench/update_status_appointment_register?selectedpatienid=${serialid}&location=${userRecord?.location}`
      )
      .then((response) => {
        if (response.data) {
        } else {
          console.error("Error updating record:", response.data.message);
          // Handle error, e.g., show an error message to the user
        }
      })
      .catch((error) => {
        console.error("Error updating record:", error);
      });

    const data = {
      serialid: serialid,
      location: location,
    };

    dispatchvalue({ type: "Data", value: data });
    dispatchvalue({ type: "NurseworkbenchformData", value: [] });

    navigate("/Home/Navigation");
  };

  const handleList = (params) => {
    // Extract the id from the selected row
    const selectedRowId = params.row.id;

    axios
      .get(`${urllink}patientmanagement/get_personal_info`)
      .then((response) => {
        const data1 = response.data;
        const foundPatient = data1.find(
          (patient) => patient.PatientID === selectedRowId
        );

        axios
          .get(`${urllink}patientmanagement/get_communication_address`)
          .then((response) => {
            const data2 = response.data;
            const patient_address = data2.find(
              (patient) => patient.PatientID === selectedRowId
            );

            axios
              .get(
                `${urllink}doctorsworkbench/get_vitalform1?selectedRowId=${selectedRowId}`
              )
              .then((response) => {
                const data3 = response.data;
                // const vital_data = data3.find(patient => patient.PatientID === selectedRowId);
                const vital = data3[0];

                axios
                  .get(
                    `${urllink}doctorsworkbench/get_treatment_forprofile?selectedRowId=${selectedRowId}`
                  )
                  .then((response) => {
                    const data4 = response.data;

                    const firstimge = data4.first_image;
                    const lastimg = data4.last_image;

                    axios
                      .get(
                        `${urllink}doctorsworkbench/get_patientphoto?selectedRowId=${selectedRowId}`
                      )
                      .then((response) => {
                        const data5 = response.data;

                        const PatientPhoto = data5.PatientPhoto;

                        axios
                          .get(`${urllink}doctorsworkbench/get_treatment`)
                          .then((response) => {
                            const data6 = response.data;
                            const filteredData = data6.filter(
                              (row) => selectedRowId === data5.PatientID
                            );

                            const history = filteredData.History;

                            axios
                              .get(
                                `${urllink}doctorsworkbench/get_emergencydetails_info1?selectedRowId=${selectedRowId}`
                              )
                              .then((response) => {
                                const data7 = response.data;

                                const EmergencyName = data7.Name;

                                axios
                                  .get(
                                    `${urllink}doctorsworkbench/get_allergiesname_forprofile?selectedRowId=${selectedRowId}`
                                  )
                                  .then((response) => {
                                    const data8 = response.data;

                                    const allergies = data8.allergies;

                                    axios
                                      .get(
                                        `${urllink}doctorsworkbench/get_previousvisit?selectedRowId=${selectedRowId}`
                                      )
                                      .then((response) => {
                                        const data9 = response.data;

                                        const previousvisit =
                                          data9.previousvisit;

                                        axios
                                          .get(
                                            `${urllink}doctorsworkbench/get_next_visit?selectedRowId=${selectedRowId}`
                                          )
                                          .then((response) => {
                                            const data10 = response.data;

                                            const Next_Appointment =
                                              data10.Next_Appointment;

                                            axios
                                              .get(
                                                `${urllink}doctorsworkbench/get_diseasename?selectedRowId=${selectedRowId}`
                                              )
                                              .then((response) => {
                                                const data11 = response.data;

                                                axios
                                                  .get(
                                                    `${urllink}doctorsworkbench/get_allimages_for_patients?selectedRowId=${selectedRowId}`
                                                  )
                                                  .then((response) => {
                                                    const data12 =
                                                      response.data;

                                                    const patientAction = (
                                                      data
                                                    ) => ({
                                                      type: "forPatientData",
                                                      value: data,
                                                    });

                                                    dispatchvalue(
                                                      patientAction({
                                                        // ...prevInfo,
                                                        ...foundPatient,
                                                        ...patient_address,
                                                        ...vital,
                                                        ...firstimge,
                                                        ...lastimg,
                                                        ...PatientPhoto,
                                                        ...history,
                                                        ...EmergencyName,
                                                        ...allergies,
                                                        ...previousvisit,
                                                        ...Next_Appointment,
                                                        ...data11,
                                                        ...data12,
                                                      })
                                                    );
                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });

    navigate("/Home/PatientProfile");
  };

  const handledoctorchange = (e, row) => {
    const { value } = e.target;

    const confor = window.confirm("Are You Sure ,Want to change the Doctor");
    if (confor) {
      axios
        .post(
          `${urllink}appointmentmanagement/Update_doctor_Name?id=${row.id}&DoctorName=${value}&location=${row.Location}`
        )
        .then((response) => {
          alert(response.data.message);
          getmethod();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.PatientName.toLowerCase();
      const lowerCasePhoneNo = row.PhoneNumber.toString();

      const startsWithFirstName = lowerCaseSupplierName.startsWith(
        searchQuery.toLowerCase()
      );
      const startsWithPhoneNo = lowerCasePhoneNo.startsWith(
        searchQuery1.toLowerCase()
      );

      return (
        (startsWithFirstName || !searchQuery) &&
        (startsWithPhoneNo || !searchQuery1)
      );
    });

    // If there is a search query, sort the data to bring the left-to-right matching rows to the top
   
    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, rows]);


  return (
    <>
  
      <div className="appointment">
        <div className="h_head h_head_h_2">
          <h4>Patient Queue List</h4>
          <div className="doctor_select_1 selt-dctr-nse">
            <label htmlFor="names">
              Doctor <span>:</span>
            </label>
            <select
              name="Doctor Name"
              onChange={(e) => setselectedDoctor(e.target.value)}
              value={selectedDoctor}
            >
              <option value="all">Select</option>
              {Doctors.map((p, index) => (
                <option key={index} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </div>
        </div>
        <br />
       
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Patient Name
              <span>:</span>
            </label>
            <input
              type="text"
              id="FirstName"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">
              Phone No <span>:</span>
            </label>
            <input
              type="number"
              id="PhoneNo"
              value={searchQuery1}
              onChange={handleSearchChange}
              placeholder="Enter the Phone No"
            />
          </div>
        
        </div>
       
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>

        {filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientQueueList;
