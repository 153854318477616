const initstate={
    GroupEditdata:{},
    LedgerEditdata:{},
    BankAccountData:[],
    PaymenttypeEdit:{},

};

const FinanceStore=(state=initstate,action)=>
{
    switch(action.type)
    {
        case 'Groupdata':
            return{...state,GroupEditdata:action.value}

        case 'Ledgerdata':
            return{...state,LedgerEditdata:action.value}

        case 'BankAccountlist':
            return{...state,BankAccountData:action.value}
        
        case 'PaymenttypeEdit':
            return{...state,PaymenttypeEdit:action.value}
        
        default:
            return state;
    }
}

export default FinanceStore;