import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const AddvaceConsume = () => {
  const userRecord=useSelector(state=>state.userRecord?.UserData);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const totalPages = Math.ceil(rows.length / 10);
  const pageSize = 10;
  

  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  
  

  const [columns] = React.useState([
    { field: 'employeeid', headerName: 'Employee ID', width: 100 },
    { field: 'employeename', headerName: 'Employee Name', width: 130 },
    { field: 'reqamount', headerName: 'Request Amount', width: 120 },
    { field: 'reason', headerName: 'Reason', width: 150 },
    { field: 'installment', headerName: 'No.of.Installment', width: 150 },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'rejectreason', headerName: 'Reject Reason', width: 130 },
    { field: 'issueddate', headerName: 'Issued Date', width: 120 },
    { field: 'issuedby', headerName: 'Issued By', width: 120 },
  ]);


  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = () => {
    const employeeid = userRecord.EmployeeId;
    fetch(`${urllink}HRmanagement/get_all_advance_register_by_employeeid?EmployeeID=${employeeid}&location=${userRecord.location}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            reqdate: userdata.RequestDate,
            reqamount: userdata.RequestAmount,
            reason: userdata.Reason,
            installment: userdata.RepaymentDue,
            status: userdata.Status,
            rejectreason: userdata.RejectReason,
            approvedby: userdata.ApproverName,
            issueddate: userdata.IssuedDate,
            issuedby: userdata.IssueverName
          }));
          setRows(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showdown = rows.length;

  return (
    <div>
      <ThemeProvider theme={theme}>
        <div className=" grid_1">
          <DataGrid
            rows={rows.slice(page * pageSize, (page + 1) * pageSize)}
            columns={columns}
            pageSize={100}
            onSelectionModelChange={handleSelectionModelChange}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={handlePageChange}
            hideFooterPagination
            hideFooterSelectedRowCount
            className=" data_grid"
          />
          {showdown > 10 && (
            <div className="grid_foot">
              <button
                onClick={() =>
                  setPage((prevPage) => Math.max(prevPage - 1, 0))
                }
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() =>
                  setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                }
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </ThemeProvider>
      {showdown !== 0 && rows.length !== 0 ? (
        ''
      ) : (
        <div className='IP_norecords'>
          <span>No Records Found</span>
        </div>
      )}
    </div>
  );
};

export default AddvaceConsume;
