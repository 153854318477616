import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const PerformanceManagement = () => {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [openModal, setOpenModal] = useState(false);

  const [status, setStatus] = useState("");
  const [oldStatus, setOldStatus] = useState("");
  const [reason, setReason] = useState("");
  const [issuedby, setIssued] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [installment, setInstallment] = useState("");
  const columns = [
    { field: "Sl_No", headerName: "Sl No", width: 50 },
    { field: "employeeid", headerName: "Employee ID", width: 100 },
    { field: "employeename", headerName: "Employee Name", width: 140 },
    { field: "Date", headerName: "Hike Date", width: 100 },
    { field: "Current_Payment", headerName: "Current Salary", width: 120 },
    { field: "Performance_Rate", headerName: "Performance Rate", width: 130 },
    { field: "Hike_Percentage", headerName: "Hike Percentage", width: 130 },
    { field: "Hike_Amount", headerName: "Hike Amount", width: 100 },
    { field: "New_Pay", headerName: "New Salary", width: 100 },
    { field: "Remarks", headerName: "Remarks", width: 110 },
    { field: "ApprovedBy", headerName: "Approved By", width: 100 },
    { field: "Location", headerName: "Branch Location", width: 120 },
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const location = userRecord.location;

    axios
      .get(
        `${urllink}HRmanagement/get_employeeperformance?location=${location}`
      )
      .then((response) => {
        const data = response.data;

        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            Date: userdata.Date,
            Current_Payment: userdata.Current_Payment,
            Performance_Rate: userdata.Performance_Rate,
            Hike_Percentage: userdata.Hike_Percentage + "%",
            Hike_Amount: userdata.Hike_Amount,
            New_Pay: userdata.New_Pay,
            Remarks: userdata.Remarks,
            ApprovedBy: userdata.ApprovedBy,
            Location: userdata.Location,
          }));
          setRows(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord]);

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
  };

  const handleReasonChange = (e) => {
    const newReason = e.target.value;
    setReason(newReason);
  };
  const handleInstallmentChange = (e) => {
    const newReason = e.target.value;
    setInstallment(newReason);
  };

  const handleissuedByChange = (e) => {
    const issued = e.target.value;
    setIssued(issued);
  };

  const handleSubmit = () => {
    const apiUrl = `${urllink}HRmanagement/update_advance_approval_Edit`;
    const submissionData = {
      Sl_No: selectedRowData.Sl_No,
      status: status,
      reason: reason,
      installment: installment,
      issuedBy: issuedby,
    };

    axios
      .post(apiUrl, submissionData)
      .then((response) => {
        alert("Leave status updated successfully");
        setOpenModal(false);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Performance Management </h4>
      </div>

      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="input">
            Employee Name <span>:</span>
          </label>
          <input
            type="text"
            id="date"
            name="employeeName"
            placeholder="Enter Employee Name"
          />
        </div>
        <div className="inp_1">
          <label htmlFor="input">
            Designation <span>:</span>
          </label>
          <select
            name="designation"
            className="new-custom-input-phone vital_select"
            id=""
          >
            <option value="alldesignation"> Select</option>
            <option value="doctor">Doctor</option>
            <option value="Nurse"> Nurse</option>
            <option value="frontoffice">Front Office </option>
          </select>
        </div>
        <div className="inp_1">
          <label htmlFor="input">
            Date <span>:</span>
          </label>
          <input type="date" id="date" />
        </div>
        <button className="btn_1">
          <SearchIcon />
        </button>
      </div>

      <ThemeProvider theme={theme}>
        <div style={{ height: 400, width: "100%" }}>
          <div
            className="grid_1 "
            style={{ overflowX: "auto", maxWidth: "100%" }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              hideFooter={true}
              isCellEditable={(params) => params.row.id !== 1}
            />
          </div>
        </div>
      </ThemeProvider>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwProfiles1122"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="editcont">
              <div className="RegisFormcon leavecon">
                <div className="RegisForm_1 leaveform_1">
                  <label htmlFor="issued">
                    Status<span>:</span>
                  </label>
                  <select
                    name="approval"
                    id="approval"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="">Select</option>
                    {oldStatus !== "Approved" && (
                      <option value="Approved">Approved</option>
                    )}
                    {oldStatus !== "Not Approved" && (
                      <option value="Not Approved">Not Approved</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="RegisFormcon leavecon">
                <div className="RegisForm_1 leaveform_1">
                  <label htmlFor="installment">
                    No.of Installment <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="installment"
                    id="installment"
                    placeholder="Enter Issuever Name"
                    value={installment}
                    onChange={handleInstallmentChange}
                  />
                </div>
              </div>
            </div>
            <div className="editcont">
              <div className="RegisFormcon leavecon">
                <div className="RegisForm_1 leaveform_1">
                  <label htmlFor="issued">
                    Issued By <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="issued"
                    id="issued"
                    placeholder="Enter Issuever Name"
                    value={issuedby}
                    onChange={handleissuedByChange}
                  />
                </div>
              </div>
              {status === "Not Approved" && (
                <div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1 ">
                    <label htmlFor="reason">
                      Reason <span>:</span>
                    </label>
                    <textarea
                      type="text"
                      name="reason"
                      id=""
                      onChange={handleReasonChange}
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
            <div className="Register_btn_con regster_btn_contsai">
              <button className="RegisterForm_1_btns" onClick={handleSubmit}>
                Submit
              </button>
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PerformanceManagement;
