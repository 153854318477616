import React, { useEffect, useState } from "react";
import axios from 'axios';
import { FaUser, FaLock, FaEyeSlash } from "react-icons/fa";
import { FaEye, FaLocationDot } from "react-icons/fa6";
import bgImg2 from "../assets/bgImg2.jpg";
import "../LoginPage/LoginPage.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const [ClinicDetails, setClinicDetails] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");
  const [locationoptions, setlocationOptions] = useState([]);
  const [type, setType] = useState("Employee");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const handleUsernameChange = (event) => {
    const value = event.target.value.trim();
    setUsername(value);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value.trim();
    setPassword(value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlelocationchange = (event) => {
    const value = event.target.value.trim();
    setLocation(value);
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;
          setClinicDetails(firstClinic);
        } else {
          console.error("No record found");
        }
      })
      .catch((error) => console.error("Error fetching data"));
  }, []);

  useEffect(() => {
    if (username !== "") {
      let timer = setTimeout(() => {
        axios
          .get(
            `${urllink}usercontrol/getlocationdataforlogin?username=${username}`
          )
          .then((response) => {
            const data = response.data;
            if (data) {
              setlocationOptions(data);
            }
          })
          .catch((error) => {
            console.error("Error fetching Location options:", error);
          });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [username]);

  const handleLoginFormSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${urllink}loginpage/login`, {
        username,
        password,
        location: type !== "Employee" ? null : location,
      })
      .then((response) => {
        const data = response.data;
        if (data.success) {
          successMsg("Login Successful");
          localStorage.setItem("token", data.token);
          const userRecord = localStorage.getItem("token");
          const decodedToken = (token) => {
            const payloadBase64 = token.split(".")[1];
            const decodedPayload = atob(payloadBase64);
            return JSON.parse(decodedPayload);
          };
          const decodedTokenData = decodedToken(userRecord);
          if (decodedTokenData.Patient_id) {
            setTimeout(() => {
              navigate("/Home/PatientView");
            }, 1000);
          } else {
            setTimeout(() => {
              navigate("/Home/ClinicMetrics");
            }, 1000);
          }
        } else {
          userwarn("Incorrect Password or Username.");
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        errmsg("An error occurred during login");
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };
  useEffect(() => {
    setUsername("");
    setPassword("");
    setLocation("");
  }, [type]);

  return (
    <>
      <section className="register-container">
        <div className="register">
          <div className="col-1">
            <img src={bgImg2} alt="" />
          </div>
          <div className="col-2">
            <form className="flex-col" onSubmit={handleLoginFormSubmit}>
              <div className="login-logo">
                {ClinicDetails.clinicLogo && (
                  <img
                    src={`data:image/*;base64,${ClinicDetails.clinicLogo}`}
                    alt={ClinicDetails.clinicName}
                    className="rounded-logo-img"
                  />
                )}
              </div>

              <ToggleButtonGroup
                value={type}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton
                  value="Employee"
                  style={{
                    height: "30px !important",
                    width: "130px !important",
                    backgroundColor:
                      type === "Employee"
                        ? "var(--selectbackgroundcolor)"
                        : "inherit",
                  }}
                  className="togglebutton_container_login"
                >
                  Employee
                </ToggleButton>
                <ToggleButton
                  value="Patient"
                  style={{
                    backgroundColor:
                      type === "Patient"
                        ? "var(--selectbackgroundcolor)"
                        : "inherit",
                    height: "30px !important",
                    width: "130px !important",
                  }}
                  className="togglebutton_container_login"
                >
                  Patient
                </ToggleButton>
              </ToggleButtonGroup>

              <div className="input-group">
                <FaUser className="input-icon" />
                <input
                  type="text"
                  id="username"
                  name="username"
                  onChange={handleUsernameChange}
                  placeholder="Enter your username"
                  required
                  autoFocus
                  value={username}
                />
              </div>

              <div className="input-group">
                <FaLock className="input-icon" />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="passd321"
                  placeholder="Enter your Password"
                  required
                />
                <span
                  className="toggle-password"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>

              {type !== "Employee" ? null : (
                <div className="input-group">
                  <FaLocationDot className="input-icon loctn-big" />
                  <select
                    id="location"
                    name="location"
                    onChange={handlelocationchange}
                    required
                  >
                    <option value={""}>Select</option>
                    {locationoptions.map((loc) => (
                      <option key={loc.location_id} value={loc.location_name}>
                        {loc.location_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <button type="submit" className="login-btn">
                Login
              </button>
            </form>

            <ToastContainer />
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
