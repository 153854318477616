
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RateCardMaster.css";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function RateCardMaster() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [doctorOptions, setDoctorOptions] = useState([]);

  const [ServiceData, setServiceData] = useState([]);
  const [ServiceEdit, setServiceEdit] = useState(false);


  const [formData1, setFormData1] = useState({
    ServiceId: "",
    ServiceType: "",
    DoctorName: "",
    ProcedureName: "",
    GeneralCharge: "",
    Complimentary: false,
    GSTCharge: "",
    CompliProcedure: "",
    SACcode:"",
    CompSessions:'',
  });

  console.log("formData1",formData1);

  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleFormChange1 = (e) => {
    
    const { name, type, checked, value } = e.target;

    if (name === "ServiceType") {
      if (value === "DoctorConsultation") {
        setFormData1((prevFormData) => ({
          ...prevFormData,
          ServiceType: value,
          ProcedureName: "Null",
          DoctorName: "",
        }));
      } 
      else if (value === "Procedure") {
        setFormData1((prevFormData) => ({
          ...prevFormData,
          ServiceType: value,
          ProcedureName: "",
          DoctorName: "Null",
        }));
      }
    } 
    else if (name === "ProcedureName" || name === "SACcode") {
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: value.toUpperCase(),
      }));
    }
    else if (name === "Complimentary") {
      const newValue = type === 'checkbox' ? checked : value;
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    }
    else {
      setFormData1((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };





useEffect(()=>{

getServicedata()

},[userRecord.location])


const getServicedata =()=>{
  if(Object.keys(userRecord).length !==0){

    axios.get(`${urllink}usercontrol/get_service_charge?Location=${userRecord?.location}`)
    .then((res)=>{
  
      console.log('output',res.data)
  
      if(res.data.length!==0){
        setServiceData(res.data)
      }else{
        setServiceData([])
      }
    })
    .catch((err)=>{
      console.log(err);
    })
  
  }
}



  const handleAddService = () => {

    let requiredFields = [
      "ServiceType",
      "DoctorName",
      "ProcedureName",
      "GeneralCharge",
      "GSTCharge",
      "SACcode",
    ];

    if (formData1.Complimentary === true) {
      requiredFields.push(
        "CompliProcedure",
        "CompSessions"
      );
    }


    const missingFields = requiredFields.filter(field => !formData1[field]);

    if (
      missingFields.length === 0) {
      const nnn={
        ...formData1,
        Location:userRecord?.location
      }
      axios
        .post(`${urllink}usercontrol/insert_RateCard_Service_Charge`, nnn)
        .then((response) => {
          if(response.data?.warn){
            userwarn(response.data?.warn);
          }else if(response.data?.message){
            successMsg(response.data?.message);
          }          
          setServiceEdit(!ServiceEdit);
          getServicedata()
        })
        .catch((error) => {
          console.error("Error adding service:", error);
        });
     

      setFormData1({
        ServiceId:'',
        ServiceType: "",
        DoctorName: "",
        ProcedureName: "",
        GeneralCharge: "",
        Complimentary: false,
        GSTCharge: "",
        CompliProcedure: "",
        SACcode:"",
        CompSessions:'',
      });

    } else {
      userwarn(`Please fill required fields: ${missingFields.join(', ')}`);
    }
  };

 


  const handleEditService = (index) => {

    console.log("index",index);
    setFormData1({...index,
      CompSessions:+index.CompSessions,
      GeneralCharge:+index.GeneralCharge,
      GSTCharge:+index.GSTCharge,
    });
    
  };




  useEffect(() => {
    const location = userRecord?.location;
    axios
      .get(`${urllink}usercontrol/get_doctor_info?location=${location}`)
      .then((response) => {
        const fetchedDoctors = response.data.map((doctor, index) => ({
          id: index,
          name: doctor,
        }));
        setDoctorOptions(fetchedDoctors);
      })
      .catch((error) => {
        console.error("Error fetching doctor information:", error);
      });
  }, [userRecord]);







// important ---------------------------

  // const handleCsvupload = (type) => {
  //   const formData = new FormData();
  //   formData.append("file", SelectedFile);

  //   if (SelectedFile) {
  //     if (type === "Service") {
  //       axios
  //         .post(
  //           `${urllink}usercontrol/post_RateCard_Service_Charge_csvto_sql_link_route`,
  //           formData,
  //           { headers: { "Content-Type": "multipart/form-data" } }
  //         )
  //         .then((response) => {
  //           setSelectedFile(null);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     } else if (type === "Client") {
  //       axios
  //         .post(
  //           `${urllink}usercontrol/post_RateCard_Client_Charge_csvto_sql_link_route`,
  //           formData,
  //           { headers: { "Content-Type": "multipart/form-data" } }
  //         )
  //         .then((response) => {
  //           setSelectedFile(null);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     } else if (type === "Insurance") {
  //       axios
  //         .post(
  //           `${urllink}usercontrol/post_RateCard_Insurance_Charge_csvto_sql_link_route`,
  //           formData,
  //           { headers: { "Content-Type": "multipart/form-data" } }
  //         )
  //         .then((response) => {
  //           setSelectedFile(null);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     }
  //   }
  // };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Rate Card Charges</h4>
      </div>

      <div sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Service Type<span>:</span>{" "}
                </label>
                <select
                  name="ServiceType"
                  value={formData1.ServiceType}
                  onChange={handleFormChange1}
                >
                  <option value="">Select</option>
                  <option value="DoctorConsultation">
                    Doctor Consultation
                  </option>
                  <option value="Procedure">Procedure</option>
                </select>
              </div>

              {formData1.ServiceType === "Procedure" ? (
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Procedure Name<span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="ProcedureName"
                    required
                    value={formData1.ProcedureName}
                    onChange={handleFormChange1}
                  />
                </div>
              ) : (
                <div className="RegisForm_1">
                  <label htmlFor="Title">
                    Doctor Name <span>:</span>
                  </label>
                  <select
                    name="DoctorName"
                    value={formData1.DoctorName}
                    onChange={handleFormChange1}
                  >
                    <option value="">Select</option>
                    {doctorOptions.map((doctor) => (
                      <option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
               <div className="RegisForm_1">
                <label>
                  {" "}
                  SAC code<span>:</span>{" "}
                </label>
                <input
                  type="text"
                  name="SACcode"
                  required
                  value={formData1.SACcode}
                  onChange={handleFormChange1}
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  {" "}
                Charge<span>:</span>{" "}
                </label>
                <input
                  type="number"
                  name="GeneralCharge"
                  required
                  value={formData1.GeneralCharge}
                  onChange={handleFormChange1}
                />
              </div>

              {formData1.ServiceType === "Procedure" ?<>
              <div className="RegisForm_1">
              <label>
               Complimentary<span>:</span>{" "}
              </label>
              <input
                type="checkbox"
                name="Complimentary"
                checked={formData1.Complimentary}
                onChange={handleFormChange1}
              />
              </div>

              {formData1.Complimentary && (
                <>
                <div className="RegisForm_1">
                  <label>
                    Complimentary Procedure <span>:</span>{" "}
                  </label>
                  <input

                    list="procedure-options"
                    name="CompliProcedure"
                    required
                    value={formData1.CompliProcedure}
                    onChange={handleFormChange1}
                  />
                  <datalist id="procedure-options">
                    {ServiceData.filter(service => service.ProcedureName !== 'Null').map((service, index) => (
                      <option key={index} value={service.ProcedureName} />
                    ))}
                  </datalist>
                </div>


                <div className="RegisForm_1">
                <label>
                Complimentary Sessions<span>:</span>{" "}
                </label>
                <input
                  type="number"
                  name="CompSessions"
                  value={formData1.CompSessions}
                  onChange={handleFormChange1}
                />
               </div>
              
              </>
              )}
              </> : <></>}

              <div className="RegisForm_1">
                <label>
                  {" "}
                  GST Charge <span>:</span>{" "}
                </label>
                <select
                name="GSTCharge"
                placeholder="Enter Tax Percentage"
                value={formData1.GSTCharge}
                onChange={handleFormChange1}
              >
                <option value="">Select</option>
                <option value={0}>Nill</option>
                <option value={28}>28 %</option>
                <option value={18}>18 %</option>
                <option value={12}>12 %</option>
                <option value={5}>5 %</option>
              </select>
              </div>




              {/* <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="Servicechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="Servicechoose"
                  className="RegisterForm_1_btns choose_file_update"
                >
                  Choose File
                </label>

                <button
                  className="RegisterForm_1_btns choose_file_update"
                  onClick={() => handleCsvupload("Service")}
                >
                  Upload
                </button>
              </div> */}

            </div>
            <br/>
            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={handleAddService}
              >
                {formData1.ServiceId?"Update" : "Add"}
              </button>
            </div>
            <div className="Selected-table-container">
              <div className="selected-medicine-table2">
                <table>
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Service Id</th>
                      <th id="slectbill_ins">Service Type</th>
                      <th id="slectbill_ins">Doctor Name</th>
                      <th id="slectbill_ins">Procedure Name</th>
                      <th id="slectbill_ins">SAC Code</th>
                      <th id="slectbill_ins">General Charge</th>
                      <th id="slectbill_ins">GST Charge</th>
                      <th id="slectbill_ins">Complimentary</th>
                      <th id="slectbill_ins">Complimentary <br/>Procedure</th>                      
                      <th id="slectbill_ins">Complimentary <br/>Sessions</th>
                      <th id="slectbill_ins">Location</th>
                      <th id="slectbill_ins">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ServiceData.map((client, index) => (
                      <tr key={index}>
                        <td>{client.ServiceId}</td>
                        <td>{client.ServiceType}</td>
                        <td>{client.DoctorName}</td>
                        <td>{client.ProcedureName}</td>
                        <td>{client.SACcode}</td>
                        <td>{client.GeneralCharge}</td>
                        <td>{client.GSTCharge}</td>
                        <td>{client.Complimentary}</td>
                        <td>{client.CompliProcedure}</td>
                        <td>{client.CompSessions}</td>
                        <td>{client.Location}</td>
                        <td>
                          <button
                            onClick={() => handleEditService(client)}
                            className="ratecarededitbutton"
                          >
                            <EditIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      

      
      <ToastContainer />
    </div>
  );
}
