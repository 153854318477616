import React, { useState, useEffect, useRef } from "react";
import "./TreatmentComponent.css";
import "./Prescription.css";
import axios from "axios";
import Canva from "./Canva";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Preview from "./Preview";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

function Treatment() {

  const dispatchvalue = useDispatch();
  const getformData = useSelector(
    (state) => state.userRecord?.workbenchformData
  );
  const userRecord = useSelector((state) => state.userRecord?.UserData);


  const formgenesis = useSelector((state) => state.userRecord?.workbenchformData);
  console.log("123456789", formgenesis);
  

  const isnewSidebarOpen = useSelector(
    (state) => state.userRecord?.isSidebarOpen
  );

  const create = userRecord?.username;
  const Location = userRecord?.location;
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  

  // prescription.......................................................................................

  const navigate = useNavigate();

  const treatmentcomplain =useSelector((state)=>state.userRecord?.Complaint)
  console.log(treatmentcomplain);
  const [type, setType] = useState("Intake");

  const [appointmentDate1, setAppointmentDate1] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [openModal2, setOpenModal2] = useState(false);
  const [modalContent, setModalContent] = useState("");
  
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const openModal = (content) => {
    setModalContent(content);
    setOpenModal2(true);
  };

  const [formDataPrescription, setFormDataPrescription] = useState({
    Complaint:treatmentcomplain,
    GenericName: "",
    ItemName: "",
    dose: "",
    // route: "",
    qty: "",
    instruction: "",
    frequency: "",
    notes: "",
    durationNumber: "",
    durationUnit: "",
    itemtype: "",
  });


  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [issuedmedicine, setIssuedmedicine] = useState([]);
  const [prevmedicine, setPrevmedicine] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [genericName, setgenericName] = useState([]);
  const [itemName, setitemName] = useState([]);
  const [getdatass, setgetdatass] = useState(false);
  const [prodData, setProdData] = useState([]);

  const {
    GenericName,
    ItemName,
    dose,
    // route,
    qty,
    instruction,
    notes,
    frequency,
    durationNumber,
    durationUnit,
    itemtype,
  } = formDataPrescription;

  const handlePageChange = (event, newType) => {
    if (newType !== null && newType !== type) {
      setType(newType);
    }
  };
  useEffect(() => {
    axios
      .get(`${urllink}quickstockreceive/getgenericname`)
      .then((response) => {
        // Process the response data as needed
        setgenericName(response.data);
      })
      .catch((error) => {
        console.error("Error fetching generic names:", error);
        // Handle the error, e.g., show an error message to the user
      });
  }, []);

  useEffect(() => {
    const selectedGenericName = formDataPrescription.GenericName;

    axios
      .get(
        `${urllink}quickstockreceive/getitemname_bygenericname?genericName=${selectedGenericName}&location=${Location}`
      )
      .then((response) => {
        setitemName(response.data);
      })
      .catch((error) => {
        console.error("Error fetching item names:", error);
      });
  }, [formDataPrescription.GenericName, Location]);
  useEffect(() => {
    axios
      .get(`${urllink}SupplierMaster/getProductData`)
      .then((response) => {
        const data = response.data;
        setProdData(data);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, [urllink])

  // Fetch UOM when the selected item name changes
  useEffect(() => {
    const selectedItemName = formDataPrescription.ItemName;

    axios
      .get(
        `${urllink}quickstockreceive/getDose_By_Itemname?selectedItemname=${selectedItemName}&location=${Location}`
      )
      .then((response) => {
        const itemData = response.data[0];
        setFormDataPrescription((prev) => ({
          ...prev,
          dose: itemData?.dose,
          itemtype: itemData?.Pack_type,
        }));
      })
      .catch((error) => {
        console.error("Error fetching UOM:", error);
      });
  }, [formDataPrescription.ItemName]);

  useEffect(() => {
    const patientid = formgenesis.PatientID;
    const visitNo = formgenesis.visitNo;

    axios
      .get(
        `${urllink}doctorsworkbench/get_prescriptionforworkbench?patientid=${patientid}&visitid=${visitNo}&location=${Location}`
      )
      .then((response) => {
        setIssuedmedicine(response.data);
      })
      .catch((error) => {
        console.error("Error fetching UOM:", error);
      });
  }, [formgenesis, getdatass]);

  useEffect(() => {
    const patientid = formgenesis.PatientID;
    const visitNo = formgenesis.visitNo;

    axios
      .get(
        `${urllink}doctorsworkbench/get_previouse_prescription?patientid=${patientid}&visitid=${visitNo}&location=${Location}`
      )
      .then((response) => {
        setPrevmedicine(response.data);
      })
      .catch((error) => {
        console.error("Error fetching UOM:", error);
      });
  }, [formgenesis, getdatass]);


  useEffect(() => {
    console.log(formDataPrescription.Complaint)
    axios.get(`${urllink}doctorsworkbench/get_by_compalaint_prescription?complaint=${formDataPrescription.Complaint}`,)
        .then((response) => {
            const data = response.data;
            if (Array.isArray(data)) {
              setSelectedMedicines(data);
          } else {
              console.error("Fetched data is not an array:", data);
              setSelectedMedicines([]);
          }
           
        })
        .catch((error) => {
            console.error("Error fetching treatment data:", error);
        });
  }, [urllink,formDataPrescription.Complaint]);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate1(currentDate);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    let updatedValue = value;
  
    if (name === "frequency") {
      updatedValue = updatedValue.replace(/[^\d-]/g, ""); // Remove any non-digit or non-hyphen characters
      const parts = updatedValue.split("-");
      if (parts.length > 3) {
        parts.length = 3; // Ensure there are at most 3 parts
      }
      updatedValue = parts.map((part) => part.slice(0, 1)).join("-"); // Ensure each part has at most 1 digit
    }
  
    // Update form data with the changed field
    const updatedFormData = {
      ...formDataPrescription,
      [name]: updatedValue,
    };
  
    setFormDataPrescription(updatedFormData);
  
    console.log(`Updated ${name}:`, updatedValue);
    console.log("Updated Form Data:", updatedFormData);
  
    const {
      frequency,
      durationNumber,
      durationUnit,
      itemtype = "", // Default to an empty string if itemtype is undefined
    } = updatedFormData;
  
    if (name === "GenericName" && updatedValue === "") {
      // Reset all fields to their initial empty state
      setFormDataPrescription({
        GenericName: "",
        ItemName: "",
        dose: "",
        // route: "",
        qty: "",
        instruction: "",
        notes: "",
        frequency: "",
        durationNumber: "",
        durationUnit: "",
        itemtype: "",
      });
      return; // Exit function after resetting the form
    }
  
    if (itemtype.toLowerCase() === "tablet" || itemtype.toLowerCase() === "tablets") {
      if (["frequency", "durationNumber", "durationUnit"].includes(name)) {
        // Check if frequency, duration number, or duration unit is changed
  
        // If duration number or unit is not selected, set qty to empty
        if (!durationNumber || !durationUnit) {
          setFormDataPrescription((prevData) => ({
            ...prevData,
            qty: "",
          }));
          return; // Exit the function
        }
  
        // Split frequency into morning, afternoon, and night frequencies
        const [morning = "0", afternoon = "0", night = "0"] = frequency.split("-");
  
        let times1 = (parseInt(morning) || 0) + (parseInt(afternoon) || 0) + (parseInt(night) || 0);
        let times = 1;
  
        switch (durationUnit) {
          case "days":
            times = parseInt(durationNumber);
            break;
          case "weeks":
            times = parseInt(durationNumber) * 7;
            break;
          case "months":
            times = parseInt(durationNumber) * 30;
            break;
          default:
            return;
        }
  
        const qty = times1 * times;
  
        setFormDataPrescription((prevData) => ({
          ...prevData,
          qty: qty.toString(),
        }));
      }
    }
  };
  
  const validateForm = () => {
    const requiredFields = [
      "GenericName",
      "ItemName",
      "dose",
      // "route",
      "qty",
      // "instruction",
      "frequency",
      "durationNumber",
      "durationUnit",
    ];

    // Access the current form data from the state
    const currentFormData = formDataPrescription;

    const emptyFields = requiredFields.filter(
      (field) => !currentFormData[field]
    );

    if (emptyFields.length > 0) {
      alert(`Fields ${emptyFields.join(", ")} cannot be empty.`);
      return false;
    }

    return true;
  };
  const addMedicine = () => {
   

    const {
      GenericName,
      ItemName,
      dose,
      // route,
      qty,
      instruction,
      notes,
      frequency,
      durationNumber,
      durationUnit,
      itemtype,
    } = formDataPrescription;

    const medicineData = {
      id: selectedMedicines.length + 1,
      GenericName,
      ItemName,
      dose,
      // route,
      qty,
      instruction,
      notes,
      frequency,
      durationNumber,
      durationUnit,
      itemtype,
    };

    const isDuplicate = selectedMedicines.some(
      (medicine) => medicine.ItemName === medicineData.ItemName
    );

    if (isDuplicate) {
      warnmsg("Medicine with the same Item Name is already added.");
    } else {
      setSelectedMedicines([...selectedMedicines, medicineData]);
    }

    // Clear form data after adding
    setFormDataPrescription({
      GenericName: "",
      ItemName: "",
      dose: "",
      // route: "",
      qty: "",
      instruction: "",
      notes: "",
      frequency: "",
      durationNumber: "",
      durationUnit: "",
      itemtype: "",
    });
    // After adding the medicine, navigate to "View Drugs" section
  };



  const updateMedicine = () => {
    

    const updatedMedicines = [...selectedMedicines];
    updatedMedicines[editIndex] = {
      id: selectedMedicines[editIndex].id,
      GenericName,
      ItemName,
      dose,
      // route,
      qty,
      instruction,
      notes,
      frequency,
      durationNumber,
      durationUnit,
      itemtype,
    };

    setSelectedMedicines(updatedMedicines);
    setEditIndex(null);

    // Clear form data after updating
    setFormDataPrescription({
      GenericName: "",
      ItemName: "",
      dose: "",
      // route: "",
      qty: "",
      instruction: "",
      notes: "",
      frequency: "",
      durationNumber: "",
      durationUnit: "",
      itemtype: "",
    });
  };
  const addprevmedicine = (index) => {
    const prevMedicine = prevmedicine[index];
    setFormDataPrescription({ ...prevMedicine });
    setEditIndex(null); // Set to null since it's a new addition, not an edit
  };

  const handleEditMedicine = (index) => {
    // Set form data with selected medicine for editing
    const selectedMedicine = selectedMedicines[index];
    setFormDataPrescription({ ...selectedMedicine });
    setEditIndex(index);
  };

  const handleDeleteMedicine = (index) => {
    const updatedMedicines = selectedMedicines.filter((_, i) => i !== index);
    setSelectedMedicines(updatedMedicines);
    setEditIndex(null);
  };





  const handleSave = () => {
    // Log Blobs for verification
      const dataToSend = selectedMedicines.map((p) => ({
        PatientID: formgenesis.PatientID,
        appointmentDate: formgenesis.AppointmentDate,
        AppointmentID: formgenesis.AppointmentID,
        visitNo: formgenesis.visitNo,
        GenericName: p.GenericName,
        ItemName: p.ItemName,
        itemtype: p.itemtype,
        dose: p.dose,
        // route: p.route,
        notes: p.notes,
        frequency: p.frequency,
        duration: `${p.durationNumber} ${p.durationUnit}`,
        qty: p.qty,
        instruction: p.instruction,
        createdBy: create,
        location: Location,
        complaint:treatmentcomplain,
       
      }));

    
  
      if (dataToSend.length !== 0) {
        
        axios
          .post(`${urllink}doctorsworkbench/insert_prescription`, {
            data: dataToSend,
            procedurestatus: 'Prescription Issued', // Include the status in the request payload
          })
          .then((response) => {
            if (response.data.duplicate_item_names) {
              const duplicateItems =
                response.data.duplicate_item_names.join(", ");
              warnmsg(`Duplicate ItemNames found: ${duplicateItems}`);
            } else {
              console.log(response.data.message);
              successMsg(response.data.message);
              setSelectedMedicines([]);
              setType("Output");
              setgetdatass((prev) => !prev);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        warnmsg("No Prescription Data To Save");
      }

  };

 

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const warnmsg = (errorMessage) => {
    toast.warn(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

 

  return (
    <>
      <div className="appointment">
      

        {/* <div className="treatcon_body_1 with-icd-his">
            <label htmlFor="history">
                 Patient Photo <span>:</span>
                </label>
              </div> */}

        <br />
        <div className="RegisFormcon" style={{justifyContent:'center'}}>
          <div className="RegisForm_1 input-with-icon">
            <label htmlFor="history">
              Chief Complaints <span>:</span>
            </label>
            <div className="input-container">
              <input
                
                id="Complaint"
                name="Complaint"
                value={formDataPrescription.Complaint}
                onChange={handleChange}
              />
            </div>
          </div>



          {/* <div className="RegisForm_1">
            <label htmlFor="history">
              History <span>:</span>
            </label>
            <textarea
              id="history"
              name="history"
              cols="25"
              rows="3"
              className="RegisForm_1 textarea"
              value={historyValue}
              onChange={(e) => setHistoryValue(e.target.value)}
            ></textarea>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="examination">
              Examination <span>:</span>
            </label>
            <textarea
              id="examination"
              name="examination"
              cols="25"
              rows="3"
              value={examinationValue}
              onChange={(e) => setExaminationValue(e.target.value)}
            ></textarea>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="diagnosis">
              Diagnosis <span>:</span>
            </label>
            <textarea
              id="diagnosis"
              name="diagnosis"
              cols="25"
              rows="3"
              value={diagnosisValue}
              onChange={(e) => setDiagnosisValue(e.target.value)}
            ></textarea>
          </div> */}

        </div>


      </div>

      <br />
      <div className="for">
      <div className="RegisFormcon">
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handlePageChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="Intake"
              style={{
                height: "30px",
                width: "180px",
                backgroundColor:
                  type === "Intake"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
              }}
              className="togglebutton_container"
            >
              Add Drugs
            </ToggleButton>
            <ToggleButton
              value="Output"
              style={{
                backgroundColor:
                  type === "Output"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
                width: "180px",
                height: "30px",
              }}
              className="togglebutton_container"
            >
              View Drugs
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {type === "Intake" && (
          <>
            <div className="RegisFormcon">
              {prevmedicine.length > 0 && (
                <div className="for">
                  <div className="Add_items_Purchase_Master">
                    <span>Previous Visit Medicine</span>
                  </div>
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2">
                      <thead>
                        <tr>
                          <th id="slectbill_ins">GenericName</th>
                          <th id="slectbill_ins">ItemName</th>
                          <th id="slectbill_ins">Item Type</th>
                          <th id="slectbill_ins">Dose</th>
                          <th id="slectbill_ins">Notes</th>
                          <th id="slectbill_ins">Frequency</th>
                          <th id="slectbill_ins">Duration</th>
                          <th id="slectbill_ins">Qty</th>
                          <th id="slectbill_ins">Instruction</th>
                          <th id="slectbill_ins">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prevmedicine?.map((previnfo, index) => (
                          <tr key={index}>
                            <td>{previnfo.GenericName}</td>
                            <td>{previnfo.ItemName}</td>
                            <td>{previnfo.itemtype}</td>
                            <td>{previnfo.dose}</td>
                            <td>{previnfo.notes}</td>
                            <td>{previnfo.notes}</td>
                            <td>{previnfo.frequency}</td>
                            <td>
                              {previnfo.durationNumber} {previnfo.durationUnit}
                            </td>
                            <td>{previnfo.qty}</td>
                            <td>{previnfo.instruction}</td>
                            <td>
                              <button
                                className="delnamebtn"
                                onClick={() => addprevmedicine(index)}
                              >
                                <AddIcon />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <br></br>

              <div className="RegisFormcon">

             
          
       
                <div className="RegisForm_1">
                  <label htmlFor="title">
                    Generic Name<span>:</span>
                  </label>
                  <input
                    id="medicine"
                    name="GenericName"
                    value={formDataPrescription.GenericName}
                    onChange={handleChange}
                    list="GenericName-options"
                    autoComplete="off"
                  />
                  <datalist id="GenericName-options">
                    <option value="">Select</option>
                    {genericName.map((item, index) => (
                      <option key={index} value={item.GenericName}></option>
                    ))}
                  </datalist>
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="title">
                    Item Name<span>:</span>
                  </label>
                  <input
                    id="medicine"
                    name="ItemName"
                    value={formDataPrescription.ItemName}
                    onChange={handleChange}
                    list="ItemName-options"
                    autoComplete="off"
                  />
                  <datalist id="ItemName-options">
                    <option value="">Select</option>
                    {itemName.map((item, index) => (
                      <option key={index} value={item.ItemName}>
                        {`${item.ItemCode} | Av.Qty : ${item.AvailableQuantity} `}
                      </option>
                    ))}
                  </datalist>

                </div>
                <div className="RegisForm_1">
                  <label htmlFor="itemtype">
                    Item Type<span>:</span>
                  </label>
                  {formDataPrescription.itemtype ? (
                    <input
                      id="itemtype"
                      name="itemtype"
                      value={formDataPrescription.itemtype}
                      onChange={handleChange}
                    />
                  ) : (
                    <select
                      id="itemtype"
                      name="itemtype"
                      value={formDataPrescription.itemtype || 'default'}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {prodData.map((option) => (
                        <option key={option.prod_id} value={option.productType} >
                          {option.productType}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="dose">
                    Dose<span>:</span>
                  </label>
                  <input
                    id="dose"
                    name="dose"
                    value={formDataPrescription.dose}
                    onChange={handleChange}
                  />
                </div>

                <div className="RegisForm_1">
                  <label>
                    Frequency<span>:</span>
                  </label>

                  <select
                    id="frequency"
                    name="frequency"
                    rows="2"
                    style={{ width: "72px" }}
                    value={formDataPrescription.frequency}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="0-0-1">0-0-1</option>
                    <option value="0-1-1">0-1-1</option>
                    <option value="1-1-1">1-1-1</option>
                    <option value="1-1-0">1-1-0</option>
                    <option value="1-0-1">1-0-1</option>
                  </select>

                  <select
                    id="notes"
                    name="notes"
                    style={{ width: "85px" }}
                    value={formDataPrescription.notes}
                    onChange={handleChange}
                  >
                    <option value="" style={{ color: 'grey !important' }}> Select</option>
                    <option value="AfterFood">After Food</option>
                    <option value="BeforeFood">Before Food</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="duration">
                    Duration<span>:</span>
                  </label>
                  <input
                    id="durationNumber"
                    name="durationNumber"
                    className="dura_with1"
                    value={formDataPrescription.durationNumber}
                    onChange={handleChange}
                  ></input>
                  <select
                    id="durationUnit"
                    name="durationUnit"
                    className="dura_with"
                    value={formDataPrescription.durationUnit}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="days">Days</option>
                    <option value="weeks">Weeks</option>
                    <option value="months">Months</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="title">
                    Qty<span>:</span>
                  </label>
                  <input
                    id="qty"
                    name="qty"
                    value={formDataPrescription.qty}
                    onChange={handleChange}
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="instruction">
                    Instruction<span>:</span>
                  </label>
                  <textarea
                    id="instruction"
                    name="instruction"
                    rows="2"
                    value={formDataPrescription.instruction}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="Register_btn_con">
                <button
                  className="RegisterForm_1_btns"
                  type="button"
                  onClick={editIndex !== null ? updateMedicine : addMedicine}
                >
                  {editIndex !== null ? "Update " : "Add "}
                </button>
              </div>
              
                <div className="for">
                  <div className="h_head">
                    <span>Selected Medicine</span>
                  </div>
                  <div className="Selected-table-container">
                    <table className="selected-medicine-table2">
                      <thead>
                        <tr>
                          <th id="slectbill_ins">GenericName</th>
                          <th id="slectbill_ins">ItemName</th>
                          <th id="slectbill_ins">Item Type</th>
                          <th id="slectbill_ins">Dose</th>
                          <th id="slectbill_ins">notes</th>
                          <th id="slectbill_ins">Frequency</th>
                          <th id="slectbill_ins">Duration</th>
                          <th id="slectbill_ins">Qty</th>
                          <th id="slectbill_ins">Instruction</th>
                          <th id="slectbill_ins">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedMedicines && selectedMedicines.map((medicineInfo, index) => (
                          <tr key={index}>
                            <td>{medicineInfo.GenericName}</td>
                            <td>{medicineInfo.ItemName}</td>
                            <td>{medicineInfo.itemtype}</td>
                            <td>{medicineInfo.dose}</td>
                            <td>{medicineInfo.notes}</td>
                            <td>{medicineInfo.frequency}</td>
                            <td>
                              {medicineInfo.durationNumber}{" "}
                              {medicineInfo.durationUnit}
                            </td>
                            <td>{medicineInfo.qty}</td>
                            <td>{medicineInfo.instruction}</td>
                            <td>
                              <button
                                className="delnamebtn"
                                onClick={() => handleEditMedicine(index)}
                              >
                                <EditIcon />
                              </button>
                              <button
                                className="delnamebtn"
                                onClick={() => handleDeleteMedicine(index)}
                              >
                                <DeleteIcon />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              

              {selectedMedicines.length > 0 && (
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    type="button"
                    onClick={handleSave}
                  >
                    Submit
                  </button>
                </div>
              )}
               <div className="Register_btn_con">
            <button
                onClick={() => openModal("calendar")}
                className="RegisterForm_1_btns"
              >
                Preview
              </button>
              </div>
              {openModal2 && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setOpenModal2(false);
              }}
            >
              <div
                className="newwProfiles newwPopupforreason"
                onClick={(e) => e.stopPropagation()}
              >
                <Preview modalContent={modalContent} />
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => {
                      setOpenModal2(false);
                    }}
                  >
                    <HighlightOffIcon />
                  </button>
                </div>
              </div>
            </div>
          )}
            </div>
          </>
        )}

        {type === "Output" && (
          <>
            {issuedmedicine.length > 0 ? (
              <div className="for">
                <div className="Add_items_Purchase_Master">
                  <span>Selected Medicine</span>
                </div>
                <div className="Selected-table-container">
                  <table className="selected-medicine-table2">
                    <thead>
                      <tr>
                        <th id="slectbill_ins">GenericName</th>
                        <th id="slectbill_ins">ItemName</th>
                        <th id="slectbill_ins">Item Type</th>
                        <th id="slectbill_ins">Dose</th>
                        <th id="slectbill_ins">Notes</th>
                        <th id="slectbill_ins">Frequency</th>
                        <th id="slectbill_ins">Duration</th>
                        <th id="slectbill_ins">Qty</th>
                        <th id="slectbill_ins">Instruction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {issuedmedicine.map((isssueInfo, index) => (
                        <tr key={index}>
                          <td>{isssueInfo.GenericName}</td>
                          <td>{isssueInfo.ItemName}</td>
                          <td>{isssueInfo.itemtype}</td>
                          <td>{isssueInfo.dose}</td>
                          <td>{isssueInfo.notes}</td>
                          <td>{isssueInfo.frequency}</td>
                          <td>
                            {isssueInfo.durationNumber} {isssueInfo.durationUnit}
                          </td>
                          <td>{isssueInfo.qty}</td>
                          <td>{isssueInfo.instruction}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="Add_items_Purchase_Master">
                <span>No Medicine Selected</span>
              </div>
            )}
           
          </>
        )}


        <ToastContainer />
      </div>
    </div>
     

    </>
  );
}

export default Treatment;
