import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const PayRoll = () => {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const [rolename, setRolename] = useState([]);

  const [columns] = React.useState([
    { field: "id", headerName: "Serial No", width: 100 },
    { field: "employeeid", headerName: "Employee ID", width: 150 },
    {
      field: "employeephoto",
      headerName: "Employee Photo",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Employee"
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
      ),
    },
    { field: "employeename", headerName: "Employee Name", width: 180 },
    { field: "phone", headerName: "Phone No", width: 150 },
    { field: "designation", headerName: "Designation", width: 200 },
    { field: "location", headerName: "Branch Location", width: 150 },

    {
      field: "Action ",
      headerName: "View",
      width: 150,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ]);

  useEffect(() => {
    axios
      .get(
        `${urllink}HRmanagement/get_employee_personaldetails?location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;
        const Records = data.map((userdata, index) => ({
          id: index + 1,
          employeeid: userdata.EmployeeID,
          employeephoto: userdata.EmployeePhoto,
          employeename: userdata.EmployeeName,
          phone: userdata.PhoneNumber,
          designation: userdata.Designation,
          location: userdata.Locations,
          // department: userdata.Department
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord?.location]);

  const navigate = useNavigate();

  const handleList = (params) => {
    const employeeid = params.row.employeeid;
    const location = params.row.location;
    axios
      .get(
        `${urllink}HRmanagement/for_Employee_Payslip?employeeid=${employeeid}&location=${location}`
      )
      .then((response) => {
        dispatchvalue({ type: "employeedata", value: response.data });
      })
      .catch((error) => {
        console.error(error);
      });

    navigate("/Home/Employee-PaySlip");
  };

  useEffect(() => {
    axios
      .get(`${urllink}HRmanagement/getRole_all`)
      .then((response) => {
        setRolename(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord?.location]);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "Employeeid") {
      setSearchQuery1(value);
    } else if (id === "designation") {
      setSearchQuery2(value);
    }
  };

  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.employeename.toLowerCase();
      const lowerCasePhoneNo = row.employeeid.toString().toLowerCase();
      const lowerdesignation = row.designation.toLowerCase();

      const matchesFirstName = lowerCaseSupplierName.includes(
        searchQuery.toLowerCase()
      );
      const matchesPhoneNo = lowerCasePhoneNo.includes(
        searchQuery1.toLowerCase()
      );

      const matchesdesignation = lowerdesignation.includes(
        searchQuery2.toLowerCase()
      );

      return (
        (matchesFirstName || !searchQuery) &&
        (matchesPhoneNo || !searchQuery1) &&
        (matchesdesignation || !searchQuery2)
      );
    });

    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, searchQuery2, rows]);

  return (
    <div>
      <div className="appointment">
        <div className="h_head">
          <h4>Pay Roll</h4>
        </div>

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Employee Name <span>:</span>
            </label>
            <input
              type="text"
              id="FirstName"
              onChange={handleSearchChange}
              value={searchQuery}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">
              Employee ID <span>:</span>
            </label>
            <input
              type="text"
              id="Employeeid"
              value={searchQuery1}
              onChange={handleSearchChange}
              placeholder="Enter the Employee ID"
            />
          </div>
        </div>
        <div className="con_1">
          <div className="inp_1">
            <label htmlFor="input">
              Designation <span>:</span>
            </label>
            <select
              name="designation"
              className="new-custom-input-phone vital_select"
              id="designation"
              value={searchQuery2}
              onChange={handleSearchChange}
            >
              <option value=""> Select </option>
              {rolename.map((role) => (
                <option key={role.role_id} value={role.role_name}>
                  {role.role_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayRoll;
