import { format } from "date-fns";
import React, { useEffect, useState } from "react";
// import Webcam from 'react-webcam';
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import "../../PatientManagement/PatientRegister.css";
import { useNavigate } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const Concern = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink)
const [PatientData,setPatientData] = useState([])
  const create = userRecord?.username;
  const [formData, setFormData] = useState({
    patientId: "",
    patientName: "",
    date: format(new Date(), "yyyy-MM-dd"),
    capturedImage: null,
    ChoosenFile: null,
  });
  const navigate = useNavigate();
  // const [showFile, setShowFile] = useState(false);
  // const [isImageCaptured, setIsImageCaptured] = useState(false);
  // const webcamRef = useRef(null);

  const handleInputFileChange = (e) => {
    // Handle file input change if needed
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      capturedImage: null,
      ChoosenFile: file,
    }));
  };

  //   const dataURItoBlob = (dataURI) => {
  //     const byteString = atob(dataURI.split(',')[1]);
  //     const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  //     const ab = new ArrayBuffer(byteString.length);
  //     const ia = new Uint8Array(ab);
  //     for (let i = 0; i < byteString.length; i++) {
  //         ia[i] = byteString.charCodeAt(i);
  //     }
  //     return new Blob([ab], { type: mimeString });
  // };
  // const handleCaptureImage = () => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   setFormData((prev) => ({
  //     ...prev,
  //     capturedImage: imageSrc ? dataURItoBlob(imageSrc, 'captured_image.jpg') : null,
  //     ChoosenFile:null
  //   }));
  //   setIsImageCaptured(true);
  // };

  // const handleRecaptureImage = () => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     capturedImage: null,
  //   }));
  //   setIsImageCaptured(false);
  // };

  const handlePrint = () => {
    const consentFileInput = document.getElementById("CapturedFile");
    const selectedFile = consentFileInput.files[0];

    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      const newWindow = window.open(fileURL);
      newWindow.print();
    }
  };

  const handleSubmit = () => {
    const form = new FormData();

    const data = {
      patientId: formData.patientId,
      PatientName: formData.patientName,
      date: formData.date,
      capturedImage: formData.capturedImage
        ? formData.capturedImage
        : formData.ChoosenFile,
      Created_By: create,
      location: userRecord?.location,
    };
    Object.keys(data).forEach((key) => {
      if (data[key] !== null && data[key] !== undefined) {
        form.append(key, data[key]);
      }
    });
    axios
      .post(
        `${urllink}appointmentmanagement/insert_concernform`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        successMsg("Saved Successfully");
      })
      .catch((error) => {
        errmsg("Error occured");
      });

    navigate("/Home/Register_concern_List");
  };

  const Getpatientdata = () => {
    if (formData.patientId === "") {
      alert("Enter PatientId");
    } else {
      axios
        .get(
          `${urllink}patientmanagement/get_communication_address_billing/${formData.patientId}/${userRecord?.location}`
        )
        .then((response) => {
          const A_data = response.data[0];

          if (A_data) {
            setFormData((prev) => ({
              ...prev,
              patientName: A_data?.FirstName + " " + A_data?.LastName,
            }));
          } else {
            alert("Enter Valid PatientID");
          }
        })
        .catch((error) => {
          // Handle the error, e.g., show an error message to the user
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${urllink}labtest/getallpatientids`)
      .then((response) => {
        setPatientData(response.data)
      })
      .catch((error) => {
      });
  }, []);

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Concern Form</h4>
      </div>
      <br />


      <div className="RegisFormcon">
        <div className="RegisForm_1 input-with-icon">
          <label htmlFor="Title">
            Patient ID <span>:</span>
          </label>
          <div className="input-container">
            <input
              name="Title"
              list="patients"
              onChange={(e) => {
                const selectedPatient = PatientData.find(patient => patient.PatientID === e.target.value);
                if (selectedPatient) {
                  setFormData({ ...formData, patientId: selectedPatient.PatientID, patientName: selectedPatient.PatientName });
                } else {
                  setFormData({ ...formData, patientId: e.target.value, patientName: '' });
                }
              }}
            />
            <datalist id="patients">
              {PatientData.map((patient, index) => (
                <option key={index} value={patient.PatientID}>
                  PatientName : {patient.PatientName}
                </option>
                
              
              ))}
            </datalist>

            <button onClick={Getpatientdata} className="searching_input_icon3">
              <SearchIcon />
            </button>
          </div>
        </div>

        <div className="RegisForm_1">
          <label>
            {" "}
            Patient Name <span>:</span>{" "}
          </label>
          <input
            type="text"
            name="FirstName"
            value={formData.patientName}
            // onChange={(e) => setFormData({ ...formData, patientName: e.target.value })}
            required
            readOnly
          />
        </div>

        <div className="RegisForm_1">
          <label>
            {" "}
            Date <span>:</span>{" "}
          </label>
          <input
            type="date"
            name="Date"
            value={formData.date}
            required
            readOnly
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="CapturedFile">
            Consent Form{" "}
            <button
              className="newicon_regis"
              title="Print"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
            :
          </label>
          <div className="file-input-444">
            <div className="RegisterForm_2">
              <input
                type="file"
                id="CapturedFile"
                name="CapturedFile"
                accept="image/pdf"
                onChange={handleInputFileChange}
                required
                style={{ display: "none" }}
              />
              <label
                htmlFor="CapturedFile"
                className="RegisterForm_1_btns choose_file_update"
              >
                Choose File
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="Register_btn_con">
        <button onClick={handleSubmit} className="RegisterForm_1_btns">
          Submit
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Concern;
