import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import LoupeIcon from '@mui/icons-material/Loupe';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import '../SupplierMaster/SupplierMaster.css'
import { useDispatch, useSelector } from 'react-redux';





const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
  
export default function ProductList( ) {


const dispatchvalue = useDispatch();

const urllink=useSelector(state=>state.userRecord?.UrlLink);


    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [Productype, setProductype] = useState('');
    const [summa, setsumma] = useState([])

    console.log('out',summa)

    useEffect(() => {
      if(Productype === 'Medical'){
        axios.get(`${urllink}quickstockreceive/get_Medical_ProductMaster_data`)
        .then((response) => {
            const data = response.data;
            console.log('out', data);
            setsumma( [
                ...data.map((row, ind) => ({
                    id: ind + 1,
                    ...row,
                }))
            ]);
        })
        .catch((error) => {
            console.error('Error fetching patients data:', error);
        });
    }else if (Productype === 'Non_Medical'){
        axios.get(`${urllink}quickstockreceive/get_NONMedical_ProductMaster_data`)
        .then((response) => {
            const data = response.data;
            console.log('out', data);
            setsumma( [
                ...data.map((row, ind) => ({
                    id: ind + 1,
                    ...row,
                }))
            ]);
        })
        .catch((error) => {
            console.error('Error fetching patients data:', error);
        });
    }
    else if (Productype === 'Assets'){
        axios.get(`${urllink}quickstockreceive/get_Assets_ProductMaster_data`)
        .then((response) => {
            const data = response.data;
            console.log('out', data);
            setsumma( [
                ...data.map((row, ind) => ({
                    id: ind + 1,
                    ...row,
                }))
            ]);
        })
        .catch((error) => {
            console.error('Error fetching patients data:', error);
        });
    }
    else if (Productype === 'Stationary'){
        axios.get(`${urllink}quickstockreceive/get_Stationary_ProductMaster_data`)
        .then((response) => {
            const data = response.data;
            console.log('out', data);
            setsumma( [
                ...data.map((row, ind) => ({
                    id: ind + 1,
                    ...row,
                }))
            ]);
        })
        .catch((error) => {
            console.error('Error fetching patients data:', error);
        });
    }
    else if (Productype === 'Non_Stationary'){
        axios.get(`${urllink}quickstockreceive/get_NONStationary_ProductMaster_data`)
        .then((response) => {
            const data = response.data;
            console.log('out', data);
            setsumma( [
                ...data.map((row, ind) => ({
                    id: ind + 1,
                    ...row,
                }))
            ]);
        })
        .catch((error) => {
            console.error('Error fetching patients data:', error);
        });
    }
    }, [Productype]);

    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {        
        dispatchvalue({ type : 'selectedProductIndex' , value : ({'ItemCode':params.row.ItemCode,'Productype':Productype})})
        navigate('/Home/Product_Master');
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.ItemName.toLowerCase();
            const lowerCaseSupplierCode = row.ItemCode.toString().toLowerCase(); // Convert to string for case-insensitive search

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery) ||
                lowerCaseSupplierCode.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);
        
    }, [searchQuery,summa]);



    const handleRequestForm = () => {

        navigate('/Home/Product_Master')
        dispatchvalue({type : 'selectedProductIndex' , value : (null)})
    }


    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'ItemCode', headerName: 'Item Code', width: 180 },
        { field: 'ItemName', headerName: 'Item Name', width: 180 },
        { field: 'GenericName', headerName: 'Generic Name', width: 180 },
        { field: 'Strength', headerName: 'Strength', width: 100 },
        { field: 'UOM', headerName: 'UOM', width: 100 },
        { field: 'Status', headerName: 'Status', width: 120 },
        { field: 'ProductType', headerName: 'Product Type', width: 120 },
        { field: 'ProductQuantity', headerName: 'Product Quantity', width: 120 },
        { field: 'ItemRateWithouttax', headerName: 'Item Rate', width: 120 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <BorderColorIcon />
                    </Button>
                </>
            ),
        },
    ];

    const dynamicColumns2 = [
        { field: 'ItemCode', headerName: 'Item Code', width: 200 },
        { field: 'ItemName', headerName: 'Item Name', width: 200 },
        { field: 'ProductType', headerName: 'Product Type', width: 150 },
        { field: 'ProductQuantity', headerName: 'Product Quantity', width: 150 },
        { field: 'Status', headerName: 'Status', width: 120 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <BorderColorIcon />
                    </Button>
                </>
            ),
        },
    ];


    return (
        <>
            
            <div className="Supplier_Master_Container">
                
                <div className="Product_Master_h_head">
                    <h3>Product List</h3>
                    <div className="Product_Master_div_select">
                        <label htmlFor="Calender"> Product Type :</label>
                        <select className='Product_Master_div_select_opt' value={Productype} onChange={(e)=>{setProductype(e.target.value)}}>
                                <option value=''>Select</option>
                                <option value='Medical'>Medical</option>
                                <option value='Non_Medical'>Non Medical</option>
                                <option value='Assets'>Assets</option>
                                <option value='Stationary'>Stationary</option>
                                <option value='Non_Stationary'>Non Stationary</option>
                        </select>
                    </div>

                 </div>
                    <div className="Supplier_Master_con_1 ">

                        <div className="Supplier_inputss_forms">
                            <label htmlFor="input">Supplier Code :</label>
                            <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Supplier Code' />
                        </div>
                        <button className='Supplier_Master_btn_1' type='submit'>
                            <SearchIcon />
                        </button>
                        <button className='Supplier_Master_btn_1' type='submit' onClick={() => handleRequestForm()} title='New Supplier'>
                            <LoupeIcon />
                        </button>
                    </div>
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={Productype ==='Medical'?dynamicColumns:dynamicColumns2} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
