import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../src/App.css';
import LoginPage from './LoginPage/LoginPage';
import HomePage from './HomePage/HomePage';
import RoleManagement from './UserControls/RoleManagement';
import UserRegister from './UserControls/UserRegister';
import UserList from './UserControls/UserList';
import PatientQueueList from './TreatmentRecords/PatientQueueList';
import RequestList from './AppointmentManagement/RequestList';
import RegisterList from './AppointmentManagement/RegisterList';
import Register from './AppointmentManagement/Register';
import Profile from './Profile/Profile';
import PasswordChange from './PasswordChange/PasswordChange';
import AccountSettings from './UserControls/Account Settings';
import ClinicDetails from './UserControls/ClinicDetails';
import Navigation from './TreatmentRecords/Navigation';
import BillingHistory from './Billing/BillingHistory';
import ClinicMetrics from './ClinicMetrics/ClinicMetrics';
import PatientProfile from './PatientManagement/PatientProfile';
import AppoinmentCalendar from './AppointmentManagement/AppointmentCalendar';
import Preview from './TreatmentRecords/Preview';
import PatientList from './TreatmentRecords/PatientList';
import PatientQueueList2 from './Therophist/PatientQueueList2';
import Navigation2 from './Therophist/Navigation2';
import Vital2 from './Therophist/Vital2';
import RegistrationForm from './RegistrationForm/Registration';
import PatientList2 from './Therophist/PatientList2';
import NewProcedure from './Therophist/NewProcedure';
import CounselorQueueList from './Counselor/CounselorQueueList';
import CounselorProcedure from './Counselor/CounselorProcedure';
import CounselorFullList from './Counselor/CounselorFullList';
import ExpensesMaster from './Pettycash/ExpensesMaster';
import CashExpenses from './Pettycash/CashExpenses';
import CashExpenseReport from './Pettycash/CashExpenseReport';
import HandOverSummary from './Pettycash/HandOverSummary';
import DayBookReport from './Pettycash/DayBookReport';
import DayClosing from './Pettycash/DayClosing';
import DueHistoryList from './Billing/Duehistory/DueHistoryList';
import GeneralBillingQueList from './Billing/OverallBilling/GeneralBillingQueList';
import GeneralBilling from './Billing/OverallBilling/GeneralBilling';
import Concern from './AppointmentManagement/ConcernForms/Concern';
import Concernlist from './AppointmentManagement/ConcernForms/Concernlist';
import RateCardMaster from './UserControls/RateCardMaster/RateCardMaster';
import LabQueueList from './AppointmentManagement/LabQueueList';
import LabReportFN from './AppointmentManagement/LabReportFN';
import PatientQueueList3 from './Nurse/PatientQueueList3';
import Navigation3 from './Nurse/Navigation3';
import OverallDayreport from './Pettycash/OverallDayreport';
import Walkinbilling from './Pharmacy/Walkinbilling';
import PharmacyBillingLIst from './Pharmacy/PharmacyBillingLIst';
import PharmacyBilling from './Pharmacy/PharmacyBilling';
import DeuHistory from './Billing/Duehistory/DeuHistory';
import EmployeeRegister from './HR Management/EmployeeRegister';
import EmployeeList from './HR Management/EmployeeList';
import EmployeeProfile from './HR Management/EmployeeProfile';
import EmployeeAttendance from './HR Management/Attendance';
import EmployeeCalendar from './HR Management/EmployeeCalendar';
import EmployeeQueList from './UserControls/EmployeeQueList';
import VisitingDoctorBilling from './UserControls/VisitDoctorBilling';
import LeaveMangement from './HR Management/LeaveMangement';
import PayRoll from './HR Management/PayRoll';
import PaySlip from './HR Management/PaySlip';
import VisitingDoctor from './VisitingDoctor/VisitingDoctor';
import PatientVisitingDoctor from './VisitingDoctor/PatientVisitingDoctor';
import PerformanceAppraisal from './HR Management/Performance';
import PerformanceManagement from './HR Management/PerformanceManagement';
import AddvaceApproval from './HR Management/AdvanceApproval';
import AddvaceManagement from './HR Management/AdvanceManagement';
import LeaveNavigation from './EmployeeRequest/LeaveNavigation';
import AdvanceNavigation from './EmployeeRequest/AdvanceNavigation';
import LeaveApproval from './HR Management/LeaveApproval';
import SupplierStock from './Stock/SupplierPay/SupplierStock';
import SupplierDetails from './Stock/SupplierPay/SupplierDetails';
import SupplierList from './Stock/SupplierMaster/SupplierList';
import SupplierMaster from './Stock/SupplierMaster/SupplierMaster';
import ProductList from './Stock/ProductMaster/ProductList';
import ProductMaster from './Stock/ProductMaster/ProductMaster';
import PurchaseRaiseList from './Stock/PurchaseRaiseMaster/PurchaseRaiseList';
import PurchaseRaiseMaster from './Stock/PurchaseRaiseMaster/PurchaseRaiseMaster';
import PurchaseRecieceList from './Stock/PurchaseRecieveMaster/PurchaseRecieveList';
import PurchaseRecieveMaster from './Stock/PurchaseRecieveMaster/PurchaseRecieveMaster';
import IndentRaiseMaster from './Stock/IndentRaise/IndentRaiseMaster';
import IndentRaiseList from './Stock/IndentRaise/IndentRaiseList';
import IndentRecieveList from './Stock/IndentRecieve/IndentRecieveList';
import IndentRecieveMaster from './Stock/IndentRecieve/IndentRecieveMaster';
import IndentIssueMaster from './Stock/IndentIssue/IndentIssueMaster';
import IndentIssueList from './Stock/IndentIssue/IndentIssueList';
import IndentApprove from './Stock/ApprovePages/IndentApprove';
import IndenIssueApprove from './Stock/ApprovePages/IndenIssueApprove';
import IndentReturnList from './Stock/IndentReturn/IndentReturnList';
import IndentReturnMaster from './Stock/IndentReturn/IndentReturnMaster';
import PurchaseApprove from './Stock/ApprovePages/PurchaseApprove';
import GrnApprove from './Stock/ApprovePages/GrnApprove';
import PurchaseReturnMaster from './Stock/PurchaseReturn/PurchaseReturnMaster';
import CentralStockList from './Stock/CentralStock/CentralStockList';
import LocationStock from './Stock/CentralStock/LocationStock';
import QuickStockListLoc from './Stock/QuickStockRecieve/QuickStockListLoc';
import QuickStockMasterLoc from './Stock/QuickStockRecieve/QuickStockMasterLoc';
import IndentRecieveApprove from './Stock/ApprovePages/IndentRecieveApprove';

// import Accounts from './Accounts/Accounts';

import AccountPayable from './Accounts/AccountPayable';
// import PatientView from './patientView';
import Patientview from './PatientView'
import NotFound from './404Page';
import PrivacyPolicy from './Footer/PrivacyPolicy';
import TermsOfUse from './Footer/TermsOfUse';
import { useDispatch, useSelector } from 'react-redux';
import PatientListupdate from './AppointmentManagement/patientlistupdate';
import Expenses from './Pettycash/Expenses';
import DigitalExpenses from './Pettycash/DigitalExpense';
import QuickStockMasterCentral from './Stock/QuickStockRecieve/QuickStockMasterCentral';
import SuplierPaidlist from './Stock/SupplierPay/SuplierPaidlist';
import IndentMovementmaster from './Stock/IndentMovement/IndentMovementmaster';
import IndentMovementlist from './Stock/IndentMovement/IndentMovementlist';
import IndentReturnApprove from './Stock/ApprovePages/IndentReturnApprove';
import PurchaseReturnLocMaster from './Stock/PurchaseReturn/PurchaseReturnLocMaster';


import PurchaseRegister from './Finance/PurchaseRegister';
import AccountGroupMaster from './Finance/AccountGroupMaster';
import SalesRegister from './Finance/SalesRegister';

// ---------------------------------------------------------
import Journalentry from './Finance/Journalentry';
import JournalentryList from './Finance/JournalentryList';
import LedgerList from './Finance/LedgerList';
import BalanceSheet from  './Finance/BalanceSheet'
import AccountGroupList from  './Finance/AccountGroupList'
import Accounts from './Finance/Accounts'
import ContraVoucher from './Finance/ContraVoucher'
import ContraVoucherList from './Finance/ContraVoucherList';

import PaymentVoucherList from './Finance/PaymentVoucherList';
import ReceiptVoucher from './Finance/ReceiptVoucher'
import ReceiptVoucherList from './Finance/ReceiptVoucherList'
import DayBook from './Finance/DayBook'
import TrialBalance from './Finance/TrialBalance'
import ProfitandLoss from './Finance/ProfitandLoss'
import CashbookandBankbook from './Finance/CashbookandBankbook'

// -------------------------------------------------------------------------------------

import HSNReport from './Finance/HSNReport';
import DueReport from './Finance/DueReport';


import PatientView from './PatientView';
import VisitingDoctorBillingList from './UserControls/VisitDoctorBillingList';
import Followuplist from './AppointmentManagement/Followuplist';
import DutyManagement from './HR Management/DutyManagement';
import PharmachyMasterList from './Stock/PharmachyMaster/PharmachyMasterList';
import PharmachyMaster from './Stock/PharmachyMaster/PharmachyMaster';
import GeneralMasterList from './Stock/GeneralMaster/GeneralMasterList';
import GeneralMaster from './Stock/GeneralMaster/GeneralMaster';
import PaymentVoucher from './Finance/PaymentVoucher';

import ShiftDetails from './EmployeeRequest/ShiftDetails';
import LabPreview from './TreatmentRecords/Labpreview';
import VisitingDoctorRegister from './UserControls/VisitingDoctorRegister';
import PayslipView from './EmployeeRequest/PayslipView';
import QuickStockMasterCentrallist from './Stock/QuickStockRecieve/QuickStockMasterCentrallist';
import SupplierPayCentralStore from './Stock/SupplierPay/SupplierPayCentralStore';
import LocGrnApprove from './Stock/ApprovePages/LocGrnApprove';
import PurchaseReturnMasterlist from './Stock/PurchaseReturn/PurchaseReturnMasterlist';
import PurchaseReturnLocMasterlist from './Stock/PurchaseReturn/PurchaseReturnLocMasterlist';




import AccountsMaster from './FinanceModule/AccountsMaster';
import AccountsMasterList from './FinanceModule/AccountsMasterList';
import ProductItemMaster from './Stock/PharmachyMaster/ProductItemMaster';
import RegisterList1 from './AppointmentManagement/RegisterList1';
import RegisterFormNew from './AppointmentManagement/RegisterFormNew';
import PatientQueList1 from './TreatmentRecords/PatientQueList1';
import LabMasterone from './UserControls/RateCardMaster/LabMasterone';
import ProcedureQueList from './TreatmentRecords/ProcedureQueList';
import ProcedureAddProcedure from './TreatmentRecords/ProcedureAddProcedure';


// -----------------------------------------





const App = () => {


  const [userRecord, setUserRecord] = useState(null);

  const urllink = useSelector((state) => state.userRecord?.UrlLink);



  const navigate = useNavigate();
  const location = useLocation();

  const dispatchvalue = useDispatch();

  const  isModalOpen = useSelector(state=>state.userRecord?.isModalOpen )

  const getuserrecord = useCallback(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(storedToken);
      setUserRecord(decodedTokenData)
      dispatchvalue({ type: 'UserData', value: decodedTokenData })
    } else {
      if (location.pathname !== '/') {
        navigate('/')
      }
    }
  }, [navigate, location.pathname])



  useEffect(() => {
    const loc = location.pathname
    if (loc === '/') {
      localStorage.clear()
    }
    getuserrecord();
  }, [location, location.pathname, navigate, getuserrecord])

 
 
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${urllink}FinanceMagement/get_BanckAccount_Name`
  //     )
  //     .then((response) => {
  //       const data = response.data;
  //       if (data) {
  //         console.log('Accountssss',data);
  //         dispatchvalue({ type: "BankAccountlist", value: data });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching Location options:", error);
  //     });
  //   }, [])



  useEffect(() => {
    const sessionTimeout = 30 * 60 * 1000; // 3 minutes in milliseconds
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        localStorage.clear();
        navigate('/');
      }, sessionTimeout);
    };

    // Add event listeners to reset the timeout on user activity
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    resetTimeout(); // Initial setup

    // Clean up event listeners on unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
    };
  }, [navigate]);


  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.setAttribute('autocomplete', 'off');
      input.addEventListener('copy', (event) => {
        event.preventDefault();
      });
    });
  }, []);

  // Function to open the modal
  const openModal = () => {
    dispatchvalue({type:'isModalOpen',value:true})
  };

  // Function to close the modal
  const closeModal = () => {
    dispatchvalue({type:'isModalOpen',value:false})
  };

  return (
    <>
      <Routes>
        <Route path='/Home' element={<HomePage  />}>
          {/* Header */}
          <Route path="Profile" element={ <Profile />} />
          <Route path="PasswordChange" element={ <PasswordChange  />} />

          {/* Patient Management */}
          <Route path="PatientProfile" element={<PatientProfile  />} />


          {/* Dashboard */}
          <Route path="ClinicMetrics" element={<ClinicMetrics  />} />


          {/* Front Office */}
          <Route path="Register-Patient2" element={<RegistrationForm />} />
          <Route path="Appoinment-RequestList" element={<RequestList />} />
          <Route path="Register" element={<Register  />} />
          <Route path="Appoinment-RegisterList1" element={<RegisterList1   />} />
          <Route path="Appointment-Calendar" element={<AppoinmentCalendar  />} />
          <Route path="Register_concern_List" element={<Concernlist  />} />
          <Route path="Register_concern_Form" element={<Concern />} />
          <Route path="Lab-QueueList" element={<LabQueueList  />} />
          <Route path="Lab-ReportFN" element={<LabReportFN   />} />
          <Route path="Patient-List-update" element={<PatientListupdate  />} />
          <Route path="Followuplist" element={<Followuplist  />} />
          <Route path="RegisterFormNew" element={<RegisterFormNew />} />
        
s
          {/* Nurse */}
          <Route path="Treament-QueueList3" element={<PatientQueueList3  />} />
          <Route path="Navigation3" element={<Navigation3  />} />

          {/* Stock */}

          {/* supplier Master */}

          <Route path='Supplier_List' element={<SupplierList  />} />
          <Route path='Supplier_Master' element={<SupplierMaster  />} />
          {/*  Product Master */}
          <Route path='Product_List' element={<ProductList  />} />
          <Route path='Product_Master' element={<ProductMaster  />} />


      {/* Pahrmachy Master */}
      <Route path='Pharmacy_MasterList' element={<PharmachyMasterList  />} />
          <Route path='Pharmacy_Master' element={<PharmachyMaster  />} />

          {/* General Master */}

          <Route path='General_MasterList' element={<GeneralMasterList />} />
          <Route path='General_Master' element={<GeneralMaster  />} />
          <Route path='Productitemmaster' element={<ProductItemMaster/>}/>





          {/* purchase raise master */}
          <Route path='Purchase_Raise_list' element={<PurchaseRaiseList  />} />
          <Route path='Purchase_Raise_Master' element={<PurchaseRaiseMaster  />} />
          {/* purchase recieve master */}
          <Route path='Purchase_Recieve_list' element={<PurchaseRecieceList  />} />
          <Route path='Purchase_Recieve_Master' element={<PurchaseRecieveMaster  />} />
          {/* purchase Indent Raise master */}
          <Route path='Indent_Raise_list' element={<IndentRaiseList  />} />
          <Route path='Indent_Raise_Master' element={<IndentRaiseMaster  />} />
          {/* purchase Indent Recieve master */}
          <Route path='Indent_Recieve_list' element={<IndentRecieveList  />} />
          <Route path='Indent_Recieve_Master' element={<IndentRecieveMaster  />} />
          {/* purchase Indent Issue master */}
          <Route path='Indent_Issue_list' element={<IndentIssueList  />} />
          <Route path='Indent_Issue_Master' element={<IndentIssueMaster  />} />


          <Route path='Indent_Return_list' element={<IndentReturnList  />} />
          <Route path='Indent_Return_Master' element={<IndentReturnMaster  />} />
          <Route path="IndentMovementmaster" element={<IndentMovementmaster  />} />
          <Route path="IndentMovementlist" element={<IndentMovementlist  />} />

          {/* indent Approve */}
          <Route path='Indent_appprove_List' element={<IndentApprove  />} />
          <Route path='IndentIssue_appprove_List' element={<IndenIssueApprove />} />
          <Route path='PurchaseApprove_List' element={<PurchaseApprove  />} />
          <Route path='IndentRecieve_appprove_List' element={<IndentRecieveApprove  />} />
          <Route path='GRN_appprove_List' element={<GrnApprove  />} />
          <Route path='LocationGRN_appprove_List' element={<LocGrnApprove/>} />

          <Route path='IndentReturnApprove' element={<IndentReturnApprove />} />

          {/* expiry products */}
          <Route path='PurchaseReturn_Loc_Master' element={<PurchaseReturnLocMaster />} />

          <Route path='PurchaseReturnLocMasterlist' element={<PurchaseReturnLocMasterlist />} />

          <Route path='PurchaseReturn_Master' element={<PurchaseReturnMaster />} />
          <Route path='PurchaseReturnMasterlist' element={<PurchaseReturnMasterlist />} />


          {/* stock list */}
          <Route path='CentralStock_List' element={<CentralStockList />} />
          <Route path='LocationStock_List' element={<LocationStock  />} />
          {/* QuickStock Location */}
          <Route path='Quick_Stock_Recieve_List' element={<QuickStockListLoc  />} />
          <Route path='Quick_Stock_Recieve' element={<QuickStockMasterLoc  />} />
          <Route path='Quick_Stock_Recieve_Central' element={<QuickStockMasterCentral  />} />
          <Route path='QuickStockMasterCentrallist' element={<QuickStockMasterCentrallist  />} />

          {/* Supplier Pay */}
          <Route path='Supplier-Stock-Manager' element={<SupplierStock  />} />
          <Route path='Supplier_Pay_centralStore' element={<SupplierPayCentralStore  />} />
          <Route path='Supplier-Stock-Details' element={<SupplierDetails  />} />
          <Route path='Supplier_Pay_List' element={<SuplierPaidlist />} />



          {/*------------------------------ Finance -------------------------*/}
          <Route path='PurchaseRegister' element={<PurchaseRegister/>} />
          <Route path='SalesRegister' element={<SalesRegister/>} />
          <Route path='Journalentry' element={<Journalentry/>} />
          <Route path='LedgerList' element={<LedgerList/>} />
          <Route path="Add_Accounts" element={<Accounts />} />
          <Route path="BalanceSheet" element={<BalanceSheet />} />
          <Route path="AccountGroupList" element={<AccountGroupList />} />
          <Route path='ContraVoucher' element={<ContraVoucher />}/>
          <Route path='ContraVoucherList' element={<ContraVoucherList />}/>
          <Route path='PaymentVoucher' element={<PaymentVoucher />}/>
          <Route path='PaymentVoucherList' element={<PaymentVoucherList />}/>
          <Route path='ReceiptVoucher' element={<ReceiptVoucher />}/>
          <Route path='ReceiptVoucherList' element={<ReceiptVoucherList />}/>
          <Route path='JournalentryList' element={<JournalentryList />}/>
          <Route path='DayBook' element={<DayBook />}/>
          <Route path='TrialBalance' element={<TrialBalance />}/>
          <Route path='ProfitandLoss' element={<ProfitandLoss />}/>
          <Route path='CashbookandBankbook' element={<CashbookandBankbook />}/>
          <Route path='AccountGroupMaster' element={<AccountGroupMaster />}/>
          <Route path='HSNReport' element={<HSNReport/>}/>
          <Route path='DueReport' element={<DueReport/>}/> 


          {/* ----------------------Real Finance----------------------------- */}
             {/* FinanceModule */}

        <Route path="AccountsMasterCreate" element={<AccountsMaster/>} />
        <Route path="AccountsMasterList" element={<AccountsMasterList/>} />

       





          

          {/* Doctor work bench */}
          <Route path="Treament-QueueList1" element={<PatientQueList1  />} />

          <Route path="Navigation" element={<Navigation  />} />
          <Route path="Patient-List" element={<PatientList  />} />
          <Route path="vitalForm2" element={<Vital2  />} />   {/*Pending formData*/}
          <Route path="Visiting-Doctor-Register" element={<VisitingDoctorRegister  />} />   {/*Pending formData*/}

          <Route path="PatientView" element={<PatientView  />} />

          <Route path="Preview" element={<Preview  />} /> {/*Pending formData*/}
          <Route path="LabPreview" element={<LabPreview  />} />

  {/* Procedure work bench */}
  <Route path="Procedure-QueList" element={<ProcedureQueList  />} />
  <Route path="ProcedureAddProcedure" element={<ProcedureAddProcedure  />} />





          {/* Theraphist */}
          <Route path="Treament-QueueList2" element={<PatientQueueList2  />} />
          <Route path="Navigation2" element={<Navigation2  />} />
          <Route path="Patient-List2" element={<PatientList2 />} />
          <Route path="Treament-NewProcedure" element={<NewProcedure  />} />


          {/* Counselor */}
          <Route path="Treament-CounselorQueueList" element={<CounselorQueueList  />} />
          <Route path="Treament-CounselorProcedure" element={<CounselorProcedure  />} />
          <Route path="Treament-CounselorFullList" element={<CounselorFullList />} />


          {/* Billing */}
          <Route path="Billing-History" element={<BillingHistory  />} />
          <Route path="Deu-History" element={<DeuHistory  />} />
          <Route path="Deu-History-List" element={<DueHistoryList  />} />
          {/* <Route path="Deu-Close" element={<DueHistory />} /> */}
          <Route path="Billing-Invoice" element={<GeneralBillingQueList />} />
          <Route path="Billing-Invoice-All" element={<GeneralBilling  />} />



          <Route path="Pharmacy_Billing_List" element={<PharmacyBillingLIst  />} />
          <Route path="Pharmacy-Billing" element={<PharmacyBilling  />} />
          <Route path="Pharmacy-Walkinbilling" element={<Walkinbilling  />} />


          <Route path="Bill-Report" element={<OverallDayreport />} />



          {/* Petty Cash */}
          <Route path="Expenses-Master" element={<ExpensesMaster  />} />
          <Route path="Cash-Expenses" element={<CashExpenses  />} />
          <Route path="Digital-Expenses" element={<DigitalExpenses />} />
          <Route path="Cash-Expenses-Report" element={<CashExpenseReport  />} />
          <Route path="Hand-Over-Summary" element={<HandOverSummary  />} />
          <Route path="Day-Book-Report" element={<DayBookReport  />} />
          <Route path="Day-Closing" element={<DayClosing  />} />

          {/* HR management */}
          <Route path="Employee-Register" element={<EmployeeRegister />} />
          <Route path="Employee-List" element={<EmployeeList  />} />
          <Route path="Employee-Profile" element={<EmployeeProfile  />} />
          <Route path="Employee-Attendance" element={<EmployeeAttendance  />} />
          <Route path="Employee-Performance" element={<PerformanceAppraisal  />} />
          <Route path="Employee-PerformanceManagement" element={<PerformanceManagement  />} />
          <Route path="Employee-AdvanceApproval" element={<AddvaceApproval  />} />
          <Route path="Employee-LeaveApproval" element={<LeaveApproval  />} />
          <Route path="Employee-LeaveManage" element={<LeaveMangement  />} />
          <Route path="Employee-AdvanceManagement" element={<AddvaceManagement />} />
          <Route path="Shift-Details" element={<ShiftDetails />}/>
          <Route path="Employee-AttendanceCalendar" element={<EmployeeCalendar  />} />
          <Route path="Employee-PayRoll" element={<PayRoll  />} />
          <Route path="Employee-PaySlip" element={<PaySlip  />} />
          <Route path="Duty-Management" element={<DutyManagement  />} />




          {/* Employee Request */}
          <Route path="Navigation-leave" element={<LeaveNavigation  />} />
          <Route path="Navigation-Advance" element={<AdvanceNavigation  />} />
          <Route path="Employee-PaySlip-View" element={<PayslipView  />} />

          {/* Visiting Doctor */}
          <Route path="Patient-Visiting-Doctor" element={<PatientVisitingDoctor  />} />
          <Route path="Visiting-Doctor" element={<VisitingDoctor  />} />

          {/* User Control */}
          <Route path="Role-Management" element={<RoleManagement />} />
          <Route path="Labmasterone" element={<LabMasterone/>} />
          <Route path="Register-User" element={<UserRegister  />} />
          <Route path="User-List" element={<UserList  />} />
          <Route path="Account Settings" element={<AccountSettings  />} />
          <Route path="Clinic Details"element={<ClinicDetails  />} />
          {/* <Route path="Doctor Consulation Charges" element={<DoctorCharges closeModal={closeModal} openModal={openModal} isModalOpen={isModalOpen} isSidebarOpen={isSidebarOpen} userRecord={userRecord} />} /> */}
          <Route path="EmployeeQue-List" element={ <EmployeeQueList /> } />
          {/* <Route path="Employee-LeaveManagement" element={<LeaveMangement userRecord={userRecord} setSelectedRowData={setSelectedRowData} isSidebarOpen={isSidebarOpen} />} /> */}
         <Route  path="Doctor Consulation Charges"  element={<RateCardMaster  />}/>
         <Route path="Visit-Doctor-Billing-List" element={<VisitingDoctorBillingList />} />
         <Route path="Visit-Doctor-Billing" element={<VisitingDoctorBilling />} />





          {/* Footer */}
          <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="Terms-of-Use" element={<TermsOfUse />} />

          {/* NotFound */}
          <Route path="*" element={<NotFound />} />

        </Route>

        <Route path='/' element={<LoginPage />} />
        <Route path='/' element={<LoginPage />} />
        {/* NotFound */}
        <Route path="*" element={<NotFound />} />


       







      </Routes>
    </>
  )
}

export default App;