import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { useSelector } from "react-redux";

const DayClosing = () => {
  const [formData, setFormData] = useState({
    invoiceNo: "",
    date: "",
    issueFromId: "",
    issueFromName: "",
    issueToId: "",
    issueToName: "",
    openingCash: "",
    PharmacycollectedCash: "",
    ProcedurecollectedCash: "",
    ConsultancycollectedCash: "",
    totalCash: "",
    handoverCash: "",
    expenses: "",
    refundcash: "",
    balanceCash: "",
    availableCash: "",
    reason: "",
    Branch_Name: "",
  });
  const [userList, setuserList] = useState([]);

  const [DisapleDayclose, setDisapleDayclose] = useState(false);

  const reduxvalueUsercord = useSelector((state) => state);
  const userRecord = reduxvalueUsercord.userRecord?.UserData;
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "issueToId" || name === "issueToName") {
      if (name === "issueToId") {
        const selectedPerson = userList.find(
          (item) => item.user_id === parseInt(value)
        );
        setFormData({
          ...formData,
          [name]: value,
          issueToName: selectedPerson ? selectedPerson.username : "",
        });
      } else {
        const selectedPerson = userList.find((item) => item.username === value);
        setFormData({
          ...formData,
          [name]: value,
          issueToId: selectedPerson ? selectedPerson.user_id : "",
        });
      }
    } else if (name === "handoverCash") {
      if (+value <= +formData.availableCash) {
        const newval = parseFloat(formData.availableCash) - +value;

        setFormData({
          ...formData,
          [name]: value,
          balanceCash: newval.toFixed(2),
        });
      } else {
        alert(
          `the collected amount is ${formData.availableCash} so please enter below or equal to that`
        );
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    if (Object.keys(userRecord).length !== 0) {
      axios
        .get(
          `${urllink}usercontrol/getuserregisterID_NAME?location=${userRecord?.location}`
        )
        .then((response) => {
          setuserList(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");
      setFormData((prev) => ({
        ...prev,
        issueFromId: userRecord?.user_id,
        issueFromName: userRecord?.username,
        date: formattedDate,
        Branch_Name: userRecord?.location,
      }));
      axios
        .get("${urllink}Pettycash_link/get_Day_closing_invoice")
        .then((response) => {
          const data = response.data;
          setFormData((prev) => ({
            ...prev,
            invoiceNo: data.nextInvoiceNumber,
          }));
        })
        .catch((error) => {
          console.error(error);
        });
      axios
        .get(
          `${urllink}Pettycash_link/get_Day_Closing_Data?location=${userRecord?.location}&BillingDate=${formattedDate}`
        )
        .then((response) => {
          const data = response.data;
          const datacondition = data.Availeable;
          if (datacondition === false) {
            const total =
              data.Opening_Cash +
              data.Pharmacy_Net_Amount +
              data.Procedure_Net_Amount +
              data.Consultancy_Net_Amount;
            const available =
              +total - (data.Expenses_Amount + data.Refunt_amount);
            setFormData((prev) => ({
              ...prev,
              openingCash: data.Opening_Cash.toFixed(2),
              expenses: data.Expenses_Amount.toFixed(2),
              PharmacycollectedCash: data.Pharmacy_Net_Amount.toFixed(2),
              ProcedurecollectedCash: data.Procedure_Net_Amount.toFixed(2),
              ConsultancycollectedCash: data.Consultancy_Net_Amount.toFixed(2),
              totalCash: total.toFixed(2),
              availableCash: available.toFixed(2),
              refundcash: data.Refunt_amount.toFixed(2),
            }));
          } else {
            setFormData((prev) => ({
              ...prev,
              invoiceNo: data.DCD_Invoice_No,
              date: data.DCD_Date,
              issueFromId: data.Issue_from_Id,
              issueFromName: data.Issue_from_Name,
              issueToId: data.Issue_To_Id,
              issueToName: data.Issue_To_Name,
              openingCash: data.Opening_Cash,
              PharmacycollectedCash: data.Pharmacy_Collected_Cash,
              ProcedurecollectedCash: data.Procedure_Collected_Cash,
              ConsultancycollectedCash: data.Consultancy_Collected_Cash,
              totalCash: data.Total_Cash,
              handoverCash: data.HandOver_Cash,
              expenses: data.Expenses_Cash,
              refundcash: data.refundcash,
              balanceCash: data.Balance_Cash,
              availableCash: data.Available_cash,
              reason: data.Reason,
              Branch_Name: data.Location,
            }));
          }

          setDisapleDayclose(datacondition);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [userRecord]);

  const getdata = () => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd");
    setFormData((prev) => ({
      ...prev,
      issueFromId: userRecord?.user_id,
      issueFromName: userRecord?.username,
      date: formattedDate,
    }));
    axios
      .get(
        `${urllink}Pettycash_link/get_Day_closing_invoice?location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;
        setFormData((prev) => ({
          ...prev,
          invoiceNo: data.nextInvoiceNumber,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(
        `${urllink}Pettycash_link/get_Day_Closing_Data?location=${userRecord?.location}&BillingDate=${formattedDate}`
      )
      .then((response) => {
        const data = response.data;
        const total =
          data.Opening_Cash +
          data.Pharmacy_Net_Amount +
          data.Procedure_Net_Amount +
          data.Consultancy_Net_Amount;
        const available = +total - (data.Expenses_Amount + data.Refunt_amount);
        setFormData((prev) => ({
          ...prev,
          openingCash: data.Opening_Cash.toFixed(2),
          expenses: data.Expenses_Amount.toFixed(2),
          PharmacycollectedCash: data.Pharmacy_Net_Amount.toFixed(2),
          ProcedurecollectedCash: data.Procedure_Net_Amount.toFixed(2),
          ConsultancycollectedCash: data.Consultancy_Net_Amount.toFixed(2),
          totalCash: total.toFixed(2),
          availableCash: available.toFixed(2),
          refundcash: data.Refunt_amount.toFixed(2),
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSave = () => {
    const requiredFields = [
      "issueToId",
      "issueToName",
      "handoverCash",
      "reason",
    ];
    const emptyFields = requiredFields.filter((field) => !formData[field]);
    if (emptyFields.length > 0) {
      alert(`The following fields are required: ${emptyFields.join(", ")}`);
      return;
    } else {
      axios
        .post("${urllink}Pettycash_link/Post_Day_Closing_Data", formData)
        .then((response) => {
          const data = response.data.message;
          if (data) {
            alert(data);
          }
          setFormData({
            invoiceNo: "",
            date: "",
            issueFromId: "",
            issueFromName: "",
            issueToId: "",
            issueToName: "",
            openingCash: "",
            PharmacycollectedCash: "",
            ConsultancycollectedCash: "",
            ProcedurecollectedCash: "",
            totalCash: "",
            handoverCash: "",
            refundcash: "",
            expenses: "",
            balanceCash: "",
            availableCash: "",
            reason: "",
            Branch_Name: userRecord?.location,
          });
          getdata();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Day Closing</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="">
            Invoice No <span>:</span>
          </label>
          <input
            type="number"
            readOnly
            name="invoiceNo"
            value={formData.invoiceNo}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Date <span>:</span>
          </label>
          <input
            type="date"
            readOnly
            name="date"
            value={formData.date}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Issue From <span>:</span>
          </label>
          <div className="Issue_input">
            <input
              type="number"
              name="issueFromId"
              readOnly
              value={formData.issueFromId}
              className="dura_with1"
              onChange={handleChange}
              disabled={DisapleDayclose}
            />
            <input
              type="text"
              name="issueFromName"
              readOnly
              className="saxdsed"
              value={formData.issueFromName}
              onChange={handleChange}
              disabled={DisapleDayclose}
            />
          </div>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Issue To <span>:</span>
          </label>
          <div className="Issue_input">
            <input
              type="number"
              name="issueToId"
              list="issueToId_1"
              className="dura_with1"
              value={formData.issueToId}
              onChange={handleChange}
              disabled={DisapleDayclose}
            />
            <datalist id="issueToId_1">
              {userList.map((item, index) => (
                <option key={index} value={item.user_id}></option>
              ))}
            </datalist>
            <input
              type="text"
              name="issueToName"
              list="issueToId_2"
              className="saxdsed"
              value={formData.issueToName}
              onChange={handleChange}
              disabled={DisapleDayclose}
            />
            <datalist id="issueToId_2">
              {userList.map((item, index) => (
                <option key={index} value={item.username}></option>
              ))}
            </datalist>
          </div>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Opening cash <span>:</span>
          </label>
          <input
            type="number"
            name="openingCash"
            value={formData.openingCash}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Pharmacy Collected Cash <span>:</span>
          </label>
          <input
            type="number"
            name="collectedCash"
            readOnly
            value={formData.PharmacycollectedCash}
            disabled={DisapleDayclose}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Consulation Collected cash <span>:</span>
          </label>
          <input
            type="number"
            name="openingCash"
            readOnly
            value={formData.ConsultancycollectedCash}
            disabled={DisapleDayclose}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Procedure Collected Cash <span>:</span>
          </label>
          <input
            type="number"
            name="collectedCash"
            readOnly
            value={formData.ProcedurecollectedCash}
            disabled={DisapleDayclose}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Total cash <span>:</span>
          </label>
          <input
            type="number"
            name="totalCash"
            value={formData.totalCash}
            readOnly
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Expenses <span>:</span>
          </label>
          <input
            type="number"
            name="expenses"
            readOnly
            value={formData.expenses}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            {" "}
            Refund Cash <span>:</span>
          </label>
          <input
            type="number"
            name="refundcash"
            readOnly
            value={formData.refundcash}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            {" "}
            Available Cash <span>:</span>
          </label>
          <input
            type="number"
            name="availableCash"
            readOnly
            value={formData.availableCash}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            HandOver Cash <span>:</span>
          </label>
          <input
            type="number"
            name="handoverCash"
            value={formData.handoverCash}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Balance <span>:</span>
          </label>
          <input
            type="number"
            name="balanceCash"
            readOnly
            value={formData.balanceCash}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Reason <span>:</span>
          </label>
          <textarea
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            disabled={DisapleDayclose}
          />
        </div>
      </div>
      <div className="Register_btn_con">
        {DisapleDayclose ? (
          <button className="RegisterForm_1_btns" disabled={DisapleDayclose}>
            Closed
          </button>
        ) : (
          <button className="RegisterForm_1_btns" onClick={handleSave}>
            save
          </button>
        )}
      </div>
    </div>
  );
};

export default DayClosing;
