import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
// import { saveAs } from 'file-saver';
import Button from "@mui/material/Button";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const IndentIssueMaster = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);
  const IndentIssueListData = useSelector(
    (state) => state.userRecord?.IndentIssueListData
  );

  console.log(IndentIssueListData);
  const navigate = useNavigate();

  const [datalist, setdatalist] = useState([]);

  const cu_date = format(new Date(), "yyyy-MM-dd");

  console.log("vathuruchu", datalist);

  const [isedit, setisedit] = useState(null);

  const [Selecteditemdata, setSelecteditemdata] = useState([]);
  const [AvailQuantity, setAvailQuantity] = useState(0);
  console.log(Selecteditemdata);
  const [OverallPurchaseRecieveFormData, setOverallPurchaseRecieveFormData] =
    useState({
      Indent_Issue_Invoice_NO: "",
      Indent_Issue_Date: cu_date,
      ProductCategory: "",
      IndentRaiseInvoice: "",
      Request_From: "",
      Request_Raise_Date: "",
      Request_To: "",
      Reason: "",
      CreatedBy: userRecord?.username,
      Location: "CentralStore",
      RecievedBy: "",
      Finel_NetAmount: "",
      Issue_From: "",
      Issue_To: "",
    });

  const [PurchaseRecieveFormData, setPurchaseRecieveFormData] = useState({
    Item_Code: "",
    Item_Name: "",
    Generic_Name: "",
    Strength: "",
    UOM: "",
    Pack_type: "",
    Pack_Quantity: "",
    Order_Quantity: "",
    TotalQuantity: "",
    Issue_Quantity: "",
    Issue_TotalQuantity: "",
    HSNCode: "",
    BatchNo: "",
    ManufactureDate: "",
    ExpiryDate: "",
    MRP: "",
    Tax_Percentage: "",
    Selling_Rate: "",
    Taxable_Selling_Rate: "",
    TotalAmount: "",
  });

  console.log("PurchaseRecieveFormData :", PurchaseRecieveFormData);

  console.log(
    "OverallPurchaseRecieveFormData :",
    OverallPurchaseRecieveFormData
  );

  const [PurchaseRecieveFormData1, setPurchaseRecieveFormData1] = useState({
    Item_Code: "",
    Item_Name: "",
    Generic_Name: "None",
    Strength: "None",
    UOM: "None",
    Pack_type: "",
    Pack_Quantity: "",
    Order_Quantity: "",
    TotalQuantity: "",
    Issue_Quantity: "",
    Issue_TotalQuantity: "",
    HSNCode: "",
    BatchNo: "",
    ManufactureDate: "",
    ExpiryDate: "",
    MRP: "",
    Tax_Percentage: "",
    Selling_Rate: "",
    Taxable_Selling_Rate: "",
    TotalAmount: "",
  });

  console.log("PurchaseRecieveFormData1 :", PurchaseRecieveFormData1);

  const [datalist1, setdatalist1] = useState([]);
  const [locationOptions, setlocationOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const showdown = datalist.length;
  const showdown1 = datalist1.length;
  const totalPages = Math.ceil(datalist.length / 10);
  const totalPages1 = Math.ceil(datalist1.length / 10);

  console.log("cu_date:", datalist1);


  const fetchLocationData = () => {
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data;
        let Bdata = data
        .filter(ele =>  ele.location_name !== userRecord.location)
        .map(ele => {
          if (ele.location_name === 'ALL') {
            ele.location_name = 'CentralStore';
          }
          return ele;
        });
        setlocationOptions(Bdata);
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  useEffect(()=>{
  fetchLocationData()
  },[])

  console.log('locationOptions',locationOptions)

  // useEffect(() => {
  //   axios
  //     .get("http://127.0.0.1:8000/usercontrol/getlocationdata")
  //     .then((response) => {
  //       const data = response.data;
  //       if (data) {
  //         const updatedData = data.filter(
  //           (p) => p.status === "Active" && p.location_name !== "ALL"
  //         );

  //         setlocationOptions([...updatedData]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching Location options:", error);
  //     });
  // }, []);

  useEffect(() => {
    console.log("qwe", IndentIssueListData);

    if (IndentIssueListData && Object.keys(IndentIssueListData).length !== 0) {
      if (
        IndentIssueListData.Productype === "MedicalConsumable" ||
        IndentIssueListData.Productype === "MedicalNonConsumable" ||
        IndentIssueListData.Productype === "NonMedicalConsumable" ||
        IndentIssueListData.Productype === "NonMedicalNonConsumable" ||
        IndentIssueListData.Productype === "Surgical"
      ) {
        axios
          .get(
            `${urllink}IndentRaise/get_Medical_Indent_Issue_InvoiceNo?producttype=${IndentIssueListData.Productype}`
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Issue_Invoice_NO: response.data.nextInvoiceNo,
              ProductCategory: IndentIssueListData.Productype,
              IndentRaiseInvoice: IndentIssueListData.IndentRaise_InvoiceNo,
              Request_From: IndentIssueListData.RequestFrom,
              Request_Raise_Date: IndentIssueListData.RequestDate,
              Request_To: IndentIssueListData.RequestTo,
              Issue_To: IndentIssueListData.RequestFrom,
            }));

            const A_data = IndentIssueListData.items;

            const b_data = A_data.filter((r)=>r.Status!=="Rejected").map((p, index) => {
              const { Approved_Quantity, Pack_Quantity, ...rest } = p;

              // Calculate TotalQuantity based on Approved_Quantity and Pack_Quantity
              const TotalQuantity = Approved_Quantity * Pack_Quantity;

              return {
                id: index + 1,
                ...rest,
                ApprovedQuantity: Approved_Quantity,
                Pack_Quantity: Pack_Quantity,
                TotalQuantity: TotalQuantity,
              };
            });

            console.log("A_data", b_data);

            setdatalist(b_data);
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (IndentIssueListData.Productype === "Assets") {
        axios
          .get(
            `${urllink}IndentRaise/get_Assets_Indent_Issue_InvoiceNo`
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Issue_Invoice_NO: response.data.nextInvoiceNo,
              ProductCategory: IndentIssueListData.Productype,
              IndentRaiseInvoice: IndentIssueListData.IndentRaise_InvoiceNo,
              Request_From: IndentIssueListData.RequestFrom,
              Request_Raise_Date: IndentIssueListData.RequestDate,
              Request_To: IndentIssueListData.RequestTo,
              Issue_To: IndentIssueListData.RequestFrom,
            }));

            const A_data = IndentIssueListData.items;

            const b_data = A_data.map((p, index) => {
              const { Approved_Quantity, Pack_Quantity, ...rest } = p;

              // Calculate TotalQuantity based on Approved_Quantity and Pack_Quantity
              const TotalQuantity = Approved_Quantity * Pack_Quantity;

              return {
                id: index + 1,
                ...rest,
                ApprovedQuantity: Approved_Quantity,
                Pack_Quantity: Pack_Quantity, // Include Pack_Quantity in b_data
                TotalQuantity: TotalQuantity,
              };
            });

            console.log("A_data", b_data);

            setdatalist(b_data);
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (IndentIssueListData.Productype === "Stationary") {
        axios
          .get(
            `${urllink}IndentRaise/get_Stationary_Indent_Issue_InvoiceNo`
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Issue_Invoice_NO: response.data.nextInvoiceNo,
              ProductCategory: IndentIssueListData.Productype,
              IndentRaiseInvoice: IndentIssueListData.IndentRaise_InvoiceNo,
              Request_From: IndentIssueListData.RequestFrom,
              Request_Raise_Date: IndentIssueListData.RequestDate,
              Request_To: IndentIssueListData.RequestTo,
              Issue_To: IndentIssueListData.RequestFrom,
            }));
            const A_data = IndentIssueListData.items;

            const b_data = A_data.map((p, index) => {
              const { Approved_Quantity, Pack_Quantity, ...rest } = p;

              // Calculate TotalQuantity based on Approved_Quantity and Pack_Quantity
              const TotalQuantity = Approved_Quantity * Pack_Quantity;

              return {
                id: index + 1,
                ...rest,
                ApprovedQuantity: Approved_Quantity,
                Pack_Quantity: Pack_Quantity, // Include Pack_Quantity in b_data
                TotalQuantity: TotalQuantity,
              };
            });

            console.log("A_data", b_data);

            setdatalist(b_data);
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (IndentIssueListData.Productype === "Non_Stationary") {
        axios
          .get(
            `${urllink}IndentRaise/get_Non_Stationary_Indent_Issue_InvoiceNo`
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Issue_Invoice_NO: response.data.nextInvoiceNo,
              ProductCategory: IndentIssueListData.Productype,
              IndentRaiseInvoice: IndentIssueListData.IndentRaise_InvoiceNo,
              Request_From: IndentIssueListData.RequestFrom,
              Request_Raise_Date: IndentIssueListData.RequestDate,
              Request_To: IndentIssueListData.RequestTo,
              Issue_To: IndentIssueListData.RequestFrom,
            }));

            const A_data = IndentIssueListData.items;

            const b_data = A_data.map((p, index) => {
              const { Approved_Quantity, Pack_Quantity, ...rest } = p;

              // Calculate TotalQuantity based on Approved_Quantity and Pack_Quantity
              const TotalQuantity = Approved_Quantity * Pack_Quantity;

              return {
                id: index + 1,
                ...rest,
                ApprovedQuantity: Approved_Quantity,
                Pack_Quantity: Pack_Quantity, // Include Pack_Quantity in b_data
                TotalQuantity: TotalQuantity,
              };
            });

            console.log("A_data", b_data);

            setdatalist(b_data);
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [IndentIssueListData]);

  useEffect(() => {
    console.log("qwe", IndentIssueListData);
    console.log(OverallPurchaseRecieveFormData.ProductCategory);

    if (
      PurchaseRecieveFormData.Item_Code ||
      PurchaseRecieveFormData1.Item_Code ||
      OverallPurchaseRecieveFormData.Issue_From
    ) {
      if (
        OverallPurchaseRecieveFormData.ProductCategory ===
          "MedicalConsumable" ||
        OverallPurchaseRecieveFormData.ProductCategory ===
          "MedicalNonConsumable" ||
        OverallPurchaseRecieveFormData.ProductCategory ===
          "NonMedicalConsumable" ||
        OverallPurchaseRecieveFormData.ProductCategory ===
          "NonMedicalNonConsumable" ||
        OverallPurchaseRecieveFormData.ProductCategory === "Surgical"
      ) {
        axios
          .get(
            `${urllink}quickstockreceive/get_medical_stock_information?Location=${OverallPurchaseRecieveFormData.Issue_From}&Item_Code=${PurchaseRecieveFormData.Item_Code}&producttype=${OverallPurchaseRecieveFormData.ProductCategory}`
          )
          .then((response) => {
            console.log("wrt:", response.data);
            setSelecteditemdata(response.data);
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }

      if (
        OverallPurchaseRecieveFormData.ProductCategory !==
          "MedicalConsumable" &&
        OverallPurchaseRecieveFormData.ProductCategory !==
          "MedicalNonConsumable" &&
        OverallPurchaseRecieveFormData.ProductCategory !==
          "NonMedicalConsumable" &&
        OverallPurchaseRecieveFormData.ProductCategory !==
          "NonMedicalNonConsumable" &&
        OverallPurchaseRecieveFormData.ProductCategory !== "Surgical"
      ) {
        axios
          .get(
            `${urllink}quickstockreceive/getAssets_Stock_information?Location=${OverallPurchaseRecieveFormData.Issue_From}&Item_Code=${PurchaseRecieveFormData1.Item_Code}&Productype=${OverallPurchaseRecieveFormData.ProductCategory}`
          )
          .then((response) => {
            console.log("wrt:", response.data);
            let d_data = response.data.stock_information[0];
            console.log("d_data", d_data);

            setPurchaseRecieveFormData1((prev) => ({
              ...prev,
              MRP: d_data.MRP_Per_Quantity,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [
    PurchaseRecieveFormData.Item_Code,
    PurchaseRecieveFormData1.Item_Code,
    OverallPurchaseRecieveFormData.ProductCategory,
    OverallPurchaseRecieveFormData.Issue_From,
  ]);

  useEffect(() => {
    if (datalist1.length !== 0) {
      // console.log('datalist1:',datalist1)

      let G_Total = 0;

      datalist1.map((ele, ind) => {
        return (G_Total += +ele.TotalAmount);
      });

      console.log("G_Total:", G_Total);

      setOverallPurchaseRecieveFormData((prev) => ({
        ...prev,
        Finel_NetAmount: G_Total,
      }));
    }
  }, [datalist1]);

  const dynamicColumns = () => {
    if (
      OverallPurchaseRecieveFormData.ProductCategory === "MedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "MedicalNonConsumable"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 120 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 140 },
        { field: "ApprovedQuantity", headerName: "Order Quantity", width: 140 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 140 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else if (
      OverallPurchaseRecieveFormData.ProductCategory ===
        "NonMedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory ===
        "NonMedicalNonConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "Surgical"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Pack_type", headerName: "Pack Type", width: 170 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 170 },
        { field: "ApprovedQuantity", headerName: "Order_Quantity", width: 170 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 140 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Pack_type", headerName: "Pack Type", width: 170 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 170 },
        { field: "ApprovedQuantity", headerName: "Order_Quantity", width: 170 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 140 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEditNon(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    }
  };

  const dynamicColumns1 = () => {
    if (
      OverallPurchaseRecieveFormData.ProductCategory === "MedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "MedicalNonConsumable"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 100 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Order_Quantity", headerName: "Order Quantity", width: 150 },
        { field: "Issue_Quantity", headerName: "Issued Quantity", width: 170 },
        { field: "MRP", headerName: "MRP", width: 150 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        {
          field: "Taxable_Selling_Rate",
          headerName: "Taxable Selling Rate",
          width: 170,
        },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else if (
      OverallPurchaseRecieveFormData.ProductCategory ===
        "NonMedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory ===
        "NonMedicalNonConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "Surgical"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Order_Quantity", headerName: "Order Quantity", width: 150 },
        { field: "Issue_Quantity", headerName: "Issued Quantity", width: 170 },
        { field: "MRP", headerName: "MRP", width: 150 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        {
          field: "Taxable_Selling_Rate",
          headerName: "Taxable Selling Rate",
          width: 170,
        },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Pack_type", headerName: "Pack Type", width: 170 },
        { field: "Order_Quantity", headerName: "Pack Quantity", width: 170 },
        {
          field: "Issue_Quantity",
          headerName: "Recieved Quantity",
          width: 170,
        },
        { field: "MRP", headerName: "MRP", width: 170 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEditNon1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    }
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };

  const handlechangeissueto = (e) => {
    const { name, value } = e.target;

    setOverallPurchaseRecieveFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "BatchNo") {
      const S_data = Selecteditemdata.find((ele) => {
        return ele.Batch_No === value;
      });
      console.log("S_data:", S_data);
      if (S_data) {
        setAvailQuantity(S_data.AvailableQuantity)
        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          [name]: value,
          HSNCode: S_data.HSN_Code,
          ManufactureDate: S_data.ManufactureDate,
          ExpiryDate: S_data.ExpiryDate,
          MRP: S_data.MRP_Per_Quantity,
          Selling_Rate: S_data.Selling_Rate,
          Tax_Percentage: S_data.Tax_Percentage,
          Taxable_Selling_Rate: (+S_data.Taxable_Selling_Rate).toFixed(2),
          GRN_InvoiceNo: S_data.GRN_InvoiceNo,
        }));
      } else {
        setAvailQuantity(0)
        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          [name]: value,
          HSNCode: "",
          ManufactureDate: "",
          ExpiryDate: "",
          MRP: "",
          Selling_Rate: "",
          Tax_Percentage: "",
          Taxable_Selling_Rate: "",
        }));
      }
    } else if (name === "Issue_Quantity") {
      if (+value <= +AvailQuantity) {
        const S_data = Selecteditemdata[0];
        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          [name]: value,
          Issue_TotalQuantity: value * PurchaseRecieveFormData.Pack_Quantity,
          MRP: S_data.MRP_Per_Quantity,
        }));
      } else {
        alert(`Available Quantity is less the the entered Quantity ,enter belew the ${AvailQuantity}`);
      }
    } else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const T_Amount =
      PurchaseRecieveFormData.Taxable_Selling_Rate *
      PurchaseRecieveFormData.Issue_TotalQuantity;

    setPurchaseRecieveFormData((prev) => ({
      ...prev,
      TotalAmount: T_Amount.toFixed(2),
    }));
  }, [
    PurchaseRecieveFormData.Issue_TotalQuantity,
    PurchaseRecieveFormData.Taxable_Selling_Rate,
  ]);

  const handleInputFormNonChange = (e) => {
    const { name, value } = e.target;

    if (name === "Issue_Quantity") {
      if (+value <= +PurchaseRecieveFormData1.Order_Quantity) {
        setPurchaseRecieveFormData1((prev) => ({
          ...prev,
          [name]: value,
          Issue_TotalQuantity: value * PurchaseRecieveFormData1.Pack_Quantity,
          TotalAmount:
            value *
            PurchaseRecieveFormData1.Pack_Quantity *
            PurchaseRecieveFormData1.MRP,
        }));
      } else {
        alert("Enter Correct Issue Quantity");
      }
    } else {
      setPurchaseRecieveFormData1((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRequestEdit = (params) => {
    if(OverallPurchaseRecieveFormData.Issue_From !==''){
      setPurchaseRecieveFormData({
        Item_Code: params.Item_Code,
        Item_Name: params.Item_Name,
        Generic_Name: params.Generic_Name,
        Strength: params.Strength,
        UOM: params.UOM,
        Pack_type: params.Pack_type,
        Pack_Quantity: params.Pack_Quantity,
        Order_Quantity: params.ApprovedQuantity,
        TotalQuantity: params.TotalQuantity,
        Issue_Quantity: "",
        Issue_TotalQuantity: "",
        HSNCode: "",
        BatchNo: "",
        ManufactureDate: "",
        ExpiryDate: "",
        MRP: "",
        Tax_Percentage: "",
        Selling_Rate: "",
        Taxable_Selling_Rate: "",
        TotalAmount: "",
      });
    }else{
      alert('Select The Issue From')
    }
  };
  const handleRequestEdit1 = (params) => {
    setPurchaseRecieveFormData({ ...params });
    setisedit(params);
  };
  const handleAdd = () => {
    let requiredFields;

    if (
      OverallPurchaseRecieveFormData.ProductCategory === "MedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "MedicalNonConsumable"
    ) {
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Generic_Name",
        "Strength",
        "UOM",
        "Pack_type",
        "Order_Quantity",
        "TotalQuantity",
        "Issue_Quantity",
        "Issue_TotalQuantity",
        "HSNCode",
        "BatchNo",
        "ManufactureDate",
        "ExpiryDate",
        "MRP",
        "Tax_Percentage",
        "Selling_Rate",
        "Taxable_Selling_Rate",
        "TotalAmount",
      ];
    }  else if(
      OverallPurchaseRecieveFormData.ProductCategory ===
      "NonMedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory ===
      "NonMedicalNonConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "Surgical"
    ){
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Generic_Name",
        "Pack_type",
        "Order_Quantity",
        "TotalQuantity",
        "Issue_Quantity",
        "Issue_TotalQuantity",
        "HSNCode",
        "BatchNo",
        "ManufactureDate",
        "ExpiryDate",
        "MRP",
        "Tax_Percentage",
        "Selling_Rate",
        "Taxable_Selling_Rate",
        "TotalAmount",
      ];
    }else{
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Generic_Name",
        "Pack_type",
        "Order_Quantity",
        "TotalQuantity",
        "Issue_Quantity",
        "Issue_TotalQuantity",
        "HSNCode",
        "BatchNo",
        "ManufactureDate",
        "ExpiryDate",
        "MRP",
        "Tax_Percentage",
        "Selling_Rate",
        "Taxable_Selling_Rate",
        "TotalAmount",
      ];
    }

    if (
      (PurchaseRecieveFormData1.GST === null &&
        PurchaseRecieveFormData1.IGST === null) ||
      (PurchaseRecieveFormData1.GST === 0 &&
        PurchaseRecieveFormData1.IGST === 0) ||
      (PurchaseRecieveFormData1.GST === "" &&
        PurchaseRecieveFormData1.IGST === "")
    ) {
      requiredFields.push("GST");
    }

    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      console.log("PurchaseRecieveFormData", PurchaseRecieveFormData);
      let id = 0;
      const data = { id: datalist1.length + 1, ...PurchaseRecieveFormData };

      console.log("dataArray", data);
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) =>
        prev.filter(
          (item) => item.Item_Code !== PurchaseRecieveFormData.Item_Code
        )
      );
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleUpdate = () => {
    const requiredFields = [
      "Item_Code",
      "Item_Name",
      "Generic_Name",
      "Strength",
      "UOM",
      "Pack_type",
      "Order_Quantity",
      "TotalQuantity",
      "Issue_Quantity",
      "Issue_TotalQuantity",
      "HSNCode",
      "BatchNo",
      "ManufactureDate",
      "ExpiryDate",
      "MRP",
      "Tax_Percentage",
      "Selling_Rate",
      "Taxable_Selling_Rate",
      "TotalAmount",
    ];

    if (
      (PurchaseRecieveFormData1.GST === null &&
        PurchaseRecieveFormData1.IGST === null) ||
      (PurchaseRecieveFormData1.GST === 0 &&
        PurchaseRecieveFormData1.IGST === 0) ||
      (PurchaseRecieveFormData1.GST === "" &&
        PurchaseRecieveFormData1.IGST === "")
    ) {
      requiredFields.push("GST");
    }
    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex(
        (item) => item.Item_Code === isedit.Item_Code
      );
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData;
        return updatedCart;
      });
      setisedit(null);
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  // non medical
  const handleRequestEditNon = (params) => {
    if(OverallPurchaseRecieveFormData.Issue_From !==''){
    setPurchaseRecieveFormData1({
      Item_Code: params.Item_Code,
      Item_Name: params.Item_Name,
      Generic_Name: params.Generic_Name,
      Pack_type: params.Pack_type,
      Pack_Quantity: params.Pack_Quantity,
      Order_Quantity: params.ApprovedQuantity,
      TotalQuantity: params.TotalQuantity,
      Issue_Quantity: "",
      Issue_TotalQuantity: "",
      HSNCode: "",
      BatchNo: "",
      ManufactureDate: "",
      ExpiryDate: "",
      MRP: "",
      Tax_Percentage: "",
      Selling_Rate: "",
      Taxable_Selling_Rate: "",
      TotalAmount: "",
    });
  }else{
    alert('Enter the Issue From')
  }
  };
  const handleRequestEditNon1 = (params) => {
    setPurchaseRecieveFormData1({ ...params });
    setisedit(params);
  };
  const handleClear = () => {
    if (OverallPurchaseRecieveFormData.ProductCategory ===
      "MedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory ===
      "MedicalNonConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory ===
      "NonMedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory ===
      "NonMedicalNonConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "Surgical") {
      setPurchaseRecieveFormData({
        Item_Code: "",
        Item_Name: "",
        Generic_Name: "",
        Strength: "",
        UOM: "",
        Pack_type: "",
        Pack_Quantity: "",
        Order_Quantity: "",
        TotalQuantity: "",
        Issue_Quantity: "",
        Issue_TotalQuantity: "",
        HSNCode: "",
        BatchNo: "",
        ManufactureDate: "",
        ExpiryDate: "",
        MRP: "",
        Tax_Percentage: "",
        Selling_Rate: "",
        Taxable_Selling_Rate: "",
        TotalAmount: "",
      });
    } else {
      setPurchaseRecieveFormData1({
        Item_Code: "",
        Item_Name: "",
        Pack_type: "",
        Pack_Quantity: "",
        Order_Quantity: "",
        TotalQuantity: "",
        Issue_Quantity: "",
        Issue_TotalQuantity: "",
        MRP: "",
        TotalAmount: "",
      });
    }
  };

  const handleAddNon = () => {
    const requiredFields = [
      "Item_Code",
      "Item_Name",
      "Pack_type",
      "Pack_Quantity",
      "Order_Quantity",
      "TotalQuantity",
      "Issue_Quantity",
      "Issue_TotalQuantity",
      "MRP",
      "TotalAmount",
    ];

    if (
      (PurchaseRecieveFormData1.GST === null &&
        PurchaseRecieveFormData1.IGST === null) ||
      (PurchaseRecieveFormData1.GST === 0 &&
        PurchaseRecieveFormData1.IGST === 0) ||
      (PurchaseRecieveFormData1.GST === "" &&
        PurchaseRecieveFormData1.IGST === "")
    ) {
      requiredFields.push("GST");
    }
    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData1[field]
    );

    if (missingFields.length === 0) {
      const data = { id: datalist1.length + 1, ...PurchaseRecieveFormData1 };
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) =>
        prev.filter(
          (item) => item.Item_Code !== PurchaseRecieveFormData1.Item_Code
        )
      );
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };
  const handleUpdateNon = () => {
    const requiredFields = [
      "Item_Code",
      "Item_Name",
      "Pack_type",
      "Pack_Quantity",
      "Order_Quantity",
      "TotalQuantity",
      "Issue_Quantity",
      "Issue_TotalQuantity",
      "MRP",
      "TotalAmount",
    ];

    if (
      (PurchaseRecieveFormData1.GST === null &&
        PurchaseRecieveFormData1.IGST === null) ||
      (PurchaseRecieveFormData1.GST === 0 &&
        PurchaseRecieveFormData1.IGST === 0) ||
      (PurchaseRecieveFormData1.GST === "" &&
        PurchaseRecieveFormData1.IGST === "")
    ) {
      requiredFields.push("GST");
    }

    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData1[field]
    );

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex(
        (item) => item.Item_Code === isedit.Item_Code
      );
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData1;
        return updatedCart;
      });
      setisedit(null);
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  // const handleExportToExcel = () => {
  //     if(datalist1.length !==0){
  //         let columns;
  //         if(OverallPurchaseRecieveFormData.ProductCategory === 'Medical'){
  //            columns = [
  //                 { header: 'Item Code', dataKey: 'Item_Code' },
  //                 { header: 'Item Name', dataKey: 'Item_Name' },
  //                 { header: 'Generic_Name', dataKey: 'Generic_Name' },
  //                 { header: 'Strength', dataKey: 'Strength' },
  //                 { header: 'UOM', dataKey: 'UOM' },
  //                 { header: 'ManufactureDate', dataKey: 'ManufactureDate' },
  //                 { header: 'ExpiryDate', dataKey: 'ExpiryDate' },
  //                 { header: 'HSNCode', dataKey: 'HSNCode' },
  //                 { header: 'BatchNo', dataKey: 'BatchNo' },
  //                 { header: 'Pack_type', dataKey: 'Pack' },
  //                 { header: 'Order_Quantity', dataKey: 'Order_Quantity' },
  //                 { header: 'PerOrder_Quantity', dataKey: 'PerOrder_Quantity' },
  //                 { header: 'RecievedPack', dataKey: 'RecievedPack' },
  //                 { header: 'Issue_Quantity', dataKey: 'Issue_Quantity' },
  //                 { header: 'RecievedPerOrder_Quantity', dataKey: 'RecievedPerOrder_Quantity' },
  //                 { header: 'PendingQuantity', dataKey: 'PendingQuantity' },
  //                 { header: 'Reason', dataKey: 'Reason' },
  //                 { header: 'MRP', dataKey: 'MRP' },
  //                 { header: 'GST', dataKey: 'GST' },
  //                 { header: 'IGST', dataKey: 'IGST' },
  //                 { header: 'DiscountType', dataKey: 'DiscountType' },
  //                 { header: 'Discount', dataKey: 'Discount' },
  //                 { header: 'TotalAmount', dataKey: 'TotalAmount' },
  //               ];
  //         }else{
  //             columns = [
  //                 { header: 'Item Code', dataKey: 'Item_Code' },
  //                 { header: 'Item Name', dataKey: 'Item_Name' },
  //                 { header: 'Pack_type', dataKey: 'Pack' },
  //                 { header: 'Order_Quantity', dataKey: 'Order_Quantity' },
  //                 { header: 'PerOrder_Quantity', dataKey: 'PerOrder_Quantity' },
  //                 { header: 'RecievedPack', dataKey: 'RecievedPack' },
  //                 { header: 'Issue_Quantity', dataKey: 'Issue_Quantity' },
  //                 { header: 'RecievedPerOrder_Quantity', dataKey: 'RecievedPerOrder_Quantity' },
  //                 { header: 'PendingQuantity', dataKey: 'PendingQuantity' },
  //                 { header: 'Reason', dataKey: 'Reason' },
  //                 { header: 'MRP', dataKey: 'MRP' },
  //                 { header: 'GST', dataKey: 'GST' },
  //                 { header: 'IGST', dataKey: 'IGST' },
  //                 { header: 'DiscountType', dataKey: 'DiscountType' },
  //                 { header: 'Discount', dataKey: 'Discount' },
  //                 { header: 'TotalAmount', dataKey: 'TotalAmount' },
  //               ];
  //         }

  //         const header = columns.map(col => col.header);

  //         const csv = [
  //             `ProductCategory : ${OverallPurchaseRecieveFormData.ProductCategory}`,
  //             `IndentRaiseInvoice : ${OverallPurchaseRecieveFormData.IndentRaiseInvoice}`,
  //             `PurchaseRaiseDate : ${OverallPurchaseRecieveFormData.Purchaserise_date}`,
  //             `Raised By : ${OverallPurchaseRecieveFormData.CreatedBy}`,
  //             `SupplierCode : ${OverallPurchaseRecieveFormData.SupplierCode}`,
  //             `Request_From : ${OverallPurchaseRecieveFormData.Request_From}`,
  //             `GRN Invoice : ${OverallPurchaseRecieveFormData.PurchaseRecieveInvoice}`,
  //             `GRN Date : ${OverallPurchaseRecieveFormData.Purchaserecieve_date}`,
  //             `GRN Recieved By : ${OverallPurchaseRecieveFormData.RecievedBy}`,
  //             " ",
  //             '\ufeff' + header.join(","), // BOM + header row
  //             ...datalist1.map(row => columns.map(col => row[col.dataKey])),
  //             '', // Empty row for spacing
  //         ].join("\r\n");

  //         var data = new Blob([csv], { type: "text/csv" });
  //         // Fix the template literal for saveAs function
  //         saveAs(data, `Report_${new Date().toISOString()}.csv`);
  //     }else{
  //         alert('No Data to Save')
  //     }
  // };

  const handleSubmit = () => {
    const requiredFields = ["Finel_NetAmount", "Issue_From", "Issue_To"];

    const missingFields = requiredFields.filter(
      (field) => !OverallPurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const newdataa = {
        OverallPurchaseRecieveFormData: { ...OverallPurchaseRecieveFormData },
        datalist1: [...datalist1],
      };

      axios
        .post(
          `${urllink}IndentRaise/All_indent_issue_insert`,
          newdataa
        )
        .then((response) => {
          console.log(response.data);
          navigate("/Home/Indent_Issue_list");
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });

      console.log(newdataa);
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  return (
    <>
      <div className="Supplier_Master_Container">
        <div className="Product_Master_h_head">
          <h3>Indent Issue Invoice</h3>
        </div>
        <div className="Stoct_Product_master_Container">
          <div className="Stoct_Product_master_form">
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Indent Issue Invoice No :
                </label>
                <input
                  type="text"
                  name="Indent_Issue_Invoice_NO"
                  placeholder="Enter Product Category"
                  value={OverallPurchaseRecieveFormData.Indent_Issue_Invoice_NO}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Indent Issue Date :
                </label>
                <input
                  type="date"
                  name="Indent_Issue_Date"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.Indent_Issue_Date}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Product Category :
                </label>
                <input
                  type="text"
                  name="ProductCategory"
                  placeholder="Enter Product Category"
                  value={OverallPurchaseRecieveFormData.ProductCategory}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Raise Invoice No :
                </label>
                <input
                  type="text"
                  name="IndentRaiseInvoice"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.IndentRaiseInvoice}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Stoct_Product_master_Container">
          <div className="Stoct_Product_master_form">
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Request From :
                </label>
                <input
                  type="text"
                  name="Request_From"
                  placeholder="Enter Supplier Name"
                  value={OverallPurchaseRecieveFormData.Request_From}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Request Raise Date :
                </label>
                <input
                  type="date"
                  name="Request_Raise_Date"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.Request_Raise_Date}
                  className="Stoct_Product_master_form_Input"
                  readOnly
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Request To :
                </label>
                <input
                  type="text"
                  name="Request_To"
                  placeholder="Enter Supplier Code"
                  value={OverallPurchaseRecieveFormData.Request_To}
                  autoComplete="off"
                  className="Stoct_Product_master_form_Input"
                  readOnly
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Net Amount :
                </label>
                <input
                  type="number"
                  name="Finel_NetAmount"
                  placeholder="Enter Total Amount"
                  value={OverallPurchaseRecieveFormData.Finel_NetAmount}
                  readOnly
                  required
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Stoct_Product_master_Container">
          <div className="Stoct_Product_master_form">
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Issue From :
                </label>
                <select
                  name="Issue_From"
                  onChange={handlechangeissueto} 
                  value={OverallPurchaseRecieveFormData.Issue_From} 
              >
                  <option value="">Select</option>
                  {locationOptions.map((p, ind) => (
                      <option key={ind+'key'} value={p.location_name}>{p.location_name}</option>
                  ))}
              </select>
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Issue To :
                </label>
                <input
                  type="text"
                  name="Issue_To"
                  placeholder="Enter Issue To"
                  value={OverallPurchaseRecieveFormData.Issue_To}
                  className="Stoct_Product_master_form_Input"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={datalist.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns()} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && datalist.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && datalist.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>

        {(OverallPurchaseRecieveFormData.ProductCategory ===
          "MedicalConsumable" ||
          OverallPurchaseRecieveFormData.ProductCategory ===
            "MedicalNonConsumable") && (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Item Code :
                  </label>
                  <input
                    type="text"
                    name="Item_Code"
                    placeholder="Enter Item Code"
                    value={PurchaseRecieveFormData.Item_Code}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Item Name :
                  </label>
                  <input
                    type="text"
                    name="Item_Name"
                    placeholder="Enter Item Name"
                    value={PurchaseRecieveFormData.Item_Name}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Generic Name :
                  </label>
                  <input
                    type="text"
                    name="Generic_Name"
                    placeholder="Enter Generic Name"
                    value={PurchaseRecieveFormData.Generic_Name}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Strength :
                  </label>
                  <input
                    type="text"
                    name="Strength"
                    placeholder="Enter Strength"
                    value={PurchaseRecieveFormData.Strength}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    UOM :
                  </label>
                  <input
                    type="text"
                    name="UOM"
                    placeholder="Enter UOM"
                    value={PurchaseRecieveFormData.UOM}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Pack Type :
                  </label>
                  <input
                    type="text"
                    name="Pack_type"
                    placeholder="Enter Pack Type"
                    value={PurchaseRecieveFormData.Pack_type}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pack Quantity :
                  </label>
                  <input
                    type="number"
                    name="Pack_Quantity"
                    placeholder="Enter Pack Quantity"
                    value={PurchaseRecieveFormData.Pack_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Order Quantity :
                  </label>
                  <input
                    type="number"
                    name="Order_Quantity"
                    placeholder="Enter Order Quantity"
                    value={PurchaseRecieveFormData.Order_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="TotalQuantity"
                    placeholder="Enter Total Quantity"
                    value={PurchaseRecieveFormData.TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Batch No :
                  </label>
                  <input
                    id="browser"
                    list="browsers"
                    type="text"
                    name="BatchNo"
                    placeholder="Enter Batch No"
                    value={PurchaseRecieveFormData.BatchNo}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    autoComplete="off"
                  />

                  <datalist id="browsers">
                    {Selecteditemdata.map((item, index) => (
                      <option key={index} value={`${item.Batch_No}`}>
                        {`${item.Item_Name} | BatchNo:${
                          item.Batch_No
                        } | Ava.Quantity:${
                          item.AvailableQuantity
                        } | MRP:${parseFloat(item.MRP_Per_Quantity).toFixed(
                          2
                        )}`}
                      </option>
                    ))}
                  </datalist>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    HSN Code :
                  </label>
                  <input
                    type="text"
                    name="HSNCode"
                    placeholder="Enter HSNCode"
                    value={PurchaseRecieveFormData.HSNCode}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Manufacture Date :
                  </label>
                  <input
                    type="date"
                    name="ManufactureDate"
                    placeholder="Enter ManufactureDate"
                    value={PurchaseRecieveFormData.ManufactureDate}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Expiry Date :
                  </label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    placeholder="Enter ExpiryDate"
                    value={PurchaseRecieveFormData.ExpiryDate}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Issue Quantity :
                  </label>
                  <input
                    type="number"
                    name="Issue_Quantity"
                    placeholder="Enter Issue Pack Quantity "
                    value={PurchaseRecieveFormData.Issue_Quantity}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Issue Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="Issue_TotalQuantity"
                    placeholder="Enter Issue TotalQuantity"
                    value={PurchaseRecieveFormData.Issue_TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    MRP (Per Quantity) :
                  </label>
                  <input
                    type="number"
                    name="MRP"
                    placeholder="Enter MRP"
                    value={PurchaseRecieveFormData.MRP}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Selling Rate (Per Quantity):
                  </label>
                  <input
                    type="number"
                    name="Selling_Rate"
                    placeholder="Enter Selling Rate"
                    value={PurchaseRecieveFormData.Selling_Rate}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Tax Percentage
                  </label>
                  <input
                    type="number"
                    name="Tax_Percentage"
                    placeholder="Enter Tax Percentage"
                    value={PurchaseRecieveFormData.Tax_Percentage}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Taxable Selling Rate :
                  </label>
                  <input
                    type="number"
                    name="Taxable_Selling_Rate"
                    placeholder="Enter Taxable Selling Rate"
                    value={PurchaseRecieveFormData.Taxable_Selling_Rate}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Amount :
                  </label>
                  <input
                    type="number"
                    name="TotalAmount"
                    placeholder="Enter Total Amount"
                    value={PurchaseRecieveFormData.TotalAmount}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_Save">
                <button
                  className=" Stoct_Product_master_form_Save_button"
                  onClick={isedit ? handleUpdate : handleAdd}
                >
                  {isedit ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {(OverallPurchaseRecieveFormData.ProductCategory ===
          "NonMedicalConsumable" ||
          OverallPurchaseRecieveFormData.ProductCategory ===
            "NonMedicalNonConsumable" ||
          OverallPurchaseRecieveFormData.ProductCategory === "Surgical") && (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Item Code :
                  </label>
                  <input
                    type="text"
                    name="Item_Code"
                    placeholder="Enter Item Code"
                    value={PurchaseRecieveFormData.Item_Code}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Item Name :
                  </label>
                  <input
                    type="text"
                    name="Item_Name"
                    placeholder="Enter Item Name"
                    value={PurchaseRecieveFormData.Item_Name}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Generic Name :
                  </label>
                  <input
                    type="text"
                    name="Generic_Name"
                    placeholder="Enter Generic Name"
                    value={PurchaseRecieveFormData.Generic_Name}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Pack Type :
                  </label>
                  <input
                    type="text"
                    name="Pack_type"
                    placeholder="Enter Pack Type"
                    value={PurchaseRecieveFormData.Pack_type}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pack Quantity :
                  </label>
                  <input
                    type="number"
                    name="Pack_Quantity"
                    placeholder="Enter Pack Quantity"
                    value={PurchaseRecieveFormData.Pack_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Order Quantity :
                  </label>
                  <input
                    type="number"
                    name="Order_Quantity"
                    placeholder="Enter Order Quantity"
                    value={PurchaseRecieveFormData.Order_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="TotalQuantity"
                    placeholder="Enter Total Quantity"
                    value={PurchaseRecieveFormData.TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Batch No :
                  </label>
                  <input
                    id="browser"
                    list="browsers"
                    type="text"
                    name="BatchNo"
                    placeholder="Enter Batch No"
                    value={PurchaseRecieveFormData.BatchNo}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    autoComplete="off"
                  />

                  <datalist id="browsers">
                    {Selecteditemdata.map((item, index) => (
                      <option key={index} value={`${item.Batch_No}`}>
                        {`${item.Item_Name} | BatchNo:${
                          item.Batch_No
                        } | Ava.Quantity:${
                          item.AvailableQuantity
                        } | MRP:${parseFloat(item.MRP_Per_Quantity).toFixed(
                          2
                        )}`}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    HSN Code :
                  </label>
                  <input
                    type="text"
                    name="HSNCode"
                    placeholder="Enter HSNCode"
                    value={PurchaseRecieveFormData.HSNCode}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Manufacture Date :
                  </label>
                  <input
                    type="date"
                    name="ManufactureDate"
                    placeholder="Enter ManufactureDate"
                    value={PurchaseRecieveFormData.ManufactureDate}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Expiry Date :
                  </label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    placeholder="Enter ExpiryDate"
                    value={PurchaseRecieveFormData.ExpiryDate}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Issue Quantity :
                  </label>
                  <input
                    type="number"
                    name="Issue_Quantity"
                    placeholder="Enter Issue Pack Quantity "
                    value={PurchaseRecieveFormData.Issue_Quantity}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Issue Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="Issue_TotalQuantity"
                    placeholder="Enter Issue TotalQuantity"
                    value={PurchaseRecieveFormData.Issue_TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    MRP (Per Quantity) :
                  </label>
                  <input
                    type="number"
                    name="MRP"
                    placeholder="Enter MRP"
                    value={PurchaseRecieveFormData.MRP}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Selling Rate (Per Quantity):
                  </label>
                  <input
                    type="number"
                    name="Selling_Rate"
                    placeholder="Enter Selling Rate"
                    value={PurchaseRecieveFormData.Selling_Rate}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Tax Percentage
                  </label>
                  <input
                    type="number"
                    name="Tax_Percentage"
                    placeholder="Enter Tax Percentage"
                    value={PurchaseRecieveFormData.Tax_Percentage}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Taxable Selling Rate :
                  </label>
                  <input
                    type="number"
                    name="Taxable_Selling_Rate"
                    placeholder="Enter Taxable Selling Rate"
                    value={PurchaseRecieveFormData.Taxable_Selling_Rate}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Amount :
                  </label>
                  <input
                    type="number"
                    name="TotalAmount"
                    placeholder="Enter Total Amount"
                    value={PurchaseRecieveFormData.TotalAmount}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_Save">
                <button
                  className=" Stoct_Product_master_form_Save_button"
                  onClick={isedit ? handleUpdate : handleAdd}
                >
                  {isedit ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {(OverallPurchaseRecieveFormData.ProductCategory == "Assets" ||
          OverallPurchaseRecieveFormData.ProductCategory == "Stationary" ||
          OverallPurchaseRecieveFormData.ProductCategory ==
            "Non_Stationary") && (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Item Code :
                  </label>
                  <input
                    type="text"
                    name="Item_Code"
                    placeholder="Enter Item Code"
                    value={PurchaseRecieveFormData1.Item_Code}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Item Name :
                  </label>
                  <input
                    type="text"
                    name="Item_Name"
                    placeholder="Enter Item Name"
                    value={PurchaseRecieveFormData1.Item_Name}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Pack Type :
                  </label>
                  <input
                    type="text"
                    name="Pack_type"
                    placeholder="Enter Pack Type"
                    value={PurchaseRecieveFormData1.Pack_type}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pack Quantity :
                  </label>
                  <input
                    type="number"
                    name="Pack_Quantity"
                    placeholder="Enter Pack Quantity"
                    value={PurchaseRecieveFormData1.Pack_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Order Quantity :
                  </label>
                  <input
                    type="number"
                    name="Order_Quantity"
                    placeholder="Enter Order Quantity"
                    value={PurchaseRecieveFormData1.Order_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="TotalQuantity"
                    placeholder="Enter Total Quantity"
                    value={PurchaseRecieveFormData1.TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Issue Quantity :
                  </label>
                  <input
                    type="number"
                    name="Issue_Quantity"
                    placeholder="Enter Issue Quantity"
                    value={PurchaseRecieveFormData1.Issue_Quantity}
                    onChange={handleInputFormNonChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Issue Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="Issue_TotalQuantity"
                    placeholder="Enter Issue TotalQuantity"
                    value={PurchaseRecieveFormData1.Issue_TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    MRP (Per Quantity) :
                  </label>
                  <input
                    type="text"
                    name="MRP"
                    placeholder="Enter MRP"
                    value={PurchaseRecieveFormData1.MRP}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Amount :
                  </label>
                  <input
                    type="text"
                    name="TotalAmount"
                    placeholder="Enter Total Amount"
                    value={PurchaseRecieveFormData1.TotalAmount}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_Save">
                <button
                  className=" Stoct_Product_master_form_Save_button"
                  onClick={isedit ? handleUpdateNon : handleAddNon}
                >
                  {isedit ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {datalist1.length !== 0 && (
          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={datalist1.slice(
                    page1 * pageSize,
                    (page1 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns1()} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange1}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown1 > 0 && datalist1.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page1 === 0}
                    >
                      Previous
                    </button>
                    Page {page1 + 1} of {totalPages1}
                    <button
                      onClick={() =>
                        setPage1((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page1 === totalPages1 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown1 !== 0 && datalist1.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        )}
        {datalist1.length !== 0 && (
          <div className="Stoct_Product_master_form_Save">
            <button
              className=" Stoct_Product_master_form_Save_button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default IndentIssueMaster;
