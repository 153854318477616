import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import LoupeIcon from "@mui/icons-material/Loupe";

import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function CounselorQueueList() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const dispatchvalue = useDispatch();

  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const pageSize = 10;
  const showdown = rows.length;

  const handleRequestProceed = (params) => {
   
    const for_counselor_navigation_data = {
      Patientid: params.row.id,
      PatientName: params.row.PatientName,
      nextapppoinmentdate: params.row.nextapppoinmentdate,
      PhoneNumber: params.row.PhoneNumber,
      procedurestatus: params.row.procedurestatus,
    };

    dispatchvalue({
      type: "for_counselor_navigation_data",
      value: for_counselor_navigation_data,
    });

    navigate("/Home/Treament-CounselorProcedure");
  };

  const [columns] = React.useState([
    {
      field: "PatientName",
      headerName: "Patient Name",
      width: 150,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.TotalSession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.RemainingSession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "PhoneNumber",
      headerName: "Phone Number",
      width: 130,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.TotalSession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.RemainingSession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    // { field: 'Status', headerName: 'Status', width: 100 },
    {
      field: "consultationstatus",
      headerName: "Dcotor Consultaion Status",
      width: 200,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.TotalSession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.RemainingSession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "procedurestatus",
      headerName: "Procedure Status",
      width: 200,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.TotalSession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.RemainingSession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "nextapppoinmentdate",
      headerName: "Next Appointment",
      width: 150,
      renderCell: (params) => (
        <Tooltip
          title={
            <div className="forTooltip_ou8">
              <h4>
                <label className="forTooltip_ou8_labl">
                  Procedurename <span>:</span>{" "}
                </label>
                <p>{params.row.Treatment_Procedure}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Total Session <span>:</span>{" "}
                </label>
                <p>{params.row.TotalSession}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Completed Session <span>:</span>{" "}
                </label>
                <p>{params.row.completedsessions}</p>
              </h4>

              <h4>
                <label className="forTooltip_ou8_labl">
                  Current Session <span>:</span>{" "}
                </label>
                <p>{params.row.currentsession}</p>
              </h4>
              <h4>
                <label className="forTooltip_ou8_labl">
                  Remaining Session <span>:</span>{" "}
                </label>
                <p>{params.row.RemainingSession}</p>
              </h4>
            </div>
          }
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "Action ",
      headerName: "Action",
      width: 80,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestProceed(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ]);

  useEffect(() => {
    const location = userRecord?.location;
    fetch(
      `http://127.0.0.1:8000/Counselor/Fdisp?location=${location}`
    )
      .then((response) => response.json())
      .then((data) => {
      
        const Records = data.map((userdata) => ({
          id: userdata.PatientID,
          PatientName: userdata.FirstName + " " + userdata.LastName,
          PhoneNumber: userdata.PhoneNumber,
          consultationstatus: userdata.ConsultationStatus,
          procedurestatus: userdata.Status,
          nextapppoinmentdate: userdata.next_appointment_date,
          Treatment_Procedure: userdata.Treatment_Procedure,
          TotalSession: userdata.TotalSession,
          completedsessions: userdata.completedsessions,
          currentsession: userdata.currentsession,
          RemainingSession: userdata.RemainingSession,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [userRecord]);

  columns[4].renderCell = ({ row }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        color: row.nextapppoinmentdate === "Tomorrow" ? "black" : "black",
        backgroundColor:
          row.nextapppoinmentdate === "Tomorrow"
            ? "var(--selectbackgroundcolor)"
            : "transparent", // Light green
      }}
    >
      {row.nextapppoinmentdate}
    </div>
  );

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const totalPages = Math.ceil(rows.length / 10);

  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");
  const [FirstName, setFirstName] = useState([]);
  const [PhoneNo, setPhoneNo] = useState([]);

  const filterRows = (rowData) => {
    const lowerCaseFirstName = (rowData.First_Name || "").toLowerCase();
    const phoneNoString = rowData.PhoneNo.toString() || "";
    const lowerCaseSearchName = PatientFirstName.toLowerCase();
    const lowerCaseSearchPhoneNo = PatientPhoneNo.toString();

    return (
      (PatientFirstName === "" ||
        lowerCaseFirstName.startsWith(lowerCaseSearchName)) &&
      (PatientPhoneNo === "" ||
        phoneNoString.startsWith(lowerCaseSearchPhoneNo))
    );
  };

  const filteredByName = FirstName.filter(filterRows);
  const filteredByPhoneNo = PhoneNo.filter(filterRows);

  const mergedData = [...filteredByName, ...filteredByPhoneNo];

  const uniqueData = Array.from(new Set(mergedData.map((item) => item.id))).map(
    (id) => {
      return mergedData.find((item) => item.id === id);
    }
  );

 

  if (uniqueData.length === 0) {
   
  } else {
    setRows(uniqueData);
  }



  return (
    <>
      <Helmet>
        <title>Patient Queue List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h4>Patient Queue List(C)</h4>
        </div>
        <form>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                First Name <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                value={PatientFirstName}
                onChange={(e) => setPatientFirstName(e.target.value)}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Phone No <span>:</span>
              </label>
              <input
                type="text"
                id="PhoneNo"
                value={PatientPhoneNo}
                onChange={(e) => setPatientPhoneNo(e.target.value)}
                placeholder="Enter the Phone No"
              />
            </div>
          </div>
        </form>
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={rows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
    </>
  );
}
