import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PastHistory from "./PastHistory";
import Prescription from "./Prescription";
import TreatmentComponent from "./TreatmentComponent";
import Vital from "./Vital";
// import Procedure from "./Procedure";
import axios from "axios";
import LabMaster from "./LabMaster";
import bgImg2 from "../assets/bgImg2.jpg";
import LabReport from "./LabReport";
import Procedure2 from "../Therophist/Procedure2";
import { useDispatch, useSelector } from "react-redux";
import VisitingDoctor1 from "./VisitingDoctor1";
import { useNavigate } from "react-router-dom";
import NewProcedure from "./NewProcedureDoc";
import NewProcedureDoc from "./NewProcedureDoc";
import CaseSheet from "./CaseSheet";

function Navigation() {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const DataGnesis = useSelector((state) => state.userRecord?.DataGnesis);
  console.log(DataGnesis)
  const [activeTab, setActiveTab] = useState("vitalForm");
  const [isToggled, setIsToggled] = useState(false);
  const [workbenchformData, setFormData] = useState({
    appointment_patientrequestID: '',
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    fullName: "",
    Status: '',
    AppointmentDate: "",
    PatientPhoto: "",
    Age: "",
    Gender: "",
  });
  console.log(workbenchformData)
const navigate=useNavigate()
  

  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  useEffect(() => {
    if(Object.keys(DataGnesis).length===0){
      navigate('/Home/Treament-QueueList1')
    }else{

   
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${urllink}appointmentmanagement/get_appointments_Navigation?patientid=${DataGnesis.patientID}&location=${userRecord.location}`
        );
        console.log(response)

        const matchingAppointment = response.data[0];

        setFormData({
          appointment_patientrequestID: matchingAppointment.appointment_patientrequestID,
          PatientID: matchingAppointment.PatientID,
          AppointmentID: matchingAppointment.AppointmentID,
          visitNo: matchingAppointment.VisitID,
          fullName: matchingAppointment.fullName,
          Status: matchingAppointment.Status,
          AppointmentDate: matchingAppointment.date,
          PatientPhoto: matchingAppointment.PatientPhoto,
          Age: matchingAppointment.age,
          Gender: matchingAppointment.gender,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    };

    //   // Call the fetchData function when the component mounts
    fetchData(userRecord?.location);
  }
  }, [DataGnesis, userRecord?.location]);

  useEffect(()=>{
    dispatchvalue({
      type: "workbenchformData",
      value: workbenchformData,
    });
  },[workbenchformData])

  return (
    <>
      

      <div className="new-patient-registration-form">
        <br />
        <div className="dctr_info_up_head">
          

          <div className="RegisFormcon">
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient Name <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.fullName}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Patient ID <span>:</span>
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.PatientID}{" "}
              </span>
            </div>

            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Age <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.Age}{" "}
              </span>
            </div>
            <div className="RegisForm_1 ">
              <label htmlFor="FirstName">
                Gender <span>:</span>{" "}
              </label>

              <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                {workbenchformData.Gender}{" "}
              </span>
            </div>
          </div>
        </div>

        <br />
        <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange("vitalForm")}>
                Vital Form
              </button>
              |
              <button onClick={() => handleTabChange("pastHistory")}>
                Past History
              </button>
              |
              <button onClick={() => handleTabChange("CaseSheet")}>
                Case Sheet
              </button>
              |
              <button onClick={() => handleTabChange("Treatment")}>
              Prescription
              </button>
              
              |
              <button onClick={() => handleTabChange("NewProcedureDoc")}>
              Procedure
              </button>
              |
              <div class="Lab_dropdown">
                <button class="Lab_button">Lab</button>
                <div class="Lab_dropdown_content">
                  <button onClick={() => handleTabChange("Lab")}>
                    Lab Test
                  </button>
                  <button onClick={() => handleTabChange("LabReport")}>
                    Lab Test Preview
                  </button>
                </div>
              </div>
           
              
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                 <h2>
              <button onClick={() => handleTabChange("vitalForm")}>
                Vital Form
              </button>
              |
              <button onClick={() => handleTabChange("pastHistory")}>
                Past History
              </button>
              |
              <button onClick={() => handleTabChange("CaseSheet")}>
                Case Sheet
              </button>
              |
              <button onClick={() => handleTabChange("Treatment")}>
                Prescription
              </button>
              
              |
              <button onClick={() => handleTabChange("NewProcedureDoc")}>
              Procedure
              </button>
              |
              <div class="Lab_dropdown">
                <button class="Lab_button">Lab</button>
                <div class="Lab_dropdown_content">
                  <button onClick={() => handleTabChange("Lab")}>
                    Lab Test
                  </button>
                  <button onClick={() => handleTabChange("LabReport")}>
                    Lab Test Preview
                  </button>
                </div>
              </div>
           
              
            </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTab === "vitalForm" && <Vital />}
      {activeTab === "pastHistory" && <PastHistory />}
      {activeTab === "CaseSheet" && <CaseSheet />}
      {activeTab === "Treatment" && <TreatmentComponent />}
      {activeTab === "Prescription" && <Prescription />}
      {/* {activeTab === "Procedure" && <Procedure />} */}
      {activeTab === "NewProcedureDoc" && <NewProcedureDoc />}
      {activeTab === "Lab" && <LabMaster />}
      {activeTab === "LabReport" && <LabReport />}
      {activeTab === "TherapistProcedure" && <Procedure2 />}
      {activeTab === "VisitingDoctor1" && <VisitingDoctor1 />}


    </>
  );
}

export default Navigation;
