import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

// import "./LabMaster.css";

const LabReportFN = () => {
  let formData;

  const formData1 = useSelector((state) => {
    if (state.userRecord && state.userRecord.labformdata && Object.keys(state.userRecord.labformdata).length > 0)
    {
      formData = state.userRecord.labformdata;
    } else {
        formData = state.userRecord.NurseworkbenchformData;
      }
});



  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const navigate = useNavigate();

  const [defaultDate, setDefaultDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  // const formData = useSelector(state => state.userRecord?.formData);

  const lab_Patient_ID = formData?.PatientID;

  const visitNo = formData?.visitNo;


  const [showCamera, setShowCamera] = useState(false);

  const [capturedImageSec, setCapturedImageSec] = useState(null);

  const [Selected_File, setSelected_File] = useState(null);

  const [isImageCaptured, setIsImageCaptured] = useState(false);

  const webcamRef = useRef(null);

  const [Temp_testnamelist, setTemp_testnamelist] = useState([]);

  const [Request_date, setRequest_date] = useState("");


  // -------------------------------------
  const [facingMode, setFacingMode] = useState("user");
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);

  useEffect(() => {
    // Fetch device information from the backend
    axios
      .get(`${urllink}patientmanagement/detect_device`)
      .then((response) => {
        const detectedDevice = response.data.device_type;

        setIsmobileorNot(devices.includes(detectedDevice) ? true : false);
      })
      .catch((error) => {
      });
  }, []);
  const videoConstraints = {
    facingMode: facingMode,
  };
  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  //   --------------------------

  useEffect(() => {
    axios
      .get(
        `${urllink}labtest/get_Passiont_lab_detailes?patientid=${lab_Patient_ID}&visitNo=${visitNo}&location=${userRecord?.location}`
      )
      .then((response) => {
        let A_data = response.data[0].Date;
        let B_data = response.data[0].Temp_testnamelist;
        setRequest_date(A_data);
        setTemp_testnamelist(B_data);
      })
      .catch((err) => console.log(err));
  }, [lab_Patient_ID]); // Add lab_Patient_ID as a dependency to rerun the effect when it changes

  const handleOpenCamera = () => {
    setShowCamera(true);
  };

  const handleCloseCamera = () => {
    setShowCamera(false);
  };

  const handleRecaptureImageSec = () => {
    setCapturedImageSec(null);
    setIsImageCaptured(false);
  };

  const capturedImageSecnd = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = dataURItoBlob(imageSrc);
    setCapturedImageSec(blob);
    setIsImageCaptured(true);
  };

  // Convert a data URI to a Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleHideCamera = () => {
    setShowCamera(false);
  };

  const test_name_submit = () => {
    const url = `${urllink}labtest/update_lab_reportdocument`;
    const formData = new FormData();
    formData.append("lab_Patient_ID", lab_Patient_ID);
    formData.append("Date", defaultDate);
    formData.append("visitNo", visitNo);
    formData.append(
      "Lab_image_document",
      capturedImageSec ? capturedImageSec : Selected_File
    );


    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        successMsg(response.data.message);
        setTemp_testnamelist([]);
        setIsImageCaptured(null);
        setCapturedImageSec(null);
        setSelected_File(null);
        setDefaultDate("");
        setRequest_date("");
        // navigate('/Lab-QueueList')
      })
      .catch((error) => {
        errmsg("Error Occured");
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Lab Service</h4>
        </div>

        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="lastName">
              Patient ID <span>:</span>
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              required
              value={lab_Patient_ID}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="firstName">
              Patient Name <span>:</span>
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData?.firstName + formData?.lastName}
              required
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="Doctor_name">
              Doctor Name <span>:</span>
            </label>
            <input
              type="text"
              id="Doctor_name"
              name="Doctor_name"
              value={formData?.DoctorName}
              required
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="lastName">
              Request Date <span>:</span>
            </label>
            <input type="Date" readOnly value={Request_date} required />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="lastName">
              Update Date <span>:</span>
            </label>
            <input
              type="Date"
              id="lastName"
              name="lastName"
              value={defaultDate}
              onChange={(e) => setDefaultDate(e.target.value)}
              required
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="patientPhoto">
              Lab Report<span>:</span>
            </label>
              <div className="file-input-444">
                  <div className="RegisterForm_2">
                    <input
                      type="file"
                      id="CapturedFile1"
                      name="CapturedFile1"
                      accept="image/*"
                      onChange={(e) => {
                        // Use e.target.files to access selected files
                        const selectedFile = e.target.files[0];
    
                        // Check if a file was selected
                        if (selectedFile) {
                          // Do something with the selected file, e.g., set state or call a function
                          setSelected_File(selectedFile);
                        }
                      }}
                      required
                    />
                    <label
                      htmlFor="CapturedFile1"
                      className="RegisterForm_1_btns choose_file_update"
                    >
                      Choose File
                    </label>
                  </div>
            


              <span>or</span>
              <div className="RegisterForm_2">
                <button
                  onClick={handleOpenCamera}
                  className="RegisterForm_1_btns choose_file_update"
                >
                  Take Pic
                </button>

                {showCamera && (
                  <div
                    className="showcamera_takepic"
                    onClick={handleHideCamera}
                  >
                    <div
                      className="showcamera_1_takepic1"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {isImageCaptured ? ( // Display the captured image
                        <img
                          src={URL.createObjectURL(capturedImageSec)}
                          alt="captured"
                          className="captured-image11"
                        />
                      ) : (
                        <div className="camera-container">
                          <div className="web_head">
                            <h3>Image</h3>
                          </div>
                          <br></br>
                          <div className="RotateButton_canva">
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              mirrored={facingMode === "user"}
                              className="web_cam"
                              videoConstraints={videoConstraints}
                            />
                            {devices.includes(IsmobileorNot) && (
                              <button onClick={switchCamera}>
                                <CameraswitchIcon />
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="web_btn">
                        {isImageCaptured ? ( // Render the Recapture button if an image is captured
                          <button
                            onClick={handleRecaptureImageSec}
                            className="btncon_add"
                          >
                            Recapture
                          </button>
                        ) : (
                          <button
                            onClick={capturedImageSecnd}
                            className="btncon_add"
                          >
                            Capture
                          </button>
                        )}
                        <button
                          onClick={handleCloseCamera}
                          className="btncon_add"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <br />

        {Temp_testnamelist.length === 0 ? (
          ""
        ) : (
          <div style={{ marginTop: "10px" }}>
            <h3>Test List</h3>
            <div className="Selected-table-container RateCard_table">
              <br />

              <br />
              <table className="selected-medicine-table2 tablewidth">
                <thead>
                  <tr>
                    <th id="vital_Twidth">Name</th>
                  </tr>
                </thead>
                <tbody>
                  {Temp_testnamelist &&
                    Temp_testnamelist.map((ele, ind) => (
                      <tr key={"mytbl" + ind}>
                        <td>{ele.Test_Name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={test_name_submit}>
            Save
          </button>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};
export default LabReportFN;
