import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./Account Settings.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function AccountSettings() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState("");
  const [contentType, setContentType] = useState("");

  const [selectedImage, setSelectedImage] = useState(null); // Added a state for selected image

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const getimage = () => {
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const { clinicName, clinicLogo, contentType } = response.data;
          if (clinicName && clinicLogo && contentType) {
            setClinicName(clinicName);
            setClinicLogo(`data:${contentType};base64,${clinicLogo}`);
            setContentType(contentType);
          } else {
            // Handle case where clinicName, clinicLogo, or contentType is null
          }
        } else {
          // Handle case where response data is null

          // Set default values or handle the case accordingly
          setClinicName("");
          setClinicLogo("");
          setContentType("");
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  useEffect(() => {
    if (!selectedImage) {
      getimage();
    }
  }, [selectedImage]);

  const onImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setClinicLogo(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Reset the clinic logo to the one fetched from the server if no file is selected
      getimage(); // Fetch the clinic logo from the server again
    }
  };

  const handleClinicNameChange = (event) => {
    setClinicName(event.target.value);
  };

  function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const onInsertOrUpdate = () => {
    if (!clinicName || !selectedImage) {
      // Show alert message
      userwarn("Please provide both clinic name and image.");
      return; // Exit the function early
    }
    const data = new FormData();
    data.append("clinicName", clinicName);

    if (selectedImage) {
      // Append the selected image file to the form data
      data.append("selectedImage", selectedImage);
    } else {
      // If no new image is selected, send the existing clinic logo
      const [, base64Data] = clinicLogo.split(",");
      const blob = b64toBlob(base64Data, `image/${contentType}`);
      data.append("selectedImage", blob);
    }

    axios
      .post(`${urllink}usercontrol/updateAccountsetting`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        successMsg("Saved Successfully");
        getimage();
      })
      .catch((error) => {
        console.error("Error updating clinic name and logo:", error);
        // Handle error
      });
  };

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      <div className="appointment">
        <div className="h_head">
          <h4>Account Settings</h4>
        </div>
        <br />
        <br />

        <div className="img_section_acctn2">
          <div className="img_17">
            <div className="img_27">
              {clinicLogo && <img src={clinicLogo} alt="Clinic Logo" />}
            </div>
            <input
              type="file"
              name="file"
              id="Filechoosen"
              accept="image/*"
              onChange={onImageChange}
              style={{ display: "none" }}
            />
            <label
              htmlFor="Filechoosen"
              className="RegisterForm_1_btns choose_file_update"
            >
              Choose File
            </label>
          </div>
          <div className=" inp_1">
            <label htmlFor="input">
              Clinic Name<span>:</span>
            </label>
            <input
              type="text"
              placeholder="Enter the Clinic Name"
              value={clinicName}
              onChange={handleClinicNameChange}
            />
          </div>
        </div>
        <br />

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={onInsertOrUpdate}>
            Save
          </button>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default AccountSettings;
