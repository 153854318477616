import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Webcam from "react-webcam";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import Preview from "./Preview";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";

const ProcedureAddProcedure = () => {

  const workbenchformData = useSelector(
    (state) => state.userRecord?.workbenchformData
  );
  console.log(workbenchformData);


  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const DataGnesis = useSelector((state) => state.userRecord?.DataGnesis);
  console.log("data344", DataGnesis);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [formValues, setFormValues] = useState({
    Index: null,
    DoctorName: "",
    ProcedureName: "",
    Sessions: "",
    Complementry: "",
    appointmentDate: "",
    PatientID: "",
    VisitID: "",
    createdBy: "",
    Status: "Pending",
    branchlocation: "",
    KitName: "",
    ServiceType: "Procedure",
  });
  const [ProcedureName, setProcedureName] = useState([]);
  const [ProcedureData, setProcedureData] = useState([]);
  const [openModal2, setOpenModal2] = useState(false);
  const [modalContent, setModalContent] = useState("");

 
 
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const [type, setType] = useState("Patient");
  const [getprocedure, setgetprocedure] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event) => {
    setType(event.target.value);
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

 
  useEffect(() => {
    axios
      .get(
        `${urllink}doctorsworkbench/get_procedurename_fromratecard?location=${userRecord?.location}`
      )
      .then((response) => {
        setProcedureName(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    setFormValues((prev) => ({
      ...prev,
      DoctorName: workbenchformData?.DoctorName,
      PatientID: DataGnesis?.patientID,
      createdBy: userRecord?.username,
      VisitID: DataGnesis.visitID,
      branchlocation: userRecord?.location,
    }));
  }, [userRecord?.location]);

 

 

  //  treat procedure

  const [FormTreatprocedure, setFormTreatprocedure] = useState({

    PatientID: "",
    VisitID: "",
    AppointmentDate: "",
    TherapistName: "",
    TreatmentProcedure: "",
    NextAppointment: "",
    Sessions: "",
    CompletedSessions: "",
    currentsession: "",
    AdditionalComments: "",
    GraftCount: "",
    Complementry: "",

  });
  const [Ratecard, setRatecard] = useState({
    RatecardType: "",
    InsuranceName: "",
    ClientName: "",
  });
  
  const [SelectedProcedure, setSelectedProcedure] = useState([]);
  const [StatusSelectedProcedure, setStatusSelectedProcedure] = useState([]);
  const [showCamera1, setShowCamera1] = useState(false);
  const [isCameraImageCaptured1, setIsCameraImageCaptured1] = useState(false);
  const [capturedImage1, setCapturedImage1] = useState(null);

  const [showCamera2, setShowCamera2] = useState(false);
  const [isCameraImageCaptured2, setIsCameraImageCaptured2] = useState(false);
  const [capturedImage2, setCapturedImage2] = useState(null);
  const webcamRef = useRef(null);

  const [facingMode, setFacingMode] = useState("user");
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);

  useEffect(() => {
    if (DataGnesis.patientID) {
      axios
        .get(
          `${urllink}patientmanagement/get_communication_address_billing?Patient_Id=${DataGnesis.patientID}`
        )
        .then((response) => {
          const data = response.data[0];
          console.log("get_communication_address_billing", response.data);
          setRatecard({
            RatecardType: data?.RatecardType || "General",
            InsuranceName: data?.InsuranceName,
            ClientName: data?.ClientName,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [DataGnesis.patientID, userRecord?.location, urllink]);

  useEffect(() => {
    // Use an axios request within useEffect to avoid infinite rendering
    axios
      .get(`${urllink}patientmanagement/detect_device`)
      .then((response) => {
        setIsmobileorNot(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  const videoConstraints = {
    facingMode: facingMode,
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  //   ---

  const handleonchange1 = (e) => {
    const { name, value } = e.target;

    setFormTreatprocedure((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOpenCamera = () => {
    setShowCamera1(true);
  };

  const handleCloseCamera = () => {
    setShowCamera1(false);
  };
  const handleHideCamera = () => {
    setShowCamera1(false);
  };
  const handleRecaptureCameraImage = () => {
    setCapturedImage1(null);
    setIsCameraImageCaptured1(false);
  };
  const handlecaptureImage1 = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setCapturedImage1(imageSrc);
    setIsCameraImageCaptured1(true);
  };

  const handleOpenCamera2 = () => {
    setShowCamera2(true);
  };

  const handleCloseCamera2 = () => {
    setShowCamera2(false);
  };
  const handleHideCamera2 = () => {
    setShowCamera2(false);
  };
  const handleRecaptureCameraImage2 = () => {
    setCapturedImage2(null);
    setIsCameraImageCaptured2(false);
  };
  const handlecaptureImage2 = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setCapturedImage2(imageSrc);
    setIsCameraImageCaptured2(true);
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}doctorsworkbench/get_current_session?Patientid=${DataGnesis?.patientID}`
      )
      .then((res) => {
        const data = res.data;
        console.log(data, "----");
        setSelectedProcedure(data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getprocedure, DataGnesis?.patientID]);
  useEffect(() => {
    axios
      .get(
        `${urllink}doctorsworkbench/get_procedurestatus?Patientid=${DataGnesis?.patientID}`
      )
      .then((res) => {
        const data = res.data;
        console.log(data, "----uuuu");
        setStatusSelectedProcedure(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [DataGnesis?.patientID]);
  // useEffect(() => {
  //     if (workbenchformData?.PatientID && workbenchformData?.visitNo) {
  //         axios.get(`https://gen.vesoft.co.in//doctorsworkbench/get_joined_data`, {
  //             params: {
  //                 Patientid: workbenchformData.PatientID,
  //                 Visitid: workbenchformData.visitNo,

  //             }
  //         })
  //         .then((res) => {
  //             const data = res.data;
  //             console.log( '----123uuuu',data);
  //             // setStatusSelectedProcedure(data);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });
  //     }
  // }, [workbenchformData?.PatientID, workbenchformData?.visitNo]);

  const handleEdit1 = (client) => {
    setFormTreatprocedure((prev) => ({
      ...prev,
      PatientID: client.PatientID,
      VisitID: DataGnesis?.visitID,
      AppointmentDate: client.AppointmentDate,
      TherapistName: client.TherapistName,
      TreatmentProcedure: client.Treatment_Procedure,
      Sessions: client.Number_of_Sessions,
      CompletedSessions: client.Number_of_Sessions_completed,
      currentsession: client.current_session,
      CreatedBy: userRecord?.username,
      location: userRecord?.location,
      Complementry: client?.Complementry,
    }));
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleSave1 = () => {
    console.log(FormTreatprocedure)
    const dataToSend = {
      ...FormTreatprocedure,
      ServiceType: "Procedure",
      beforecapturedImage: capturedImage1
        ? dataURItoBlob(capturedImage1)
        : null,
      aftercapturedImage: capturedImage2 ? dataURItoBlob(capturedImage2) : null,
      CreatedBy: userRecord?.username,
      location: userRecord?.location,
      // VisitID: workbenchformData.VisitID,
    };

    console.log(dataToSend);
    const BackformData = new FormData();

    for (const key in dataToSend) {
      if (dataToSend.hasOwnProperty(key)) {
        BackformData.append(key, dataToSend[key]);
      }
    }
    let arr = ["NextAppointment"];
    if (FormTreatprocedure.TreatmentProcedure.split("-")[0].includes("HT")) {
      arr = ["NextAppointment", "GraftCount"];
    }
    const exist = arr.filter((p) => !FormTreatprocedure[p]);
    if (exist.length > 0) {
      userwarn(`please fill allthe fields : ${exist.join(",")}`);
    } else {
      axios
        .post(
          `${urllink}doctorsworkbench/insert_therapist_procedure`,
          BackformData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log(res, "-=-=-=-=-=-=-");
          if (dataToSend?.Complementry === "No") {
            let billingdata = {
              PatientID: DataGnesis?.patientID,
              PatientName: `${DataGnesis?.fullName}`,
              VisitID: DataGnesis?.visitID,
              ServiceType: "Procedure",
              DoctorName: DataGnesis?.DoctorName,
              ProcedureName: FormTreatprocedure?.TreatmentProcedure,
              appointmentDate: DataGnesis?.date,
              Sessions: FormTreatprocedure?.currentsession,
              Unit: parseInt(FormTreatprocedure?.GraftCount) || 0,
              Amount: 0,
              Discount: 0,
              Gstcharge: 0,
              TotalAmount: 0,
              Status: "Pending",
              location: userRecord?.location,
              CreatedBy: userRecord?.username,
            };

            let urlval = "get_RateCard_Service_Charge";
            let ratecardType = Ratecard.RatecardType;

            if (Ratecard.RatecardType === "Insurance") {
              urlval = "get_RateCard_Insurance_Charge";
              ratecardType = Ratecard.InsuranceName;
            }
            if (Ratecard.RatecardType === "Client") {
              urlval = "get_RateCard_client_Charge";
              ratecardType = Ratecard.ClientName;
            }

            const fetchAndPostBillingData = async () => {
              try {
                const response = await axios.get(
                  `${urllink}usercontrol/${urlval}?servicetype=Procedure&servicename=${FormTreatprocedure.TreatmentProcedure}&ratecardtype=${ratecardType}&location=${userRecord?.location}`
                );

                const data = response.data.data[0];
                console.log(response.data);

                if (data?.Charge) {
                  billingdata["Amount"] = parseFloat(data.Charge) || 0;
                  billingdata["Gstcharge"] = parseFloat(data.GstCharge) || 0;
                  billingdata["TotalAmount"] =
                    parseFloat(data.Charge) * parseFloat(billingdata.Unit) || 0;
                }

                const ProcedureData = [{ ...billingdata }];
                const postResponse = await axios.post(
                  `${urllink}GeneralBilling/insertGeneral_Billing_Data`,
                  ProcedureData
                );

                console.log(postResponse, "=====");
              } catch (error) {
                console.error(
                  "Error in fetching and posting billing data:",
                  error
                );
              }
            };

            fetchAndPostBillingData();
          }

          setgetprocedure((prev) => !prev);
          setFormTreatprocedure({
            PatientID: "",
            VisitID: "",
            AppointmentDate: "",
            TherapistName: "",
            TreatmentProcedure: "",
            NextAppointment: "",
            Sessions: "",
            CompletedSessions: "",
            currentsession: "",
            AdditionalComments: "",
          });
          setCapturedImage1(null);
          setCapturedImage2(null);
        })
        .catch((err) => {
          console.log(err);
        });
      const shouldProceed = window.confirm("Do you Complete Procedure?");
      const statuss = shouldProceed ? "Yes" : "No";
    }
  };

  // -------------ratecard type

  return (
    <>
      <div style={{ display: "grid", placeContent: "center" }}>
        <br />
        <ToggleButtonGroup
          value={type}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          


          <ToggleButton
            value="Patient"
            style={{
              backgroundColor:
                type === "Patient" ? "var(--selectbackgroundcolor)" : "inherit",
              height: "30px !important",
              width: "180px !important",
            }}
            className="togglebutton_container_procedure"
          >
            Treat Procedure
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <br />

      {type === "Patient" && (
        <>
          {StatusSelectedProcedure.length>0 &&<div className="Add_items_Purchase_Master">
            <span>Completed Therapy List</span>
          </div>}

          {StatusSelectedProcedure.length > 0 && (
            <div className="Selected-table-container">
              <table className="selected-medicine-table2">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th>Visit Id</th>
                    {/* <th>Therapist Name</th> */}
                    <th>Procedure Name</th>
                    <th>Sessions</th>
                    <th>Sessions Completed</th>

                    <th>Complementry</th>
                    <th>Kit Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {StatusSelectedProcedure.map((client, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{client.VisitID}</td>
                      {/* <td>{client.TherapistName}</td> */}
                      <td>{client.Treatment_Procedure}</td>
                      <td>{client.Number_of_Sessions}</td>
                      <td>{client.Number_of_Sessions_completed}</td>

                      <td>{client.Complementry || "-"}</td>
                      <td>{client.KitName || "-"}</td>
                      <td>{client.Status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <br />
          {SelectedProcedure.length>0 &&<div className="Add_items_Purchase_Master">
            <span>Current Therapy List</span>
          </div>}

          {SelectedProcedure.length > 0 && (
            <div className="Selected-table-container">
              <table className="selected-medicine-table2">
                <thead>
                  <tr>
                    <th>S No</th>
                    <th>Visit Id</th>
                    {/* <th>Therapist Name</th> */}
                    <th>Procedure Name</th>
                    <th>Sessions</th>
                    <th>Sessions Completed</th>
                    <th>Current Session</th>
                    <th>Complementry</th>
                    <th>Kit Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {SelectedProcedure.map((client, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{client.VisitID}</td>
                      {/* <td>{client.TherapistName}</td> */}
                      <td>{client.Treatment_Procedure}</td>
                      <td>{client.Number_of_Sessions}</td>
                      <td>{client.Number_of_Sessions_completed}</td>
                      <td>{client.current_session}</td>
                      <td>{client.Complementry || "-"}</td>
                      <td>{client.KitName || "-"}</td>
                      <td>{client.Status}</td>
                      <td>
                        <button
                          className="delnamebtn"
                          onClick={() => handleEdit1(client)}
                        >
                          <EditNoteIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <br />
          <div className="RegisFormcon" style={{ justifyContent: "center" }}>
            <div className="RegisForm_1">
              <label htmlFor="TreatmentProcedure">
                Procedure Name<span>:</span>
              </label>
              <input
                type="text"
                id="TreatmentProcedure"
                name="TreatmentProcedure"
                readOnly
                value={FormTreatprocedure.TreatmentProcedure}
                onChange={handleonchange1}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="Sessions">
                Sessions<span>:</span>
              </label>
              <input
                type="number"
                id="Sessions"
                name="Sessions"
                readOnly
                value={FormTreatprocedure.Sessions}
                onChange={handleonchange1}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="CompletedSessions">
                Completed Sessions<span>:</span>
              </label>
              <input
                type="number"
                id="CompletedSessions"
                name="CompletedSessions"
                readOnly
                value={FormTreatprocedure.CompletedSessions}
                onChange={handleonchange1}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="currentsession">
                Current Sessions<span>:</span>
              </label>
              <input
                type="number"
                id="currentsession"
                name="currentsession"
                readOnly
                value={FormTreatprocedure.currentsession}
                onChange={handleonchange1}
              />
            </div>
            {FormTreatprocedure.TreatmentProcedure.split("-")[0].includes(
              "HT"
            ) && (
              <div className="RegisForm_1">
                <label htmlFor="GraftCount">
                  Graft Count<span>:</span>
                </label>
                <input
                  type="number"
                  id="GraftCount"
                  name="GraftCount"
                  value={FormTreatprocedure.GraftCount}
                  onChange={handleonchange1}
                />
              </div>
            )}
            <div className="RegisForm_1">
              <label htmlFor="patientPhoto">
                Before Treatment<span>:</span>
              </label>
              <div className="file-input-444">
                <div className="RegisterForm_2">
                  <button
                    onClick={handleOpenCamera}
                    className="RegisterForm_1_btns choose_file_update"
                  >
                    Take Pic
                  </button>

                  {showCamera1 && (
                    <div
                      className={
                        isSidebarOpen ? "sideopen_showcamera" : "showcamera"
                      }
                      onClick={handleHideCamera}
                    >
                      <div
                        className={
                          isSidebarOpen
                            ? "sideopen_showcamera_1"
                            : "showcamera_1"
                        }
                        onClick={(e) => e.stopPropagation()}
                      >
                        {isCameraImageCaptured1 ? ( // Display the captured camera image
                          <img
                            src={capturedImage1}
                            alt="captured"
                            className="captured-image11"
                          />
                        ) : (
                          <div className="camera-container">
                            <div className="web_head">
                              <h3>Image</h3>
                            </div>
                            <br></br>
                            <div className="RotateButton_canva">
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                mirrored={facingMode === "user"}
                                className="web_cam"
                                videoConstraints={videoConstraints}
                              />
                              {devices.includes(IsmobileorNot) && (
                                <div className="web_cam__1">
                                  <button onClick={switchCamera}>
                                    <CameraswitchIcon />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="Register_btn_con">
                          {isCameraImageCaptured1 ? ( // Render the Recapture button if a camera image is captured
                            <button
                              onClick={handleRecaptureCameraImage}
                              className="RegisterForm_1_btns"
                            >
                              Recapture
                            </button>
                          ) : (
                            <button
                              onClick={handlecaptureImage1}
                              className="RegisterForm_1_btns"
                            >
                              Capture
                            </button>
                          )}
                          <button
                            onClick={handleCloseCamera}
                            className="RegisterForm_1_btns"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="RegisForm_1">
              <label htmlFor="patientPhoto">
                After Treatment<span>:</span>
              </label>
              <div className="file-input-444 ">
                <div className="RegisterForm_2">
                  <button
                    onClick={handleOpenCamera2}
                    className="RegisterForm_1_btns choose_file_update"
                  >
                    Take Pic
                  </button>

                  {showCamera2 && (
                    <div
                      className={
                        isSidebarOpen ? "sideopen_showcamera" : "showcamera"
                      }
                      onClick={handleHideCamera2}
                    >
                      <div
                        className={
                          isSidebarOpen
                            ? "sideopen_showcamera_1"
                            : "showcamera_1"
                        }
                        onClick={(e) => e.stopPropagation()}
                      >
                        {isCameraImageCaptured2 ? ( // Display the captured camera image
                          <img
                            src={capturedImage2}
                            alt="captured"
                            className="captured-image11"
                          />
                        ) : (
                          <div className="camera-container">
                            <div className="web_head">
                              <h3>Image</h3>
                            </div>
                            <br></br>
                            <div className="RotateButton_canva">
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                mirrored={facingMode === "user"}
                                className="web_cam"
                                videoConstraints={videoConstraints}
                              />
                              {devices.includes(IsmobileorNot) && (
                                <div className="web_cam__1">
                                  <button onClick={switchCamera}>
                                    <CameraswitchIcon />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="Register_btn_con">
                          {isCameraImageCaptured2 ? ( // Render the Recapture button if a camera image is captured
                            <button
                              onClick={handleRecaptureCameraImage2}
                              className="RegisterForm_1_btns"
                            >
                              Recapture
                            </button>
                          ) : (
                            <button
                              onClick={handlecaptureImage2}
                              className="RegisterForm_1_btns"
                            >
                              Capture
                            </button>
                          )}
                          <button
                            onClick={handleCloseCamera2}
                            className="RegisterForm_1_btns"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="RegisForm_1">
              <label htmlFor="AdditionalComments">
                Additional Comments<span>:</span>
              </label>
              <textarea
                id="AdditionalComments"
                name="AdditionalComments"
                value={FormTreatprocedure.AdditionalComments}
                onChange={handleonchange1}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="NextAppointment">
                Next Appointment<span>:</span>
              </label>
              <input
                type="date"
                id="NextAppointment"
                name="NextAppointment"
                value={FormTreatprocedure.NextAppointment}
                onChange={handleonchange1}
                required
              />
            </div>
          </div>
          <br />
          <div className="Register_btn_con">
            <button className="RegisterForm_1_btns" onClick={handleSave1}>
              Save
            </button>
          </div>
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default ProcedureAddProcedure;
