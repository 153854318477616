import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useCallback } from "react";
import axios from "axios";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const PatientQueList1 = () => {
  const dispatchvalue = useDispatch();
  const SelectedPatientCalender = useSelector(
    (state) => state.userRecord?.SelectedPatientCalender
  );
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  console.log("SelectedPatientCalender", SelectedPatientCalender);

  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [filteredRows, setFilteredRows] = useState([]);
  console.log(filteredRows);
  const[Dateforget,setDateforget]=useState(new Date().toLocaleDateString('en-CA'))

  const [page, setPage] = useState(0);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const handleRequestProceed = (params) => {
    console.log(params);
    const serialid = params.row.id;
    const PatientID=params.row.patientid;
    const PatientName = params.row.PatientName;
    const PhoneNo = params.row.PhoneNo;
    const Source = params.row.Source;
    const gender = params.row.gender;
    const date = params.row.date;
    const age = params.row.age;
    const consultation_type = params.row.consultation_type;

    axios
      .post(
        `${urllink}doctorsworkbench/update_status_appointment_register?selectedpatienid=${serialid}&location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          console.log(response.data);
          // Handle success, e.g., navigate to another page
        } else {
          console.error("Error updating record:", response.data.message);
          // Handle error, e.g., show an error message to the user
        }
      })
      .catch((error) => {
        console.error("Error updating record:", error);
      });

    const datasgenesis = {
      serialid: serialid,
      patientID:PatientID,
      PatientName: PatientName,
      PhoneNo: PhoneNo,
      Source: Source,
      gender: gender,
      date:date,
      age: age,
      consultation_type: consultation_type,
    };
    console.log(datasgenesis, "ggg");
    dispatchvalue({ type: "DataGnesis", value: datasgenesis });

    navigate("/Home/Navigation3");
  };
  const handleList = (params) => {
    // Extract the id from the selected row
    const selectedRowId = params.row.id;
    // Log the selected row id
    console.log("Selected Row id:", selectedRowId);

    axios
      .get(`patientmanagement/get_personal_info`)
      .then((response) => {
        const data1 = response.data;
        const foundPatient = data1.find(
          (patient) => patient.PatientID === selectedRowId
        );

        console.log(foundPatient);

        // Fetch communication address
        axios
          .get(
            `${urllink}patientmanagement/get_communication_address`
          )
          .then((response) => {
            const data2 = response.data;
            const patient_address = data2.find(
              (patient) => patient.PatientID === selectedRowId
            );
            console.log(patient_address);

            axios
              .get(
                `${urllink}doctorsworkbench/get_vitalform1?selectedRowId=${selectedRowId}`
              )
              .then((response) => {
                const data3 = response.data;
                // const vital_data = data3.find(patient => patient.PatientID === selectedRowId);
                const vital = data3[0];
                console.log(vital);

                axios
                  .get(
                    `${urllink}doctorsworkbench/get_treatment_forprofile?selectedRowId=${selectedRowId}`
                  )
                  .then((response) => {
                    const data4 = response.data;
                    console.log(data4);
                    const firstimge = data4.first_image;
                    const lastimg = data4.last_image;

                    axios
                      .get(
                        `${urllink}doctorsworkbench/get_patientphoto?selectedRowId=${selectedRowId}`
                      )
                      .then((response) => {
                        const data5 = response.data;
                        console.log(data5);
                        const PatientPhoto = data5.PatientPhoto;

                        axios
                          .get(
                            `${urllink}doctorsworkbench/get_treatment`
                          )
                          .then((response) => {
                            const data6 = response.data;
                            const filteredData = data6.filter(
                              (row) => selectedRowId === data5.PatientID
                            );
                            console.log(data5);
                            const history = filteredData.History;

                            axios
                              .get(
                                `${urllink}doctorsworkbench/get_emergencydetails_info1?selectedRowId=${selectedRowId}`
                              )
                              .then((response) => {
                                const data7 = response.data;
                                console.log("/===========", data7);

                                const EmergencyName = data7.Name;
                                console.log(EmergencyName);

                                axios
                                  .get(
                                    `${urllink}doctorsworkbench/get_allergiesname_forprofile?selectedRowId=${selectedRowId}`
                                  )
                                  .then((response) => {
                                    const data8 = response.data;
                                    console.log("/===========", data8);

                                    const allergies = data8.allergies;
                                    console.log(allergies);

                                    axios
                                      .get(
                                        `${urllink}doctorsworkbench/get_previousvisit?selectedRowId=${selectedRowId}`
                                      )
                                      .then((response) => {
                                        const data9 = response.data;
                                        console.log("/===========", data9);

                                        const previousvisit =
                                          data9.previousvisit;
                                        console.log(previousvisit);

                                        axios
                                          .get(
                                            `${urllink}doctorsworkbench/get_next_visit?selectedRowId=${selectedRowId}`
                                          )
                                          .then((response) => {
                                            const data10 = response.data;
                                            console.log("/===========", data10);

                                            const Next_Appointment =
                                              data10.Next_Appointment;
                                            console.log(Next_Appointment);

                                            axios
                                              .get(
                                                `${urllink}doctorsworkbench/get_diseasename?selectedRowId=${selectedRowId}`
                                              )
                                              .then((response) => {
                                                const data11 = response.data;
                                                console.log(
                                                  "/===========",
                                                  data11
                                                );

                                                axios
                                                  .get(
                                                    `${urllink}doctorsworkbench/get_allimages_for_patients?selectedRowId=${selectedRowId}`
                                                  )
                                                  .then((response) => {
                                                    const data12 =
                                                      response.data;
                                                    console.log(
                                                      "/===========",
                                                      data12
                                                    );

                                                    const patientAction = (
                                                      data
                                                    ) => ({
                                                      type: "forPatientData",
                                                      value: data,
                                                    });

                                                    dispatchvalue(
                                                      patientAction({
                                                        // ...prevInfo,
                                                        ...foundPatient,
                                                        ...patient_address,
                                                        ...vital,
                                                        ...firstimge,
                                                        ...lastimg,
                                                        ...PatientPhoto,
                                                        ...history,
                                                        ...EmergencyName,
                                                        ...allergies,
                                                        ...previousvisit,
                                                        ...Next_Appointment,
                                                        ...data11,
                                                        ...data12,
                                                      })
                                                    );
                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });

    navigate("/Home/PatientProfile");
  };

  const columns = [
    { field: "id", headerName: "S.No", width: 140 },
    { field: "patientid", headerName: "Patient ID", width: 140 },
    { field: "Appointmentid", headerName: "Appointment ID", width: 140 },
    { field: "PatientName", headerName: "Patient Name", width: 140 },
    { field: "date", headerName: "Date", width: 100 },
    { field: "gender", headerName: "Gender", width: 120 },
    { field: "PhoneNo", headerName: "PhoneNo", width: 130 },
    { field: "Status", headerName: "Status", width: 120 },
    // {
    //   field: "actions",
    //   headerName: "View",
    //   width: 100,
    //   renderCell: (params) => (
    //     <Button className="cell_btn" onClick={() => handleList(params)}>
    //       <VisibilityIcon />
    //     </Button>
    //   ),
    // },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <Button className="cell_btn" onClick={() => handleRequestProceed(params)}>
          <ArrowForwardIcon />
        </Button>
      ),
    },
  ];

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.PatientName.toLowerCase();
      const lowerCasePhoneNo = row.PhoneNo.toString();

      const startsWithFirstName = lowerCaseSupplierName.startsWith(searchQuery.toLowerCase());
      const startsWithPhoneNo = lowerCasePhoneNo.startsWith(searchQuery1.toLowerCase());

      return (startsWithFirstName || !searchQuery) && (startsWithPhoneNo || !searchQuery1);
    });

    // If there is a search query, sort the data to bring the left-to-right matching rows to the top
    const sortedData = searchQuery || searchQuery1
      ? [...filteredData].sort((a, b) => {
          const aStartsWith =
            a.PatientName.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
            a.PhoneNumber.toString().toLowerCase().startsWith(searchQuery1.toLowerCase());
          const bStartsWith =
            b.PatientName.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
            b.PhoneNumber.toString().toLowerCase().startsWith(searchQuery1.toLowerCase());

          return bStartsWith - aStartsWith;
        })
      : filteredData;

    setFilteredRows(sortedData);
    setPage(0);
  }, [searchQuery, searchQuery1, rows]);

  const highlightSearchTerm = (text, searchQuery, searchQuery1) => {
    if (!text || (!searchQuery && !searchQuery1)) {
      return text;
    }

    const lowerCaseText = typeof text === "string" ? text.toLowerCase() : text.toString().toLowerCase();
    const lowerCaseSearchTerm = searchQuery ? searchQuery.toString().toLowerCase() : "";
    const lowerCaseSearchTerm1 = searchQuery1 ? searchQuery1.toString().toLowerCase() : "";

    let highlightedText = text.toString();

    if ((lowerCaseSearchTerm && typeof lowerCaseText === "string") || (lowerCaseSearchTerm1 && typeof lowerCaseText === "string")) {
      const searchTerm = lowerCaseSearchTerm || lowerCaseSearchTerm1;
      const startIndex = lowerCaseText.indexOf(searchTerm);

      if (startIndex === 0) {
        // Check if the search term starts from the beginning
        const endIndex = startIndex + searchTerm.length;
        highlightedText = (
          <>
            <span style={{ backgroundColor: "var(--ProjectColor)", fontWeight: "bold", padding: "2px 0px" }}>
              {lowerCaseText.substring(0, endIndex)}
            </span>
            {lowerCaseText.substring(endIndex)}
          </>
        );
      }
    }

    return highlightedText;
  };

  const handleFetchData = useCallback(() => {
    axios
      .get(`${urllink}doctorsworkbench/get_fortheraphist_queuelist?location=${userRecord?.location}&Dateforget=${Dateforget}`)
      .then((response) => {
        console.log("responsegenesis", response.data); // Fix console log
        const data = response.data;

        const Records = data.map((userdata, index) => ({
          id: index + 1,
          patientid: userdata.PatientID,
          Appointmentid: userdata.AppointmentId,
          PatientName: userdata.fullName,
          date:userdata.date,
          age: userdata.age,
          gender: userdata.gender,
          consultation_type: userdata.consultation_type,
          PhoneNo: userdata.phoneNo,
          address: userdata.address,
          Source: userdata.Source,
          Status: userdata.Status,
          Location: userdata.Location,
        }));

        if (SelectedPatientCalender) {
          const newrecords = Records.filter((p) => SelectedPatientCalender === p.PatientName);
          setRows(newrecords);
        } else {
          setRows(Records);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [urllink, userRecord, Dateforget, SelectedPatientCalender]);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);

  useEffect(() => {
    axios
      .get(`${urllink}appointmentmanagement/get_genesis_data?location=${userRecord.location}`)
      .then((response) => {
        console.log("datasgenesis", response.data);
        const  data = response.data 
        const Records = data.map((userdata,index) => ({
          id:index +1,
          patientid: userdata.PatientID ,
          Appointmentid: userdata.AppointmentId,
          PatientName: userdata.fullName,
          date:userdata.date,
          age: userdata.age,
          gender: userdata.gender,
          consultation_type: userdata.consultation_type,
          PhoneNo: userdata.phoneNo,
          address: userdata.address,
          Source: userdata.Source,
          Status: userdata.Status,
          Location: userdata.location,
        }));
        if (SelectedPatientCalender) {
          const newrecords = Records.filter(
            (p) => SelectedPatientCalender === p.PatientName
          );
          setRows(newrecords);
        } else {
          setRows(Records);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [SelectedPatientCalender]);

  // useEffect(() => {
  //   axios
  //     .get('${urllink}appointmentmanagement/get_genesis_data')
  //     .then((response) => {
  //       console.log("genesispenddinh", response.data);

  //     })
  //     .catch((err) => {
  //       console.error("Error fetching patient data:", err);
  //     });
  // }, []);

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Patient Queue List GENESIS</h4>
        </div>

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              First Name <span>:</span>
            </label>
            <input
              type="text"
              id="FirstName"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">
              Phone No <span>:</span>
            </label>
            <input
              type="number"
              id="PhoneNo"
              value={searchQuery1}
              onChange={handleSearchChange}
              placeholder="Enter the Phone No"
            />
          </div>

          
          <div className="inp_1">
            <label htmlFor="input">
              Date <span>:</span>
            </label>
            <input
              type="date"
              id="DateforgetID"
              value={Dateforget}
              onChange={(e)=>{setDateforget(e.target.value)}}
            />
          </div>
        </div>

        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
    </>
  );
};

export default PatientQueList1;
