import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function UserList() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [page, setPage] = React.useState(0);
  const pageSize = 10;

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const [columns] = React.useState([
    { field: "username", headerName: "UserName", width: 200 },
    { field: "First_Name", headerName: "Full Name", width: 200 },
    { field: "email", headerName: "Mail ID", width: 200 },
    { field: "role_name", headerName: "Role Name", width: 200 },
  ]);
  const location = userRecord?.location;
  const [userData, setUserData] = React.useState([]);
  const [userName, setUserName] = useState("");
  const [filteredSearch, setFilteredSearch] = useState([]);

  // Get method to fetch user data based on the entered user name
  const getUserdata = () => {
    axios
      .get(`${urllink}usercontrol/userListfetch?location=${location}`)
      .then((response) => {
        const data = response.data;
        setUserData(
          data.map((row, index) => ({
            id: index + 1,
            ...row,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // Fetch and set user data when the component mounts
    getUserdata();
  }, []);

  const handleSearch = () => {
    const lowerCase = userName.toLowerCase();
    const filterData = userData.filter((user) => {
      const lowerCaseUserName = user.username.toLowerCase();
      return lowerCaseUserName.startsWith(lowerCase);
    });

    setFilteredSearch(filterData);
  };

  return (
    <>
      <Helmet>
        <title>User List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h4>User List</h4>
        </div>

        <div>
          <div className="con_1">
            <div className="inp_1">
              <label htmlFor="input">
                User Name <span>:</span>
              </label>
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter the User Name"
              />
            </div>
            <button className="btn_1" onClick={handleSearch}>
              <SearchIcon />
            </button>
          </div>
        </div>

        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={
                filteredSearch.length > 0
                  ? filteredSearch
                  : userData.slice(page * pageSize, (page + 1) * pageSize)
              }
              columns={columns}
              pageSize={100}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {filteredSearch.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of{" "}
                {Math.ceil((filteredSearch.length || userData.length) / 10)}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(
                          (filteredSearch.length || userData.length) / 10
                        ) - 1
                      )
                    )
                  }
                  disabled={
                    page ===
                    Math.ceil((filteredSearch.length || userData.length) / 10) -
                      1
                  }
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
    </>
  );
}
