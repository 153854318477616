import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SideBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {
  faUsers, faHeadset, faStethoscope, faClipboard, faFileMedical,faMitten, faMoneyBillAlt, faRightFromBracket, faPenNib, faLayerGroup, faUserDoctor, faPersonShelter, faShop, faPeopleArrows,
  faUserNurse, faSackDollar, faUserNinja, faUserTag, faChartBar, faAngleDown, faBuildingColumns, faStore, faSuitcaseMedical, faThumbsUp, faIndent, faRegistered, faHeartPulse
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

function Sidebar({ }) {



  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [mainAccess, setMainAccess] = useState([]);
  const [subAccess, setSubAccess] = useState([]);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  // const handleSidebarToggle = () => {
  //   setSidebarOpen(!isSidebarOpen);
  // };
  const location = useLocation();

  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)

  useEffect(() => {
    dispatchvalue({ type: 'isSidebarOpen', value: false })
    // Close the sidebar when the route changes
    console.log(location);
    const userRecord = localStorage.getItem("token")
    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      const setAccess1 = (decodedTokenData && decodedTokenData.Access_one.split(',').map(item => item.trim())) || [];
      const setAccess2 = (decodedTokenData && decodedTokenData.Access_two.split(',').map(item => item.trim())) || [];

      setMainAccess(setAccess1);
      setSubAccess(setAccess2);

    };
    // Split the string and then trim each element
  }, [location.pathname]);

  // console.log(subAccess);
  // console.log(mainAccess)
  useEffect(() => {
    // Disable text selection on the entire document
    const disableTextSelection = () => {
      document.body.style.userSelect = 'none';
      document.body.style.MozUserSelect = 'none';
      document.body.style.msUserSelect = 'none';
    };
    disableTextSelection();
    // Enable text selection when the component is unmounted
    return () => {
      document.body.style.userSelect = 'auto';
      document.body.style.MozUserSelect = 'auto';
      document.body.style.msUserSelect = 'auto';
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // This effect runs once when the component mounts


  const handleSubMenuClick = (subMenu) => {
    setOpenSubMenu(openSubMenu === subMenu ? null : subMenu);

  };


  const handleLogoutClick = () => {
    navigate('/')
    localStorage.clear()
  };



  return (
    <nav id="inventory_sidebar" className={isSidebarOpen ? 'inventory_sidebar_open' : ''} ref={sidebarRef}>

      <div className="inv_sidebar_header" onMouseEnter={() => dispatchvalue({ type: 'isSidebarOpen', value: true })} onMouseLeave={() => dispatchvalue({ type: 'isSidebarOpen', value: false })}>
        <div className="inv_components_sidebar">

          {mainAccess.includes("A") && <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'} id='font_111'>
            <FontAwesomeIcon icon={faStethoscope} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name" onClick={(() => { navigate("/Home/ClinicMetrics") })}>Clinic Metrics</span>}

          </div>}

          {mainAccess.includes("B") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu1')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUsers} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Front Office</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu1' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu1' && <div className="subSidebarmenuhover">
              {/* {subAccess.includes("B2-1") && <div onClick={(() => { navigate("/Home/Appoinment-RequestList") })}> {isSidebarOpen && <span className="icon-name" >Appoinment Request</span>}</div>} */}
              {!subAccess.includes("B2-0") && <div onClick={(() => { navigate("/Home/RegisterFormNew")})}> {isSidebarOpen && <span className="icon-name" >Appointment Register</span>}</div>}
              {subAccess.includes("B2-2") && <div onClick={(() => { navigate("/Home/Appoinment-RegisterList1"); dispatchvalue({ type: 'selectedRequestList', value: [] }) })}> {isSidebarOpen && <span className="icon-name" >Appointment Register List</span>}</div>}
              {subAccess.includes("B2-3") && <div onClick={(() => { navigate("/Home/Followuplist") })}>{isSidebarOpen && <span className="icon-name" >Follow-up List</span>}</div>}
              {subAccess.includes("B2-4") && <div onClick={(() => { navigate("/Home/Register_concern_List") })}>{isSidebarOpen && <span className="icon-name" >Consent Forms</span>}</div>}
              {subAccess.includes("B2-5") && <div onClick={(() => { navigate("/Home/Lab-QueueList"); dispatchvalue({ type: 'NurseworkbenchformData', value: [] }) })}>{isSidebarOpen && <span className="icon-name" >Lab Report</span>}</div>}
              {subAccess.includes("B2-6") && <div onClick={(() => { navigate("/Home/Patient-List-update") })}>{isSidebarOpen && <span className="icon-name" >Patient Management</span>}</div>}


            </div>}
          </div>}

          {mainAccess.includes("Q") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu1')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUsers} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Tele Calling</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu1' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu1' && <div className="subSidebarmenuhover">
              {/* {subAccess.includes("B2-1") && <div onClick={(() => { navigate("/Home/Appoinment-RequestList") })}> {isSidebarOpen && <span className="icon-name" >Appoinment Request</span>}</div>} */}
              {subAccess.includes("Q17-1") && <div onClick={(() => { navigate("/Home/Followuplist") })}>{isSidebarOpen && <span className="icon-name" >Follow-up List</span>}</div>}
              {subAccess.includes("Q17-2") && <div onClick={(() => { navigate("/Home/Patient-List-update") })}>{isSidebarOpen && <span className="icon-name" >Patient Management</span>}</div>}

            </div>}
          </div>}







          {mainAccess.includes("C") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu2')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserNurse} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Nurse</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu2' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu2' && <div className="subSidebarmenuhover">
              {subAccess.includes("C3-1") && <div onClick={(() => { navigate("/Home/Treament-QueueList3") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
            </div>}
          </div>}

          {mainAccess.includes("D") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu3')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserDoctor} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Doctor's WorkBench</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu3' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu3' && <div className="subSidebarmenuhover">
              {subAccess.includes("D4-1") && <div onClick={(() => { navigate("/Home/Treament-QueueList1") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
            </div>}
          </div>}

          {!mainAccess.includes("") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu21')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faMitten} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Procedure WorkBench</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu21' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu21' && <div className="subSidebarmenuhover">
              {!subAccess.includes("") && <div onClick={(() => { navigate("/Home/Procedure-QueList") })}> {isSidebarOpen && <span className="icon-name" >Procedure Queue List </span>}</div>}
            </div>}
          </div>}


         
          {mainAccess.includes("E") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu6')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faFileMedical} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Pharmacy</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu6' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu6' && <div className="subSidebarmenuhover">
              {subAccess.includes("E5-1") && <div onClick={(() => { navigate("/Home/Pharmacy_Billing_List") })}> {isSidebarOpen && <span className="icon-name" >Pharmacy Billing</span>}</div>}
      

            </div>}
          </div>}


          {mainAccess.includes("F") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu7')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faMoneyBillAlt} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Cashier</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu7' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu7' && <div className="subSidebarmenuhover">
              {subAccess.includes("F6-1") && <div onClick={(() => { navigate("/Home/Billing-Invoice") })}> {isSidebarOpen && <span className="icon-name" >Billing </span>}</div>}

              {subAccess.includes("F6-2") && <div onClick={(() => { navigate("/Home/Deu-History-List") })}> {isSidebarOpen && <span className="icon-name" >Due History </span>}</div>}



            </div>}
          </div>}



          {mainAccess.includes("G") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu8")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faSackDollar}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Petty Cash</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu8" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu8" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("G7-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Expenses-Master");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Expense Master</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("G7-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Cash Expenses </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("G7-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Digital-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Digital Expenses </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("G7-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Expenses Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("G7-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Hand-Over-Summary");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">HandOver Summary</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("G7-6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Book-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("G7-7") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Closing");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}




          {mainAccess.includes("H") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu17')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faSuitcaseMedical} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Inventory Master</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu17' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu17' && <div className="subSidebarmenuhover">

              {subAccess.includes("H8-1") && <div onClick={(() => { navigate("/Home/Supplier_List") })}> {isSidebarOpen && <span className="icon-name" >Supplier Master </span>}</div>}
              {subAccess.includes("H8-2") && <div onClick={(() => { navigate("/Home/Productitemmaster") })}> {isSidebarOpen && <span className="icon-name" >Product Type Master</span>}</div>}
              {subAccess.includes("H8-3") && <div onClick={(() => { navigate("/Home/Pharmacy_MasterList") })}> {isSidebarOpen && <span className="icon-name" >Pharmacy Master </span>}</div>}
              {subAccess.includes("H8-4") && <div onClick={(() => { navigate("/Home/General_MasterList") })}> {isSidebarOpen && <span className="icon-name" >General Master </span>}</div>}


            </div>}
          </div>}

          {mainAccess.includes("I") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu15')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faLayerGroup} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Inventory</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu15' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu15' && <div className="subSidebarmenuhover">
              {subAccess.includes("I9-1") && <div onClick={(() => { navigate("/Home/Indent_Raise_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Raise </span>}</div>}
              {subAccess.includes("I9-2") && <div onClick={(() => { navigate("/Home/Indent_Recieve_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Recieve </span>}</div>}
              {subAccess.includes("I9-3") && <div onClick={(() => { navigate("/Home/Indent_Return_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Return</span>}</div>}

              {subAccess.includes("I9-4") && <div onClick={(() => { navigate("/Home/IndentMovementlist") })}> {isSidebarOpen && <span className="icon-name" >Indent Move </span>}</div>}

              {subAccess.includes("I9-5") && <div onClick={(() => { navigate("/Home/Quick_Stock_Recieve_List") })}> {isSidebarOpen && <span className="icon-name" >Quick Stock Recieve</span>}</div>}

              {subAccess.includes("I9-6") && <div onClick={(() => { navigate("/Home/LocationStock_List") })}> {isSidebarOpen && <span className="icon-name" >Location Stock_List </span>}</div>}
              {subAccess.includes("I9-7") && <div onClick={(() => { navigate("/Home/PurchaseReturnLocMasterlist") })}> {isSidebarOpen && <span className="icon-name" >Purchase Return </span>}</div>}

              {subAccess.includes("I9-8") && <div onClick={(() => { navigate("/Home/Supplier-Stock-Manager") })}> {isSidebarOpen && <span className="icon-name" >Supplier pay </span>}</div>}

            </div>}
          </div>} 




          {mainAccess.includes("J") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu16')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faStore} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Central Store</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu16' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu16' && <div className="subSidebarmenuhover">
              {subAccess.includes("J10-1") && <div onClick={(() => { navigate("/Home/QuickStockMasterCentrallist") })}> {isSidebarOpen && <span className="icon-name" > Quick Stock Recieve</span>}</div>}
              {subAccess.includes("J10-2") && <div onClick={(() => { navigate("/Home/Purchase_Raise_list") })}> {isSidebarOpen && <span className="icon-name" >Purchase Master </span>}</div>}
              {subAccess.includes("J10-3") && <div onClick={(() => { navigate("/Home/Purchase_Recieve_list") })}> {isSidebarOpen && <span className="icon-name" >GRN Recieve</span>}</div>}
              {subAccess.includes("J10-4") && <div onClick={(() => { navigate("/Home/Indent_Issue_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Issue </span>}</div>}

              {subAccess.includes("J10-5") && <div onClick={(() => { navigate("/Home/PurchaseReturnMasterlist") })}> {isSidebarOpen && <span className="icon-name" >Purchase Return </span>}</div>}
              {subAccess.includes("J10-6") && <div onClick={(() => { navigate("/Home/CentralStock_List") })}> {isSidebarOpen && <span className="icon-name" >Central Stock List </span>}</div>}
              {subAccess.includes("J10-7") && <div onClick={(() => { navigate("/Home/Supplier_Pay_centralStore") })}> {isSidebarOpen && <span className="icon-name" >Supplier pay </span>}</div>}
            </div>}
          </div>}

          {mainAccess.includes("K") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu18')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faThumbsUp} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">CentralStore Approve</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu18' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu18' && <div className="subSidebarmenuhover">

              {subAccess.includes("K11-1") && <div onClick={(() => { navigate("/Home/GRN_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >GRN Approve </span>}</div>}
              {!subAccess.includes("K11-2") && <div onClick={(() => { navigate("/Home/LocationGRN_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Loc GRN Approve </span>}</div>} 
              {subAccess.includes("K11-3") && <div onClick={(() => { navigate("/Home/PurchaseApprove_List") })}> {isSidebarOpen && <span className="icon-name" >Purchase Approve </span>}</div>}
               {subAccess.includes("K11-4") && <div onClick={(() => { navigate("/Home/IndentIssue_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Issue Approve </span>}</div>}
              {subAccess.includes("K11-5") && <div onClick={(() => { navigate("/Home/IndentReturnApprove") })}> {isSidebarOpen && <span className="icon-name" >Indent Return Approve </span>}</div>} 

            </div>}
          </div>}

        {mainAccess.includes("L") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu19')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faIndent} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Indent Approve</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu19' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu19' && <div className="subSidebarmenuhover">

              {subAccess.includes("L12-1") && <div onClick={(() => { navigate("/Home/Indent_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Approve </span>}</div>}
              {subAccess.includes("L12-2") && <div onClick={(() => { navigate("/Home/IndentRecieve_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Recieve Approve </span>}</div>}


            </div>}
          </div>} 

          {mainAccess.includes("M") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu20')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faRegistered} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Reports</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu20' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu20' && <div className="subSidebarmenuhover">

              {subAccess.includes("M13-1") && <div onClick={(() => { navigate("/Home/Supplier_Pay_List") })}> {isSidebarOpen && <span className="icon-name" >Supplier Pay List </span>}</div>}
              {subAccess.includes("M13-2") && <div onClick={(() => { navigate("/Home/PurchaseRegister") })}> {isSidebarOpen && <span className="icon-name" >Purchase Register </span>}</div>}
              {subAccess.includes("M13-3") && <div onClick={(() => { navigate("/Home/SalesRegister") })}> {isSidebarOpen && <span className="icon-name" >Sales Register </span>}</div>}
              {subAccess.includes("M13-4") && <div onClick={(() => { navigate("/Home/HSNReport") })}> {isSidebarOpen && <span className="icon-name" >HSN Report</span>}</div>}
              {subAccess.includes("M13-5") && <div onClick={(() => { navigate("/Home/DueReport") })}> {isSidebarOpen && <span className="icon-name" >Due Report</span>}</div>}


            </div>}
          </div>}


          {mainAccess.includes("N") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu10')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faPenNib} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">HR Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu10' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu10' && <div className="subSidebarmenuhover">
              {subAccess.includes("N14-1") && <div onClick={(() => { navigate("/Home/Employee-Register"); dispatchvalue({ type: 'foremployeeedit', value: [] }) })}>{isSidebarOpen && <span className="icon-name" >Employee Register</span>}</div>}
              {subAccess.includes("N14-2") && <div onClick={(() => { navigate("/Home/Employee-List") })}>{isSidebarOpen && <span className="icon-name" >Employee List</span>}</div>}
              {subAccess.includes("N14-3") && <div onClick={(() => { navigate("/Home/Employee-Attendance") })}>{isSidebarOpen && <span className="icon-name" >Attendance</span>}</div>}
              {subAccess.includes("N14-4") && <div onClick={(() => { navigate("/Home/Employee-LeaveApproval") })}> {isSidebarOpen && <span className="icon-name" >Leave Approval </span>}</div>}
              {subAccess.includes("N14-5") && <div onClick={(() => { navigate("/Home/Employee-AdvanceApproval") })}> {isSidebarOpen && <span className="icon-name" >Advance Approval </span>}</div>}
              {subAccess.includes("N14-6") && <div onClick={(() => { navigate("/Home/Employee-Performance") })}> {isSidebarOpen && <span className="icon-name" >Performance Appraisal </span>}</div>}
              {subAccess.includes("N14-7") && <div onClick={(() => { navigate("/Home/Employee-PerformanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Performance Management </span>}</div>}
              {subAccess.includes("N14-8") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {subAccess.includes("N14-9") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>}
              {subAccess.includes("N14-10") && <div onClick={(() => { navigate("/Home/Employee-PayRoll") })}> {isSidebarOpen && <span className="icon-name" >Pay Roll </span>}</div>}
              {subAccess.includes("N14-11") && <div onClick={(() => { navigate("/Home/Duty-Management") })}> {isSidebarOpen && <span className="icon-name" >Duty Management</span>}</div>}


              {/* <div onClick={(() => { navigate("/Home/Employee-PaySlip") })}> {isSidebarOpen && <span className="icon-name" >Pay Slip </span>}</div> */}
            </div>}
          </div>
          }


          {mainAccess.includes("O") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu12')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserTag} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Employee Request</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu12' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu12' && <div className="subSidebarmenuhover">

              {subAccess.includes("O15-1") && <div onClick={(() => { navigate("/Home/Navigation-leave") })}> {isSidebarOpen && <span className="icon-name" >Leave Management</span>}</div>}
              {subAccess.includes("O15-2") && <div onClick={(() => { navigate("/Home/Navigation-Advance") })}>{isSidebarOpen && <span className="icon-name" >Advance Management</span>}</div>}
              {subAccess.includes("O15-3") && <div onClick={(() => { navigate("/Home/Shift-Details") })}>{isSidebarOpen && <span className="icon-name" >Shift Management</span>}</div>}
              {subAccess.includes("O15-4") && <div onClick={(() => { navigate("/Home/Employee-PaySlip-View") })}>{isSidebarOpen && <span className="icon-name" >Pay Slip Download</span>}</div>}


            </div>}
          </div>}

          {mainAccess.includes("P") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu13')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faChartBar} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">User Control</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu13' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu13' && <div className="subSidebarmenuhover">
              {subAccess.includes("P16-1") && <div onClick={(() => { navigate("/Home/Role-Management") })}>{isSidebarOpen && <span className="icon-name" >Role Management </span>}</div>}
              {subAccess.includes("P16-2") && <div onClick={(() => { navigate("/Home/EmployeeQue-List") })}> {isSidebarOpen && <span className="icon-name" >Employee Queue List</span>}</div>}
              {subAccess.includes("P16-3") && <div onClick={(() => { navigate("/Home/Register-User"); dispatchvalue({ type: 'foredituserregisteremployeedata', value: [] }); dispatchvalue({ type: "foruserregisteremployeedata", value: [] }) })}> {isSidebarOpen && <span className="icon-name" > User Register</span>}</div>}
              {subAccess.includes("P16-4") && <div onClick={(() => { navigate("/Home/Doctor Consulation Charges") })}> {isSidebarOpen && <span className="icon-name" >Ratecard Charges </span>}</div>}
              {subAccess.includes("P16-5") && <div onClick={(() => { navigate("/Home/Labmasterone") })}> {isSidebarOpen && <span className="icon-name" >Lab Master </span>}</div>}
           
              {subAccess.includes("P16-6") && <div onClick={(() => { navigate("/Home/Account Settings") })}> {isSidebarOpen && <span className="icon-name" >Account Settings </span>}</div>}
              {subAccess.includes("P16-7") && <div onClick={(() => { navigate("/Home/Clinic Details") })}> {isSidebarOpen && <span className="icon-name" >Clinic Details </span>}</div>}
              {subAccess.includes("P16-8") && <div onClick={(() => { navigate("/Home/User-List") })}> {isSidebarOpen && <span className="icon-name" >User List </span>}</div>}
              {subAccess.includes("P16-9") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {subAccess.includes("P16-10") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>}

            </div>}
          </div>
          }





         





          <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'} onClick={handleLogoutClick} id='font_111'>
            <FontAwesomeIcon icon={faRightFromBracket} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name" >Logout</span>}
          </div>

        </div>
      </div>
    </nav >
  );
};

export default Sidebar;
