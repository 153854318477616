import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import bgImg2 from "../assets/bgImg2.jpg";
import { useDispatch, useSelector } from "react-redux";
import DotPic2 from '../assets/DotPic2.png';
import { ToastContainer, toast } from "react-toastify";



const PrintContent = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} id="reactprintcontent">
      {props.children}
    </div>
  );
});

function CaseSheet() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log("userRecord", userRecord);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const DataGnesis = useSelector((state) => state.userRecord?.DataGnesis);
  console.log("data344", DataGnesis);

  const formgenesis = useSelector((state) => state.userRecord?.workbenchformData);
  console.log("123456789", formgenesis);
  const dispatchvalue = useDispatch();
  const [SelectedFile, setSelectedFile] = useState(null);
  console.log("SelectedFile", SelectedFile);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  const [predefinedComplaints, setPredefinedcomplaints] = useState([]);
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [Complaint, setComplaint] = useState("");

  const [ClinicDetails, setClinicDetails] = useState({});

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=${userRecord?.location}`)

      .then((response) => {
        const record = response.data[0];
        if (record) {
          setClinicDetails(record);
        } else {
          console.error("No clinic details found");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    axios
      .get(
        `${urllink}doctorsworkbench/get_vital_forprofile?selectedRowId=${DataGnesis?.patientID}`
      )

      .then((response) => {
        const record = response.data[0];
        console.log("past", record);
        if (record) {
          console.log("ggg", record);
          setCaseSheetFormData((prev) => ({
            ...prev,
            Height: record.HeightCm,
            Weight: record.WeightKg,
            BMI: record.BMI,
          }));
        } else {
          console.error("No pasthistory details found");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    axios
      .get(
        `${urllink}doctorsworkbench/get_allergiesname_forprofile?selectedRowId=${DataGnesis?.patientID}`
      )

      .then((response) => {
        const record = response.data;
        console.log("medicalhis", record);
        if (record) {
          console.log("frsd", record);
          setCaseSheetFormData((prev) => ({
            ...prev,
            MedicalTreatment: record.medicalHistory.medicalHistory,
          }));
        } else {
          console.error("No pasthistory details found");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;
          setClinicLogo(`data:image/png;base64,${firstClinic.clinicLogo}`);
        } else {
          console.error("No record found");
        }
      })
      .catch((error) => console.error("Error fetching data"));
  }, [userRecord?.location, formgenesis?.PatientID, urllink]); // Empty dependency array ensures this effect runs only once

  const [workbenchformData, setFormData] = useState({
    SerialNo: "",
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    firstName: "",
    lastName: "",
    AppointmentDate: "",
    Complaint: "",
    PatientPhoto: "",
    DoctorName: "",
    Age: "",
    Gender: "",
    Location: "",
  });

  console.log(workbenchformData);



  const componentRef = useRef();

  const handlePrint2 = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: async () => {
      // Additional action after printing, if needed
    },
  });

  const Submitalldata = () => {
    setIsPrintButtonVisible(false);
    setTimeout(() => {
      handlePrint2();
      setIsPrintButtonVisible(true); // Resetting print button visibility
    }, 500); // Adjust delay as needed
  };

  const [clinicName, setClinicName] = useState("");
  const [clinicLogo, setClinicLogo] = useState(null);
  const [location, setlocation] = useState("");

  useEffect(() => {
    const location = userRecord?.location;

    axios
      .get("your_api_endpoint")
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.Clinic_Name);
          setClinicLogo(`data:image/png;base64,${data.Clinic_Logo}`);
          setlocation(data.location);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [userRecord]);
  //

  const [caseSheetformData, setCaseSheetFormData] = useState({
    PatientPhoto: SelectedFile,
    Complaint: "",
    Severity: "",
    Onset: "",
    PastTreatment: "",
    MedicalTreatment: "",
    SurgicalHistory: "",
    FamilyHistory: "",
    Diet: "",
    Sleep: "",
    MensturalCycles: "",
    Stress: "",
    Height: "",
    Weight: "",
    BMI: "",
    Allergy: "",
    Diagnosis: "",
    Doctornotes: "",
    ProcedureAdvice: "",
    CaseSheetId: "",
  });

  console.log("caseSheetformData", caseSheetformData);
  const [checkboxState, setCheckboxState] = useState({
    none: false,
    alcohol: false,
    tobacco: false,
    caffeine: false,
    narcotics: false,
    others: false,
  });
  console.log("checkboxState", checkboxState);

  const [alcoholInputsVisible, setAlcoholInputsVisible] = useState(false);
  const [tobaccoInputsVisible, setTobaccoInputsVisible] = useState(false);
  const [caffeineInputsVisible, setCaffeineInputsVisible] = useState(false);
  const [narcoticsInputsVisible, setNarcoticsInputsVisible] = useState(false);
  const [othersInputsVisible, setOthersInputsVisible] = useState(false);

  const [alcoholInputs, setAlcoholInputs] = useState({
    daily: "",
    frequently: "",
    occasionally: "",
  });
  const [tobaccoInputs, setTobaccoInputs] = useState({
    daily: "",
    frequently: "",
    occasionally: "",
  });
  const [caffeineInputs, setCaffeineInputs] = useState({
    daily: "",
    frequently: "",
    occasionally: "",
  });
  const [narcoticsInputs, setNarcoticsInputs] = useState({
    daily: "",
    frequently: "",
    occasionally: "",
  });
  const [othersInput, setOthersInput] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCaseSheetFormData({
      ...caseSheetformData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'none') {
      setCheckboxState({
        none: checked,
        alcohol: false,
        tobacco: false,
        caffeine: false,
        narcotics: false,
        others: false,
      });
    } else {
      setCheckboxState(prevState => ({
        ...prevState,
        [name]: checked,
        none: false,
      }));
    }
  };

  const handleInputChange = (habit, type, value) => {
    switch (habit) {
      case "alcohol":
        setAlcoholInputs((prevState) => ({
          ...prevState,
          [type]: value,
        }));
        break;
      case "tobacco":
        setTobaccoInputs((prevState) => ({
          ...prevState,
          [type]: value,
        }));
        break;
      case "caffeine":
        setCaffeineInputs((prevState) => ({
          ...prevState,
          [type]: value,
        }));
        break;
      case "narcotics":
        setNarcoticsInputs((prevState) => ({
          ...prevState,
          [type]: value,
        }));
        break;
      case "others":
        setOthersInput(value);
        break;
      default:
        break;
    }
  };
  const [pdfBlob, setPdfBlob] = useState(null);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforePrint: () => {
      console.log("Before");
    },

    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log("After");
      const printdata = document.getElementById("reactprintcontent");
      console.log("printdata", printdata);

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20; // Adjust the padding as needed
          const pdfWidth = contentWidth + 2 * padding; // Add padding to width
          const pdfHeight = contentWidth * 1.5; // Add padding to height
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });
          pdf.html(printdata, {
            x: padding, // Set x-coordinate for content
            y: padding, // Set y-coordinate for content
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              setPdfBlob(generatedPdfBlob);
              console.log("generatedPdfBlob", generatedPdfBlob);
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
        // setpreview(false)
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCaseSheetFormData((prev) => ({
      ...prev,
      PatientPhoto: file
    }));
  };




  useEffect(() => {
    axios
      .get(`${urllink}doctorsworkbench/get_chief_complaint`)
      .then((response) => {
        const data = response.data; // Use the correct response format
        console.log('fetched', data);
        if (Array.isArray(data)) {
          const uniqueComplaints = [
            ...new Set(data.map((item) => item.CheifComplaints)),
          ];
          console.log('uniqueComplaints', uniqueComplaints);
          setPredefinedcomplaints(uniqueComplaints);
        } else {
          setPredefinedcomplaints([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching treatment data:', error);
      });
  }, [urllink]);


  useEffect(() => {
    if (caseSheetformData.Complaint) {
      axios
        .get(
          `${urllink}doctorsworkbench/get_chiefcompby?complaint=${caseSheetformData.Complaint}`
        )
        .then((response) => {
          const data = response.data;
          if (Array.isArray(data)) {
            // Assuming you want to update form data with the first record
            setCaseSheetFormData((prev) => ({
              ...prev,
              ...data[0],
            }));
          } else {
            console.error('Fetched data is not an array:', data);
          }
        })
        .catch((error) => {
          console.error('Error fetching treatment data:', error);
        });
    }
  }, [urllink, caseSheetformData.Complaint]);

  useEffect(() => {
    dispatchvalue({ type: 'Complaint', value: caseSheetformData.Complaint })
  }, [caseSheetformData.Complaint])

  const imagePreview =
    caseSheetformData.PatientPhoto instanceof Blob
      ? URL.createObjectURL(caseSheetformData.PatientPhoto)
      : caseSheetformData.PatientPhoto;

  const handleSave = () => {
    const formData = new FormData();
    for (const key in caseSheetformData) {
      formData.append(key, caseSheetformData[key]);
    }

    const trueValues = Object.keys(checkboxState).filter(
      (key) => checkboxState[key]
    );
    formData.append("SocialHabits", JSON.stringify(trueValues));
    formData.append("PatientId", formgenesis?.PatientID);
    formData.append("visitId", formgenesis.visitNo);
    formData.append("AppointmentDate", formgenesis?.AppointmentDate);
    formData.append("createdby", userRecord?.username);
    formData.append("location", userRecord?.location);

    // Log the FormData keys and values (for debugging purposes)
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }


    axios
      .post(`${urllink}doctorsworkbench/insert_casesheet_form`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        console.log(response.data);
        successMsg(response.data.message);
      })
      .catch((error) => {
        errmsg("Error Occurred");
        console.error("There was an error!", error);
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const base64toFile = (base64String, fileName, mimeType) => {
    if (!base64String) {
      console.error("base64String is undefined or null.");
      return null;
    }

    // Remove any non-base64 characters (e.g., whitespace)
    const cleanedBase64String = base64String.replace(/[^A-Za-z0-9+/=]/g, '');

    // Add padding if necessary
    const padding = "=".repeat((4 - (cleanedBase64String.length % 4)) % 4);
    const paddedBase64String = cleanedBase64String + padding;

    try {
      // Decode base64 string
      const byteString = atob(paddedBase64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: mimeType });
      return new File([blob], fileName, { type: mimeType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null;
    }
  };


  useEffect(() => {
    const fetchData = () => {
      const PatientID = formgenesis.PatientID;
      const Location = userRecord.location;
      const visitId = formgenesis.visitNo;
      axios
        .get(
          `${urllink}doctorsworkbench/for_common_get_casesheet?patientid=${PatientID}&location=${Location}&visitId=${visitId}`
        )
        .then((response) => {
          console.log("success", response.data);
          if (response.data.length > 0) {
            const matchedData = response.data.find(
              (item) => item.PatientID === PatientID
            );



            if (matchedData) {


              const capturedImage1 = base64toFile(
                matchedData.PatientPhoto,
                matchedData.DecoFile1name,
                matchedData.DecoFile1type
              );

              console.log('veeeee', capturedImage1)

              const {
                CheifComplaints,
                BMI,
                Diagnosis,
                FamilyHistory,
                SurgicalHistory,
                HeightCm,
                Diet,
                MedicalHistory,
                MensturalCycle,
                Onset,
                Severity,
                SocialHabits,
                PastTreatment,
                Doctornotes,
                Sleep,
                Stress,
                WeightKg,
                ProcedureAdvice,
                CaseSheetId,
              } = matchedData;

              // Update form data
              setCaseSheetFormData({
                CaseSheetId: CaseSheetId || "",
                Complaint: CheifComplaints,
                Severity: Severity || "",
                Onset: Onset || "",
                PastTreatment: PastTreatment || "",
                MedicalTreatment: MedicalHistory || "",
                SurgicalHistory: SurgicalHistory || "",
                FamilyHistory: FamilyHistory || "",
                Diet: Diet || "",
                Sleep: Sleep || "",
                MensturalCycles: MensturalCycle || "",
                Stress: Stress || "",
                Weight: WeightKg || "",
                Height: HeightCm || "",
                BMI: BMI || "",
                Diagnosis: Diagnosis || "",
                Doctornotes: Doctornotes || "",
                Diet: Diet || "",
                Allergy: matchedData.Allergy || "",
                ProcedureAdvice: ProcedureAdvice || "",
                PatientPhoto: capturedImage1 || "",
              });

              if (Array.isArray(SocialHabits)) {
                const newCheckboxState = { ...checkboxState };
                SocialHabits.forEach((habit) => {
                  if (newCheckboxState.hasOwnProperty(habit)) {
                    newCheckboxState[habit] = true;
                  }
                });
                setCheckboxState(newCheckboxState);
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, [DataGnesis.patientID, DataGnesis.visitID, userRecord.location, urllink]);



  return (
    <>
      {isPrintButtonVisible ? (
        <div className="appointment case_sheet_Doctor">
          <div className="RegisFormcon" style={{ justifyContent: "center" }}>

            <div className="RegisForm_1 input-with-icon">
              <label htmlFor="history">
                Chief Complaints <span>:</span>
              </label>
              <div className="input-container">
                <input
                  list="complaints"
                  id="Complaint"
                  name="Complaint"
                  value={caseSheetformData.Complaint}
                  onChange={handleChange}
                />
                <datalist id="complaints">
                  {predefinedComplaints &&
                    predefinedComplaints.map((complaint, index) => (
                      <option key={index} value={complaint} />
                    ))}
                </datalist>
              </div>
            </div>

          </div>

          <br />
          <h4
            style={{
              color: "var(--labelcolor)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              padding: "5px",
            }}
          >
            Concerns
          </h4>

          <div className="case_sheet_5con">
            {/* <div className="case_sheet_5con_20">
              <label>
                Chief Complaints <span>:</span>
              </label>
              <textarea
                id="Complaint"
                name="Complaint"
                value={caseSheetformData.Complaint}
                onChange={handleChange}
              ></textarea>
            </div> */}


            <div className="RegisForm_1">
              <label htmlFor="CapturedFile1">
                Upload Photo <span>:</span>
              </label>
              <div className="file-input-444">
                <div className="RegisterForm_2">
                  <input
                    type="file"
                    id="CapturedFile1"
                    name="CapturedFile1"
                    accept='image/jpeg,image/png,application/pdf'
                    onChange={handleFileChange}
                    required
                  />
                  <label
                    htmlFor="CapturedFile1"
                    className="RegisterForm_1_btns choose_file_update"
                  >
                    Choose File
                  </label>
                </div>
              </div>
              {console.log(imagePreview)}
              {imagePreview && (
                <div style={{ marginTop: "10px" }}>
                  <img
                    src={imagePreview}
                    alt="Selected"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
              )}
            </div>

            <div className="case_sheet_5con_20_withs">
              <div className="case_sheet_5con_20">
                <label>
                  Severity <span>:</span>
                </label>
                <textarea
                  id="Severity"
                  name="Severity"
                  className="case_sheet_txtarea_e_2e"
                  value={caseSheetformData.Severity}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="case_sheet_5con_20">
                <label>
                  Onset <span>:</span>
                </label>
                <textarea
                  id="Onset"
                  name="Onset"
                  className="case_sheet_txtarea_e_2e"
                  value={caseSheetformData.Onset}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>

          <br />

          <h4
            style={{
              color: "var(--labelcolor)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              padding: "5px",
            }}
          >
            History
          </h4>

          <div className="case_sheet_5con">
            <div className="case_sheet_5con_20">
              <label>
                Past Treatment <span>:</span>
              </label>
              <textarea
                id="PastTreatment"
                name="PastTreatment"
                value={caseSheetformData.PastTreatment}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="case_sheet_5con_20">
              <label>
                Medical History or Allergies <span>:</span>
              </label>
              <textarea
                id="MedicalTreatment"
                name="MedicalTreatment"
                value={caseSheetformData.MedicalTreatment}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <br />
          <div className="case_sheet_5con">
            <div className="case_sheet_5con_20">
              <label>
                Surgical History <span>:</span>
              </label>
              <textarea
                id="SurgicalHistory"
                name="SurgicalHistory"
                value={caseSheetformData.SurgicalHistory}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="case_sheet_5con_20">
              <label>
                Family History <span>:</span>
              </label>
              <textarea
                id="FamilyHistory"
                name="FamilyHistory"
                value={caseSheetformData.FamilyHistory}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>

          <br />
          <h4
            style={{
              color: "var(--labelcolor)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              padding: "5px",
            }}
          >
            LifeStyle
          </h4>

          <div className="case_sheet_5con">
            <div className="case_sheet_5con_20">
              <label>
                Diet <span>:</span>
              </label>
              <textarea
                id="Diet"
                name="Diet"
                value={caseSheetformData.Diet}
                onChange={handleChange}
                className="lifecycle_h3udwh34"
              ></textarea>
            </div>
            <div className="case_sheet_5con_20">
              <label>
                Sleep <span>:</span>
              </label>
              <textarea
                id="Sleep"
                name="Sleep"
                value={caseSheetformData.Sleep}
                onChange={handleChange}
                className="lifecycle_h3udwh34"
              ></textarea>
            </div>
          </div>
          <br />

          <div className="case_sheet_5con">
            <div className="case_sheet_5con_20">
              <label>
                Menstural Cycles <span>:</span>
              </label>
              <textarea
                id="MensturalCycles"
                name="MensturalCycles"
                value={caseSheetformData.MensturalCycles}
                onChange={handleChange}
                className="lifecycle_h3udwh34"
              ></textarea>
            </div>
            <div className="case_sheet_5con_20">
              <label>
                Stress <span>:</span>
              </label>
              <textarea
                id="Stress"
                name="Stress"
                value={caseSheetformData.Stress}
                onChange={handleChange}
                className="lifecycle_h3udwh34"
              ></textarea>
            </div>
          </div>

          <br />
          <div className="case_sheet_5con">
            <div className="case_sheet_5con_20">
              <label>
                Height <span>:</span>
              </label>
              <input
                type="text"
                id="Height"
                name="Height"
                value={caseSheetformData.Height}
                onChange={handleChange}
                className="lifecycle_h3udwh34_OIIIUI"
              ></input>
            </div>
            <div className="case_sheet_5con_20">
              <label>
                Weight <span>:</span>
              </label>
              <input
                type="text"
                id="Weight"
                name="Weight"
                value={caseSheetformData.Weight}
                onChange={handleChange}
                className="lifecycle_h3udwh34_OIIIUI"
              ></input>
            </div>
            <div className="case_sheet_5con_20">
              <label>
                BMI <span>:</span>
              </label>
              <input
                type="text"
                id="BMI"
                name="BMI"
                value={caseSheetformData.BMI}
                onChange={handleChange}
                className="lifecycle_h3udwh34_OIIIUI"
              ></input>
            </div>
          </div>
          <br />

          <h4
            style={{
              color: "var(--labelcolor)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
              padding: "5px",
            }}
          >
            Social Habits
          </h4>
          <br />
          <div className="checkboxState_stylenbxf_head">
            <div className="checkboxState_stylenbxf">
              <label>
                <input
                  type="checkbox"
                  name="none"
                  checked={checkboxState.none}
                  onChange={handleCheckboxChange}
                />
                None
              </label>
            </div>

            <div className="checkboxState_stylenbxf">
              <label>
                <input
                  type="checkbox"
                  name="alcohol"
                  checked={checkboxState.alcohol}
                  onChange={handleCheckboxChange}
                />
                Alcohol
              </label>
            </div>

            <div className="checkboxState_stylenbxf">
              <label>
                <input
                  type="checkbox"
                  name="tobacco"
                  checked={checkboxState.tobacco}
                  onChange={handleCheckboxChange}
                />
                Tobacco
              </label>
            </div>
            <div className="checkboxState_stylenbxf">
              <label>
                <input
                  type="checkbox"
                  name="caffeine"
                  checked={checkboxState.caffeine}
                  onChange={handleCheckboxChange}
                />
                Caffeine
              </label>
            </div>
            <div className="checkboxState_stylenbxf">
              <label>
                <input
                  type="checkbox"
                  name="narcotics"
                  checked={checkboxState.narcotics}
                  onChange={handleCheckboxChange}
                />
                Narcotics
              </label>
            </div>
            <div className="checkboxState_stylenbxf">
              <label>
                <input
                  type="checkbox"
                  name="others"
                  checked={checkboxState.others}
                  onChange={handleCheckboxChange}
                />
                Others
              </label>
            </div>
          </div>


          <br />

          <div className="case_sheet_5con"></div>

          <br />
          <div className="case_sheet_5con">
            <div className="case_sheet_5con_20 jhnbjhse34">
              <label>
                Diagnosis <span>:</span>
              </label>
              <textarea
                id="Diagnosis"
                name="Diagnosis"
                value={caseSheetformData.Diagnosis}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="case_sheet_5con_20 jhnbjhse34">
              <label>
                Doctor Notes <span>:</span>
              </label>
              <textarea
                id="Doctornotes"
                name="Doctornotes"
                value={caseSheetformData.Doctornotes}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="case_sheet_5con_20 jhnbjhse34">
              <label>
                Procedure Advised <span>:</span>
              </label>
              <textarea
                id="ProcedureAdvice"
                name="ProcedureAdvice"
                value={caseSheetformData.ProcedureAdvice}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <br />

          <div className="iuhdyuew78554">
            <div className="jdefueur_908">
              <label>Doctor Signature & Seal</label>
              <div className="signature-container_976"></div>
            </div>
            
          </div>

          {isPrintButtonVisible && (
            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={Submitalldata}>
                Print
              </button>
            </div>
          )}
          <div className="Register_btn_con">
            <button className="RegisterForm_1_btns" onClick={handleSave}>
              {caseSheetformData.CaseSheetId ? "Update" : "Save"}
            </button>
            <ToastContainer />
          </div>
          <br />
        </div>
      ) : (
        <PrintContent
          ref={componentRef}
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
          }}
        >

          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: `url(${DotPic2})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
              backgroundSize: "60% 70%",
              marginTop: '50px',
              zIndex: -2,
              pointerEvents: "none",
              opacity: '0.2'
            }}
          />

          <div className="edxwedwe3_p" id="reactprintcontent" style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 9999 }}>
            <div className="New_billlling_invoice_head ">
              <div className="new_billing_logo_con ljkkhku7780">
                <img src={clinicLogo} alt="Medical logo" />
              </div>
            </div>

            <div className="new-patient-registration-form ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <h4>Case sheet</h4>
              </div>

              <div className="dctr_info_up_head">
                <div className=" ">

                </div>

                <div className="RegisFormcon">
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Patient Name <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {formgenesis.fullName}{" "}
                    </span>
                  </div>
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Patient ID <span>:</span>
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {formgenesis.PatientID}{" "}
                    </span>
                  </div>

                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Age <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {formgenesis.Age}{" "}
                    </span>
                  </div>
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Gender <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {formgenesis.Gender}{" "}
                    </span>
                  </div>
                  <div className="RegisForm_1 ">
                    <label htmlFor="FirstName">
                      Location <span>:</span>{" "}
                    </label>

                    <span className="dctr_wrbvh_pice" htmlFor="FirstName">
                      {userRecord.location}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="appointment case_sheet_Doctor">
              <div
                className="RegisFormcon"
                style={{ justifyContent: "center" }}
              >
                <div className="RegisForm_1 input-with-icon">
                  <label htmlFor="history">
                    Chief Complaints <span>:</span>
                  </label>
                  <div className="input-container">
                    <input
                      list="complaints"
                      id="Complaint"
                      name="Complaint"
                      value={caseSheetformData.Complaint}
                      onChange={handleChange}
                    />
                    <datalist id="complaints">
                      {predefinedComplaints &&
                        predefinedComplaints.map((complaint, index) => (
                          <option key={index} value={complaint} />
                        ))}
                    </datalist>
                  </div>
                </div>
              </div>

              <br />
              <h4
                style={{
                  color: "var(--labelcolor)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "start",
                  padding: "5px",
                }}
              >
                Concerns
              </h4>

              <div className="case_sheet_5con">
                {/* <div className="case_sheet_5con_20">
              <label>
                Chief Complaints <span>:</span>
              </label>
              <textarea
                id="Complaint"
                name="Complaint"
                value={caseSheetformData.Complaint}
                onChange={handleChange}
              ></textarea>
            </div> */}
                 {/*
                <div className="RegisForm_1">
                  <label htmlFor="CapturedFile1">
                    Upload Photo <span>:</span>
                  </label>
                  <div className="file-input-444">
                    <div className="RegisterForm_2">
                      <input
                        type="file"
                        id="CapturedFile1"
                        name="CapturedFile1"
                        accept="image/*"
                        onChange={handleFileChange}
                        required
                      />
                      <label
                        htmlFor="CapturedFile1"
                        className="RegisterForm_1_btns choose_file_update"
                      >
                        Choose File
                      </label>
                    </div>
                  </div>
                  {imagePreview && (
                    <div style={{ marginTop: "10px" }}>
                      <img
                        src={imagePreview}
                        alt="Selected"
                        style={{ width: "100px", height: "auto" }}
                      />
                    </div>
                  )}
                </div>
                */}
                <div className="case_sheet_5con_20_withs" style={{ display: 'flex' }}>
                  <div className="case_sheet_5con_20">
                    <label>
                      Severity <span>:</span>
                    </label>
                    <textarea
                      id="Severity"
                      name="Severity"
                      className="case_sheet_txtarea_e_2e"
                      value={caseSheetformData.Severity}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="case_sheet_5con_20">
                    <label>
                      Onset <span>:</span>
                    </label>
                    <textarea
                      id="Onset"
                      name="Onset"
                      className="case_sheet_txtarea_e_2e"
                      value={caseSheetformData.Onset}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>

              <br />

              <h4
                style={{
                  color: "var(--labelcolor)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "start",
                  padding: "5px",
                }}
              >
                History
              </h4>

              <div className="case_sheet_5con">
                <div className="case_sheet_5con_20">
                  <label>
                    Past Treatment <span>:</span>
                  </label>
                  <textarea
                    id="PastTreatment"
                    name="PastTreatment"
                    value={caseSheetformData.PastTreatment}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="case_sheet_5con_20">
                  <label>
                    Medical History or Allergies <span>:</span>
                  </label>
                  <textarea
                    id="MedicalTreatment"
                    name="MedicalTreatment"
                    value={caseSheetformData.MedicalTreatment}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <br />
              <div className="case_sheet_5con">
                <div className="case_sheet_5con_20">
                  <label>
                    Surgical History <span>:</span>
                  </label>
                  <textarea
                    id="SurgicalHistory"
                    name="SurgicalHistory"
                    value={caseSheetformData.SurgicalHistory}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="case_sheet_5con_20">
                  <label>
                    Family History <span>:</span>
                  </label>
                  <textarea
                    id="FamilyHistory"
                    name="FamilyHistory"
                    value={caseSheetformData.FamilyHistory}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <br />
              <h4
                style={{
                  color: "var(--labelcolor)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "start",
                  padding: "5px",
                }}
              >
                LifeStyle
              </h4>

              <div className="case_sheet_5con">
                <div className="case_sheet_5con_20">
                  <label>
                    Diet <span>:</span>
                  </label>
                  <textarea
                    id="Diet"
                    name="Diet"
                    value={caseSheetformData.Diet}
                    onChange={handleChange}
                    className="lifecycle_h3udwh34"
                  ></textarea>
                </div>
                <div className="case_sheet_5con_20">
                  <label>
                    Sleep <span>:</span>
                  </label>
                  <textarea
                    id="Sleep"
                    name="Sleep"
                    value={caseSheetformData.Sleep}
                    onChange={handleChange}
                    className="lifecycle_h3udwh34"
                  ></textarea>
                </div>
              </div>
              <br />

              <div className="case_sheet_5con">
                <div className="case_sheet_5con_20">
                  <label>
                    Menstural Cycles <span>:</span>
                  </label>
                  <textarea
                    id="MensturalCycles"
                    name="MensturalCycles"
                    value={caseSheetformData.MensturalCycles}
                    onChange={handleChange}
                    className="lifecycle_h3udwh34"
                  ></textarea>
                </div>
                <div className="case_sheet_5con_20">
                  <label>
                    Stress <span>:</span>
                  </label>
                  <textarea
                    id="Stress"
                    name="Stress"
                    value={caseSheetformData.Stress}
                    onChange={handleChange}
                    className="lifecycle_h3udwh34"
                  ></textarea>
                </div>
              </div>

              <br />
              <div className="case_sheet_5con">
                <div className="case_sheet_5con_20">
                  <label>
                    Height <span>:</span>
                  </label>
                  <input
                    type="text"
                    id="Height"
                    name="Height"
                    value={caseSheetformData.Height}
                    onChange={handleChange}
                    className="lifecycle_h3udwh34_OIIIUI"
                  ></input>
                </div>
                <div className="case_sheet_5con_20">
                  <label>
                    Weight <span>:</span>
                  </label>
                  <input
                    type="text"
                    id="Weight"
                    name="Weight"
                    value={caseSheetformData.Weight}
                    onChange={handleChange}
                    className="lifecycle_h3udwh34_OIIIUI"
                  ></input>
                </div>
                <div className="case_sheet_5con_20">
                  <label>
                    BMI <span>:</span>
                  </label>
                  <input
                    type="text"
                    id="BMI"
                    name="BMI"
                    value={caseSheetformData.BMI}
                    onChange={handleChange}
                    className="lifecycle_h3udwh34_OIIIUI"
                  ></input>
                </div>
              </div>
              <br />

              <h4
                style={{
                  color: "var(--labelcolor)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "start",
                  padding: "5px",
                }}
              >
                Social Habits
              </h4>
              <br />
              <div className="checkboxState_stylenbxf_head">
                <div className="checkboxState_stylenbxf">
                  <label>
                    <input
                      type="checkbox"
                      name="none"
                      checked={checkboxState.none}
                      onChange={handleCheckboxChange}
                    />
                    None
                  </label>
                </div>

                <div className="checkboxState_stylenbxf">
                  <label>
                    <input
                      type="checkbox"
                      name="alcohol"
                      checked={checkboxState.alcohol}
                      onChange={handleCheckboxChange}
                    />
                    Alcohol
                  </label>
                </div>

                <div className="checkboxState_stylenbxf">
                  <label>
                    <input
                      type="checkbox"
                      name="tobacco"
                      checked={checkboxState.tobacco}
                      onChange={handleCheckboxChange}
                    />
                    Tobacco
                  </label>
                </div>
                <div className="checkboxState_stylenbxf">
                  <label>
                    <input
                      type="checkbox"
                      name="caffeine"
                      checked={checkboxState.caffeine}
                      onChange={handleCheckboxChange}
                    />
                    Caffeine
                  </label>
                </div>
                <div className="checkboxState_stylenbxf">
                  <label>
                    <input
                      type="checkbox"
                      name="narcotics"
                      checked={checkboxState.narcotics}
                      onChange={handleCheckboxChange}
                    />
                    Narcotics
                  </label>
                </div>
                <div className="checkboxState_stylenbxf">
                  <label>
                    <input
                      type="checkbox"
                      name="others"
                      checked={checkboxState.others}
                      onChange={handleCheckboxChange}
                    />
                    Others
                  </label>
                </div>
              </div>


              <br />


              <div className="case_sheet_5con ewdjk90_o">
                <div className="case_sheet_5con_20 jhnbjhse34">
                  <label>
                    Diagnosis <span>:</span>
                  </label>
                  <textarea
                    id="Diagnosis"
                    name="Diagnosis"
                    value={caseSheetformData.Diagnosis}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="case_sheet_5con_20 jhnbjhse34">
                  <label>
                    Doctor Notes <span>:</span>
                  </label>
                  <textarea
                    id="Doctornotes"
                    name="Doctornotes"
                    value={caseSheetformData.Doctornotes}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="case_sheet_5con_20 jhnbjhse34">
                  <label>
                    Procedure Advised <span>:</span>
                  </label>
                  <textarea
                    id="ProcedureAdvice"
                    name="ProcedureAdvice"
                    value={caseSheetformData.ProcedureAdvice}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <br />

              <div className="iuhdyuew78554">
                <div className="jdefueur_908">
                  <label>Doctor Signature & Seal</label>
                  <div className="signature-container_976"></div>
                </div>
              </div>

              <br />
            </div>

            <div className="uygftrhy_p3">
              <span className="">{ClinicDetails.ClinicName}</span>

              <span className="kughyftt4543">
                {ClinicDetails.doorNo +
                  "," +
                  ClinicDetails.street +
                  ClinicDetails.area +
                  "," +
                  ClinicDetails.city +
                  "," +
                  ClinicDetails.state +
                  "-" +
                  ClinicDetails.pincode}
              </span>

              <span className="">Contact Us : {ClinicDetails.phoneNo + " , "} {ClinicDetails.email}</span>

            </div>
           
          </div>
        </PrintContent>
      )}
    </>
  );
}

export default CaseSheet;
