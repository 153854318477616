import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

function ShiftDetails() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const navigate = useNavigate();

  const [rows, setRows] = React.useState([]);

  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = rows.length;
  const totalPages = Math.ceil(rows.length / 10);

  const [columns] = React.useState([
    { field: "shiftstartdate", headerName: "Shift Start Date", width: 150 },
    { field: "shiftenddate", headerName: "Shift End Date", width: 130 },
    { field: "shiftstarttime", headerName: "Shift Start Time", width: 150 },
    { field: "shiftendtime", headerName: "Shift End Time", width: 130 },
    { field: "shifthours", headerName: "Shift Hours", width: 100 },
    { field: "status", headerName: "Status", width: 130 },
  ]);

  useEffect(() => {
    fetch(
      `${urllink}HRmanagement/Get_shift_details?location=${userRecord?.location}&employeeid=${userRecord?.EmployeeId}`
    )
      .then((response) => response.json())
      .then((data) => {
        const Records = data.map((i, index) => ({
          id: index + 1,
          shiftstartdate: i.Shift_StartDate,
          shiftenddate: i.Shift_EndDate,
          shiftstarttime: i.Shift_StartTime,
          shiftendtime: i.Shift_EndTime,
          shifthours: i.ShiftHours,
          status: i.Status,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [userRecord?.location, userRecord?.EmployeeId]);

  columns[1].renderCell = ({ row }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        color: row.shiftenddate === "Tomorrow" ? "black" : "black",
        backgroundColor:
          row.shiftenddate === "Tomorrow"
            ? "var(--selectbackgroundcolor)"
            : "transparent", // Light green
      }}
    >
      {row.shiftenddate}
    </div>
  );

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  return (
    <>
      <Helmet>
        <title>Patient Queue List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h4>Shift Details</h4>
        </div>
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={rows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 0 && rows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {rows.length === 0 && (
          <div className="norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </>
  );
}

export default ShiftDetails;
