import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function VisitingDoctorBilling() {
  const Navigate = useNavigate();

  const visitingdoctorprocedure = useSelector(
    (state) => state.userRecord?.visitingdoctorprocedure
  );
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [formData, setFormData] = useState({
    doctorName: "",
    phoneNumber: "",
    email: "",
    location: "",
    specialist: "",
    experience: "",
    dateOfVisit: "",
    duration: "",
    treatmentProcedure: "",
    numberOfSessions: "",
    perCraftCharge: "",
    numberofcraftpersitting: "",
    totalcost: 0,
    hospitalPercentage: 0,
    doctorPercentage: 0,
  });

  const handlePerCraftChargeInputChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      perCraftCharge: value,
    }));
  };

  const handleDoctorValueChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,

      doctorPercentage: value,
    }));
    if (
      parseInt(value) + parseInt(formData.doctorPercentage) >
      parseInt(formData.totalcost)
    ) {
      if (formData.hospitalPercentage) {
        alert(`
                Total Cost is ${formData.totalcost}
                Hospital Value is  ${formData.hospitalPercentage}
                so Enter Below  ${
                  parseInt(formData.totalcost) -
                  parseInt(formData.hospitalPercentage)
                }`);
        setFormData((prev) => ({
          ...prev,
          doctorPercentage: "",
        }));
      } else {
        const HospitalPercentage = 0;
        alert(`
                Total Cost is ${formData.totalcost}
                so Enter Below ${
                  parseInt(formData.totalcost) - parseInt(HospitalPercentage)
                }`);
        setFormData((prev) => ({
          ...prev,
          doctorPercentage: "",
        }));
      }
    }
  };

  const handleHospitalValueChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      hospitalPercentage: value,
    }));

    if (
      parseInt(value) + parseInt(formData.doctorPercentage) >
      parseInt(formData.totalcost)
    ) {
      if (formData.doctorPercentage) {
        alert(`
            Total Cost is ${formData.totalcost}
            Doctor Value is  ${formData.doctorPercentage}
            so Enter Below ${
              parseInt(formData.totalcost) - parseInt(formData.doctorPercentage)
            }`);
        setFormData((prev) => ({
          ...prev,
          hospitalPercentage: "",
        }));
      } else {
        const DoctorPercentage = 0;
        alert(`
            Total Cost is ${formData.totalcost}
            so Enter Below ${
              parseInt(formData.totalcost) - parseInt(DoctorPercentage)
            }`);
        setFormData((prev) => ({
          ...prev,
          hospitalPercentage: "",
        }));
      }
    }
  };

  useEffect(() => {
    const numberOfSessions = parseFloat(formData.numberOfSessions) || 0;
    const numberofcraftpersitting =
      parseFloat(formData.numberofcraftpersitting) || 0;
    const PerCraftCharge = parseInt(formData.perCraftCharge) || 0;

    const numbercraftfortotalsittin =
      numberOfSessions * numberofcraftpersitting;

    console.group(numbercraftfortotalsittin);

    const totalCost = numbercraftfortotalsittin * PerCraftCharge;

    setFormData((prev) => ({ ...prev, totalcost: totalCost }));
  }, [
    formData.numberOfSessions,
    formData.numberofcraftpersitting,
    formData.perCraftCharge,
  ]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...(visitingdoctorprocedure || []) }));
  }, [visitingdoctorprocedure]);

  const handleSubmit = (e) => {
    const form = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && formData[key] !== undefined) {
        form.append(key, formData[key]);
      }
    });

    form.append("branchlocation", userRecord.location);
    form.append("createdby", userRecord.username);

    axios
      .post(`${urllink}VisitingDoctor/insert_VisitingDoctor_Billing`, form)
      .then((response) => {
        successMsg("Saved Successfully");
        Navigate("/Home/Visit-Doctor-Billing-List");
      })
      .catch((error) => {
        errmsg("Error occurred");
      });

    e.preventDefault();
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Visiting Doctor Billing</h4>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="doctorName">
              Visit Doctor <span> Dr.</span>
            </label>
            <select
              id="doctorName"
              name="doctorName"
              value={formData?.doctorName || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            >
              <option value="" disabled>
                Select Doctor
              </option>
              <option value="BalaChandran"> BalaChandran</option>
              <option value="Rajesh"> Rajesh</option>
              <option value="Hemalatha"> Hemalatha</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="phoneNumber">
              Phone Number <span>:</span>
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={formData?.phoneNumber || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="email">
              Email <span>:</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData?.email || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="location">
              Location <span>:</span>
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData?.location || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="specialist">
              Specialist <span>:</span>
            </label>
            <input
              type="text"
              id="specialist"
              name="specialist"
              value={formData?.specialist || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="experience">
              Experience <span>:</span>
            </label>
            <input
              type="text"
              id="experience"
              name="experience"
              value={formData?.experience || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="">
              Date of Visit <span>:</span>
            </label>
            <input
              type="text"
              id="dateOfVisit"
              name="dateOfVisit"
              value={formData?.dateOfVisit || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="duration">
              Duration <span>:</span>
            </label>
            <input
              type="text"
              id="duration"
              name="duration"
              value={formData?.duration || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="treatmentProcedure">
              Treatment Procedure <span>:</span>
            </label>
            <select
              id="treatmentProcedure"
              name="treatmentProcedure"
              value={formData?.treatmentProcedure || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            >
              <option value="">Select </option>
              <option value={formData.treatmentProcedure}>
                {formData.treatmentProcedure}
              </option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="numberOfSessions">
              Sitting / Session <span>:</span>
            </label>
            <input
              type="text"
              id="numberOfSessions"
              name="numberOfSessions"
              value={formData?.numberOfSessions || ""}
              onChange={handlePerCraftChargeInputChange}
              required
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="numberofcraftpersitting">
              No.of Craft Per Sitting / Session <span>:</span>
            </label>
            <input
              id="numberofcraftpersitting"
              name="numberofcraftpersitting"
              value={formData?.numberofcraftpersitting || ""}
              onChange={handlePerCraftChargeInputChange}
              required
              disabled
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="percraftcharge">
              Per Craft Charge <span>:</span>
            </label>
            <input
              id="percraftcharge"
              name="percraftcharge"
              value={formData?.perCraftCharge || ""}
              onChange={handlePerCraftChargeInputChange}
              required
              disabled
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="totalcost">
              Total Cost <span>:</span>
            </label>
            <input
              type="number"
              id="totalcost"
              name="totalcost"
              value={formData?.totalcost || ""}
              readOnly
              disabled
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="hospitalPercentage">
              Hospital Value <span>:</span>
            </label>
            <input
              type="text"
              id="hospitalPercentage"
              name="hospitalPercentage"
              value={formData?.hospitalPercentage || ""}
              onChange={handleHospitalValueChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="doctorPercentage">
              Doctor Value <span>:</span>
            </label>
            <input
              type="text"
              id="doctorPercentage"
              name="doctorPercentage"
              value={formData?.doctorPercentage || ""}
              onChange={handleDoctorValueChange}
              required
            />
          </div>
        </div>
      </div>
      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <ToastContainer />
      </div>
    </>
  );
}

export default VisitingDoctorBilling;
