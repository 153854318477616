const initstate = {
  UserData: {},
  isSidebarOpen: false,
  registerdata: [],
  forpatienteedit: [],
  toggled: false,
  isModalOpen: false,
  isModalShowVisible: false,
  forPatientData: [],
  SelectedPatientCalender: "",
  selectedRequestList: [],
  labformdata: [],
  Data: {},
  Selected_Patient_Pharmacy: [],
  foremployeedata: [],
  foruserregisteremployeedata: [],
  foremployeeedit: [],
  employeeIdget: [],
  selectedRowData: null,
  employeedata: [],
  formData: [],
  workbenchformData: {},
  foredituserregisteremployeedata: [],
  selectedSupplierIndex: null,
  selectedProductIndex: {},
  PurchaseInvoiceGRN: {},
  GRNedit: {},
  IndentRecieveListData: null,
  IndentIssueListData: null,
  IndentReturnListData: {},
  Supplierpay: null,
  vistingdoctordata: [],
  therapydata: [],
  for_counselor_navigation_data: null,

  Temp_testnamelist: null,

  SelectedPateintBilling: {},
  NurseData: null,
  NurseworkbenchformData: [],
  SelectedGeneralPateintBilling: {},
  SelectedVisitingPateintBilling: {},
  SelectedPateintDeuBilling: {},
  DataGnesis:[],
  Complaint:"",

  UrlLink: "https://gen.vesoft.co.in/"
  
   //UrlLink: "http://127.0.0.1:8000/"
};

const userRecorddata = (state = initstate, action) => {
  switch (action.type) {
    case "UserData":
      return { ...state, UserData: action.value };

    case "isSidebarOpen":
      return { ...state, isSidebarOpen: action.value };

    case "isModalShowVisible":
      return { ...state, isModalShowVisible: action.value };

    case "isModalOpen":
      return { ...state, isModalOpen: action.value };

    case "toggled":
      return { ...state, toggled: action.value };

    case "registerdata":
      return { ...state, registerdata: action.value };

    case "forpatienteedit":
      return { ...state, forpatienteedit: action.value };

    case "forPatientData":
      return { ...state, forPatientData: action.value };

    case "SelectedPatientCalender":
      return { ...state, SelectedPatientCalender: action.value };

    case "selectedRequestList":
      return { ...state, selectedRequestList: action.value };

    case "labformdata":
      return { ...state, labformdata: action.value };

    case "Data":
      return { ...state, Data: action.value };
    case "therapydata":
      return { ...state, therapydata: action.value };

    case "Selected_Patient_Pharmacy":
      return { ...state, Selected_Patient_Pharmacy: action.value };

    case "foremployeedata":
      return { ...state, foremployeedata: action.value };

    case "foruserregisteremployeedata":
      return { ...state, foruserregisteremployeedata: action.value };

    case "foremployeeedit":
      return { ...state, foremployeeedit: action.value };

    case "employeeIdget":
      return { ...state, employeeIdget: action.value };

    case "selectedRowData":
      return { ...state, selectedRowData: action.value };

    case "employeedata":
      return { ...state, employeedata: action.value };

    case "formData":
      return { ...state, formData: action.value };

    case "workbenchformData":
      return { ...state, workbenchformData: action.value };

    case "foredituserregisteremployeedata":
      return { ...state, foredituserregisteremployeedata: action.value };

    case "selectedSupplierIndex":
      return { ...state, selectedSupplierIndex: action.value };

    case "selectedProductIndex":
      return { ...state, selectedProductIndex: action.value };

    case "PurchaseInvoiceGRN":
      return { ...state, PurchaseInvoiceGRN: action.value };

    case "GRNedit":
      return { ...state, GRNedit: action.value };

    case "IndentRecieveListData":
      return { ...state, IndentRecieveListData: action.value };

    case "IndentIssueListData":
      return { ...state, IndentIssueListData: action.value };

    case "IndentReturnListData":
      return { ...state, IndentReturnListData: action.value };

    case "Supplierpay":
      return { ...state, Supplierpay: action.value };

    case "vistingdoctordata":
      return { ...state, vistingdoctordata: action.value };

    case "visitingdoctorprocedure":
      return { ...state, visitingdoctorprocedure: action.value };

    case "for_counselor_navigation_data":
      return { ...state, for_counselor_navigation_data: action.value };

    case "Temp_testnamelist":
      return { ...state, Temp_testnamelist: action.value };

    case "NurseData":
      return { ...state, NurseData: action.value };

    case "NurseworkbenchformData":
      return { ...state, NurseworkbenchformData: action.value };

    case "SelectedGeneralPateintBilling":
      return { ...state, SelectedGeneralPateintBilling: action.value };

    case "SelectedVisitingPateintBilling":
      return { ...state, SelectedVisitingPateintBilling: action.value };

    case "SelectedPateintDeuBilling":
      return { ...state, SelectedPateintDeuBilling: action.value };

    case "SelectedPateintVisitingDeuBilling":
      return { ...state, SelectedPateintVisitingDeuBilling: action.value };

    case "DataGnesis":
      console.log(action);
      return { ...state, DataGnesis:action.value};

    case "Complaint":
      console.log(action);
      return { ...state, Complaint:action.value};

      
        
    default:
      return state;
  }
};

export default userRecorddata;
