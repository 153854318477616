import React, { useState, useEffect } from "react";
import "../../Pettycash/Pettycash.css"
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { useSelector } from "react-redux";

function LabMasterone() {

  const [testname, settestname] = useState("");

  const [allTestName, setAllTestName] = useState([]);
  const [labmasterdata, setLabmasterData] = React.useState([]);
  const [packagename, setPackageName] = useState("");
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [selectedTests, setSelectedTests] = useState([]);

  const [packdata, setPackData] = useState([]);



  React.useEffect(() => {
    // Fetch role data from the backend and update the state

    fetchlabmasterData();
    handlegetpackagedata();

    // fetchdepartmentforrole();
  }, []);



  const fetchlabmasterData = () => {
    axios
      .get(`${urllink}usercontrol/get_lab_data`)
      .then((response) => {
        const data = response.data;

        setLabmasterData(data);
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/get_alltestname`)
      .then((response) => {
        setAllTestName(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [urllink]);






  const handleSubmitTwo = (e) => {
    e.preventDefault();
    const uppercasetestname = testname.toUpperCase();

    if (uppercasetestname !== "") {
      // Create a FormData object with the location name
      const formData = new FormData();
      formData.append("testname", uppercasetestname);

      axios
        .post(`${urllink}usercontrol/insert_labmaster`, formData)
        .then((response) => {
          // Handle the response as needed

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else {
            errmsg(response.data.error);
          }
          // Clear the form field after successful submission
          settestname("");

          // Fetch the updated location data
          fetchlabmasterData();
        })
        .catch((error) => {
          console.error("Error:", error);
          errmsg("Error occurred while inserting location.");
        });
    } else {
      userwarn("please enter the location name");
    }
  };

  const handleLocClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const testid = data.testid;
      Update_loc_fun(testid, status);
    } else {
      const status = "Inactive";
      const testid = data.testid;
      Update_loc_fun(testid, status);
    }
  };

  const Update_loc_fun = (testid, status) => {

    console.log('mjkl', testid, status)
    axios
      .post(`${urllink}usercontrol/update_status_lab`, {
        status,
        testid,
      })
      .then((res) => {
        fetchlabmasterData();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.error(err));
  };




  const handlepackagesubmit = () => {
    if (!packagename) {
      alert("Package name cannot be empty");
      return;
    }

    if (selectedTests.length === 0) {
      alert("You must add at least one test");
      return;
    }
    const formData = new FormData();
    formData.append("packagename", packagename);
    formData.append("testnames", JSON.stringify(selectedTests));

    axios
      .post(`${urllink}usercontrol/insert_packagemaster`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        successMsg(response.data.message);
        setSelectedTests([]);
      })
      .catch((error) => {
        console.error(error);
        errmsg("Error Occurred");
      });
  };

  const handleAddTest = () => {
    const selectedTest = allTestName.find(test => test.testname === testname);
  
    // Check if selectedTest exists and is not already in selectedTests
    if (selectedTest && !selectedTests.some(test => test.testid === selectedTest.testid)) {
      setSelectedTests([...selectedTests, selectedTest]);
    } else if (selectedTests.some(test => test.testid === selectedTest.testid)) {
      // If selectedTest already exists in selectedTests, show an alert
      alert('Test name is already added.');
    }
  
    settestname('');
  };
  
  

  const handleDeleteTest = (testid) => {
    setSelectedTests(selectedTests.filter(test => test.testid !== testid));
  };

  
  const handleview = (testpackage) => {
    axios
      .get(`${urllink}usercontrol/get_testnamepackage?packagename=${testpackage}`)
      .then((response) => {
        const data = response.data;
        console.log(data)
        setSelectedTests(data);
        setPackageName(testpackage)
        handlegetpackagedata();
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  const handlegetpackagedata = () => {
    axios
      .get(`${urllink}usercontrol/get_packagedata`)
      .then((response) => {
        setPackData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const handlestatuschange = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const testid = data.testid;
      const packagenamests=data.packagename
      Updatestatus(testid, status,packagenamests);
      
    } else {
      const status = "Inactive";
      const testid = data.testid;
      const packagenamests=data.packagename
      Updatestatus(testid, status,packagenamests);
    }
  };

  const Updatestatus = (testid, status,packagenamests ) => {

    console.log('mjkl', testid, status)
    axios
      .post(`${urllink}usercontrol/updatepackagedata`, {
        status,
        testid,
        packagenamests
      })
      .then((res) => {
        // Update selectedTests state with updated status
        const updatedTests = selectedTests.map((test) =>
          test.testid === testid ? { ...test, status } : test
        );
        setSelectedTests(updatedTests);
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.error(err));
  };


  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 400,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const errmsg = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <div className="appointment">
        <div className="ShiftClosing_Container">
          <div className="FirstpartOFExpensesMaster">
            <h2 style={{ textAlign: "center" }}>Lab Master</h2>

            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  Test Name <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="testname"
                  value={testname}
                  onChange={(e) => settestname(e.target.value)}
                  placeholder="Enter Test Name"
                />
              </div>
              <button className="btn_1" onClick={handleSubmitTwo}>
                <AddIcon />
              </button>
            </div>

            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Table</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2 ">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Test Name</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labmasterdata.map((row, index) => (
                      <tr key={index}>
                        <td>{row.testid}</td>
                        <td>{row.testname}</td>
                        <td>
                          <button
                            onClick={() => handleLocClick(row)}
                            className="Addnamebtn_pt2"
                          >
                            {row.status}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <br />


        <div className="ShiftClosing_Container">
          <div className="FirstpartOFExpensesMaster">
            <h2 style={{ textAlign: "center" }}>Lab Master</h2>

            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>
                  package Name <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="testname"
                  value={packagename}
                  onChange={(e) => setPackageName(e.target.value)}
                  placeholder="Enter Test Name"
                />
              </div>

              <div className="RegisForm_1 input-with-icon">
                <label htmlFor="packagename">
                  Test Name <span>:</span>
                </label>
                <div className="input-container">
                  <input
                    list="packagenameOptions"
                    id="packagename"
                    name="packagename"
                    value={testname}
                    onChange={(e) => settestname(e.target.value)}
                  />
                  <datalist id="packagenameOptions">
                    {allTestName.map((pack, index) => (
                      <option key={index} value={pack.testname} />
                    ))}
                  </datalist>
                </div>
              </div>
              <button className="btn_1" onClick={handleAddTest}>
                <AddIcon />
              </button>
              <button className="btn_1" onClick={handlepackagesubmit}>
                Submit
              </button>
            </div>


            {selectedTests && selectedTests.length > 0 && (
              <div
                style={{ width: "100%", display: "grid", placeItems: "center" }}
              >
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Test Name</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedTests.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{row.testname}</td>
                          <td>
                            {row.status ? ( // Check if status exists (for data from handleview)
                              <button onClick={()=>handlestatuschange(row)} className="Addnamebtn_pt2">{row.status}</button>
                            ) : ( // No status (for data from handleAddTest)
                              <button className="Addnamebtn_pt2" onClick={() => handleDeleteTest(row.testid)}>
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}


            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <h4>Table</h4>

              <div className="Selected-table-container ">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Package Name</th>
                      <th>View</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {packdata.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.testpackage}</td>
                        <td>
                          <button onClick={() => handleview(row.testpackage)} className="Addnamebtn_pt2">
                            View
                          </button>
                        </td>
                        <td>
                          <button onClick={() => handleDeleteTest(row.testid)} className="Addnamebtn_pt2">
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>



        <ToastContainer />
      </div>
    </>
  );
}

export default LabMasterone;
