import React, { useState, useEffect } from 'react';
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";  // Corrected import for EditIcon
import { useSelector } from "react-redux";

const ProductItemMaster = () => {
  const [prodType, setProdType] = useState({
    prod_id: '',
    productType: '',
    Status: 'Active'
  });
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [prodData, setProdData] = useState([]);
  const [isProdGet, setIsProdGet] = useState(false);
  const [isProdEdit, setIsProdEdit] = useState(false);

  useEffect(() => {
    fetchProdData();
  }, [isProdGet]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProdType({
      ...prodType,
      [name]: value.toUpperCase().trim()
    });
  };

  const handleSubmitProduct = () => {
    const data = {
      prod_id: prodType.prod_id,
      productType: prodType.productType,
      Status: prodType.Status
    };

    const url = `${urllink}SupplierMaster/${isProdEdit ? 'update_ProductType' : 'insert_ProductType'}`;

    axios.post(url, data)
      .then((response) => {
        console.log(response);
        fetchProdData();
        setIsProdGet(!isProdGet);

        setProdType({
          prod_id: '',
          productType: '',
          Status: 'Active'
        });
        setIsProdEdit(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStatusToggle = (row) => {
    const updatedStatus = row.Status === 'Active' ? 'Inactive' : 'Active';
    const data = {
      prod_id: row.prod_id,
      productType: row.productType,
      Status: updatedStatus
    };

    const url = `${urllink}SupplierMaster/update_ProductType`;

    axios.post(url, data)  // Corrected method name
      .then((response) => {
        console.log(response);
        setIsProdGet(!isProdGet);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditProd = (row) => {
    setIsProdEdit(true);
    const inputElement = document.getElementById("productType");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setProdType({
      prod_id: row.prod_id,
      productType: row.productType,
      Status: row.Status
    });
  };

  const fetchProdData = () => {
    axios
      .get(`${urllink}SupplierMaster/getProductData`)
      .then((response) => {
        const data = response.data;
        setProdData(data);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };

  const updateProductStatus = (prod_id, Status) => {
    axios
      .post(`${urllink}SupplierMaster/statusupdatefor_ProductType`, {
        Status,
        prod_id,
      })
      .then((res) => {
        console.log(res.data);
        fetchProdData();
        alert("Status Update Successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleProdClick = (data) => {
    const Status = data.Status === "Inactive" ? "Active" : "Inactive";
    const prod_id = data.prod_id;
    updateProductStatus(prod_id, Status);
  };

  return (
    <div className="ShiftClosing_Container">
      <div className="FirstpartOFExpensesMaster">
        <h2 style={{ textAlign: "center" }}>Product Type</h2>
        <div className="con_1">
          <div className="inp_1">
            <label htmlFor="productType" style={{ whiteSpace: "nowrap" }}>
              Product Type <span>:</span>
            </label>
            <input
              type="text"
              id="productType"
              name="productType"
              value={prodType.productType}
              onChange={handleInputChange}
              placeholder="Enter Product Type"
            />
          </div>
          <button className="btn_1" onClick={handleSubmitProduct}>
            {isProdEdit ? "Update" : "Add"}
          </button>
        </div>
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <h4>Table</h4>
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Product Type</th>
                  <th>Edit</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {prodData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.prod_id}</td>
                    <td>{row.productType}</td>
                    <td>
                      <button className="cell_btn" onClick={() => handleEditProd(row)}>
                        <EditIcon />
                      </button>
                    </td>
                    <td>
                      <button onClick={() => handleStatusToggle(row)} className="Addnamebtn_pt2">
                        {row.Status}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItemMaster;

