import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import "./PatientList.css";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function PatientList2() {
  const Navigate = useNavigate();

  const dispatchvalue = useDispatch();
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [columns] = React.useState([
    { field: "id", headerName: "Patient ID", width: 180 },
    { field: "firstName", headerName: "First Name", width: 180 },
    { field: "lastName", headerName: "Last Name", width: 180 },
    { field: "phone", headerName: "Phone No", width: 180 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "actions ",
      headerName: "View",
      width: 150,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
  ]);

  const [PatientData, setPatientData] = React.useState([]);
  // const [selectedRows, setSelectedRows] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const showdown = PatientData.length;
  const pageSize = 10;

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const totalPages = Math.ceil(PatientData.length / 10);

  // const handleSelectionModelChange = (selectionModel) => {
  //   setSelectedRows(selectionModel);
  // };

  const handleList = (params) => {
    // Extract the id from the selected row
    const selectedRowId = params.row.id;
    // Log the selected row id

    axios
      .get(`${urllink}patientmanagement/get_personal_info`)
      .then((response) => {
        const data1 = response.data;
        const foundPatient = data1.find(
          (patient) => patient.PatientID === selectedRowId
        );

        dispatchvalue({ type: "forPatientData", value: foundPatient });

        // Fetch communication address
        axios
          .get(`${urllink}patientmanagement/get_communication_address`)
          .then((response) => {
            const data2 = response.data;
            const patient_address = data2.find(
              (patient) => patient.PatientID === selectedRowId
            );

            axios
              .get(
                `${urllink}doctorsworkbench/get_vitalform1?selectedRowId=${selectedRowId}`
              )
              .then((response) => {
                const data3 = response.data;
                // const vital_data = data3.find(patient => patient.PatientID === selectedRowId);
                const vital = data3[0];

                axios
                  .get(
                    `${urllink}doctorsworkbench/get_treatment_forprofile?selectedRowId=${selectedRowId}`
                  )
                  .then((response) => {
                    const data4 = response.data;

                    const firstimge = data4.first_image;
                    const lastimg = data4.last_image;

                    axios
                      .get(
                        `${urllink}doctorsworkbench/get_patientphoto?selectedRowId=${selectedRowId}`
                      )
                      .then((response) => {
                        const data5 = response.data;

                        const PatientPhoto = data5.PatientPhoto;

                        axios
                          .get(`${urllink}doctorsworkbench/get_treatment`)
                          .then((response) => {
                            const data6 = response.data;
                            const filteredData = data6.filter(
                              (row) => selectedRowId === data5.PatientID
                            );

                            const history = filteredData.History;

                            axios
                              .get(
                                `${urllink}doctorsworkbench/get_emergencydetails_info1?selectedRowId=${selectedRowId}`
                              )
                              .then((response) => {
                                const data7 = response.data;

                                const EmergencyName = data7.Name;

                                axios
                                  .get(
                                    `${urllink}doctorsworkbench/get_allergiesname_forprofile?selectedRowId=${selectedRowId}`
                                  )
                                  .then((response) => {
                                    const data8 = response.data;

                                    const allergies = data8.allergies;

                                    axios
                                      .get(
                                        `${urllink}doctorsworkbench/get_previousvisit?selectedRowId=${selectedRowId}`
                                      )
                                      .then((response) => {
                                        const data9 = response.data;

                                        const previousvisit =
                                          data9.previousvisit;

                                        axios
                                          .get(
                                            `${urllink}doctorsworkbench/get_next_visit?selectedRowId=${selectedRowId}`
                                          )
                                          .then((response) => {
                                            const data10 = response.data;

                                            const Next_Appointment =
                                              data10.Next_Appointment;

                                            axios
                                              .get(
                                                `${urllink}doctorsworkbench/get_diseasename?selectedRowId=${selectedRowId}`
                                              )
                                              .then((response) => {
                                                const data11 = response.data;

                                                dispatchvalue = {
                                                  type: "forPatientData",
                                                  value: (prevInfo) => ({
                                                    ...prevInfo,
                                                    ...patient_address,
                                                    ...vital,
                                                    ...firstimge,
                                                    ...lastimg,
                                                    ...PatientPhoto,
                                                    ...history,
                                                    ...EmergencyName,
                                                    ...allergies,
                                                    ...previousvisit,
                                                    ...Next_Appointment,
                                                    ...data11,
                                                  }),
                                                };
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });

    Navigate("/PatientProfile");
  };

  const fetchPatientData = () => {
    // Fetch patient data from the backend
    fetch(`${urllink}doctorsworkbench/get_fortheraphist_patientlist`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data)) {
          // Assuming data is an array, adjust this condition based on the actual structure
          const lastTenRecords = data.slice(-10);
          const rows = lastTenRecords.map((userdata) => ({
            id: userdata.PatientID,
            firstName: userdata.FirstName,
            lastName: userdata.LastName,
            phone: userdata.PhoneNumber,
            email: userdata.Email,
          }));
          setPatientData(rows);
        } else {
          console.error("Fetched data is not as expected:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };

  useEffect(() => {
    // Fetch and set Patient data when the component mounts
    fetchPatientData();
  }, []);

  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    fetch(`${urllink}patientmanagement/get_personal_info`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          // Check if data is an array
          const filteredData = data.filter((user) => {
            const lowerCaseFirstName = user.FirstName.toLowerCase();
            const lowerCasePhoneNo = user.PhoneNumber.toString();

            const lowerCaseSearch = PatientFirstName.toLowerCase();
            const lowerCasePhoneSearch = PatientPhoneNo.toLowerCase();

            // Check if the search query matches the start of the name or phone number,
            // and ignore the empty field
            return (
              (PatientFirstName === "" ||
                lowerCaseFirstName.startsWith(lowerCaseSearch)) &&
              (PatientPhoneNo === "" ||
                lowerCasePhoneNo.startsWith(lowerCasePhoneSearch))
            );
          });

          if (filteredData.length === 0) {
            alert("No matching appointments found.");
          } else {
            const rows = filteredData.map((appointment) => ({
              id: appointment.PatientID, // Use a unique identifier or fallback to index
              firstName: appointment.FirstName,
              lastName: appointment.LastName,
              phone: appointment.PhoneNumber,
              email: appointment.Email,
            }));
            setPatientData(rows);
          }
        } else {
          console.error("Fetched data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Patient List</title>
      </Helmet>
      <div className="appointment">
        <div className="h_head">
          <h3>Patient List</h3>
        </div>
        <form onSubmit={handleSearch}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">First Name :</label>
              <input
                type="text"
                id="FirstName"
                value={PatientFirstName}
                onChange={(e) => setPatientFirstName(e.target.value)}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Phone No :</label>
              <input
                type="text"
                id="PhoneNo"
                value={PatientPhoneNo}
                onChange={(e) => setPatientPhoneNo(e.target.value)}
                placeholder="Enter the Phone No"
              />
            </div>
            <button className="btn_1" type="submit">
              <SearchIcon />
            </button>
          </div>
        </form>
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={PatientData.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              // onSelectionModelChange={handleSelectionModelChange}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
    </>
  );
}
