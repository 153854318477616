import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const OverallDayreport = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [Select_namedata, setSelect_namedata] = useState([]);

  const [formData, setFormData] = useState({
    BranchName: userRecord?.location,
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
    BillType: "",
    Billed_by: "",
  });
  const [formDataPrint, setFormDataPrint] = useState(null);

  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd");
    setFormData((prev) => ({
      ...prev,
      date: formattedDate,
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");

      if (value === "Day") {
        setFormData({
          ...formData,
          CurrentDate: formattedDate,
          FromDate: "",
          ToDate: "",
          [name]: value,
        });
      } else if (value === "Customs") {
        setFormData({
          ...formData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const dynamicColumns = [
    { field: "id", headerName: "S.NO", width: 60 },
    { field: "service_type", headerName: "service Type", width: 150 },
    { field: "Cash_Amount", headerName: "Cash Amount", width: 150 },
    { field: "Card_Amount", headerName: "Card Payment", width: 150 },
    { field: "OnlinePayment_Amount", headerName: "Online Payment", width: 120 },
    { field: "Total_Amount", headerName: "Collected Amount", width: 150 },
    { field: "RefundAmount", headerName: "Refund Amount", width: 120 },
    { field: "BalanceAmount", headerName: "Balance Amount", width: 120 },
  ];

  useEffect(() => {
    axios
      .get(`${urllink}Pettycash_link/Get_billed_by_Name`)
      .then((response) => {
        setSelect_namedata(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handletoSearch = () => {
    const params = {
      BranchName: formData.BranchName, // Replace with actual values or variables
      DateType: formData.DateType,
      CurrentDate: formData.CurrentDate,
      FromDate: formData.FromDate,
      ToDate: formData.ToDate,
      BillType: formData.BillType,
      Billed_by: formData.Billed_by || "all",
    };

    if (params.DateType === "" && params.BranchName === "") {
      alert("Enter Data");
    } else {
      const mergeData = (dataA, dataB) => {
        const serviceTypeMap = dataB.reduce((acc, item) => {
          acc[item.Service_Type] = item;
          return acc;
        }, {});

        return dataA.map((itemA) => ({
          ...itemA,
          ...serviceTypeMap[itemA.service_type],
        }));
      };

      // Fetch data from the first endpoint
      axios
        .get(`${urllink}Pettycash_link/get_billing_Amount_All_Transaction`, {
          params,
        })
        .then((response) => {
          const A_data = response.data.data.map((p, index) => ({
            id: index + 1,
            ...p,
          }));

          // Fetch data from the second endpoint
          axios
            .get(
              `${urllink}GeneralBilling/get_All_billing_data_history?selectedOption=${formData.DateType}&selectedDate=${formData.CurrentDate}&CostomsFromdate=${formData.FromDate}&CostomsTodate=${formData.ToDate}&location=${userRecord?.location}`
            )
            .then((response) => {
              const B_data = response.data.data.map((p, index) => ({
                id: index + 1,
                ...p,
              }));

              // Merge the data based on Service_Type
              const mergedData = mergeData(A_data, B_data);
              const Aftermerged = mergedData.map((p) => ({
                ...p,
                BalanceAmount: (+p.Total_Amount - +p.RefundAmount).toFixed(2),
              }));

              // Handle the merged data as needed

              setFilteredRows(Aftermerged);
              setFormDataPrint(formData);
              setFormData({
                BranchName: "Blossom",
                DateType: "",
                CurrentDate: "",
                FromDate: "",
                ToDate: "",
                BillType: "",
                Billed_by: "",
              });
            })
            .catch((error) => {
              console.error("Error fetching billing data:", error.message);
              // Handle errors, e.g., display an error message to the user.
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleExportToExcel = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { header: "ID", dataKey: "id" },
        { header: "Service Type", dataKey: "service_type" },
        { header: "Cash", dataKey: "Cash_Amount" },
        { header: "Card Payment", dataKey: "Card_Amount" },
        { header: "Online Payment", dataKey: "OnlinePayment_Amount" },
        { header: "Collected Amount", dataKey: "Total_Amount" },
        { header: "Refund Amount", dataKey: "RefundAmount" },
        { header: "Balance Amount", dataKey: "BalanceAmount" },
      ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...filteredRows.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
        `Location: ${formDataPrint.BranchName}`,
        formDataPrint.DateType === "Day"
          ? `Date : ${formDataPrint.CurrentDate}`
          : `From: ${formDataPrint.FromDate}  To: ${formDataPrint.ToDate}`,
        `Billed Type: ${
          formDataPrint.BillType ? formDataPrint.BillType : "All"
        }`,
        `Billed By: ${
          formDataPrint.Billed_by ? formDataPrint.Billed_by : "All"
        }`,
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, "Report.csv");
    } else {
      alert("No Data to Save");
    }
  };

  const handlePDF = () => {
    if (filteredRows.length !== 0) {
      const doc = new jsPDF();
      const columns = [
        { header: "ID", dataKey: "id" },
        { header: "Service Type", dataKey: "service_type" },
        { header: "Cash", dataKey: "Cash_Amount" },
        { header: "Card Payment", dataKey: "Card_Amount" },
        { header: "Online Payment", dataKey: "OnlinePayment_Amount" },
        { header: "Collected Amount", dataKey: "Total_Amount" },
        { header: "Refund Amount", dataKey: "RefundAmount" },
        { header: "Balance Amount", dataKey: "BalanceAmount" },
      ];
      const columnStyles = {
        id: { width: 10 },
        service_type: { width: 80 },
        Cash_Amount: { width: 80 },
        Card_Amount: { width: 80 },
        OnlinePayment_Amount: { width: 80 },
        Total_Amount: { width: 80 },
        RefundAmount: { width: 80 },
        BalanceAmount: { width: 80 },
      };
      let additionalInfo = "";
      let additionalInfo1 = "";
      if (formDataPrint) {
        if (formDataPrint.DateType === "Day") {
          additionalInfo = `Location: ${formDataPrint.BranchName} | Date : ${formDataPrint.CurrentDate}`;
          additionalInfo1 = `Billed Type: ${
            formDataPrint.BillType ? formDataPrint.BillType : "All"
          }  |  Billed By: ${
            formDataPrint.Billed_by ? formDataPrint.Billed_by : "All"
          } `;
        } else {
          additionalInfo = `Location: ${formDataPrint.BranchName} | From: ${formDataPrint.FromDate}  |  To: ${formDataPrint.ToDate} `;
          additionalInfo1 = `Billed Type: ${
            formDataPrint.BillType ? formDataPrint.BillType : "All"
          }  |  Billed By: ${
            formDataPrint.Billed_by ? formDataPrint.Billed_by : "All"
          } `;
        }
      }
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(12);

      doc.text(additionalInfo, pageWidth - 10, 20, { align: "right" });
      doc.text(additionalInfo1, pageWidth - 10, 10, { align: "right" });
      doc.setFontSize(14);

      doc.autoTable({
        columns,
        body: filteredRows,
        startY: 30,
        styles: columnStyles,
      });

      const pdfBlob = doc.output("blob");
      saveAs(pdfBlob, "data.pdf");
    } else {
      alert("No Data to save");
    }
  };

  const handlePrintToPDF = () => {
    if (filteredRows.length !== 0) {
      const doc = new jsPDF();
      const columns = [
        { header: "ID", dataKey: "id" },
        { header: "Service Type", dataKey: "service_type" },
        { header: "Cash", dataKey: "Cash_Amount" },
        { header: "Card Payment", dataKey: "Card_Amount" },
        { header: "Online Payment", dataKey: "OnlinePayment_Amount" },
        { header: "Collected Amount", dataKey: "Total_Amount" },
        { header: "Refund Amount", dataKey: "RefundAmount" },
        { header: "Balance Amount", dataKey: "BalanceAmount" },
      ];
      const columnStyles = {
        id: { width: 10 },
        service_type: { width: 80 },
        Cash_Amount: { width: 80 },
        Card_Amount: { width: 80 },
        OnlinePayment_Amount: { width: 80 },
        Total_Amount: { width: 80 },
        RefundAmount: { width: 80 },
        BalanceAmount: { width: 80 },
      };
      let additionalInfo = "";
      let additionalInfo1 = "";
      if (formDataPrint) {
        if (formDataPrint.DateType === "Day") {
          additionalInfo = `Location: ${formDataPrint.BranchName} | Date : ${formDataPrint.CurrentDate}`;
          additionalInfo1 = `Billed Type: ${
            formDataPrint.BillType ? formDataPrint.BillType : "All"
          }  |  Billed By: ${
            formDataPrint.Billed_by ? formDataPrint.Billed_by : "All"
          } `;
        } else {
          additionalInfo = `Location: ${formDataPrint.BranchName} | From: ${formDataPrint.FromDate}  |  To: ${formDataPrint.ToDate} `;
          additionalInfo1 = `Billed Type: ${
            formDataPrint.BillType ? formDataPrint.BillType : "All"
          }  |  Billed By: ${
            formDataPrint.Billed_by ? formDataPrint.Billed_by : "All"
          } `;
        }
      }
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(12);

      doc.text(additionalInfo, pageWidth - 10, 20, { align: "right" });
      doc.text(additionalInfo1, pageWidth - 10, 10, { align: "right" });
      doc.setFontSize(14);

      doc.autoTable({
        columns,
        body: filteredRows,
        startY: 30,
        styles: columnStyles,
      });

      // Get the data URI of the PDF
      const pdfDataUri = doc.output("datauristring");

      // Open the PDF in a new window for printing
      const printWindow = window.open();
      printWindow.document.write(
        `<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`
      );
    } else {
      alert("No Data to Print");
    }
  };

  return (
    <div className="ShiftClosing_over">
      <div className="ShiftClosing_Container">
        <div className="ShiftClosing_header">
          <h3>Overall Day Report</h3>
        </div>
        <div className="ShiftClosing_Invoice_container">
          <div className="ShiftClosing_Con">
            <div className="ShiftClosing_Con_row">
              <div className="ShiftClosing_Con_column">
                <label htmlFor="">
                  Branch Name <span>:</span>
                </label>
                <input
                  type="text"
                  name="BranchName"
                  value={formData.BranchName}
                  onChange={handleChange}
                />
              </div>
              <div className="ShiftClosing_Con_column">
                <label htmlFor="">
                  Date Type <span>:</span>
                </label>
                <select
                  name="DateType"
                  value={formData.DateType}
                  onChange={handleChange}
                >
                  <option value="">-Select-</option>
                  <option value="Day">Current Date</option>
                  <option value="Customs">Customize</option>
                </select>
              </div>
            </div>

            {formData.DateType === "Customs" && (
              <div className="ShiftClosing_Con_row">
                <div className="ShiftClosing_Con_column">
                  <label htmlFor="">
                    From Date <span>:</span>
                  </label>
                  <input
                    type="date"
                    name="FromDate"
                    value={formData.FromDate}
                    onChange={handleChange}
                  />
                </div>
                <div className="ShiftClosing_Con_column">
                  <label htmlFor="">
                    To Date <span>:</span>
                  </label>
                  <input
                    type="date"
                    name="ToDate"
                    value={formData.ToDate}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            <div className="ShiftClosing_Con_row">
              <div className="ShiftClosing_Con_column">
                <label htmlFor="">
                  Bill Type<span>:</span>
                </label>
                <select
                  name="BillType"
                  value={formData.BillType}
                  onChange={handleChange}
                >
                  <option value="">-Select-</option>
                  <option value="All">All</option>
                  <option value="Procedure">Procedure</option>
                  <option value="DoctorConsultation">Consultation</option>
                  <option value="PharmacyBilling">pharmacybilling</option>
                </select>
              </div>

              <div className="ShiftClosing_Con_column">
                <label htmlFor="">
                  Billed by<span>:</span>
                </label>
                <input
                  type="text"
                  name="Billed_by"
                  list="Billed_bys"
                  value={formData.Billed_by}
                  onChange={handleChange}
                />

                <datalist id="Billed_bys">
                  {Select_namedata.map((item, index) => (
                    <option key={index} value={item}></option>
                  ))}
                </datalist>
              </div>
            </div>
          </div>
        </div>
        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handletoSearch}>
            Search
          </button>
        </div>
      </div>
      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="IP_grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      {filteredRows.length !== 0 && (
        <div className="PrintExelPdf">
          <button onClick={handleExportToExcel}>Save Exel</button>
          <button onClick={handlePDF}> Save PDF</button>
          <button onClick={handlePrintToPDF}>Print PDF</button>
        </div>
      )}
    </div>
  );
};

export default OverallDayreport;
