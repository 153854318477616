import React, { useEffect, useState,useCallback} from 'react';
import { Helmet } from 'react-helmet';
import './RegisReqList.css';

import LoupeIcon from '@mui/icons-material/Loupe';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
function CancelAppointmentDialog({ open, onClose, onConfirm ,cancelReason,setCancelReason}) {

  

    const handleConfirm = () => {

      onConfirm(cancelReason);
    
      onClose();
      
    };
  
    const handleClose=()=>{
      setCancelReason('')
      onClose();
    }
  
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Cancel Register Reason</DialogTitle>
        <DialogContent>
          <TextField
            label="Cancellation Reason"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign:'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display:'flex !important',
              justifyContent:'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });


const RegisterList1 = () => {
    const dispatchvalue = useDispatch();
    const urllink = useSelector((state) => state.userRecord?.UrlLink);
    const SelectedPatientCalender = useSelector(state => state.userRecord?.SelectedPatientCalender);
    console.log("SelectedPatientCalender",SelectedPatientCalender);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedId, setselectedId] = useState(null);
    const [cancelReason, setCancelReason] = useState('');
    const [rows, setRows] = useState([]);
   const navigate = useNavigate();
   const [filteredRows, setFilteredRows] = useState([]);
   console.log(filteredRows)
   const [page, setPage] = useState(0);
   const pageSize = 10;
   const showdown = filteredRows.length;
   const totalPages = Math.ceil(filteredRows.length / 10);
   const [searchQuery,setSearchQuery] = useState("");
   const [searchQuery1,setSearchQuery1] = useState("");
   const userRecord=useSelector(state=>state.userRecord?.UserData)
   const columns = [
    { field: 'id', headerName: 'Appointment Id', width: 150},
    { field: 'PatientName', headerName: 'Patient Name', width: 150},
    { field: 'age', headerName: 'Age', width: 150},
    { field: 'gender', headerName: 'Gender', width: 130 },
    { field: 'consultation_type', headerName: 'consultation Type', width: 160},
    { field: 'PhoneNo', headerName: 'PhoneNo', width: 130},
    { field: 'address', headerName: 'Address', width: 150},
    { field: 'Source', headerName: 'Source', width: 150},
    { field: 'Status', headerName: 'Status', width: 130},
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <>
          <Button className='cell_btn'
           onClick={() => handleCancel(params)}
           >
            <HighlightOffSharpIcon  />
          </Button>
          </>
      ),   
    },

  ];
  const handleCancel = (params) => {   

    console.log("params",params);
    const selectedId = params.row.id;
    console.log('Selected ID:', selectedId);
    setselectedId(selectedId)
    setOpenDialog(true);    
   
  };
  const handleCancelAppointment = () => {
    console.log('Cancellation Reason:', cancelReason);
    console.log(selectedId)
    
    const confirmation = window.confirm('Are you sure you want to cancel the appointment?');
    console.log(confirmation);

    if (confirmation) {
      const data = new FormData();
       data.append("selectedId", selectedId);
      data.append("cancelReason", cancelReason);
   
    axios.post(`${urllink}appointmentmanagement/update_appointment_patientrequest_genisis`, data)
  .then(response => {
    
    handleFetchData();
    console.log("jhg",response);
   
    

  })
    .catch((error) => {
      console.error('Error updating record:', error);
    });
    setCancelReason('')
    setOpenDialog(false)
    }else{
      setCancelReason('')
      setOpenDialog(false)
    }
    
  };
//   const handleRegisterForm = () => {
//     navigate('/Home/Register');
//     dispatchvalue({type:'selectedRequestList',value: null });
//   };
  // const handleRegisterFormNew = () => {
  //   navigate('/Home/RegisterFormNew');
  // };

  
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };
  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.PatientName.toLowerCase();
      const lowerCasePhoneNo = row.PhoneNo.toString();
  
      const startsWithFirstName = lowerCaseSupplierName.startsWith(searchQuery.toLowerCase());
      const startsWithPhoneNo = lowerCasePhoneNo.startsWith(searchQuery1.toLowerCase());
  
      return (startsWithFirstName || !searchQuery) && (startsWithPhoneNo || !searchQuery1);
    });
  
    // If there is a search query, sort the data to bring the left-to-right matching rows to the top
    const sortedData = searchQuery || searchQuery1
      ? [...filteredData].sort((a, b) => {
          const aStartsWith = a.PatientName.toLowerCase().startsWith(searchQuery.toLowerCase()) || a.PhoneNumber.toString().toLowerCase().startsWith(searchQuery1.toLowerCase());
          const bStartsWith = b.PatientName.toLowerCase().startsWith(searchQuery.toLowerCase()) || b.PhoneNumber.toString().toLowerCase().startsWith(searchQuery1.toLowerCase());
  
          return bStartsWith - aStartsWith;
        })
      : filteredData;
  
    setFilteredRows(sortedData);
    setPage(0);
  }, [searchQuery, searchQuery1, rows]);
  const highlightSearchTerm = (text, searchQuery, searchQuery1) => {
    if (!text || (!searchQuery && !searchQuery1)) {
      return text;
    }
  
    const lowerCaseText = typeof text === 'string' ? text.toLowerCase() : text.toString().toLowerCase();
    const lowerCaseSearchTerm = searchQuery ? searchQuery.toString().toLowerCase() : '';
    const lowerCaseSearchTerm1 = searchQuery1 ? searchQuery1.toString().toLowerCase() : '';
  
    let highlightedText = text.toString();
  
    if ((lowerCaseSearchTerm && typeof lowerCaseText === 'string') || (lowerCaseSearchTerm1 && typeof lowerCaseText === 'string')) {
      const searchTerm = lowerCaseSearchTerm || lowerCaseSearchTerm1;
      const startIndex = lowerCaseText.indexOf(searchTerm);
  
      if (startIndex === 0) { // Check if the search term starts from the beginning
        const endIndex = startIndex + searchTerm.length;
        highlightedText = (
          <>
            <span style={{ backgroundColor: 'var(--ProjectColor)', fontWeight: 'bold',padding: '2px 0px' }}>
              {lowerCaseText.substring(0, endIndex)}
            </span>
            {lowerCaseText.substring(endIndex)}
          </>
        );
      }
    }
  
    return highlightedText;
  };



  const handleFetchData = useCallback(() => {
    axios.get(`${urllink}appointmentmanagement/get_request_genisis?location=${userRecord?.location}`)
      .then((response) => {
        console.log("responsegenesis", response.data);
        const data = response.data;

        const Records = data.map((userdata) => ({
          id: userdata.AppointmentId,
          PatientName: userdata.fullName,
          gender: userdata.gender,
          age: userdata.age,
          consultation_type: userdata.consultation_type,
          PhoneNo: userdata.phoneNo,
          address: userdata.address,
          Source: userdata.Source,
          Status: userdata.Status,
          Location: userdata.location,
          appointment_patientrequestID: userdata.appointment_patientrequestID
        }));

        if (SelectedPatientCalender) {
          const newrecords = Records.filter((p) => SelectedPatientCalender === p.PatientName);
          setRows(newrecords);
        } else {
          setRows(Records);
        }

      })
      .catch((error) => {
        console.error('Error fetching patient data:', error);
      });
  }, [userRecord, SelectedPatientCalender]); 

  useEffect(()=>{
    handleFetchData()
  },[userRecord?.location])
  useEffect(() => {
    handleFetchData();
  }, [userRecord, SelectedPatientCalender]);

  useEffect(()=>{
    axios.get( `${urllink}appointmentmanagement/get_request_genisis?location=${userRecord?.location}`)
    .then((response)=>{
        console.log("datasgenesis",response.data);
        const Records = response.data.map((userdata)=>({
            id: userdata.AppointmentId,
            PatientName: userdata.fullName,
            gender: userdata.gender,
            age:userdata.age,
            consultation_type: userdata.consultation_type,
            PhoneNo: userdata.phoneNo,
            address:userdata.address,
            Source:userdata.Source,
            Status:userdata.Status,
           
        }));
        if(SelectedPatientCalender){
            const newrecords =Records.filter((p)=>SelectedPatientCalender  ===p.PatientName)
            setRows(newrecords);
          }else{
            setRows(Records);
          }
    })
    .catch((error) => {
        console.error('Error fetching patient data:', error);
      });
  },[SelectedPatientCalender]);


  return (
    <>
     <Helmet>
        <title>Register List Genesis</title>
      </Helmet> 
      <div className="appointment">
        <div className="h_head">
          <h4>Register List Genesis</h4>
        </div>
      
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">First Name <span>:</span></label>
            <input
              type="text"
              id="FirstName"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Phone No <span>:</span></label>
            <input
              type="number"
              id="PhoneNo"
              value={searchQuery1}
              onChange={handleSearchChange}
              placeholder="Enter the Phone No"
            />
          </div>
        

          {/* <button
            className="btn_1"
            onClick={() => handleRegisterFormNew()}
            title="New Register"
            style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}}
          >
            <LoupeIcon />New
          </button> */}
        </div>
        
      
        <ThemeProvider theme={theme}>
          <div className="grid_1">
           
             <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )}
                columns={columns}
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        <CancelAppointmentDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleCancelAppointment}
        setCancelReason={setCancelReason}
        cancelReason={cancelReason}
      />

      </div>
    </>
  )
}

export default RegisterList1


