import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
// import LoupeIcon from "@mui/icons-material/Loupe";
import axios from "axios";
// import Modal from "@mui/material/Modal";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const LabQueueList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [summa, setsumma] = useState([]);

  // const [openModal3, setOpenModal3] = React.useState(false);
  // const [selectedImage, setSelectedImage] = React.useState(null);

  // const [labformdata,setlabformdata] = useState([])

  const dispatchvalue = useDispatch();

  const [filteredRows, setFilteredRows] = useState([]);

  const reduxvalueUsercord = useSelector((state) => state);
  const userRecord = reduxvalueUsercord.userRecord?.UserData;

  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const handleRequestProceed = (params) => {
    const patientid = params.row.PatientID;
    const visitNo = params.row.visitno;

    axios
      .get(
        `${urllink}appointmentmanagement/get_appointments?location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;

        const matchingAppointment = response.data.find(
          (appointment) => appointment.PatientID === patientid
        );


        const data11 = {
          SerialNo: matchingAppointment.appointment_patientregisterID,
          PatientID: matchingAppointment.PatientID,
          AppointmentID: matchingAppointment.AppointmentID,
          visitNo: matchingAppointment.VisitID,
          firstName: matchingAppointment.FirstName,
          lastName: matchingAppointment.LastName,
          DoctorName: matchingAppointment.DoctorName,
          Status: matchingAppointment.Status,
        };

        dispatchvalue({ type: "labformdata", value: data11 });
        dispatchvalue({ type: "NurseworkbenchformData", value: [] });

        navigate("/Home/Lab-ReportFN");
      })
      .catch((error) => {
        // Handle the error, e.g., show an error message to the user
      });
  };

  // const handleCloseModal = () => {
  //   setOpenModal3(false);
  // };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}labtest/get_for_labqueuelist?location=${userRecord?.location}`
      )
      .then((response) => {
        const Datas = response.data;

        setsumma(
          Datas.map((row,index) => ({
            id: index+1,
            PatientID: row.PatientID,
            visitno: row.visitno,
            PatientName: row.lab_patient_Name,
            DoctorName: row.DoctorName,
            TestName: row.Temp_testnamelist.map((test) => test.Test_name).join(
              ", "
            ), // Extract test names
            Date: row.Date,
          }))
        );
      })
      .catch((error) => {
        // Handle the error, e.g., show an error message to the user
      });
  }, [userRecord?.location]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.PatientName.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  //   const handleRequestForm = () => {
  //     navigate("/Register_concern_Form");
  //   };

  // Define the columns dynamically
  const dynamicColumns = [
    { field: "visitno", headerName: "VisitID", width: 100 },
    { field: "PatientID", headerName: "PatientID", width: 130 },
    { field: "PatientName", headerName: "Patient Name", width: 180 },
    { field: "DoctorName", headerName: "Doctor Name", width: 150 },
    { field: "Date", headerName: "Date", width: 100 },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestProceed(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Lab Report List</h4>
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Patient Name <span>:</span>
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Patient Name"
            />
          </div>
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                // rows={summa}
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>

        {/* <Modal open={openModal3} onClose={handleCloseModal}>
          <div className="pdf_img_show">
            {selectedImage &&
            !selectedImage.startsWith("data:application/pdf;base64,") ? (
              <div>
                <img
                  src={selectedImage}
                  alt="ConsentForm"
                  style={{ width: "40%", height: "40%", marginTop: "80px" }}
                />
                <Button
                  onClick={handlePrint}
                  style={{ marginTop: "10px" }}
                  className=""
                >
                  Print
                </Button>
              </div>
            ) : null}

            <div className="jhuhhjh">
              <Button
                onClick={handleCloseModal}
                style={{ color: "white" }}
                className="clse_pdf_img"
              >
                <HighlightOffIcon
                  style={{
                    fontSize: "40px",
                    backgroundColor: "hsl(33, 100%, 50%)",
                    borderRadius: "40px",
                  }}
                />
              </Button>
            </div>
          </div>
        </Modal> */}
      </div>
    </>
  );
};
export default LabQueueList;
