import React, { useState, useEffect } from 'react';
import './AppointmentCalendar.css'; // Import your CSS file
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {format} from 'date-fns';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Days = ( ) => {

  const dispatchvalue = useDispatch();


  const [currentMonth] = useState(new Date());
  const [displayedDate, setDisplayedDate] = useState(currentMonth);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDoctor, setSelectedDoctor] = useState('all');
  const [Showthelist, setShowthelist] = useState('Register');
  const [dayAppointments, setDayAppointments] = useState([]);
  const [counts, setcounts] = useState({});
  const [Doctors, setDoctors] = useState([]);

  const userRecord=useSelector(state=>state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);
  
  const navigate =useNavigate()

  useEffect(() => {
    axios.get(`${urllink}usercontrol/get_doctor_info?location=${userRecord?.location}`)
      .then((response) => {
        setDoctors(response.data)
      })
      .catch((error) => {
      })
  }, [userRecord,userRecord?.location])

  useEffect(() => {
    axios 
      .get(`${urllink}appointmentmanagement/get_doctor_slots_per_day?doctor_name=${selectedDoctor}&date=${format(displayedDate, 'yyyy-MM-dd')}&location=${userRecord?.location}`)
      .then((response) => {
        const data = response.data;
        
        if (data.length === 1) {
          setDayAppointments(data);
          setcounts({
            Total: data[0].no_of_slot,
            Available: data[0].AvailableSlot,
            Booked: data[0].Registerslot,
            Canceled: data[0].Canceledslot,
            Requested: data[0].Requestslot,
          });
        } else {
          // Combine all data when length is not 1
          const combinedData = data.reduce((acc, doctorData) => {
            acc.Register_patient_data.push(...doctorData.Register_patient_data);
            acc.Request_patient_data.push(...doctorData.Request_patient_data);
            acc.Canceled_Register_patient_data.push(...doctorData.Canceled_Register_patient_data);
            acc.no_of_slot += doctorData.no_of_slot;
            acc.AvailableSlot += doctorData.AvailableSlot;
            acc.Registerslot += doctorData.Registerslot;
            acc.Canceledslot += doctorData.Canceledslot;
            acc.Requestslot += doctorData.Requestslot;
            return acc;
          }, {
            Register_patient_data: [],
            Request_patient_data: [],
            Canceled_Register_patient_data: [],
            no_of_slot: 0,
            AvailableSlot: 0,
            Registerslot: 0,
            Canceledslot: 0,
            Requestslot: 0,
          });
  
          setDayAppointments([combinedData]);
          setcounts({
            Total: combinedData.no_of_slot,
            Available: combinedData.AvailableSlot,
            Booked: combinedData.Registerslot,
            Canceled: combinedData.Canceledslot,
            Requested: combinedData.Requestslot,
          });
        }
      })
      .catch((error) => {
      });
  }, [selectedDate, displayedDate, selectedDoctor,userRecord,userRecord?.location]);
  
  

  
  const changeStructure = () => {
  
  
    const appointments = dayAppointments[0];  
   
    const renderNamesOnly = (appointmentsData, list) => {
      if ( appointmentsData && appointmentsData.length > 0) {
        return (
          <div className='appointment_details_week'>
            <div className="app_week_det_1">
              {appointmentsData.map((row, index) => (
                <div key={index} className="app_week_det_2" onClick={() => handlePatients(row.patientname)}>
                  <span>{index + 1}.</span>
                  <span style={{ color: "#000", fontSize: "14px" ,width:'calc(100% - 40px)',textAlign:'center'}}>{row.patientname}</span>
                  {list === 'Register'&&
                    <span  style={{color:'grey'}}> {row.time}</span>
                  }
                </div>
              ))}
            </div>
          </div>
        );
      } else {
        return (
          <div className='appointment_details_week'>
          <div className="app_week_det_1">
            <div className='app_week_det_2' style={{textAlign:'center',width:'auto'}}>
            {list === 'Cancelled'
              ? 'No Cancelled list for this date.'
              : list === 'Request'?'No appointment Request for this date.': 'No appointment Register for this date.'}
            </div>
           
          </div>
          </div>
        );
      }
    };
  

      if (Showthelist === 'Cancelled') {
        return renderNamesOnly(appointments?appointments.Canceled_Register_patient_data:[], Showthelist);
      } else if (Showthelist === 'Request') {
        return renderNamesOnly(appointments?appointments.Request_patient_data:[], Showthelist);
      } else if (Showthelist === 'Register'){
        return renderNamesOnly(appointments?appointments.Register_patient_data:[],Showthelist);
      }
         
  };

  useEffect(()=>{
    changeStructure()
  },[Showthelist])

  const handlePreviousDay = () => {
    const previousDay = new Date(displayedDate);
    previousDay.setDate(displayedDate.getDate() - 1);

    if (previousDay.getMonth() === currentMonth.getMonth()) {
      setDisplayedDate(previousDay);
      setShowthelist('Register')
    }
  };

  const handleNextDay = () => {
    const nextDay = new Date(displayedDate);
    nextDay.setDate(displayedDate.getDate() + 1);

    if (nextDay.getMonth() === currentMonth.getMonth()) {
      setDisplayedDate(nextDay);
      setShowthelist('Register')
    }
  };
  const handlePatients=(patient)=>{
    if(Showthelist ===  'Register'){
      navigate('/Home/Appoinment-RegisterList')

       dispatchvalue({type:'SelectedPatientCalender' , value: (patient) })
    }else if(Showthelist === 'Request'){
      navigate('/Home/Appoinment-RequestList')

      dispatchvalue({type:'SelectedPatientCalender' , value: (patient) })
    }
  }
  return (
    <div className="calendar-container">
      <div className="calendar">
        <div className="cal_mon_1">
          <button onClick={handlePreviousDay}>
            <KeyboardDoubleArrowLeftIcon />
          </button>
          <h3>{displayedDate.toDateString()}</h3>
          <button onClick={handleNextDay}>
            <KeyboardDoubleArrowRightIcon />
          </button>
        </div>
        <div className="select_items_appointment">
          <div className="doctor_select appoint_booked_count">
            <label htmlFor="names">Doctor Name <span>:</span></label>
            <select
              name="Doctor Name"
              onChange={(e) => {
                setSelectedDate(new Date());
                setSelectedDoctor(e.target.value);
              }}
              value={selectedDoctor}
            >
              <option value="all">Select</option>
              {Doctors.map((p, index) => (
                <option key={index} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </div>
          <div className="appoint_booked_count">
            <label>
            Register <span>:</span><span>{counts.Booked}</span>
            </label>
          </div>
        </div>
      </div>
      
        <div className="appointment_content">

          {/* <div className="app_d_week2">
            <h3>Total Appointments <span>:</span> </h3>
            <p> {counts.Total}</p>
          </div>
          <div className="app_d_week2">
            <h3>Available Slot <span>:</span> </h3>
            <p> {counts.Available}</p>
          </div> */}
          <div className="app_d_week_1" onClick={()=>setShowthelist('Register')}>
            <h4>Registered Slot <span>:</span> </h4>
            <p> {counts.Booked}</p>
          </div>
          <div className="app_d_week_1"  onClick={()=>setShowthelist('Request')}>
            <h4>Requested Slot <span>:</span> </h4>
            <p> {counts.Requested}</p>
          </div>
          <div className="app_d_week_1"  onClick={()=>setShowthelist('Cancelled')}>
            <h4>Canceled Slot<span>:</span> </h4>
            <p> {counts.Canceled}</p>
          </div>
          
          <h4 style={{ color: 'var(--ProjectColor)',marginTop:'15px' }}>{Showthelist} List</h4>
             {changeStructure()}

        </div>
       
    </div>
  );
};

export default Days;
